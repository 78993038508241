import { IHasOwner, IIdName } from "./id-name";
import { IDeletedParam, IQueryStrictParams, IRequestParams } from "./page";
import { IStorageNode, StorageNodeSortField, StorageNodeStatus, StorageProvider, storageProviderDict, StorageType } from "./storage-node";

type IStorageNodeCommon = Pick<IStorageNode<string>, 'id' | 'name' | 'region' | 'props'>;

export interface IStorageNodeV2 extends IStorageNodeCommon, IHasOwner {
  bucket?: string;
  storageType: StorageProviderType;
  replacedByStorageNodeId: string;

  status: StorageNodeStatus;
  types?: StorageType[];
  secret?: string;

  storagePools?: IIdName<string>[];
}

export type StorageNodeV2ListItem = Omit<IStorageNodeV2, 'props'>;

export type StorageProviderType = "S3" | "GCS" | "ABS" | "s3p";

const renamedFields: { [fieldV1: string]: string } = {
  'replacedByStorageNodeID': 'replacedByStorageNodeId',
  'StorageType': 'storageType'
}

export function getReplacedByStorageNodeId(node: IStorageNode | IStorageNodeV2): string | number | null {
  return getField(node, 'replacedByStorageNodeID');
}

export function setReplacedByStorageNodeId(node: IStorageNode | IStorageNodeV2, value: string | number): void {
  if (typeof node.id === 'number') {
    node['replacedByStorageNodeID'] = value;
  }
  else {
    node[renamedFields['replacedByStorageNodeID']] = value;
  }
}

export function getStorageProvider(node: IStorageNode | IStorageNodeV2): StorageProvider {
  if ('StorageTypeID' in node) {
    return node.StorageTypeID;
  }

  return storageProviderDict[node.storageType];
}

export function getBucketContainer(node: IStorageNode | IStorageNodeV2): string {
  if ("bucket" in node) {
    return node.bucket;
  }

  let storageProvider = getStorageProvider(node);
  let bucketField = storageProvider === StorageProvider.ABS ? 'container' : 'bucket';
  let props = !!node.props && JSON.parse(node.props);
  return props[bucketField];
}

export function getStorageProviderType(storageProvider: StorageProvider): StorageProviderType {
  return Object.keys(storageProviderDict).find(k => storageProviderDict[k] === storageProvider) as StorageProviderType;
}

function getField<T>(node: IStorageNode | IStorageNodeV2, fieldName: string): T | null {
  if (typeof node.id === 'number') {
    return node[fieldName];
  }
  return node[renamedFields[fieldName]];
}

export interface IStorageNodeV2FilterParams extends IQueryStrictParams {
  providers?: StorageProviderType[];
  pool?: string;
}

export interface IStorageNodesV2RequestParams extends
    IRequestParams<StorageNodeSortField>,
    IStorageNodeV2FilterParams,
    IDeletedParam {
  fields?: (keyof IStorageNodeV2)[];
}

export const defaultStorageNodeFields: (keyof IStorageNodeV2)[] = ['id', 'name', 'status', 'replacedByStorageNodeId', 'storageType'];
