import React, { PropsWithChildren } from "react";
import { QueryPropertyType, QueryTypeMap } from "../../../../../utils/query-utils";
import { CompactViewScreen } from "../../../../shared-ui/common/component-props";
import IOption from "../../../../shared-ui/common/option";
import { FiltersBar } from "../../../../shared-ui/filters-bar/filters-bar";
import { FiltersBarItem } from "../../../../shared-ui/filters-bar/filters-bar-item";
import { IInvitationFilterParams, InvitationStatus } from "../../../../../api/entities/invitation";
import { InvitationStatusFilter } from "./invitation-status-filter";

export interface IInvitationFilters {
    searchText: string;
    statusFilter: IOption<InvitationStatus>;
}

export const defaultFilters: IInvitationFilters = {
    searchText: null,
    statusFilter: null,
};

export const filterNames = {
    searchText: "",
    statusFilter: "Status",
};

export const invitationFilterTypeMap: QueryTypeMap<Omit<IInvitationFilterParams, 'strict'>> = {
    q: QueryPropertyType.String,
    status: QueryPropertyType.String,
}

export interface IInvitationFiltersProps {
    filters: IInvitationFilters;
    onFiltersChange: (filters: Partial<IInvitationFilters>) => void;
}

export function InvitationFilters(props: PropsWithChildren<IInvitationFiltersProps>) {

    function handleFilterChange(newFilterValues: Partial<IInvitationFilters>) {
        props.onFiltersChange({ ...props.filters, ...newFilterValues });
    }

    return (
        <FiltersBar
            testId="invitation-filter-bar"
            searchValue={props.filters.searchText}
            onSearchValueChanged={searchText => handleFilterChange({ searchText })}
            compactViewScreen={CompactViewScreen.Medium}
        >
            <FiltersBarItem>
                <InvitationStatusFilter
                    value={props.filters.statusFilter?.value}
                    onChange={(status) => handleFilterChange({ statusFilter: { value: status } })}
                />
            </FiltersBarItem>

            {!!props.children && <FiltersBarItem>{props.children}</FiltersBarItem>}
        </FiltersBar>
    );
}
