import { IItem, IUpload2Request, IUpload2Response } from "../entities/item";
import { CitrixShareFileService } from "./citrix-share-file-service";

export class ItemsService extends CitrixShareFileService {
  getHomeFolder(): Promise<IItem> {
    return this.api.get("Items");
  }

  upload2(folderId: string, uploadRequest: IUpload2Request): Promise<IUpload2Response> {
    return this.api.post(`Items(${folderId})/Upload2`, uploadRequest);
  }
}
