import React, { useEffect, useState } from "react";
import { useApi } from "../../../utils/hooks/use-api";
import IOption from "../../shared-ui/common/option";
import { Select } from "../../shared-ui/select";

export interface IFilterProps<TValue extends number | string, TItem extends TValue | IOption<TValue>> {  
  value: TItem;
  autoWidth?: boolean;
  className?: string;
  onChange: (value: TItem) => void;
  onLabelResolved?: (value: IOption<TValue>) => void;
}

export interface ISelectFilterProps<TValue extends number | string, TItem extends TValue | IOption<TValue>> extends IFilterProps<TValue, TItem> {
  testId: string;
  label: string;
  optionAsValue?: boolean;
  options: () => Promise<IOption<TValue>[]>;
}

export function SelectFilter<TValue extends number | string, TItem extends TValue | IOption<TValue>>(props: ISelectFilterProps<TValue, TItem>) {
  const [options, setOptions] = useState<IOption<TValue>[]>(null);
  const [api] = useApi();
  const value = props.optionAsValue ? (props.value as IOption<TValue>)?.value : props.value as TValue;

  useEffect(() => {
    api(props.options()).then((options) => {
      setOptions([
        { value: null, label: "All" },
        ...options
      ]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value && options) {      
      if (!options.some(o => o.value === value)) {
        props.onChange(null);
      }    
      else if (props.onLabelResolved && props.optionAsValue && !(props.value as IOption<TValue>).label) {
        props.onLabelResolved(options.find(o => o.value === value))
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value]);

  function handleChange(value: TValue): void {
    if (props.optionAsValue) {
      props.onChange((options.find(o => o.value === value) || null) as TItem);
    }
    else {
      props.onChange(value as TItem);
    }
  }

  return (
    <Select
      testId={props.testId}
      label={props.label}
      value={value}
      onChange={handleChange}
      options={options || []}
      autoWidth={props.autoWidth}
      className={props.className}
    />
  );
}