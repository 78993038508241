import React from "react";
import MoreOptions from "../../../shared-ui/more-options";
import { IPopupMenuOption } from "../../../shared-ui/popup-menu";
import { IStoragePoolNode } from "./storage-nodes-section";


export interface IMoreOptionProps {
  storagePoolNode: IStoragePoolNode;
  canReplaceNode: boolean;
  isReplaceNodeDisabled: boolean;
  onReplace: (node: IStoragePoolNode) => void;
  onEditStorageNode: (node: IStoragePoolNode) => void;
}

export function MoreOption(props: IMoreOptionProps) {
  function getOptions() {
    const options: IPopupMenuOption[] = [
      {
        label: "Manage",
        onClick: () => props.onEditStorageNode(props.storagePoolNode)
      }
    ];

    if (!props.isReplaceNodeDisabled) {
      options.push({
        label: "Replace",
        disabled: !props.canReplaceNode,
        onClick: () => props.onReplace(props.storagePoolNode)
      });
    }
    return options;
  }

  return (
    <MoreOptions
      testId="more-options"
      options={getOptions()}
    />
  );
}
