import { IDialogState } from ".";
import { AnyAction } from 'redux';
import { DialogActionType, formatAction, OpenActionType } from '../actions/dialog';


export const DIALOG_DEFAULT_STATE: IDialogState<any> = {
  isOpen: false,
  data: null
};

export function createDialogReducer(entityName: string, dialogName: string) {
  const open = formatAction(entityName, dialogName, DialogActionType.OpenDialog);
  const close = formatAction(entityName, dialogName, DialogActionType.CloseDialog);


  return function dialogReducer<TState, TDialogData>(
    state: TState,
    action: AnyAction
  ): TState {
    switch (action.type) {
      case open:
        const openAction = action as OpenActionType<TDialogData>;
        const openState: IDialogState<TDialogData> = {
          isOpen: true,
          data: openAction.data
        };
        return {
          ...state,
          [dialogName]: openState
        };
      case close:
        const closeState: IDialogState<TDialogData> = {
          ...state[dialogName],
          isOpen: false
        };
        return {
          ...state,
          [dialogName]: closeState
        };
      default:
        return state;
    }
  }
}
