import React, { PropsWithChildren } from "react";
import styles from "./side-panel-section.module.scss";
import clsx from "clsx";
import FormSection, { IFormSectionProps } from "../../shared-ui/form/form-section/form-section";

interface ISidePanelSectionProps extends IFormSectionProps {
  contentClass?: string;
}

export default function SidePanelSection(
  props: PropsWithChildren<ISidePanelSectionProps>
) {
  return (
    <FormSection
      title={props.title}
      description={props.description}
      className={clsx(styles.root, props.className)}
      testId={props.testId}
    >
      <div className={clsx(styles.content, props.contentClass)}>{props.children}</div>
    </FormSection>
  );
}
