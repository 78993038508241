import { IShareFilesEmailRequest, IShareFilesEmailResponse } from "../entities/shares";
import { CitrixShareFileService } from "./citrix-share-file-service";

export class SharesService extends CitrixShareFileService {
  constructor(token: string, apiUrl: string) {
    super(token, apiUrl, "Shares");
  }

  sendShareEmail(email: IShareFilesEmailRequest): Promise<IShareFilesEmailResponse> {
    return this.api.post("Send", email);
  }
}
