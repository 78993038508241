import React, {
  useState,
  MouseEvent,
  useEffect,
  PropsWithChildren,
} from "react";
import IComponentProps from "../common/component-props";
import styles from "./more-options.module.scss";
import PopupMenu, { IPopupMenuOption } from "../popup-menu";
import clsx from "clsx";
import { PopupType } from "../popup";

interface IMoreOptionsProps extends IComponentProps {
  options?: IPopupMenuOption[];
}

export default function MoreOptions(
  props: PropsWithChildren<IMoreOptionsProps>
) {
  const [showMenu, setShowMenu] = useState(false),
    [options, setOptions] = useState([]);

  useEffect(() => {
    const wrapedOptions =
      props.options &&
      props.options.map(opt => {
        return {
          ...opt,
          onClick: (event: MouseEvent<HTMLElement>, value?) => {
            setShowMenu(false);
            opt.onClick(event, value);
          },
        };
      });
    setOptions(wrapedOptions);
  }, [props.options]);

  return (
    <div
      className={clsx(styles.root, props.className)}
      data-testid={props.testId || "more-options"}
      style={props.style}
    >
      <button
        className={styles.button}
        disabled={props.disabled}
        onClick={e => {
          e.stopPropagation();
          setShowMenu(true);
        }}
        aria-haspopup="menu"
      >
        <div className={styles.ball}></div>
        <div className={styles.ball}></div>
        <div className={styles.ball}></div>
      </button>
      <PopupMenu
        testId={`${props.testId || "more-options"}-popup`}
        options={options}
        show={showMenu}
        popupType={PopupType.Left}
        onClose={() => setShowMenu(false)}
        children={props.children}
      />
    </div>
  );
}
