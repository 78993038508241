import { Entity } from "../../api/entities/id-name";
import { IStorageNodeListItem } from "../../components/storage-pools/panels/select-storage-nodes-panel/select-storage-nodes-panel";
import { IDeleteBlockedConfirmationData } from "../../components/v2/buckets/dialogs/delete-blocked-confirmation/delete-blocked-confirmation";
import { DialogActions } from "./dialog";

const STORAGE_NODE_ENTITY_NAME = 'storageNode';

export const DELETE_CONFIRMATION_DIALOG_NAME = "deleteConfirmation";
export const DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME = "deleteBlockedConfirmation";

export const getStorageNodeEntityName = () => STORAGE_NODE_ENTITY_NAME;

class StorageNodeActions<TListItem extends Entity = IStorageNodeListItem> {
    readonly entity;
    readonly deleteConfirmation;
    readonly deleteBlockedConfirmation;

    constructor() {
        let entityName = STORAGE_NODE_ENTITY_NAME;
        this.deleteConfirmation = new DialogActions<TListItem>(entityName, DELETE_CONFIRMATION_DIALOG_NAME);
        this.deleteBlockedConfirmation = new DialogActions<IDeleteBlockedConfirmationData>(entityName, DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME);
    }
}

export const storageNodeActions = new StorageNodeActions();
