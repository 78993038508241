export const GoogleRegions = [
  "asia-east1",
  "asia-east2",
  "asia-northeast1",
  "asia-northeast2",
  "asia-south1",
  "asia-southeast1",
  "australia-southeast1",
  "europe-north1",
  "europe-west1",
  "europe-west2",
  "europe-west3",
  "europe-west4",
  "europe-west6",
  "northamerica-northeast1",
  "southamerica-east1",
  "us-central1",
  "us-east1",
  "us-east4",
  "us-west1",
  "us-west2"
];

export const AWSRegions = [
  "us-east-2",
  "us-east-1",
  "us-west-1",
  "us-west-2",
  "ap-east-1",
  "ap-south-1",
  "ap-northeast-3",
  "ap-northeast-2",
  "ap-southeast-1",
  "ap-southeast-2",
  "ap-northeast-1",
  "ca-central-1",
  "cn-north-1",
  "cn-northwest-1",
  "eu-central-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "eu-north-1",
  "me-south-1",
  "sa-east-1",
  "us-gov-east-1",
  "us-gov-west-1"
];

export const ABSRegions = [
  "asia",
  "asiapacific",
  "australia",
  "australiacentral",
  "australiacentral2",
  "australiaeast",
  "australiasoutheast",
  "brazil",
  "brazilsouth",
  "brazilsoutheast",
  "canada",
  "canadacentral",
  "canadaeast",
  "centralindia",
  "centralus",
  "centraluseuap",
  "centralusstage",
  "eastasia",
  "eastasiastage",
  "eastus",
  "eastus2",
  "eastus2euap",
  "eastus2stage",
  "eastusstage",
  "europe",
  "francecentral",
  "francesouth",
  "germanynorth",
  "germanywestcentral",
  "global",
  "india",
  "japan",
  "japaneast",
  "japanwest",
  "jioindiawest",
  "koreacentral",
  "koreasouth",
  "northcentralus",
  "northcentralusstage",
  "northeurope",
  "norwayeast",
  "norwaywest",
  "southafricanorth",
  "southafricawest",
  "southcentralus",
  "southcentralusstage",
  "southeastasia",
  "southeastasiastage",
  "southindia",
  "switzerlandnorth",
  "switzerlandwest",
  "uaecentral",
  "uaenorth",
  "uk",
  "uksouth",
  "ukwest",
  "unitedstates",
  "westcentralus",
  "westeurope",
  "westindia",
  "westus",
  "westus2",
  "westus2stage",
  "westus3",
  "westusstage",
];