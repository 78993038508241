import { IApplicationListItem } from "../../api/entities/application";
import { Entity } from "../../api/entities/id-name";
import { DialogActions } from "./dialog";

const APPLICATION_ENTITY_NAME = 'application';

export const DELETE_CONFIRMATION_DIALOG_NAME = "deleteConfirmation";

export const getApplicationEntityName = () => APPLICATION_ENTITY_NAME;

class ApplicationActions<TListItem extends Entity = IApplicationListItem> {
    readonly entity;
    readonly deleteConfirmation;

    constructor() {
        this.deleteConfirmation = new DialogActions<TListItem>(APPLICATION_ENTITY_NAME, DELETE_CONFIRMATION_DIALOG_NAME);
    }
}

export const applicationActions = new ApplicationActions();
