import { IStorageNodeRaw } from "../../api/entities/storage-node";
import { IABSToken, IStorageExternal } from "./storage-external";

export class StorageABS implements IStorageExternal {
    getToken(storageNode: IStorageNodeRaw): IABSToken {
        return {
            sasUri: storageNode.sasUri,
            bucket: storageNode.container
        };
    }
}
