import { IApplicationListItem } from "../../api/entities/application";
import { Entity } from "../../api/entities/id-name";
import { DialogActions } from "./dialog";

const APPLICATION_CREDENTIALS_ENTITY_NAME = 'applicationCredentials';

export const DELETE_CONFIRMATION_DIALOG_NAME = "deleteConfirmation";

export const getApplicationCredentialsEntityName = () => APPLICATION_CREDENTIALS_ENTITY_NAME;

class ApplicationCredentialsActions<TListItem extends Entity = IApplicationListItem> {
    readonly entity;
    readonly deleteConfirmation;

    constructor() {
        this.deleteConfirmation = new DialogActions<TListItem>(APPLICATION_CREDENTIALS_ENTITY_NAME, DELETE_CONFIRMATION_DIALOG_NAME);
    }
}

export const applicationCredentialsActions = new ApplicationCredentialsActions();
