import React from "react";
import styles from "./input-base.module.scss";
import IComponentProps from "../../common/component-props";
import clsx from "clsx";
import { IFormField } from "../../../../utils/form-utils";

export interface IInputBaseProps extends IComponentProps {
  testId: string;
  value?: string | number;
  name?: string;
  placeholder?: string;
  type?: "text" | "password" | "number";
  min?: number;
  max?: number;
  autoFocus?: boolean;
  readOnly?: boolean;
  hasError?: boolean;
  field?: IFormField<string>;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

export function InputBase(props: IInputBaseProps) {
  const onChange = props.field ? props.field.onChange : props.onChange;
  const onBlur = props.field ? props.field.onBlur : props.onBlur;
  const disabled = props.field ? props.field.isDisabled : props.disabled;
  const hasError = props.field ? props.field.isValid === false : props.hasError; 

  return (
    <input
      data-testid={props.testId || "text-input"}
      autoFocus={props.autoFocus}
      autoComplete="off"
      type={props.type}
      min={props.min}
      max={props.max}
      name={props.name}
      readOnly={props.readOnly}
      value={props.field?.value ?? props.value ?? ""}
      onChange={(e) => { onChange(e.target.value)}}
      onBlur={(e) => { onBlur && onBlur(e.target.value)}}
      placeholder={props.placeholder}
      className={clsx(styles.input, props.className, hasError && styles["input--error"])}
      disabled={disabled}
      style={props.style}
    />
  );
}
