import { SHARING_INSTANCE_DELETE_CONFLICT} from './../../constants/messages';
import { sharingInstanceService } from '../../api/services/sharing-instance-service';
import { ISharingInstanceDeleteRequest, ISharingInstancesRequestParams, ISharingInstanceShort, SharingInstanceSortField } from './../../api/entities/sharing-instance';
import { catchException, commonActions, } from "./common";
import { IAppState } from '../reducers';
import { EntityActions, EntityThunkActions } from './entity';
import { DialogActions } from './dialog';
import { sharingInstanceUtils } from '../../utils/sharing-instance-utils';

export const SHARING_INSTANCE_ENTITY_NAME = "sharingInstance";
export const DELETE_CONFIRMATION_DIALOG_NAME = "deleteConfirmation";

class SharingInstanceActions {
  readonly entity = new EntityActions<ISharingInstanceShort, ISharingInstancesRequestParams>(SHARING_INSTANCE_ENTITY_NAME);
  readonly deleteConfirmation = new DialogActions<ISharingInstanceShort[]>(SHARING_INSTANCE_ENTITY_NAME, DELETE_CONFIRMATION_DIALOG_NAME);
}

export const sharingInstanceActions = new SharingInstanceActions();

class SharingInstanceThunkActions {
  readonly entity = new EntityThunkActions<ISharingInstanceShort, SharingInstanceSortField, ISharingInstancesRequestParams>(
    SHARING_INSTANCE_ENTITY_NAME, sharingInstanceService, (sharingInstance) => sharingInstanceUtils.canSelectSharingInstance(sharingInstance))

  delete(deleteRequest: ISharingInstanceDeleteRequest) {
    return (dispatch, getState: () => IAppState) => {
      dispatch(commonActions.startLoading());
      return sharingInstanceService
        .delete(deleteRequest)
        .then(_ => {
          dispatch(
            commonActions.showSnackBar(
              true,
              `Sharing instance${deleteRequest.ids.length > 1 ? "s": ""} successfully deleted.`
            )
          );
          const selection = getState().sharingInstance.selection;
          deleteRequest.ids.forEach(idInfo => {
            delete selection[idInfo.id];
          })
          dispatch(sharingInstanceActions.entity.setSelection(selection));
          dispatch(this.entity.fetchPage());
        })
        .catch(catchException(
          () => dispatch(commonActions.openChangesConflictInfo(SHARING_INSTANCE_DELETE_CONFLICT))))
        .finally(() => {
          dispatch(commonActions.stopLoading());
        });
    };
  }
}
export const sharingInstanceThunkActions = new SharingInstanceThunkActions();

