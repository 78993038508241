import { IStorageNodeRaw } from "../../api/entities/storage-node";
import { dateTimeHelper, DateTimePeriod } from "../../helper/date-time-helper";
import { IS3Token, IStorageExternal } from "./storage-external";

const persistentTokenFauxDurationYears = 500;

export class StorageS3 implements IStorageExternal {
    getToken(storageNode: IStorageNodeRaw): IS3Token {
        let expiration = dateTimeHelper.add(new Date(), persistentTokenFauxDurationYears, DateTimePeriod.Year);
        return {
            accessKeyId: storageNode.key,
            secretAccessKey: storageNode.secret,
            sessionToken: '',
            expiration: expiration,
            endPoint: storageNode.endpointURL
        };
    }
}
