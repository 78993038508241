import React, { PropsWithChildren } from "react";
import {
  IStyleableComponentProps,
  ITestableComponent,
} from "../../common/component-props";
import clsx from "clsx";
import styles from "./form-sub-section.module.scss";

export interface IFormSubSectionProps
  extends IStyleableComponentProps,
    ITestableComponent {
}

export function FormSubSection(props: PropsWithChildren<IFormSubSectionProps>) {
  return (
    <div
      className={clsx(styles.root, props.className)}
      style={props.style}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  );
}