import { IAppState } from "../../../../../../redux/reducers";
import { enterpriseAccountSetupActions } from "../../../../../../redux/actions/enterpriseAccountSetup";
import { commonActions } from "../../../../../../redux/actions/common";
import { EnterpriseAccountSetupStep } from "../../index";
import { connect } from "react-redux";
import React from "react";
import { FormPanel } from "../../../../../shared-ui/form/form-panel/form-panel";
import Invitation from "../../../invitation/invitation";

const mapStateToProps = (state: IAppState) => ({
    enterpriseAccountSetupSteps: state.enterpriseAccountSetup.enterpriseAccountSetupStep,
    user: state.users.currentUser
});
const mapDispatchToProps = dispatch => {
    return {
        setStep: (step: EnterpriseAccountSetupStep) => {
            dispatch(enterpriseAccountSetupActions.setStep(step));
        },
        startLoading: () => {
            dispatch(commonActions.startLoading());
        },
        stopLoading: () => {
            dispatch(commonActions.stopLoading());
        }
    };
};

interface IUserInvitationProps extends ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {
}

function UserInvitationWizardStep(props: IUserInvitationProps) {

    function goToNextStep() {
        props.setStep(EnterpriseAccountSetupStep.DataMigration);
    }

    return (
        <>
            <FormPanel
                okButtonText="Next"
                okButtonClick={goToNextStep}
            >
                <Invitation
                    title={"Invite Users To Your Organization"}
                    embedded={true}
                />
            </FormPanel>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInvitationWizardStep);