import { AnyAction } from "redux";
import { IApplicationListItem } from "../../api/entities/application";
import { getApplicationEntityName } from "../actions/application";
import { DELETE_CONFIRMATION_DIALOG_NAME } from "../actions/bucket";
import { createDialogReducer, DIALOG_DEFAULT_STATE } from "./dialog";
import { IDialogState } from "./index";

export interface IApplicationState {
    [DELETE_CONFIRMATION_DIALOG_NAME]: IDialogState<IApplicationListItem>;
}

function getApplicationDefaultState(): IApplicationState {
    return {
        [DELETE_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE
    };
}

const getDeleteConfirmationReducer = () => createDialogReducer(getApplicationEntityName(), DELETE_CONFIRMATION_DIALOG_NAME);

export function application(state: IApplicationState = getApplicationDefaultState(),
                           action: AnyAction): IApplicationState {
    state = getDeleteConfirmationReducer()(state, action);
    return state;
}
