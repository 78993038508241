import React, { PropsWithChildren, MouseEvent, useRef, useEffect } from "react";
import Popup, { IPopupProps } from "../popup";
import styles from "./popup-menu.module.scss";
import IOption from "../common/option";
import { APP_ROOT_ELEMENT_ID } from "../../../constants/common";
import ReactDOM from "react-dom";
import clsx from "clsx";
import { testUtils } from "../../../utils/test-utils";
import { KEYS } from "../../../constants/keys";

export interface IPopupMenuOption extends IOption {
  className?: string;
  onClick: (event: MouseEvent<HTMLElement>, value?) => void;
}

interface IPopupMenuProps extends IPopupProps {
  options?: IPopupMenuOption[];
  /** When click is outside menu */
  onClose?: () => void;
}

export default function PopupMenu(props: PropsWithChildren<IPopupMenuProps>) {
  const menuItemsRef = useRef(null);
  const highlightedIndexRef = useRef<number>(null);
  const onItemClick = (
      event: MouseEvent<HTMLElement>,
      option: IPopupMenuOption
    ) => {
      event.stopPropagation();
      event.preventDefault();
      option.onClick(event, option.value);
    };

  useEffect(() => {
    if (props.show && props.options?.length) {
      highlightedIndexRef.current = 0;
      (menuItemsRef.current.children[0] as HTMLElement).focus();
    }
  }, [props.show, props.options]);

  function highlightNext() {
    if (props.options && highlightedIndexRef.current < props.options.length - 1) {
      highlightedIndexRef.current += 1;
      (menuItemsRef.current.children[highlightedIndexRef.current] as HTMLElement).focus();
    }
  }

  function highlightPrevious() {
    if (props.options && props.options.length && highlightedIndexRef.current > 0) {
      highlightedIndexRef.current -= 1;
      (menuItemsRef.current.children[highlightedIndexRef.current] as HTMLElement).focus();
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    switch(e.key) {
      case KEYS.Tab:
        e.preventDefault();
        e.stopPropagation();
        props.onClose();
        break;
      case KEYS.Escape:
        e.preventDefault();
        e.stopPropagation();
        props.onClose();
        break;
      case KEYS.ArrowDown:
        e.preventDefault();
        e.stopPropagation();
        highlightNext();
        break;
      case KEYS.ArrowUp:
        e.preventDefault();
        e.stopPropagation();
        highlightPrevious();
        break;
    }
  }

  return (
    <>
      {props.show
        ? ReactDOM.createPortal(
            <div
              className={styles.overlay}
              onClick={e => {
                e.stopPropagation();
                props.onClose();
              }}
            ></div>,
            document.getElementById(APP_ROOT_ELEMENT_ID)
          )
        : null}
      <Popup className={clsx(styles.popup, props.className)} show={props.show} popupType={props.popupType} testId={props.testId}>
        <div ref={menuItemsRef} className={styles["menu-items"]} onKeyDown={handleKeyDown}>
          {!!props.options
            ? props.options.map(opt => {
                const label = opt.label == null ? opt.value : opt.label;
                return (
                  <button
                    tabIndex={-1}
                    data-testid={opt.testId || testUtils.stringToTestId(opt.label) || "menu-button"}
                    className={clsx(styles.item, opt.className)}
                    onClick={e => onItemClick(e, opt)}
                    onMouseDown={e => e.preventDefault()}
                    disabled={opt.disabled}
                    key={label}
                  >
                    {label}
                  </button>
                );
              })
            : props.children}
          </div>
      </Popup>
    </>
  );
}
