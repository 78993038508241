import React, { useContext } from "react";
import { connect } from "react-redux";
import { IStoragePoolFilterParams } from "../../../api/entities/storage-pool";
import { exportService } from "../../../api/services/export-service";
import { IAppState } from "../../../redux/reducers";
import { exportUtils } from "../../../utils/export-utils";
import { QueryPropertyType, QueryTypeMap } from "../../../utils/query-utils";
import { FiltersBar } from "../../shared-ui/filters-bar/filters-bar";
import { VersionContext } from "../storage-pools";

export const defaultFilters: IStoragePoolFilters = {
  searchText: null,
};

export const filterNames = {
  searchText: "",
};

export const storagePoolFilterTypeMap: QueryTypeMap<IStoragePoolFilterParams> = {
  q: QueryPropertyType.String,
}

const mapStateToProps = (state: IAppState) => ({
  searchParams: state.storagePoolV1.page.searchParams
});

export interface IStoragePoolFilters {
  searchText: string;
}

interface IStoragePoolFiltersProps extends ReturnType<typeof mapStateToProps> {
  filters: IStoragePoolFilters;
  onFiltersChange: (filters: IStoragePoolFilters) => void;
}

export const StoragePoolFilters = connect(mapStateToProps)((props: IStoragePoolFiltersProps) => {
  const version = useContext(VersionContext);
  const exportUrl = version === 1 ? exportService.getStoragePoolUrl(exportUtils.getExportParams(props.searchParams)) : '';

  function handleFilterChange(newFilterValues: Partial<IStoragePoolFilters>) {
    props.onFiltersChange({ ...props.filters, ...newFilterValues });
  }

  return (
    <FiltersBar
      searchValue={props.filters.searchText}
      onSearchValueChanged={searchText => handleFilterChange({ searchText })}
      exportUrl={exportUrl}
    />
  );
});