import { PickActions } from "./common";
import { EnterpriseAccountSetupStep } from "../../components/v2/account-invitation/enterprise-account-setup-wizard";

export enum EnterpriseAccountSetupActionType {
  SetStep = 'ENTERPRISE_ACCOUNT_SETUP_SET_STEP'
}

class EnterpriseAccountSetupActions {
  setStep(step: EnterpriseAccountSetupStep) {
    return {
      type: EnterpriseAccountSetupActionType.SetStep as typeof EnterpriseAccountSetupActionType.SetStep,
      step
    };
  }
}

export const enterpriseAccountSetupActions = new EnterpriseAccountSetupActions();

export type EnterpriseAccountSetupAction = PickActions<EnterpriseAccountSetupActions>;