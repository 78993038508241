import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { restoreFileService } from "../../../api/services/blackouts-service";
import { formUtils, IFormField, useField } from "../../../utils/form-utils";
import { useApi } from "../../../utils/hooks/use-api";
import { validators } from "../../../utils/validators";
import DetailsPanel from "../../shared-ui/details-panel/details-panel";
import { FormField } from "../../shared-ui/form/form-field/form-field";
import { FormPanel } from "../../shared-ui/form/form-panel/form-panel";
import { DatePicker } from "../../shared-ui/input/date-picker/date-picker";
import { dateToTime12, ITime12, time12To24, TimePicker } from "../../shared-ui/input/time-picker/time-picker";
import { RestorationHistorySection } from "./restoration-history-section/restoration-history-section";
import styles from "./restore-files-panel.module.scss";

interface IForm {
  restoreDate: IFormField<DateTime>;
  restoreTime: IFormField<ITime12>;
}

interface IFormValue {
  restoreDate: DateTime;
  restoreTime: ITime12;
}

export interface IRestoreFilesPanelProps { 
  open: boolean;
  userIds?: number[];
  uids?: string[];
  storagePoolIds?: number[];
  sharingInstanceIds?: number[];
  onFilesRestored: () => void;
  onClose: () => void;
}

export function RestoreFilesPanel(props: IRestoreFilesPanelProps) {
  const [api, isLoading] = useApi();
  const [maxDate, setMaxDate]= useState<DateTime>(null);
  const [maxTime, setMaxTime] = useState<ITime12>(null);

  const form: IForm = {
    restoreDate: useField<DateTime>(null, [validators.required(), validators.validDate]),
    restoreTime: useField<ITime12>(dateToTime12(new Date()), [validators.required()]),
  };

  function onFilesRestored() {
    props.onFilesRestored();
    props.onClose();  
  }

  function restoreFiles() {
    const formValue = formUtils.getFormValue<IFormValue>(form);
    const restoreTime = time12To24(formValue.restoreTime);
    const restoreDate = formValue.restoreDate.set({
        hour: restoreTime.hour, 
        minute: restoreTime.minute, 
        second: 0, 
        millisecond: 0
    }).toJSDate();
    
    if (props.userIds) {
      api(restoreFileService.createUserBlackout({userIds: props.userIds, restoreDate}))
        .then(onFilesRestored);
    }
    else if(props.storagePoolIds) {
      api(restoreFileService.createStoragePoolBlackout({storagePoolIds: props.storagePoolIds, restoreDate}))
        .then(onFilesRestored);
    }
    else if(props.sharingInstanceIds) {
      api(restoreFileService.createSharingInstanceBlackout({sharingInstanceIds: props.sharingInstanceIds, restoreDate}))
        .then(onFilesRestored);
    }
  }

  function canShowHistory(): boolean {
    return (
        (props.sharingInstanceIds?.length || 0) +
        (props.storagePoolIds?.length || 0) +
        (props.userIds?.length || 0)
    ) === 1;
  }

  useEffect(() => {
    if (props.open) {
      const currentDate = new Date();
      setMaxDate(DateTime.fromJSDate(currentDate));
      setMaxTime(dateToTime12(currentDate));
      form.restoreTime.init(dateToTime12(currentDate))
    }
    // eslint-disable-next-line
  }, [props.open]);

  return (
    <div className={styles.root}>
      <DetailsPanel
        className={styles.panel}
        isLoading={isLoading}
        open={props.open}
        onCloseClick={props.onClose}
        title="Restore Files"
        description="Choose a point in time that you would like all associated 
                    files restored to. The affected users will be unable to use 
                    Myota during the restoration process."
      >
        {props.open && (
          <>
            <FormPanel          
              okButtonClick={restoreFiles}
              okButtonText="Restore Files"
              okButtonDisabled={!form.restoreDate.value || !form.restoreDate.isValid}
              cancelButtonClick={props.onClose}
              heightToContent={true}
              contentClassName={styles["form-content"]}
            >
              <FormField label="Restore Date" field={form.restoreDate} className={styles["restore-date-field"]}>
                <DatePicker 
                  testId="restore-date" 
                  placeholder="Choose Date"
                  autoFocus={true}
                  className={styles["restore-date-field"]}
                  field={form.restoreDate}
                  maxDate={maxDate}
                />
              </FormField>
              <FormField label="Restore Time" field={form.restoreTime}>
                <TimePicker 
                  testId="restore-time" 
                  field={form.restoreTime}
                  maxTime={form.restoreDate.value && 
                    form.restoreDate.value.hasSame(maxDate, "day") ? maxTime : null}
                />
              </FormField>
            </FormPanel>
            {canShowHistory() && (
              <RestorationHistorySection
                uid={props.uids && props.uids[0]}
                sharingInstanceId={props.sharingInstanceIds && props.sharingInstanceIds[0]}
                storagePoolId={props.storagePoolIds && props.storagePoolIds[0]}
              />
            )}
          </>
        )}
      </DetailsPanel>
    </div>
  );
}