import React from "react";
import { enumUtils, IEnum } from "../../../../../utils/enum-utils";
import Checkbox from "../../../../shared-ui/input/checkbox";
import styles from "./flag-enum-checkbox-group.module.scss";

export interface IFlagEnumCheckboxGroupProps {
    enumType: IEnum;
    readonlyValues?: number[];
    enumValue: number;
    onChange?: (value: number) => void;
}

export function FlagEnumCheckboxGroup(props: IFlagEnumCheckboxGroupProps) {
    let enumKeys = enumUtils.keys(props.enumType)
        .filter(x => typeof props.enumType[x] === 'number' && props.enumType[x] !== 0);
    const { enumValue } = props;

    function onChange(flagValue: number) {
        let v = enumValue ^ flagValue;
        props.onChange(v);
    }

    return (
        <section
            className={styles.root}
        >
            {enumKeys.map(x =>
                <Checkbox
                    testId={`checkbox-${x}`}
                    className={styles.checkbox}
                    key={`checkbox-${x.toLowerCase()}`}

                    label={x}
                    disabled={props.readonlyValues?.includes(Number(props.enumType[x])) ?? false}
                    checked={(enumValue & Number(props.enumType[x])) === Number(props.enumType[x])}
                    onChange={() => {
                        onChange(Number(props.enumType[x]))
                    }}
                />
            )}

        </section>
    );
}
