import { IUserDataMigrationCreateRequest, IDataMigrationDeleteRequest } from './../../api/entities/data-migration';
import { IUserDataMigration, IDataMigrationPageRequestParams } from "../../api/entities/data-migration";
import { IPage } from "../../api/entities/page";
import { dataMigrationService } from "../../api/services/data-migration-service";
import { IAppState } from "../reducers";
import { catchException, handleApi, PickActions } from "./common";
import { devicesThunkActions } from './devices';

export enum DataMigrationActionType {
  FetchPageRequest = "DATA_MIGRATION_FETCH_PAGE_REQUEST",
  FetchPageSuccess = "DATA_MIGRATION_FETCH_PAGE_SUCCESS",
  FetchPageFail = "DATA_MIGRATION_FETCH_PAGE_FAIL",
}

class DataMigrationActions {
  fetchPageRequest(
    searchParams: IDataMigrationPageRequestParams,
  ) {
    return {
      type: DataMigrationActionType.FetchPageRequest as typeof DataMigrationActionType.FetchPageRequest,
      searchParams
    };
  }

  fetchPageSuccess(data: IPage<IUserDataMigration>) {
    return {
      type: DataMigrationActionType.FetchPageSuccess as typeof DataMigrationActionType.FetchPageSuccess,
      payload: data,
    };
  }

  fetchPageFail() {
    return {
      type: DataMigrationActionType.FetchPageFail as typeof DataMigrationActionType.FetchPageFail,
    };
  }
}

export const dataMigrationActions = new DataMigrationActions();
export type DataMigrationAction = PickActions<DataMigrationActions>;

class DataMigrationThunkActions {
  fetchPage(
    searchParams?: Partial<IDataMigrationPageRequestParams>
  ) {
    return (dispatch, getState: () => IAppState) => {
      dispatch(dataMigrationActions.fetchPageRequest(searchParams));
      return handleApi(dataMigrationService
        .getPage(getState().sharingProfile.page.searchParams)
        .then(page => {
          dispatch(dataMigrationActions.fetchPageSuccess(page));
          return page;
        }), false, apiError => {
          dispatch(dataMigrationActions.fetchPageFail());
          catchException()(apiError);
        });
    };
  }

  deleteMigrations(deleteRequest: IDataMigrationDeleteRequest) {
    return (dispatch) => {
      return handleApi(dataMigrationService
        .delete(deleteRequest)
        .then(_ => {
          dispatch(devicesThunkActions.refreshOpenedDevicesOrUsers())
        }), true);
    };
  }

  createMigration(createRequest: IUserDataMigrationCreateRequest) {
    return (dispatch) => {
      return handleApi(dataMigrationService
        .create(createRequest)
        .then(_ => {
          dispatch(devicesThunkActions.refreshOpenedDevicesOrUsers())
        }), true);
    };
  }
}
export const dataMigrationThunkActions = new DataMigrationThunkActions();
