import React, { useContext } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IStoragePoolShort } from "../../../api/entities/storage-pool";
import { IStoragePoolV2ListItem } from "../../../api/entities/storage-pool-v2";
import { bucketService } from "../../../api/services/bucket-service";
import { StoragePoolService } from "../../../api/services/storage-pool-service";
import { catchException, commonActions } from "../../../redux/actions/common";
import { storagePoolV1Actions, storagePoolV2Actions } from "../../../redux/actions/storage-pool";
import { IAppState } from "../../../redux/reducers";
import { storagePoolServiceFactory } from "../../../utils/factories/storage-pool-service-factory";
import { useNavigate } from "../../../utils/hooks/use-navigate";
import { routeUtils } from "../../../utils/route-utils";
import { storagePoolUtils } from "../../../utils/storage-pool-utils";
import MoreOptions from "../../shared-ui/more-options";
import { IPopupMenuOption } from "../../shared-ui/popup-menu";
import { IDeleteBlockedConfirmationDataV1, IDeleteBlockedConfirmationDataV2 } from "../dialogs/delete-blocked-confirmation/delete-blocked-confirmation";
import { VersionContext } from "../storage-pools";


const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    openDeleteConfirmationV1: (storagePool: IStoragePoolShort) =>
      dispatch(storagePoolV1Actions.deleteConfirmation.open(storagePool)),
    openDeleteBlockedConfirmationV1: (deleteBlockedData: IDeleteBlockedConfirmationDataV1) =>
      dispatch(storagePoolV1Actions.deleteBlockedConfirmation.open(deleteBlockedData)),
    openDeleteConfirmationV2: (storagePool: IStoragePoolV2ListItem) =>
      dispatch(storagePoolV2Actions.deleteConfirmation.open(storagePool)),
    openDeleteBlockedConfirmationV2: (deleteBlockedData: IDeleteBlockedConfirmationDataV2) =>
      dispatch(storagePoolV2Actions.deleteBlockedConfirmation.open(deleteBlockedData)),
    startLoading: () =>
      dispatch(commonActions.startLoading()),
    stopLoading: () =>
      dispatch(commonActions.stopLoading())
  };
};

export interface IMoreOptionProps
  extends ReturnType<typeof mapDispatchToProps> {
  data: IStoragePoolShort | IStoragePoolV2ListItem;
}

export const MoreOption = connect(null, mapDispatchToProps)((props: IMoreOptionProps) => {
  const navigate = useNavigate();
  const version = useContext(VersionContext);

  function openDeleteConfirmation() {
    props.startLoading();

    if (version === 1) {
      let storagePoolService = storagePoolServiceFactory.getStoragePoolService(1) as StoragePoolService;
      storagePoolService.get(Number(props.data.id)).then((storagePool) => {
        if (storagePool.SharingProfiles?.length) {
          props.openDeleteBlockedConfirmationV1({ sharingProfiles: storagePool.SharingProfiles, storagePool: storagePool });
        }
        else if (storagePool.Policies?.length) {
          props.openDeleteBlockedConfirmationV1({ storageProfiles: storagePool.Policies, storagePool: storagePool });
        }
        else {
          props.openDeleteConfirmationV1(storagePool);
        }
      }).catch(catchException()).finally(props.stopLoading);
    }
    else {
      bucketService.getPage({ pool: String(props.data.id)}).then(page => {
        if (page.count > 0) {
          props.openDeleteBlockedConfirmationV2({ buckets: page.data.map(x => ({ id: x.id, name: x.name })), storagePool: props.data });
        }
        else {
          props.openDeleteConfirmationV2(props.data as IStoragePoolV2ListItem);
        }
      }).catch(catchException()).finally(props.stopLoading);
    }
  }

  function getOptions() {
    const options: IPopupMenuOption[] = [
      {
        label: "Manage",
        onClick: () => {
          navigate(routeUtils.getRouteToStoragePoolDetails(props.data.id, version));
        },
      },
      {
        label: "Delete",
        onClick: openDeleteConfirmation
      },
    ];

    if (storagePoolUtils.canRestoreFiles(version)) {
      options.push({
        label: "Restore Files",
        onClick: () => navigate(routeUtils.getRouteToStoragePoolRestoreFiles(Number(props.data.id))),
      });
    }
    return options;
  }

  return (
    <MoreOptions
      options={getOptions()}
    />
  );
});
