import clsx from "clsx";
import React from "react";
import { IStyleableComponentProps } from "../common/component-props";
import ProgressBar from "../progressBar/ProgressBar";
import styles from "./wizard-progress-bar.module.scss";

interface IWizardProgressBarProps extends IStyleableComponentProps {
  value?: number;
  step?: number;
  steps: string[];
}

export function WizardProgressBar(props: IWizardProgressBarProps) {
  const value = props.value || Math.min(((props.step || 0) + 0.2) * 100 / props.steps.length, 100);
  return (
    <div data-testid="wizard-progress-bar" className={clsx(styles.root, props.className)}>
      <ProgressBar
        className={styles["progress-bar"]}
        barClassName={styles["progress-bar-bar"]}
        value={value}
      />

      <div className={styles["progress-bar-labels"]}>
        { props.steps.map(step => (
          <div 
            key={step} 
            className={styles["progress-bar-label"]}
          >
            {step}
          </div>
        ))}
      </div>
    </div>
  );
}
