import { IHasOwner, IIdName, IIdNameDateTime } from "./id-name";
import { IDeletedParam, IQueryStrictParams, IRequestParams } from "./page";
import { IStoragePoolV2DetailsItem } from "./storage-pool-v2";

export interface IBucketListItem extends IIdNameDateTime<string>, IHasOwner {
    storagePoolId: string;
    bucketConfig: IBucketConfig;
    storagePool: IIdName<string>;
    applications: IIdName<string>[];
}

export interface IBucketDetailsItem extends Omit<IBucketListItem, 'storagePool' | 'applications'> {
    tagURI: string;

    storagePool: IStoragePoolV2DetailsItem;
}

export interface IBucketConfig extends Omit<IIdNameDateTime<string>, 'name'> {
    immutable: boolean;
    retainPrevVerDays?: number;
    retainDeletedDays?: number;

    bucketId: string;
}

export enum BucketSortField {
    BucketId = "id",
    BucketName = "name",
    StoragePool = "pool",
}

export interface IBucketFilterParams extends IQueryStrictParams {
    pool?: string;
}

export interface IBucketPageRequestParams extends
    IBucketRequestParams,
    IBucketFilterParams,
    IDeletedParam {
}

export interface IBucketRetentionPolicy {
    retainDeletedDays?: number;
    retainPrevVerDays?: number;
}

export interface IBucketUpdateRequest extends IIdName<string>, IBucketRetentionPolicy {
    immutable: boolean;
}

export interface IBucketCreateRequest extends Omit<IBucketUpdateRequest, 'id'> {
    storagePoolId: string;
}

export interface IBucketRequestParams extends
    IRequestParams<BucketSortField>,
    IBucketFilterParams,
    IDeletedParam {

    fields?: (keyof IBucketListItem)[];
}
