import { DELETE_CONFIRMATION_DIALOG_NAME, SHARING_INSTANCE_ENTITY_NAME } from './../actions/sharing-instance';
import { ISharingInstancesRequestParams, ISharingInstanceShort, SharingInstanceSortField } from './../../api/entities/sharing-instance';
import { createEntityReducer, getEntityDefaultState, IEntityState } from './entity';
import { AnyAction } from 'redux';
import { IDialogState } from '.';
import { createDialogReducer, DIALOG_DEFAULT_STATE } from './dialog';
import { SortDirection } from '../../api/entities/page';

export interface ISharingInstanceState extends IEntityState<ISharingInstanceShort, SharingInstanceSortField, ISharingInstancesRequestParams> {
  [DELETE_CONFIRMATION_DIALOG_NAME]: IDialogState<ISharingInstanceShort[]>;
}

export const SHARING_INSTANCE_DEFAULT_STATE: ISharingInstanceState = {
  [DELETE_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
  ...getEntityDefaultState(SharingInstanceSortField.CreationDate, SortDirection.Descending)
};

const entityReducer = createEntityReducer(SHARING_INSTANCE_ENTITY_NAME);
const deleteConfirmationReducer = createDialogReducer(SHARING_INSTANCE_ENTITY_NAME, DELETE_CONFIRMATION_DIALOG_NAME);

export function sharingInstance(
  state: ISharingInstanceState = SHARING_INSTANCE_DEFAULT_STATE,
  action: AnyAction
): ISharingInstanceState {
  state = entityReducer(state, action);
  state = deleteConfirmationReducer(state, action);
  return state;
}
