export const V1_ROUTE = "/v1";

export const LOGIN_ROUTE = "/login";
export const LOGOUT_ROUTE = "/logout";
export const LOGOUT_SUCCESS_ROUTE = "/logout-success";
export const CONFIRMATION_ROUTE = "/confirmation";
export const CONFIRMATION_ENTERPRISE_ROUTE = "/confirmation-enterprise";
export const RESET_PASSWORD_ROUTE = "/reset-password";

export const INITIAL_SETUP_ROUTE = `${V1_ROUTE}/initial-setup`;
export const DEVICES_ROUTE = `${V1_ROUTE}/devices`;
export const STORAGE_PROFILES_ROUTE = `${V1_ROUTE}/storage-profiles`;
export const USERS_ROUTE = `${V1_ROUTE}/users`;
export const USERS_FILTER_ROUTE = `${USERS_ROUTE}/filter/:filter`;
export const SHARING_PROFILES_ROUTE = `${V1_ROUTE}/sharing-profiles`;
export const SHARING_INSTANCES_ROUTE = `${V1_ROUTE}/sharing-instances`;
export const STORAGE_POOLS_ROUTE = `${V1_ROUTE}/storage-pools`;
export const ADDONS_SETTINGS_ROUTE = `${V1_ROUTE}/addons`;
export const PROFILE_ROUTE = `${V1_ROUTE}/profile`;
export const CITRIX_SHARE_FILES_ROUTE = `${V1_ROUTE}/addons/citrix-share-files`;
export const SETTINGS_ROUTE = `${V1_ROUTE}/settings`;
export const USER_GROUPS_ROUTE = `${V1_ROUTE}/user-groups`;
export const DASHBOARD_ROUTE = V1_ROUTE;
export const DETAILED_DASHBOARD_ROUTE = `${V1_ROUTE}/detailed-dashboard`;
export const SCAN_HISTORY_ROUTE = `${V1_ROUTE}/scan-history`;

export const DASHBOARD_V2_ROUTE = "/dashboard";
export const APPLICATIONS_V2_ROUTE = "/applications";
export const APPLICATION_CREDENTIALS_V2_ROUTE = "/access-credentials";
export const BUCKETS_V2_ROUTE = "";
export const STORAGE_POOLS_V2_ROUTE = "/storage-pools";
export const STORAGE_NODES_V2_ROUTE = "/storage-nodes";
export const JOBS_V2_ROUTE = "/jobs";
export const ENTERPRISE_ACCOUNT_SETUP_ROUTE = `/enterprise-account-setup`;
export const SETTINGS_V2_ROUTE = '/settings';

export enum UserGroupRouteParam {
  Id = "id",
}

export enum StorageProfileRouteParam {
  Id = "id",
  Action = "action"
}

export enum SettingsRouteParam {
  Section = "section",
  Id = "id",
  Action = "action"
}

export enum SettingsRouteSection {
  AutoApprovalCertificate = "auto-approval-certificate",
  AutoApprovalCertificateGuide = "auto-approval-certificate-guide",
  ActiveDirectorySettings = "active-directory-settings",
  CliCredentials = "cli-credentials"
}

export enum SettingsRouteAction {
  Guide = "guide"
}

export enum StorageProfileRouteAction {
  ManageUsers = "users",
  ManageExceededUsers = "exceeded-users"
}

export enum StoragePoolRouteParam {
  IdOrAction = "idOrAction",
  NodeIdOrAction = "nodeIdOrAction",
}

export enum StoragePoolRouteAction {
  ManageNode = "node",
  New = "new",
  RestoreFiles = "restore-files",
}

export enum ApplicationV2RouteParam {
  IdOrAction = "idOrAction",
  CredentialsAction = "credentialsAction",
}

export enum ApplicationV2RouteAction {
  New = "new",
  AddCredentials = "add-credentials",
}

export enum BucketRouteParam {
  IdOrAction = "idOrAction",
}

export enum BucketRouteAction {
  New = "new"
}

export enum StorageNodeRouteParam {
  IdOrAction = "idOrAction",
}

export enum StorageNodeRouteAction {
  New = "new"
}

export enum SharingInstanceRouteParam {
  IdOrAction = "idOrAction",
  Action = "action"
}

export enum SharingInstanceRouteAction {
  ManageUsers = "users",
  RestoreFiles = "restore-files"
}

export enum SharingProfileRouteParam {
  Id = "id",
  Action = "action"
}

export enum SharingProfileRouteAction {
  ManageUsers = "users"
}

export enum DevicesRouteParam {
  Id = "id",
  Action = "action"
}

export enum DevicesRouteAction {
  LoginHistory = "login-history"
}

export enum UsersRouteParam {
  UidOrAction = "uidOrAction",
  Filter = "filter",
  Action = "action"
}

export enum UsersRouteAction {
  MigrateFiles = "migrate-files",
  RestoreFiles = "restore-files"
}

export interface IDeviceRouteParams {
  [DevicesRouteParam.Id]: string;
  [DevicesRouteParam.Action]: string;
}
