import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { QueryTypeMap, queryUtils } from "../query-utils";


export function useFilters<TFilters, TQueryParams extends object>(
  defaultFilters: TFilters,
  onRequestData: (filters: TFilters) => void,
  queryTypeMap: QueryTypeMap<TQueryParams>,
  filtersToQueryParams: (filters: TFilters) => TQueryParams,
  queryParamsToFilters: (queryParams: Partial<TQueryParams>) => TFilters): [
    TFilters/**filters value */,
    (filters: TFilters) => void/**set filters */,
    (filters: TFilters) => void/**update query*/
  ] {

  const [filters, setFilters] = useState(defaultFilters);
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  function updateQuery(filters: TFilters): void {
    setFilters(filters);
    history.push({ search: queryUtils.toQuery(filtersToQueryParams(filters))});
  }

  useEffect(() => {
    const currentFiltersQuery = queryUtils.toQuery(filtersToQueryParams(filters));
    if (currentFiltersQuery !== searchParams.toString()) {
      const newFilters = {...defaultFilters, ...queryParamsToFilters(queryUtils.parseSearchParams(searchParams, queryTypeMap))};
      setFilters(newFilters);
      onRequestData(newFilters);
    }
    else {
      onRequestData(filters);
    }
    // eslint-disable-next-line
  }, [searchParams]);

  return [filters, setFilters, updateQuery];

}