import { IPage } from '../entities/page';
import { IAddStorageNodeRequest, IStorageNode, IStorageNodeCompact, IStorageNodesRequestParams, IStorageNodeTestConnectionRequest, IStorageNodeTestConnectionResponse, IUpdateStorageNodeRequest, StorageProvider, StorageType } from '../entities/storage-node';
import { DataService } from "./data-service";

type TestConnectionRequest = IStorageNodeTestConnectionRequest | IAddStorageNodeRequest | IUpdateStorageNodeRequest;

export abstract class StorageNodeService extends DataService {
    abstract nameValidator(name: string, storageProvider?: StorageProvider): Promise<boolean>;

    testConnection(request: IStorageNodeTestConnectionRequest): Promise<IStorageNodeTestConnectionResponse> {
        return this.api.post<IStorageNodeTestConnectionResponse>("testConnection", this.extractProps(this.encodeEndpointUrl(request)));
    }

    protected encodeEndpointUrl(request: TestConnectionRequest): TestConnectionRequest {
        return {...request, endpointURL: this.encodeUri(request.endpointURL)}
    }

    protected extractProps(request: TestConnectionRequest): TestConnectionRequest {
        let extractedProps = {};
        if (request.type === StorageProvider.GCS && !!request.gcsConfigProps) {
            extractedProps = {
                secret: request.gcsConfigProps.private_key,
                project_id: request.gcsConfigProps.project_id,
                client_email: request.gcsConfigProps.client_email
            }
        }

        return {...request, ...extractedProps};
    }
}

export class StorageNodeV1Service extends StorageNodeService {
    constructor() {
        super("storagenode");
    }

    get(storageNodeId: number) {
        return this.api.get<IStorageNode>(storageNodeId);
    }

    getCompactList(storageType?: StorageType): Promise<IStorageNodeCompact[]> {
        return this.api.get<IStorageNodeCompact[]>(`compact`, { storageType });
    }

    getPage(requestParams: IStorageNodesRequestParams) {
        return this.api.get<IPage<IStorageNode>>(null, requestParams);
    }

    update(updateRequest: IUpdateStorageNodeRequest) {
        return this.api.put<IStorageNode>(updateRequest.id, this.encodeEndpointUrl(updateRequest));
    }

    addStorageNode(addRequest: IAddStorageNodeRequest) {
        return this.api.post<IStorageNode>("", this.encodeEndpointUrl(addRequest));
    }

    delete(storageNodeId: number) {
        return this.api.delete(storageNodeId);
    }

    bucketNameValidator(bucketName: string, storageProvider: StorageProvider): Promise<boolean> {
        return this.nameValidator(bucketName, storageProvider);
    }

    nameValidator(name: string, storageProvider: StorageProvider) {
        return this.api.get<boolean>(`nameValidator/${encodeURIComponent(name)}/${storageProvider}`);
    }
}
