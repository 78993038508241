import React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { StorageNodeV2ListItem } from "../../../../api/entities/storage-node-v2";
import { StorageNodeV2Service } from "../../../../api/services/storage-node-v2-service";
import { catchException, commonActions } from "../../../../redux/actions/common";
import { storageNodeActions } from "../../../../redux/actions/storage-node";
import { IAppState } from "../../../../redux/reducers";
import { storageNodeServiceFactory } from "../../../../utils/factories/storage-node-service-factory";
import { useNavigate } from "../../../../utils/hooks/use-navigate";
import { routeUtils } from "../../../../utils/route-utils";
import MoreOptions from "../../../shared-ui/more-options";
import { IPopupMenuOption } from "../../../shared-ui/popup-menu";
import { IStorageNodeListItem } from "../../../storage-pools/panels/select-storage-nodes-panel/select-storage-nodes-panel";
import { IDeleteBlockedConfirmationData } from "../dialogs/delete-blocked-confirmation/delete-blocked-confirmation";

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    openDeleteConfirmation: (storageNode: StorageNodeV2ListItem) =>
        dispatch(storageNodeActions.deleteConfirmation.open(storageNode)),
    openDeleteBlockedConfirmation: (deleteBlockedData: IDeleteBlockedConfirmationData) =>
        dispatch(storageNodeActions.deleteBlockedConfirmation.open(deleteBlockedData)),
    startLoading: () =>
        dispatch(commonActions.startLoading()),
    stopLoading: () =>
        dispatch(commonActions.stopLoading())
  };
};

export interface IMoreOptionProps
    extends ReturnType<typeof mapDispatchToProps> {
  data: IStorageNodeListItem;
}

export const MoreOption = connect(null, mapDispatchToProps)((props: IMoreOptionProps) => {
  const navigate = useNavigate();
  const storageNodeService = storageNodeServiceFactory.getStorageNodeService(2) as StorageNodeV2Service;

  function openDeleteConfirmation() {
    props.startLoading();

    storageNodeService.get(props.data.id).then(storageNode => {
      if (!!storageNode.storagePools && storageNode.storagePools.length > 0) {
        props.openDeleteBlockedConfirmation({ storagePools: storageNode.storagePools.map(x => ({ id: x.id, name: x.name })), storageNode: props.data })
      }
      else {
        props.openDeleteConfirmation(storageNode);
      }
    }).catch(catchException()).finally(props.stopLoading);
  }

  function getOptions(): IPopupMenuOption[] {
    const result = [
      {
        label: "Manage",
        onClick: () => navigate(routeUtils.getRouteToStorageNodeDetails(props.data.id.toString()))
      },
      {
        label: "Delete",
        onClick: openDeleteConfirmation
      },
    ];
    return result;
  }

  return (
    <MoreOptions
      options={getOptions()}
    />
  );
});
