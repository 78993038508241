import { IFilterProps, SelectFilter } from "../../../../shared-ui/filters/select-filter";
import { InvitationStatus, invitationStatusDict } from "../../../../../api/entities/invitation";
import IOption from "../../../../shared-ui/common/option";

export function InvitationStatusFilter(props: IFilterProps<InvitationStatus, InvitationStatus>) {

    return SelectFilter({
        testId: "invitation-status-filter",
        label: "Status",
        options: () => Promise.resolve(Object.keys(invitationStatusDict)
            .map(x => ({
                value: Number(x) as InvitationStatus,
                label: invitationStatusDict[x]
            } as IOption))),
        ...props
    });
}
