import React from "react";
import { IPage } from "../../../../../api/entities/page";
import Table, { IColumnDefinition } from "../../../../shared-ui/table/table";
import tableStyles from "../../../../shared-ui/table/table.module.scss";
import styles from "./invitation-table.module.scss";
import { MoreOption } from "./moreoption";
import { IInvitation, IInvitationRequestParams, InvitationSortField, invitationStatusDict } from "../../../../../api/entities/invitation";
import { Formatters } from "../../../../../helper/formatters";

export interface IInvitationTableProps {
  isLoading: boolean;
  invitationPage: IPage<IInvitation>;
  requestParams: IInvitationRequestParams;
  onRequestParamsChanged: (data: Partial<IInvitationRequestParams>) => void;
}

export function InvitationTable(props: IInvitationTableProps) {
  const columns: IColumnDefinition<IInvitation, string>[] = [
    {
      label: "Email",
      className: styles["col--email"],
      formatter: row => row.email,
      sortKey: InvitationSortField.Email
    },
    {
        label: "Admin",
        className: styles["col--is-admin"],
        formatter: row => row.isAdmin ? "Yes" : "No",
    },
    {
      label: "Status",
      className: styles["col--status"],
      formatter: row => invitationStatusDict[row.status],
      sortKey: InvitationSortField.Status
    },
    {
      label: "Sent date",
      className: styles["col--sent-date"],
      formatter: row => Formatters.formatDateTime(row.updatedAt),
      sortKey: InvitationSortField.SentDate
    },
    {
      label: "Manage",
      className: tableStyles["col--manage"],
      formatter: row => (<MoreOption data={row}/>),
    },
  ];

  return (
    <Table
      testId="invitation-table"
      hideCheckbox={true}
      columns={columns}
      rows={props.invitationPage.data}
      totalRows={props.invitationPage.count}
      loading={props.isLoading}
      requestParams={props.requestParams}
      onRequestParamsChanged={props.onRequestParamsChanged}
    />
  );
}
