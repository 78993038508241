import React, { useEffect, useState } from "react";
import { StorageProvider } from "../../../api/entities/storage-node";
import { storageUtils } from "../../../utils/storage-utils";
import TextInput from "../../shared-ui/input/text-input/text-input";
import { Select } from "../../shared-ui/select";
import styles from "./region-select.module.scss";

export interface IRegionSelectProps {
  provider: StorageProvider;
  value: string;
  isCustomRegion: boolean;
  onChange?: (value: string, isCustomValue: boolean) => void;
  disabled?: boolean;
  testId: string;
}

const customOption = "Custom";

export function RegionSelect(props: IRegionSelectProps) {
  const [selectValue, setSelectValue] = useState<string>("");
  const [regions, setRegions] = useState<string[]>([]);


  function onSelectValueChange(newValue: string) {
    setSelectValue(newValue);
    if (newValue === customOption) {
      props.onChange("", true);
    }
    else {
      props.onChange(newValue, false);
    }
  }

  useEffect(() => {
    const regions = storageUtils.getProviderRegions(props.provider);
    regions.unshift(customOption);
    setRegions(regions);
  }, [props.provider]);

  let newSelectValue = props.value;
  if (props.isCustomRegion) {
    newSelectValue = customOption;
  }

  if (selectValue !== newSelectValue) {
    setSelectValue(newSelectValue);
  }

  return (
    <div className={styles.root} data-testid={props.testId}>
      <div className={styles.select}>
        <Select
          testId="region-select"
          disabled={props.disabled}
          value={selectValue}
          onChange={onSelectValueChange}
          options={regions}
        />
      </div>

      {props.isCustomRegion && (
        <div className={styles.text}>
          <TextInput
            testId="region-name-input"
            placeholder="Custom region name"
            disabled={props.disabled}
            value={props.value}
            onChange={(value) => props.onChange(value, true)}
          />
        </div>
      )}
    </div>
  );
}
