import clsx from "clsx";
import React, { PropsWithChildren, ReactNode } from "react";
import { IStyleableComponentProps, ITestableComponent } from "../../common/component-props";
import styles from "./form-field-error.module.scss";

export interface IFormFieldValueProps extends ITestableComponent, IStyleableComponentProps {
  error?: ReactNode;
}

export function FormFieldError(props: PropsWithChildren<IFormFieldValueProps>) {
  return (props.error || null) && (
    <div 
      data-testid={props.testId || "form-field-error"}
      style={props.style}
      className={clsx(styles.root, props.className)}
    >
      {props.error}
    </div>
  );
}
