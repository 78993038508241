import React from "react";
import { makeStyles } from "@mui/styles";
import { ITestableComponent } from "../common/component-props";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "stretch",
    marginBottom: "20px",
    position: "relative",
    zIndex: 1
  },
  title: {
    paddingRight: "20px",
    fontSize: "1.4rem",
    fontFamily: "Raleway",
    fontWeight: 600,
    backgroundColor: "#fff"
  },
  titleHr: {
    border: "none",
    height: "1px",
    margin: 0,
    flexShrink: 0,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    position: "absolute",
    width: "100%",
    top: "50%",
    left: 0,
    zIndex: -1
  }
});

export interface ITitleWithDividerProps extends ITestableComponent {
  title: string;
}

const TitleWithDivider = (props: ITitleWithDividerProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} data-testid={props.testId || "title-with-divider"}>
      <span data-testid="title" className={classes.title}>{props.title}</span>
      <div className={classes.titleHr} />
    </div>
  );
};
export default TitleWithDivider;
