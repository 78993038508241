import { IPage } from '../entities/page';
import { IUserDataMigration, IUserDataMigrationCreateRequest, IDataMigrationPageRequestParams, IDataMigrationDeleteRequest } from './../entities/data-migration';
import { DataService } from "./data-service";

class DataMigrationService extends DataService {
  constructor() {
    super("data-migration");
  }

  getPage(requestParams: IDataMigrationPageRequestParams): Promise<IPage<IUserDataMigration>> {
    return this.api.get<IPage<IUserDataMigration>>(null, requestParams);
  }

  create(createRequest: IUserDataMigrationCreateRequest) {
    return this.api.post<IUserDataMigration>(null, createRequest);
  }

  delete(deleteRequest: IDataMigrationDeleteRequest) {
    return this.api.delete<void>(null, null, deleteRequest);
  }
}

export const dataMigrationService = new DataMigrationService();
