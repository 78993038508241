import { IAppState } from "../../../../../../redux/reducers";
import { enterpriseAccountSetupActions } from "../../../../../../redux/actions/enterpriseAccountSetup";
import { catchException, commonActions } from "../../../../../../redux/actions/common";
import { EnterpriseAccountSetupStep } from "../../index";
import { connect } from "react-redux";
import { formUtils, IFormField, useField } from "../../../../../../utils/form-utils";
import { validators } from "../../../../../../utils/validators";
import PageTitle from "../../../../../shared-ui/pageTitle";
import React from "react";
import styles from "../../../../../storage-pools/sections/pool-name-section/pool-name-section.module.scss";
import { FormField } from "../../../../../shared-ui/form/form-field/form-field";
import TextInput from "../../../../../shared-ui/input/text-input/text-input";
import FormSection from "../../../../../shared-ui/form/form-section/form-section";
import { FormPanel } from "../../../../../shared-ui/form/form-panel/form-panel";
import { accountService } from "../../../../../../api/services/account-service";
import { AccountType } from "../../../../../../api/entities/user";
import { useApi } from "../../../../../../utils/hooks/use-api";

const mapStateToProps = (state: IAppState) => ({
    enterpriseAccountSetupSteps: state.enterpriseAccountSetup.enterpriseAccountSetupStep,
    user: state.users.currentUser
});
const mapDispatchToProps = dispatch => {
    return {
        setStep: (step: EnterpriseAccountSetupStep) => {
            dispatch(enterpriseAccountSetupActions.setStep(step));
        },
        startLoading: () => {
            dispatch(commonActions.startLoading());
        },
        stopLoading: () => {
            dispatch(commonActions.stopLoading());
        }
    };
};

interface IOrganizationForm {
    name: IFormField<string>;
}

interface IOrganizationProps extends
    ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {}

function OrganizationWizardStep(props: IOrganizationProps) {
    const [api] = useApi();

    const form: IOrganizationForm = {
        name: useField<string>("",
            [validators.required("Organization Name is required")],
            [{ asyncValidator: validateOrganization }]
        ),
    };
    const formError = formUtils.getFormError(form);

    function validateOrganization(name: string): Promise<string> {
        return accountService.validateOrganization(name)
            .catch(catchException())
            .then((success) => success ? null : "Organization with specified name already exists");
    }

    function save() {
        formUtils.validateAll(form).then((isValid) => {
             if (isValid) {
                api(accountService.changeAccountType({
                    accountType: AccountType.Enterprise,
                    email: props.user.email,
                    organizationName: form.name.value
                })).then(() => props.setStep(EnterpriseAccountSetupStep.UserInvitation));
             }
        });
    }

    return (
        <>
            <PageTitle title="Specify the Organization"/>
            <FormPanel
                className={styles.root}
                okButtonText="Next"
                okButtonClick={save}
                okButtonDisabled={!!formError}
                errorMessage={formError}
            >
                <FormSection testId="organization-name-section" title="Organization Name" className={styles.section}>
                    <FormField label="Organization Name" error={form.name.error} testId="organization-name-field">
                        <TextInput
                            className={styles.name}
                            testId="organization-name-input"
                            field={form.name}
                            placeholder="e.g. 'Myota'"
                            autoFocus={true}
                        />
                    </FormField>
                </FormSection>
            </FormPanel>
        </>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationWizardStep);