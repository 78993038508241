import { EnterpriseAccountSetupStep } from "../../components/v2/account-invitation/enterprise-account-setup-wizard";
import { EnterpriseAccountSetupAction, EnterpriseAccountSetupActionType } from "../actions/enterpriseAccountSetup";

export interface IEnterpriseAccountSetupState {
  enterpriseAccountSetupStep: EnterpriseAccountSetupStep;
}

export const ENTERPRISE_ACCOUNT_SETUP_DEFAULT_STATE: IEnterpriseAccountSetupState = {
  enterpriseAccountSetupStep: EnterpriseAccountSetupStep.Organization,
};

export default function enterpriseAccountSetup(
  state = ENTERPRISE_ACCOUNT_SETUP_DEFAULT_STATE,
  action: EnterpriseAccountSetupAction
): IEnterpriseAccountSetupState {
  switch (action.type) {
    case EnterpriseAccountSetupActionType.SetStep:
      return {
        ...state,
        enterpriseAccountSetupStep: action.step,
      };
    default:
      return state;
  }
}
