import React from "react";
import { IPage } from "../../../../api/entities/page";
import { BucketSortField, IBucketListItem, IBucketPageRequestParams } from "../../../../api/entities/bucket";
import { useNavigate } from "../../../../utils/hooks/use-navigate";
import { routeUtils } from "../../../../utils/route-utils";
import Table, { IColumnDefinition, TableSelected } from "../../../shared-ui/table/table";
import tableStyles from "../../../shared-ui/table/table.module.scss";
import { MoreOption } from "./moreoption";
import styles from "./bucket-table.module.scss";
import { IAppState } from "../../../../redux/reducers";
import { connect } from "react-redux";
import { AccountType } from "../../../../api/entities/user";
import { getOwnerColumn } from "../../common/sections/owner-column/owner-column";

const mapStateToProps = (state: IAppState) => ({
    accountType: state.users.currentUser.accountType,
});

export interface IBucketTableProps
    extends ReturnType<typeof mapStateToProps> {
    isLoading: boolean;
    page: IPage<IBucketListItem>;
    requestParams: IBucketPageRequestParams;
    setRequestParams: (data: Partial<IBucketPageRequestParams>) => void;

    selection?: TableSelected<IBucketListItem>;
    onSelectionChanged?: (selection: TableSelected<IBucketListItem>) => void;
    onSelectAll?: () => void;

    isRowClickable?: boolean;
    hasManageColumn?: boolean;
}

export const BucketTable = connect(mapStateToProps, null)((props: IBucketTableProps) => {
    const navigate = useNavigate();
    const columns: IColumnDefinition<IBucketListItem, BucketSortField>[] = [
        {
            label: "ID",
            className: styles["col--id"],
            formatter: row => row.id,
            sortKey: BucketSortField.BucketId
        },
        {
            label: "Name",
            className: styles["col--name"],
            formatter: row => row.name,
            sortKey: BucketSortField.BucketName
        },
        {
            label: "Storage Pool",
            className: styles["col--storage-pool"],
            formatter: row => row.storagePool?.name,
            sortKey: BucketSortField.StoragePool
        },
        {
            label: "Used By",
            className: styles["col--applications"],
            formatter: row => row.applications && row.applications.map(x => <div>{x.name}</div>),
        },
        {
            label: "Immutable",
            className: styles["col--storage-pool"],
            formatter: row => row.bucketConfig.immutable ? "Yes" : "No"
        },
    ];

    if (props.accountType === AccountType.Enterprise) {
        columns.push(getOwnerColumn(null));
    }

    if (props.hasManageColumn) {
        columns.push(
            {
                label: "Manage",
                className: tableStyles["col--manage"],
                formatter: row => (<MoreOption data={row}/>),
            });
    }

    return (
        <Table
            testId="bucket-table"
            hideCheckbox={!props.selection}
            columns={columns}
            rows={props.page.data}
            totalRows={props.page.count}
            loading={props.isLoading}
            requestParams={props.requestParams}
            onRequestParamsChanged={props.setRequestParams}
            onRowClick={row => !!props.isRowClickable && navigate(routeUtils.getRouteToBucketDetails(row.id))}
            responsive={true}

            selected={props.selection}
            onSetSelected={props.onSelectionChanged}
            onSelectAll={props.onSelectAll}
        />
    );
});
