import React, { Component } from "react";
import styles from "./on-boarding.module.scss";
import clientStyles from "../client.module.scss";
import clsx from "clsx";
import logoPng from "../../../assets/logo.png";
import statusBarPng from "./status-bar.png";
import statusBarWinPng from "./status-bar-windows.png";
import sidebarPng from "./sidebar.png";
import sidebarWinPng from "./sidebar-windows.png";
import transferPng from "./transfer.png";
import transferWinPng from "./transfer-windows.png";
import myotaFoldersPng from "./myota-folders.png";
import myotaFoldersWinPng from "./myota-folders-windows.png";
import restoreWinPng from "./restore-windows.png";
import restorePng from "./restore.png";
import shieldPng from "./shield.png";
import myotaCloudPng from "./myota-cloud.png";

const IS_WIN = window.navigator.platform.toLowerCase().includes("win");

interface IOnBoardingProps {
  isPending?: boolean;
}

class OnBoarding extends Component<IOnBoardingProps> {
  state = { step: 1 };
  render() {
    return (
      <div className={clientStyles.container}>
        <div className={clientStyles["data-wrapper"]}>
          <img src={logoPng} alt="logo" className={clsx(clientStyles["logo"], clientStyles["logo--small"])} />
          {this.state.step === 1 && (
            <>
              <h1>
                {this.props.isPending
                  ? "Your Device is Pending Approval"
                  : "You’re Ready to use Myota!"}
              </h1>
              <p
                className={clsx({
                  [styles["pending-header"]]: this.props.isPending,
                })}
              >
                {this.props.isPending
                  ? "While you're waiting for your IT admin to approve your device, take a minute to take a tour of the easy interface."
                  : "Take a minute to take a tour of the easy interface."}
              </p>
            </>
          )}
          {this.state.step === 2 && (
            <>
              <h1>Drag & Drop to Upload</h1>
              <p>Transferring files to the cloud is easy.</p>
            </>
          )}
          {this.state.step === 3 && (
            <>
              <h1>Explore your version history</h1>
              <p>Restore almost anything from the cloud.</p>
            </>
          )}
          <div className={styles["button-groups"]}>
            {["Open Myota", "Manage Files", "Restore Files"].map(
              (title, idx) => (
                <button
                  key={idx}
                  className={`${styles.btn} ${styles["mul-button"]} ${
                    this.state.step === idx + 1 && styles["mul-button--active"]
                  }`}
                  onClick={() => this.setState({ step: idx + 1 })}
                >
                  {idx + 1}. {title}
                </button>
              )
            )}
          </div>

          {this.state.step === 1 && (
            <div className={styles["example-image"]}>
              <div className={styles["status-bar-image"]}>
                <img
                  src={IS_WIN ? statusBarWinPng : statusBarPng}
                  alt="status"
                />
                {IS_WIN ? (
                  <p>Open Myota from your system tray</p>
                ) : (
                  <p>Open Myota from your status bar</p>
                )}
              </div>
              <div>
                <img
                  src={IS_WIN ? sidebarWinPng : sidebarPng}
                  alt="sidebar"
                />
                <p>or from your Finder sidebar</p>
              </div>
            </div>
          )}
          {this.state.step === 2 && (
            <>
              <div className={styles["example-image"]}>
                <div>
                  <img
                    src={IS_WIN ? transferWinPng : transferPng}
                    alt="transfer"
                  />
                </div>
                <div>
                  <div className={styles["item-ex"]}>
                    <img
                      src={IS_WIN ? myotaFoldersWinPng : myotaFoldersPng}
                      alt="folders"
                    />
                    <div className={styles["item-ex__text"]}>
                      Add files & folders as usual...
                    </div>
                  </div>
                  <div className={styles["item-ex"]}>
                    <img src={shieldPng} alt="icon" />
                    <div className={styles["item-ex__text"]}>
                      A sheild appears when secured...
                    </div>
                  </div>
                  <div className={styles["item-ex"]}>
                    <img
                      src={myotaCloudPng}
                      alt="cloud"
                    />
                    <div className={styles["item-ex__text"]}>
                      Files are now safe in the cloud...
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles["ex-text"]}>
                <p>Transfer files to Myota like any other folder.</p>
              </div>
            </>
          )}
          {this.state.step === 3 && (
            <>
              {" "}
              <div className={styles["example-image"]}>
                <div>
                  <img
                    src={IS_WIN ? restoreWinPng : restorePng}
                    alt="restore"
                  />
                </div>
              </div>
              <div className={styles["ex-text"]} style={{ marginTop: "34px" }}>
                <h4>You’re ready to start using Myota.</h4>
                <p>Close this window & get started</p>
              </div>
            </>
          )}

          {this.state.step !== 3 && (
            <button
              className={`${styles.btn} ${styles["btn--next"]}`}
              onClick={() => this.setState({ step: this.state.step + 1 })}
            >
              Next
            </button>
          )}
        </div>
      </div>
    );
  }
}
export default OnBoarding;
