import React, { useContext } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IStoragePoolShort, IStoragePoolsRequestParams, StoragePoolSortField } from "../../../api/entities/storage-pool";
import { IStoragePoolsV2RequestParams, IStoragePoolV2ListItem } from "../../../api/entities/storage-pool-v2";
import { Formatters } from "../../../helper/formatters";
import { storagePoolV1Actions, storagePoolV1ThunkActions, storagePoolV2Actions, storagePoolV2ThunkActions } from "../../../redux/actions/storage-pool";
import { IAppState } from "../../../redux/reducers";
import { useNavigate } from "../../../utils/hooks/use-navigate";
import { routeUtils } from "../../../utils/route-utils";
import Table from "../../shared-ui/table/table";
import tableStyles from "../../shared-ui/table/table.module.scss";
import { VersionContext } from "../storage-pools";
import { MoreOption } from "./moreoption";
import styles from "./storage-pools-table.module.scss";
import { Toolbar } from "./toolbar";
import { AccountType } from "../../../api/entities/user";
import { getOwnerColumn } from "../../v2/common/sections/owner-column/owner-column";

function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, void, Action>) {
    return {
        fetchPageV1: (requestParams: IStoragePoolsRequestParams) => {
            return dispatch(storagePoolV1ThunkActions.entity.fetchPage(requestParams));
        },
        fetchPageV2: (requestParams: IStoragePoolsV2RequestParams) => {
            return dispatch(storagePoolV2ThunkActions.entity.fetchPage(requestParams));
        },
        selectAllV1: () => {
            return dispatch(storagePoolV1ThunkActions.entity.selectAll());
        },
        selectAllV2: () => {
            return dispatch(storagePoolV2ThunkActions.entity.selectAll());
        },
        setSelectedV1: (selected: Record<number, IStoragePoolShort>) => {
            return dispatch(storagePoolV1Actions.entity.setSelection(selected));
        },
        setSelectedV2: (selected: Record<number, IStoragePoolV2ListItem>) => {
            return dispatch(storagePoolV2Actions.entity.setSelection(selected));
        }
    };
}

const mapStateToProps = (state: IAppState) => ({
    accountType: state.users.currentUser.accountType,

    pageV1: state.storagePoolV1.page,
    selectionV1: state.storagePoolV1.selection,

    pageV2: state.storagePoolV2.page,
    selectionV2: state.storagePoolV2.selection
});


interface IStoragePoolTableProps extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
}

export const StoragePoolsTable = connect(mapStateToProps, mapDispatchToProps)((props: IStoragePoolTableProps) => {
    const navigate = useNavigate();
    const version = useContext(VersionContext);

    function getStorageUsageColumn() {
        return version === 1 ? [{
            label: "Usage",
            className: styles["col--usage"],
            formatter: row => Formatters.formatStorageSpace(row.usageBytes),
            sortKey: StoragePoolSortField.Usage
        }] : [];
    }

    function getV2OwnerColumn() {
        return (version === 2 && props.accountType === AccountType.Enterprise)
            ? [getOwnerColumn(null, styles["col--owner"])]
            : [];
    }

    function getProp(name: string) {
        return props[`${name}V${version}`];
    }

    const columns = [
        {
            label: "Name",
            className: styles["col--name"],
            formatter: row => row.name,
            sortKey: StoragePoolSortField.Name
        },
        {
            label: "Id",
            className: styles["col--id"],
            formatter: row => row.id,
            sortKey: StoragePoolSortField.Id
        },
        ...getStorageUsageColumn(),
        ...getV2OwnerColumn(),
        {
            label: "Manage",
            className: tableStyles["col--manage"],
            formatter: row => (<MoreOption data={row}/>),
        },
    ];


    return (
        <Table
            testId="storage-pool-table"
            hideCheckbox={version === 2}
            columns={columns}
            rows={getProp('page').entities}
            totalRows={getProp('page').count}
            loading={getProp('page').isLoading}
            requestParams={getProp('page').searchParams}
            onRequestParamsChanged={(params: any) => getProp('fetchPage')(params)}
            onRowClick={row => navigate(routeUtils.getRouteToStoragePoolDetails(row.id, version))}
            responsive={true}
            selected={getProp('selection')}
            onSelectAll={getProp('selectAll')}
            onSetSelected={getProp('setSelected')}
            toolbar={
                (selected, reset) => (
                    <Toolbar
                        selected={selected}
                        onClose={() => getProp('setSelected')({})}
                    />
                )
            }
        />
    );
});
