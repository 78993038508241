import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IAuthenticationRequest } from "../../../api/entities/user";
import { accountService } from "../../../api/services/account-service";
import { KEYS } from "../../../constants/keys";
import { accountActions, accountThunkActions } from "../../../redux/actions/account";
import { IAppState } from "../../../redux/reducers";
import { formUtils, IFormField, useField } from "../../../utils/form-utils";
import { useApi } from "../../../utils/hooks/use-api";
import { useNavigate } from "../../../utils/hooks/use-navigate";
import { queryUtils } from "../../../utils/query-utils";
import { validators } from "../../../utils/validators";
import { FormField } from "../../shared-ui/form/form-field/form-field";
import { FormPanel } from "../../shared-ui/form/form-panel/form-panel";
import { PasswordInput } from "../../shared-ui/input/password-input/password-input";
import { confirmationParametersTypeMap, defaultConfirmationParameters } from "../confirmation/confirmation";
import styles from "./reset-password.module.scss";

export interface IResetPasswordForm {
    password: IFormField<string>;
    passwordConfirmation: IFormField<string>;
}

export interface IResetPasswordFormValue {
    password: string;
    passwordConfirmation: string;
}

const resetPasswordFormDefaultValue: IResetPasswordFormValue = {
    password: "",
    passwordConfirmation: ""
}

const mapStateToProps = (state: IAppState) => ({
    user: state.users.currentUser,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IAppState, void, Action>
) => ({
    login: (accountRequest: IAuthenticationRequest) =>
        dispatch(accountThunkActions.login(accountRequest)),
    removeLoginError: () =>
        dispatch(accountActions.removeLoginErrorAction())
});

interface IResetPasswordProps
    extends ReturnType<typeof mapStateToProps>,
            ReturnType<typeof mapDispatchToProps> {
}

export const ResetPassword = connect(mapStateToProps, mapDispatchToProps)((props: IResetPasswordProps) => {
    const [api] = useApi();
    const navigate = useNavigate();
    const { search } = useLocation();
    const confirmationParams = {
        ...defaultConfirmationParameters,
        ...queryUtils.parseSearchParams(new URLSearchParams(search), confirmationParametersTypeMap)
    };

    const form: IResetPasswordForm = {
        password: useField<string>(resetPasswordFormDefaultValue.password, [
            validators.required("Password is required"),
            (value: string) => validators.isPassword(value) ? "" : `Provided password is not valid`
        ]),
        passwordConfirmation: useField<string>(resetPasswordFormDefaultValue.passwordConfirmation,
            [validators.required("Password confirmation is required"), comparePasswords]
        )
    };
    const formError = formUtils.getFormError(form);

    function comparePasswords(value: string): string {
        return form.password.value !== value ? "Passwords do not match" : ""
    }

    function handleResetPassword() {
        formUtils.validateAll(form).then((isValid) => {
            if (isValid) {
                const formValue = formUtils.getFormValue<IResetPasswordFormValue>(form);
                const request = {
                    email: confirmationParams.email,
                    token: confirmationParams.token,
                    password: formValue.password,
                    passwordConfirmation: formValue.passwordConfirmation,
                };
                api(accountService.resetPassword(request))
                    .then(() => {
                        props.removeLoginError();
                        props.login({
                            ...request,
                            token: undefined
                        } as IAuthenticationRequest);
                    });
            }
        });
    }

    useEffect(() => {
        if (props.user) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user]);

    return (
        <div className={styles.container}>
            <div className={styles.wrap}>
                <CssBaseline/>
                <div className={styles.paper}>
                    <div
                        className={styles.content}
                        onKeyDown={(e) => {
                            if (e.key === KEYS.Enter && !formError) {
                                handleResetPassword();
                            }
                        }}
                    >
                        <h3
                            className={styles.title}
                            data-testid="reset-password-title-text"
                        >
                            Password Update
                        </h3>

                        <FormPanel
                            className={styles.root}
                            okButtonText="Update password"
                            okButtonClick={handleResetPassword}
                            okButtonDisabled={!!formError}
                            errorMessage={formError}
                        >
                            <FormField label="Password" testId="password-field">
                                <PasswordInput
                                    className={styles.password}
                                    testId="password-input"
                                    field={form.password}
                                    placeholder="Password"
                                />
                            </FormField>

                            <FormField label="Password Confirmation" testId="password-confirmation-field">
                                <PasswordInput
                                    className={styles.passwordConfirmation}
                                    testId="password-confirmation-input"
                                    field={form.passwordConfirmation}
                                    placeholder="Password Confirmation"
                                />
                            </FormField>

                            <div className={styles["password-validation-rules-desc"]}>
                                Password should be the length of 8 or longer and should contain at least one character out of all of the following categories:
                                <ul>
                                    <li>Lower case and upper case english letters</li>
                                    <li>Digit</li>
                                    <li>Special symbol, such as <span>!@#$%^&*_=+.,/?~</span></li>
                                </ul>
                            </div>
                        </FormPanel>
                    </div>
                </div>
            </div>
        </div>
    );
});
