import { IIdName } from './id-name';
import { IPageRequestParams } from './page';
import { IUserShort } from './user';

export enum DataMigrationStatus {
  NotStarted = 0,
  InProgress = 1,
  Cancelled = 2,
  Failed = 3,
  Completed = 4
}

export enum DataMigrationType {
  UserAccount = 1,
  RepositoryBetweenPools = 2
}

export interface IDataMigrationBase {
  id: number;
  externalMigrationId: string;
  startDateTime: string;
  endDateTime?: string;
  dataMigrationStatus: DataMigrationStatus;
  dataMigrationType: DataMigrationType
  progressPercentage: number;
}
export interface IRepoDataMigration extends IDataMigrationBase {
  sourceStoragePool: IIdName;
  destinationStoragePool: IIdName;
}
export interface IUserDataMigrationCreateRequest {
  sourceUserId: number;
  destinationUserId: number;
}

export interface IUserDataMigration extends IDataMigrationBase {
  sourceUser: IUserShort;
  destinationUser: IUserShort;
  estimatedSeconds: number;
}

export interface IDataMigrationPageRequestParams extends IPageRequestParams {
  dataMigrationStatus?: DataMigrationStatus;
}

export interface IDataMigrationDeleteRequest {
  migrationIds: number[];
}