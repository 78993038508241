import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IAppState } from "../../redux/reducers";
import { useNavigate } from "../../utils/hooks/use-navigate";
import { ResetPasswordRequestDialog } from "./dialogs/reset-password/reset-password-request";
import { SignIn } from "./dialogs/signin/signin";
import { SignUp } from "./dialogs/signup/signup";
import styles from "./login.module.scss";

const mapStateToProps = (state: IAppState) => ({
  user: state.users.currentUser,
});

interface ILoginProps
    extends ReturnType<typeof mapStateToProps> {}

export const Login = connect(mapStateToProps, null)((props: ILoginProps) => {
  const navigate = useNavigate();

  const [isSignInModalWindowOpen, setIsSignInModalWindowOpen] = useState<boolean>(true);

  const [isSignUpModalWindowOpen, setIsSignUpModalWindowOpen] = useState<boolean>(false);
  const [isResetPasswordRequestModalWindowOpen, setIsResetPasswordRequestModalWindowOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isSignUpModalWindowOpen || isResetPasswordRequestModalWindowOpen) {
      setIsSignInModalWindowOpen(false);
    }
    else {
      setIsSignInModalWindowOpen(true);
    }
  }, [isSignUpModalWindowOpen, isResetPasswordRequestModalWindowOpen]);

  useEffect(() => {
    if (props.user) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  return (
    <div className={styles.container}>
      <div className={styles.loginwrap}>
        <CssBaseline />

        <SignIn
            open={isSignInModalWindowOpen}
            onClose={() => {}}
            onSignUpPopUpWindowOpen={() => setIsSignUpModalWindowOpen(true)}
            onResetPasswordPopUpWindowOpen={() => setIsResetPasswordRequestModalWindowOpen(true)}
          />
        <SignUp
            open={isSignUpModalWindowOpen}
            onClose={() => setIsSignUpModalWindowOpen(false)}
          />
        <ResetPasswordRequestDialog
            open={isResetPasswordRequestModalWindowOpen}
            onClose={() => setIsResetPasswordRequestModalWindowOpen(false)}
          />
      </div>
    </div>
  );
});
