import React from "react";
import { IBucketListItem } from "../../../../../api/entities/bucket";
import { BUCKETS_V2_ROUTE } from "../../../../../constants/routes";
import { useNavigate } from "../../../../../utils/hooks/use-navigate";
import { IImportError } from "../../../../../utils/import-utils";
import { InfoDialog } from "../../../../shared-ui/dialogs/common/info-dialog/info-dialog";
import { LinkButton } from "../../../../shared-ui/linkButton/linkButton";
import Table, { IColumnDefinition } from "../../../../shared-ui/table/table";
import styles from "./import-result-dialog.module.scss";

export interface IImportResultDialogProps {
    isOpen: boolean;
    errors?: IImportError[];
    bucket?: IBucketListItem;
    onClose: () => void;
}

export function ImportResultDialog(props: IImportResultDialogProps) {
    const navigate = useNavigate();
    const hasErrors = !!props.errors && props.errors.length > 0;

    const columns: IColumnDefinition<IImportError, string>[] = [
        {
            label: "Property",
            className: styles["col--property"],
            formatter: row => row.property
        },
        {
            label: "Error",
            className: styles["col--error-message"],
            formatter: row => row.message
        }
    ];

    function redirectToBucketDetails(id: string) {
        let redirectUrl = `${BUCKETS_V2_ROUTE}/${id}`;

        props.onClose();
        return navigate(redirectUrl);
    }

    return (
        <InfoDialog
            testId='import-results-dialog'
            title={hasErrors ? 'Import failed': 'Import succeeded'}
            showOkButton={true}
            open={props.isOpen}
            onClose={() => {
                props.onClose();
            }}
        >
            <div className={styles.content}>
                {!!props.bucket &&
                    <>
                        <div>
                            Bucket was successfully created. The details are accessible via &nbsp;
                            <LinkButton
                                testId="bucket-detail-redirect-btn"
                                onClick={() => redirectToBucketDetails(props.bucket.id)}>
                                this link
                            </LinkButton>.
                        </div>
                    </>
                }

                {hasErrors &&
                    <>
                        <div>
                            Import was unsuccessful. There were the following errors.
                        </div>

                        <Table
                            testId={"import-errors-table"}
                            hideCheckbox={true}
                            columns={columns}
                            rows={props.errors}
                            compact={true}
                            requestParams={{}}
                            hidePaging={true}
                        />
                    </>
                }
            </div>
        </InfoDialog>
    );
}
