export const colors = {
  white: "#FFFFFF",
  black: "#292929",
  veryLightGray: "#DBDBDB",
  darkOrange: "#CB4100",
  bluishGrey: "#6C969D",
  smaltBlue: "#567F86",
  darkBlueGrey: "#171F38",
  red: "#CB0000",
  grassyGreen: "#289F04",
  yellow: "#EFAF00",
  pestoGray: "#a8a8a8",
  geminiGray: "#707070",
  tangerine: "#F58400",
  alabaster: "#FAFAFA",
  mystic: "#EDF0F5"
};


