import React from "react";
import clientStyles from "../client.module.scss";
import Button from "../../shared-ui/button";
import logoPng from "../../../assets/logo.png";
import { useLocation } from "react-router-dom";

export function ErrorPage() {
  const { search } = useLocation<Location>();
  const params = new URLSearchParams(search);

  const title = params.get("title") || `Oh no! You’ve encountered an error.`;
  const message = params.get("message");
  const loginUri = params.get("login_uri");

  return (
    <div className={clientStyles.container}>
      <div className={clientStyles["data-wrapper"]}>
        <img src={logoPng} alt="logo" className={clientStyles.logo} />
        <h1>{title}</h1>
        <p>{message}</p>
        {loginUri && (
          <Button testId="return-to-login-link" href={loginUri} className={clientStyles["return-link"]}>
            Return to login
          </Button>
        )}
      </div>
    </div>
  );
}
