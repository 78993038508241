import React, { Component } from "react";
import styles from "./recycle-bin.module.scss";
import logoPng from "../../../assets/logo.png";
import step1Png from "./step1.png";
import step2Png from "./step2.png";
import step3Png from "./step3.png";
import step4Png from "./step4.png";

class RecycleBinPage extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles["container-style"]}>
          <div
            className={`${styles.row} ${styles["justify-content-center"]} ${styles["pt-4"]}`}
          >
            <img src={logoPng} alt="logo" className={styles.logo} />
          </div>
          <div className={styles.row}>
            <div
              className={`${styles["text-center"]} ${styles["below-header"]} ${styles["pb-4"]}`}
            >
              <h1>Restoring Deleted Files in Myota</h1>
              <p>Restore almost anything from the cloud.</p>
            </div>
          </div>
        </div>

        <div role="main" className={styles["container-style"]}>
          <div className={styles["step-outer"]}>
            <div className={styles["step-main"]}>
              <div className={styles["step-description"]}>
                <span>1</span>
                <p>
                  <strong>Right Click</strong> your Myota folder and select{" "}
                  <strong>Version History</strong>
                </p>
              </div>
              <div>
                <img src={step1Png} alt="" />
              </div>
            </div>
            <div className={styles["step-main"]}>
              <div>
                <img src={step2Png} alt="" />
              </div>
              <div className={styles["step-description"]}>
                <span>2</span>
                <p>
                  Select the file you are looking for and choose{" "}
                  <strong>Show Versions</strong>
                </p>
              </div>
            </div>
            <div className={styles["step-main"]}>
              <div className={styles["step-description"]}>
                <span>3</span>
                <p>
                  <strong>Select your deleted file</strong> that you would like
                  to restore.
                </p>
              </div>
              <div>
                <img src={step3Png} alt="" />
              </div>
            </div>
            <div className={styles["step-main"]}>
              <div>
                <img src={step4Png} alt="" />
              </div>
              <div className={styles["step-description"]}>
                <span>4</span>
                <p>
                  Simply click to <strong>restore file</strong> and it will
                  return to your <strong>Myota folder</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RecycleBinPage;
