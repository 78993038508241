class TestUtils {
    stringToTestId(str: string): string {
        if (str) {
            return str.toLowerCase()
                .replaceAll(" ", "-")
                .replaceAll(":", "")
                .replaceAll(".", "");
        }
    }
}

export const testUtils = new TestUtils();