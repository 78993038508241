import CssBaseline from "@mui/material/CssBaseline";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { accountService } from "../../../api/services/account-service";
import { useApi } from "../../../utils/hooks/use-api";
import { useNavigate } from "../../../utils/hooks/use-navigate";
import { QueryPropertyType, QueryTypeMap, queryUtils } from "../../../utils/query-utils";
import { routeUtils } from "../../../utils/route-utils";
import Button from "../../shared-ui/button";
import styles from "./confirmation-enterprise.module.scss";
import { formUtils, IFormField, useField } from "../../../utils/form-utils";
import { validators } from "../../../utils/validators";
import { KEYS } from "../../../constants/keys";
import { FormPanel } from "../../shared-ui/form/form-panel/form-panel";
import { FormField } from "../../shared-ui/form/form-field/form-field";
import { PasswordInput } from "../../shared-ui/input/password-input/password-input";

interface IConfirmationEnterpriseProps {
}

export interface IConfirmationEnterpriseForm {
    name: IFormField<string>;
    email: IFormField<string>;
    password: IFormField<string>;
    passwordConfirmation: IFormField<string>;
}

export interface IConfirmationEnterpriseFormValue {
    name: string;
    email: string;
    password: string;
    passwordConfirmation: string;
}

const confirmationEnterpriseFormDefaultValue: IConfirmationEnterpriseFormValue = {
    name: "",
    email: "",
    password: "",
    passwordConfirmation: ""
}

export interface IConfirmationEnterpriseParams {
    email: string;
    token: string;
}

export const confirmationParametersTypeMap: QueryTypeMap<IConfirmationEnterpriseParams> = {
    email: QueryPropertyType.String,
    token: QueryPropertyType.String,
}

export const defaultConfirmationParameters: IConfirmationEnterpriseParams = {
    email: "",
    token: "",
}

export const ConfirmationEnterprise = (props: IConfirmationEnterpriseProps) => {
    const [api] = useApi();
    const navigate = useNavigate();
    const { search } = useLocation();
    const confirmationParams = {
        ...defaultConfirmationParameters,
        ...queryUtils.parseSearchParams(new URLSearchParams(search), confirmationParametersTypeMap)
    };

    const form: IConfirmationEnterpriseForm = {
        name: useField<string>(confirmationEnterpriseFormDefaultValue.name, [validators.required("Name is required")]),
        email: useField<string>(confirmationParams.email),
        password: useField<string>(confirmationEnterpriseFormDefaultValue.password, [
            validators.required("Password is required"),
            (value: string) => validators.isPassword(value) ? "" : `Provided password is not valid`
        ]),
        passwordConfirmation: useField<string>(confirmationEnterpriseFormDefaultValue.passwordConfirmation,
            [validators.required("Password confirmation is required"), comparePasswords]
        )
    };
    const formError = formUtils.getFormError(form);

    function comparePasswords(value: string): string {
        return form.password.value !== value ? "Passwords do not match" : ""
    }

    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);


    function handleConfirmEnterpriseAccount() {
        formUtils.validateAll(form).then((isValid) => {
            if (isValid && !!confirmationParams.email && !!confirmationParams.token) {
                const formValue = formUtils.getFormValue<IConfirmationEnterpriseFormValue>(form);
                const request = {
                    name: formValue.name,
                    email: confirmationParams.email,
                    token: confirmationParams.token,
                    password: formValue.password,
                    passwordConfirmation: formValue.passwordConfirmation
                };

                api(accountService.confirmEnterpriseAccount(request))
                    .then(() => {
                        setIsConfirmed(true);
                    });
            }
        });
    }

    return (
        <div className={styles.container}>
            <div className={styles.loginwrap}>
                <CssBaseline/>
                <div className={styles.paper}>
                    {isConfirmed ?
                        <>
                            <div>Your account has been confirmed.</div>

                            <Button
                                colors="primary"
                                onClick={() => navigate(routeUtils.getRouteToSignInForm())}
                                className={styles.loginButton}
                                testId="login-button"
                            >
                                Login
                            </Button>
                        </>
                        :
                        <div
                            className={styles.content}
                            onKeyDown={(e) => {
                                if (e.key === KEYS.Enter && !formError) {
                                    handleConfirmEnterpriseAccount();
                                }
                            }}
                        >
                            <h3
                                className={styles.title}
                                data-testid="confirmation-enterprise-title-text"
                            >
                                Password Update
                            </h3>

                            <FormPanel
                                className={styles.root}
                                okButtonText="Update account data"
                                okButtonClick={handleConfirmEnterpriseAccount}
                                okButtonDisabled={!!formError}
                                errorMessage={formError}
                            >
                                <FormField label="Email" testId="email-field">
                                    <PasswordInput
                                        className={styles.password}
                                        testId="email-input"
                                        field={form.email}
                                        placeholder="email@corp.io"
                                        readOnly={true}
                                    />
                                </FormField>

                                <FormField label="Name" testId="name-field">
                                    <PasswordInput
                                        className={styles.password}
                                        testId="name-input"
                                        field={form.name}
                                        placeholder="Name"
                                    />
                                </FormField>

                                <FormField label="Password" testId="password-field">
                                    <PasswordInput
                                        className={styles.password}
                                        testId="password-input"
                                        field={form.password}
                                        placeholder="Password"
                                    />
                                </FormField>

                                <FormField label="Password Confirmation" testId="password-confirmation-field">
                                    <PasswordInput
                                        className={styles.passwordConfirmation}
                                        testId="password-confirmation-input"
                                        field={form.passwordConfirmation}
                                        placeholder="Password Confirmation"
                                    />
                                </FormField>

                                <div className={styles["password-validation-rules-desc"]}>
                                    Password should be the length of 8 or longer and should contain at least one character out of all of the following categories:
                                    <ul>
                                        <li>Lower case and upper case english letters</li>
                                        <li>Digit</li>
                                        <li>Special symbol, such as <span>!@#$%^&*_=+.,/?~</span></li>
                                    </ul>
                                </div>
                            </FormPanel>
                        </div>

                    }
                </div>
            </div>
        </div>
    );
};
