import React, { PropsWithChildren } from "react";
import styles from "./filters-data-bar.module.scss";
import { LinkButton } from "../../linkButton/linkButton";
import { IStyleableComponentProps } from "../../common/component-props";
import clsx from "clsx";

interface IFiltersDataBarProps extends IStyleableComponentProps {
  clearFilter: () => void;
}

export function FiltersDataBar(props: PropsWithChildren<IFiltersDataBarProps>) {
  return (
    <div
      data-testid="filters-data"
      className={clsx(styles.root, props.className)}
      style={props.style}
    >
      <div className={styles.header}>
        <div>Filtering by:</div>
        <LinkButton testId="clear-filters" onClick={props.clearFilter}>
          CLEAR ALL
        </LinkButton>
      </div>
      <div className={styles.items}>{props.children}</div>
    </div>
  );
}
