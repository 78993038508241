import { IDialogState } from ".";
import { CommonAction, CommonActionType } from "../actions/common";

export interface IConfirmationPayload {
  borderColorRed: false;
  description: string;
  handleClick: () => void;
  buttonTitle: string;
}

export interface ICommonState {
  deleteConfirmation: IDialogState<any[]>;
  openAlertDialog: boolean;
  description: string;
  title: string;
  secondaryText: string;
  ActionTitle: string;
  successFlag: boolean;
  alertFlag: boolean;
  showConfirmationFlag: boolean;
  userNameAction: Function;
  buttonAction: Function;
  selectedUserArray: any[];
  confirmationPayload: IConfirmationPayload;
  snackBarFlag: boolean;
  snackBarMessage: string;
  dataApiError: null;
  isLoading: boolean;
  loadingCount: number;
  isLeftPanelOpen: boolean;
  isRightNavOpen: boolean;
  changesConflictInfo: IDialogState<string>;
}

export interface IAsyncDataState<T> {
  loading: boolean;
  data: T;
}

export const ASYNC_DATA_DEFAULT_STATE: IAsyncDataState<null> = {
  loading: false,
  data: null,
};

export const COMMON_DEFAULT_STATE: ICommonState = {
  deleteConfirmation: {
    isOpen: false,
    data: null,
  },
  openAlertDialog: false,
  description: "Description",
  title: "Title",
  secondaryText: "",
  ActionTitle: "Button Title",
  successFlag: false,
  alertFlag: false,
  showConfirmationFlag: false,
  userNameAction: Function,
  buttonAction: Function,
  selectedUserArray: [],
  confirmationPayload: {
    borderColorRed: false,
    description: ``,
    handleClick: () => {},
    buttonTitle: "",
  },
  snackBarFlag: false,
  snackBarMessage: "",
  dataApiError: null,
  isLoading: false,
  loadingCount: 0,
  isLeftPanelOpen: false,
  isRightNavOpen: false,
  changesConflictInfo: {
    isOpen: false,
    data: null
  }
};

export default function common(
  state: ICommonState = COMMON_DEFAULT_STATE,
  action: CommonAction
): ICommonState {
  switch (action.type) {
    case CommonActionType.OpenDeleteConfirmation:
      return {
        ...state,
        deleteConfirmation: { isOpen: true, data: action.data },
      };
    case CommonActionType.CloseDeleteConfirmation:
      return {
        ...state,
        deleteConfirmation: {
          ...state.deleteConfirmation,
          isOpen: false,
        },
      };
    case CommonActionType.OpenChangesConflictInfo:
      return {
        ...state,
        changesConflictInfo: { isOpen: true, data: action.data },
      };
    case CommonActionType.CloseChangesConflictInfo:
      return {
        ...state,
        changesConflictInfo: { isOpen: false, data: null },
      };
    case CommonActionType.OpenAlertDialog:
      return {
        ...state,
        successFlag: false,
        ...action.payload,
      };
    case CommonActionType.CloseAlertDialog:
      return {
        ...state,
        openAlertDialog: false,
        selectedUserArray: [],
      };
    case CommonActionType.SetConfirmationFlag:
      return {
        ...state,
        showConfirmationFlag: action.flag,
        confirmationPayload: action.confirmationPayload,
      };
    case CommonActionType.DialogActionSucess:
      return {
        ...state,
        successFlag: action.flag,
        description:
          action.description === "" ? state.description : action.description,
        title: action.newTitle === "" ? state.title : action.newTitle,
      };
    case CommonActionType.SnackbarFlag:
      return {
        ...state,
        snackBarFlag: action.flag,
        snackBarMessage: action.message,
      };
    case CommonActionType.StartLoading:
      return {
        ...state,
        loadingCount: state.loadingCount + 1,
        isLoading: true,
      };

    case CommonActionType.StopLoding:
      return {
        ...state,
        loadingCount: state.loadingCount - 1,
        isLoading: state.loadingCount > 1,
      };

    case CommonActionType.SetLeftPanelOpen:
      return {
        ...state,
        isRightNavOpen: false,
        isLeftPanelOpen: action.flag,
      };

    case CommonActionType.SetRightNavOpen:
      return {
        ...state,
        isLeftPanelOpen: false,
        isRightNavOpen: action.flag,
      };

    default:
      return state;
  }
}
