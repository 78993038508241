import React from "react";
import { IFormField } from "../../../../utils/form-utils";
import { FormField } from "../../../shared-ui/form/form-field/form-field";
import FormSection from "../../../shared-ui/form/form-section/form-section";
import TextInput from "../../../shared-ui/input/text-input/text-input";
import styles from "./pool-name-section.module.scss";


export interface IPoolNameSectionProps {
  name: IFormField<string>;
}

export function PoolNameSection(props: IPoolNameSectionProps) {
  return (   
    <FormSection testId="storage-pool-name-section" title="Storage Pool Name" className={styles.section}>
      <FormField label="Storage Pool Name" error={props.name.error} testId="storage-pool-name-field">
        <TextInput
          className={styles.name}
          testId="storage-pool-name-input"
          field={props.name}
          placeholder="ex 'default storage pool'"
          autoFocus={true}
        />
      </FormField>
    </FormSection>
  );
}