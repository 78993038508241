import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { storagePoolV1Actions, storagePoolV1ThunkActions, storagePoolV2Actions, storagePoolV2ThunkActions } from "../../../../redux/actions/storage-pool";
import { IAppState } from "../../../../redux/reducers";
import { useField } from "../../../../utils/form-utils";
import { ConfirmDialog } from "../../../shared-ui/dialogs/common/confirm-dialog/confirm-dialog";
import TextInput from "../../../shared-ui/input/text-input/text-input";
import styles from "./delete-confirmation.module.scss";


const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    closeDeleteConfirmation: () => {
      dispatch(storagePoolV1Actions.deleteConfirmation.close());
      dispatch(storagePoolV2Actions.deleteConfirmation.close());
    },
    deleteStoragePoolV1: (storagePoolId: number) =>
      dispatch(storagePoolV1ThunkActions.delete(storagePoolId)),
    deleteStoragePoolV2: (storagePoolId: string) =>
      dispatch(storagePoolV2ThunkActions.delete(storagePoolId))
  };
};

const mapStateToProps = (state: IAppState) => ({
  isOpen: state.storagePoolV1.deleteConfirmation.isOpen || state.storagePoolV2.deleteConfirmation.isOpen,
  version: state.storagePoolV1.deleteConfirmation.isOpen ? 1 : 2,
  data: state.storagePoolV1.deleteConfirmation.data ?? state.storagePoolV2.deleteConfirmation.data,
});

export interface IDeleteConfirmationProps extends 
    ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {
}

export const DeleteConfirmation = connect(mapStateToProps, mapDispatchToProps)((props: IDeleteConfirmationProps) => {
  const deleteTextField = useField<string>("");
  const deleteText = "DELETE";

  function handleConfirm() {
    if (props.version) {
      props.deleteStoragePoolV1(Number(props.data.id));
    }
    else {
      props.deleteStoragePoolV2(String(props.data.id));
    }
    props.closeDeleteConfirmation();
  }

  useEffect(() => {
    deleteTextField.init("");
    // eslint-disable-next-line
  }, [props.isOpen]);

  return (
    <ConfirmDialog
      testId="delete-storage-pool-confirmation"
      title="Delete Storage Pool"
      confirmText="Confirm"
      open={props.isOpen}
      onClose={props.closeDeleteConfirmation}
      onConfirm={handleConfirm}
      isConfirmDisabled={deleteTextField.value !== deleteText}
    >
      <div className={styles.content}>
        Are you sure you want to delete <strong>{props.data?.name}</strong> from Myota? All data will be lost. Type {deleteText} below to confirm.
        <TextInput
          testId="delete-input"
          className={styles["delete-text-field"]}
          placeholder="DELETE"
          field={deleteTextField}
        />
      </div>
    </ConfirmDialog>
  );
});
