import { EntityActions, EntityThunkActions } from './entity';
import { IUserGroup, IUserGroupsRequestParams, UserGroupSortField } from '../../api/entities/user-group';
import { userGroupService } from '../../api/services/user-group-service';

export const USER_GROUP_ENTITY_NAME = "userGroup";

class UserGroupActions {
  readonly entity = new EntityActions<IUserGroup, IUserGroupsRequestParams>(USER_GROUP_ENTITY_NAME);
}

export const userGroupActions = new UserGroupActions();

class UserGroupThunkActions {
  readonly entity = new EntityThunkActions<IUserGroup, UserGroupSortField, IUserGroupsRequestParams>(
    USER_GROUP_ENTITY_NAME, userGroupService);
  }

export const userGroupThunkActions = new UserGroupThunkActions();

