import { IDialogState } from "./index";
import {
  IDevicesRequestParams,
  IDeviceCompact,
  DeviceSortField,
  IDeviceListItem,
} from "./../../api/entities/device";
import { IDevice } from "../../api/entities/device";
import { SortDirection } from "../../api/entities/page";
import { DevicesActionType, DevicesAction, DENY_ACCESS_CONFIRMATION, ALLOW_ACCESS_CONFIRMATION, ACCESS_ALLOWED_INFO, ACCESS_DENIED_INFO, DEVICE_ENTITY_NAME, AUTHORIZE_CONFIRMATION, AUTHORIZED_INFO } from "../actions/devices";
import { IDeviceDenyAccessConfirmationData } from "../../components/devices/dialogs/device-deny-access-confirmation/device-deny-access-confirmation";
import { IDeviceAllowAccessConfirmationData } from "../../components/devices/dialogs/device-allow-access-confirmation/device-allow-access-confirmation";
import { createEntityReducer,getEntityDefaultState,IEntityState } from "./entity";
import { createDialogReducer, DIALOG_DEFAULT_STATE } from "./dialog";

export interface IDevicesState extends IEntityState<IDeviceListItem, DeviceSortField, IDevicesRequestParams> {
  deviceDetail: IDevice;
  deviceDetailLoading: number;
  deviceDetailRequestedDeviceId: number;
  deviceTypes: {
    isLoading: boolean;
    entities: string[];
  };
  [DENY_ACCESS_CONFIRMATION]: IDialogState<IDeviceDenyAccessConfirmationData>;
  [ALLOW_ACCESS_CONFIRMATION]: IDialogState<IDeviceAllowAccessConfirmationData>;
  [AUTHORIZE_CONFIRMATION]: IDialogState<IDeviceCompact>;
  [AUTHORIZED_INFO]: IDialogState<IDeviceCompact>;
  [ACCESS_ALLOWED_INFO]: IDialogState<IDeviceCompact>;
  [ACCESS_DENIED_INFO]: IDialogState<IDeviceCompact>;
}

export const DEVICES_DEFAULT_STATE: IDevicesState = {
  ...getEntityDefaultState(DeviceSortField.Email, SortDirection.Ascending),
  deviceTypes: {
    isLoading: false,
    entities: [],
  },
  deviceDetail: null,
  deviceDetailLoading: 0,
  deviceDetailRequestedDeviceId: null,
  [DENY_ACCESS_CONFIRMATION]: DIALOG_DEFAULT_STATE,
  [ALLOW_ACCESS_CONFIRMATION]: DIALOG_DEFAULT_STATE,
  [AUTHORIZE_CONFIRMATION]: DIALOG_DEFAULT_STATE,
  [AUTHORIZED_INFO]: DIALOG_DEFAULT_STATE,
  [ACCESS_ALLOWED_INFO]: DIALOG_DEFAULT_STATE,
  [ACCESS_DENIED_INFO]: DIALOG_DEFAULT_STATE
};

const entityReducer = createEntityReducer(DEVICE_ENTITY_NAME);
const denyAccessConfirmationReducer = createDialogReducer(DEVICE_ENTITY_NAME, DENY_ACCESS_CONFIRMATION);
const allowAccessConfirmationReducer = createDialogReducer(DEVICE_ENTITY_NAME, ALLOW_ACCESS_CONFIRMATION);
const authorizeConfirmationReducer = createDialogReducer(DEVICE_ENTITY_NAME, AUTHORIZE_CONFIRMATION);
const authorizedInfoReducer = createDialogReducer(DEVICE_ENTITY_NAME, AUTHORIZED_INFO);
const accessAllowedInfoReducer = createDialogReducer(DEVICE_ENTITY_NAME, ACCESS_ALLOWED_INFO);
const accessDeniedInfoReducer = createDialogReducer(DEVICE_ENTITY_NAME, ACCESS_DENIED_INFO);

export default function devices(
  state = DEVICES_DEFAULT_STATE,
  action: DevicesAction
) {

  state = entityReducer(state, action);
  state = denyAccessConfirmationReducer(state, action);
  state = allowAccessConfirmationReducer(state, action);
  state = accessAllowedInfoReducer(state, action);
  state = accessDeniedInfoReducer(state, action);
  state = authorizeConfirmationReducer(state, action);
  state = authorizedInfoReducer(state, action);

  switch (action.type) {
    case DevicesActionType.FetchDetailsRequest:
      return {
        ...state,
        deviceDetailLoading: state.deviceDetailLoading + 1,
        deviceDetailRequestedDeviceId: action.deviceId
      };
    case DevicesActionType.FetchDetailsSuccess:
      return {
        ...state,
        deviceDetail: action.deviceDetail.id === state.deviceDetailRequestedDeviceId ? action.deviceDetail : state.deviceDetail,
        deviceDetailLoading: state.deviceDetailLoading - 1,
      };
    case DevicesActionType.FetchDetailsFail:
      return {
        ...state,
        deviceDetail: null,
        deviceDetailLoading: state.deviceDetailLoading - 1,
      };
    case DevicesActionType.ClearDetails:
      return {
        ...state,
        deviceDetail: null,
        deviceDetailRequestedDeviceId: null
      };
    case DevicesActionType.DeleteSuccess:
      if (action.deviceId === state.deviceDetail?.id) {
        return {
          ...state,
          deviceDetail: null
        }
      }
      return state;
    case DevicesActionType.FetchTypesRequest:
      return {
        ...state,
        deviceTypes: {
          ...state.deviceTypes,
          isLoading: true,
        },
      };
    case DevicesActionType.FetchTypesSuccess:
      return {
        ...state,
        deviceTypes: {
          ...state.deviceTypes,
          entities: action.payload,
          isLoading: false,
        },
      };
    case DevicesActionType.FetchTypesFail:
      return {
        ...state,
        deviceTypes: {
          ...state.deviceTypes,
          isLoading: false,
        },
      };
    default:
      return state;
  }
}
