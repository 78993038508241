import {
  IDevicesRequestParams,
  IDevice,
  IDeviceFileInfo,
  IDeviceListItem,
} from "../entities/device";
import { IPage } from "../entities/page";
import { ApiVersion } from "./api-service";
import { DataService } from "./data-service";

class DevicesService extends DataService {
  constructor() {
    super("device");
  }

  getPage(searchParams: IDevicesRequestParams): Promise<IPage<IDeviceListItem>> {
    return this.api.get<IPage<IDeviceListItem>>(null, searchParams, ApiVersion.V1_1);
  }
  getDeviceTypes(): Promise<string[]> {
    return this.api.get<string[]>("types");
  }
  getClientVersions(): Promise<string[]> {
    return this.api.get<string[]>("clientVersions");
  }
  getDevice(deviceId: number) {
    return this.api.get<IDevice>(`${deviceId}`, null, ApiVersion.V1_1);
  }
  getFileInfo(deviceId: number) {
    return this.api.get<IDeviceFileInfo>(`${deviceId}/fileInfo`);
  }
  approveDevice(deviceId: number | number[]) {
    return this.api.post("approve", this._oneOrManyPayload(deviceId));
  }
  logoutDevice(deviceId: number | number[]) {
    return this.api.post("logout", this._oneOrManyPayload(deviceId));
  }
  unBlockDevice(deviceId: number | number[]) {
    return this.api.post("unblock", this._oneOrManyPayload(deviceId));
  }
  blockDevice(deviceId: number | number[]) {
    return this.api.post("block", this._oneOrManyPayload(deviceId));
  }
  deleteDevice(deviceId: number | number[]) {
    return this.api.post("delete", {
      DevicesID: Array.isArray(deviceId) ? deviceId : [deviceId],
    });
  }

  private _oneOrManyPayload(deviceId: number | number[]) {
    return {
      [Array.isArray(deviceId) ? "DevicesID" : "DeviceID"]: deviceId,
    };
  }
}

export const devicesService = new DevicesService();
