import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { IPolicyShort } from "../../api/entities/policy";
import { BlockUserStatus, IUser, IUserDetails, IUserLoginInfo, IUserShort, IUsersRequestParams, UserSortField, UserStatus, } from "../../api/entities/user";
import { accountService } from "../../api/services/account-service";
import { devicesService } from "../../api/services/devices-service";
import { userService } from "../../api/services/user-service";
import { userUtils } from "../../utils/user-utils";
import { commonActions, handleApi } from "./common";
import { EntityActions, EntityThunkActions } from "./entity";

export enum UsersActionType {
  BlockUser = "USERS_BLOCK",
  FetchDetail = "USERS_FETCH_DETAIL",
  FetchUserPolicies = "USERS_FETCH_POLICIES",
  FetchCurrentUser = "USERS_FETCH_CURRENT",
  SessionExpired = "USERS_SESSION_EXPIRED",
  UserLoggedIn = "USERS_LOGGED_IN"
}

export const USER_ENTITY_NAME = "users";

class UsersActions {
  readonly entity = new EntityActions<IUserShort, IUsersRequestParams>(USER_ENTITY_NAME);

  updateUserStatusAction(flag: UserStatus, id: number) {
    return {
      type: UsersActionType.BlockUser as typeof UsersActionType.BlockUser,
      flag,
      id,
    };
  }
  fetchUserDetailAction(user: IUserDetails) {
    return {
      type: UsersActionType.FetchDetail as typeof UsersActionType.FetchDetail,
      user,
    };
  }
  fetchUserPoliciesAction(policies: IPolicyShort[]) {
    return {
      type: UsersActionType.FetchUserPolicies as typeof UsersActionType.FetchUserPolicies,
      policies,
    };
  }
  userLoggedInAction(loginInfo: IUserLoginInfo) {
    return {
      type: UsersActionType.UserLoggedIn as typeof UsersActionType.UserLoggedIn,
      loginInfo,
    };
  }
  currentUserAction(user: IUserLoginInfo) {
    return {
      type: UsersActionType.FetchCurrentUser as typeof UsersActionType.FetchCurrentUser,
      user,
    };
  }
  logoutCurrentUserAction() {
    return {
      type: UsersActionType.SessionExpired as typeof UsersActionType.SessionExpired,
    };
  }
}

export const usersActions = new UsersActions();

export type UsersAction =
  ReturnType<typeof usersActions.updateUserStatusAction>
  | ReturnType<typeof usersActions.fetchUserDetailAction>
  | ReturnType<typeof usersActions.fetchUserPoliciesAction>
  | ReturnType<typeof usersActions.currentUserAction>
  | ReturnType<typeof usersActions.userLoggedInAction>
  | ReturnType<typeof usersActions.logoutCurrentUserAction>
;

class UsersThunkActions {
  readonly entity = new EntityThunkActions<IUser, UserSortField, IUsersRequestParams>(
    USER_ENTITY_NAME, userService, (user) => userUtils.canSelectUser(user))

  //-----------------FETCH USERS Detail from id-----------//
  fetchUserDetail(uid: string): ThunkAction<void, unknown, unknown, Action> {
    return (dispatch) => {
      handleApi(userService
        .getUserDetail(uid)
        .then((data: any) => {
          if (data) {
            dispatch(usersActions.fetchUserDetailAction(data));
          }
        }), true);
    };
  }

  //----------------api call to block user-----------//
  blockUser(id: number, uid?: string) {
    return (dispatch) => {
      handleApi(userService
        .blockUser(id)
        .then((user) => {
          if (typeof user !== "undefined") {
            dispatch(usersActions.updateUserStatusAction(user.status, id));
            let description =
              user.status === UserStatus.Active
                ? `is now 
            allowed to use Myota.`
                : `is now blocked from using Myota.`;
            dispatch(commonActions.dialogSuccess(true, description, ""));
            dispatch(commonActions.setConfirmationFlag(false));
            if (uid) {
              dispatch(this.fetchUserDetail(uid));
            }
          }
        }), true);
    };
  }

  //----------------api call to block Multi user-----------//
  blockMultiUser(ids: number[], status?: BlockUserStatus) {
    return (dispatch) => {
      handleApi(userService
        .blockUserList(ids, status)
        .then((data) => {
          if (typeof data !== "undefined") {
            dispatch(this.entity.fetchPage());
            dispatch(commonActions.showSnackBar(true, data.msg));
            let description =
              status === BlockUserStatus.Active
                ? `These users are now allowed to 
              access Myota.
              `
                : `These users are now blocked from using Myota.`;
            dispatch(commonActions.dialogSuccess(true, description, ""));
            dispatch(commonActions.setConfirmationFlag(false));
          }
        }), true);
    };
  }

  //----------------api call to force logout Multi users-----------//
  logoutMultiUser(ids: number[]) {
    return (dispatch) => {
      dispatch(commonActions.closeAlert());
      handleApi(userService
        .expire(ids)
        .then((_) => {
        }), true);
    };
  }

  //----------------api call to approve/disapprove devices-----------//
  approveDevice(id: number) {
    return (dispatch) => {
      handleApi(devicesService
        .approveDevice(id)
        .then((_) => {
          dispatch(commonActions.closeAlert());
          dispatch(this.entity.fetchPage());
        }), true);
    };
  }

  fetchUserPolicies() {
    return (dispath) => {
      handleApi(userService
        .getPolicies()
        .then((policies) => {
          if (policies) {
            dispath(usersActions.fetchUserPoliciesAction(policies));
          }
        }));
    };
  }

  getCurrentUser() {
    return (dispatch) => {
      handleApi(accountService
        .getCurrentUser()
        .then((user) => {
          dispatch(usersActions.currentUserAction(user));
        }), true);
    };
  }
}

export const usersThunkActions = new UsersThunkActions();
