import React, { Component } from "react";
import clientStyles from "../client.module.scss";
import logoPng from "../../../assets/logo.png";
import bulbPng from "./bulb.png";

class SuccessPage extends Component {
  render() {
    return (
      <div className={clientStyles.container}>
        <div className={clientStyles["data-wrapper"]}>
          <img src={logoPng} alt="logo" className={clientStyles.logo} />
          <h1>You are now ready to use Myota.</h1>
          <p>Drag and drop your way to safe and secure files.</p>
        </div>

        <header className={clientStyles["header-svg"]}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon fill="white" points="0,100 100,0 100,100" />
          </svg>
        </header>

        <div className={clientStyles["security-wrapper"]}>
          <div className={clientStyles.bulb}>
            <img src={bulbPng} alt="bulb" />
          </div>

          <div className={clientStyles["security-desc"]}>
            <h3>Security Tip #4567</h3>
            <h4>Keep your operating system and all software up to date.</h4>
            <p>
              This is a tip you’ll hear time and time again from security
              professionals. Those pesky OS and software updates more often than
              not contain critical security updates and vulnerability patches.
              Where possible, enable automatic software updates to streamline
              the process.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default SuccessPage;
