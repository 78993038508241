import { ApiVersionNumber } from "../../api/services/data-service";
import { StoragePoolService } from "../../api/services/storage-pool-service";
import { StoragePoolV2Service } from "../../api/services/storage-pool-v2-service";

class StoragePoolServiceFactory {
    private storagePoolService = new StoragePoolService();
    private storagePoolV2Service = new StoragePoolV2Service();

    public getStoragePoolService(apiVersion?: ApiVersionNumber): StoragePoolService | StoragePoolV2Service {
        switch (apiVersion) {
            case 1:
                return this.storagePoolService;
            case 2:
                return this.storagePoolV2Service;
            default:
                throw new Error('Incorrect API Version');
        }
    }
}

export const storagePoolServiceFactory = new StoragePoolServiceFactory();
