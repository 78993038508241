import { IAddressBookPage, IAddressBookPageRequest } from "../entities/account";
import { CitrixShareFileService } from "./citrix-share-file-service";

export class AccountsService extends CitrixShareFileService {
  constructor(token: string, apiUrl: string) {
    super(token, apiUrl, "Accounts");
  }

  getAddressBookPage(pageRequest: IAddressBookPageRequest): Promise<IAddressBookPage> {
    return this.api.get("AddressBook", {
      $skip: pageRequest.skip,
      $top: pageRequest.top,
      $filter: `substringof('${pageRequest.q}', Email) eq true or substringof('${pageRequest.q}', Name) eq true or substringof('${pageRequest.q}', Company) eq true`,
      type: "allwithgroups"
    });
  }
}
