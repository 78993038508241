import React, { PropsWithChildren } from "react";
import styles from "./form-panel.module.scss";
import clsx from "clsx";
import Button from "../../button";
import IComponentProps from "../../common/component-props";

export interface IFormPanelProps extends IComponentProps {
  okButtonText?: string;
  okButtonClick?: () => void;
  okButtonDisabled?: boolean;
  cancelButtonText?: string;
  cancelButtonClick?: () => void;
  contentClassName?: string;
  errorMessage?: string;
  heightToContent?: boolean;
}

export function FormPanel(props: PropsWithChildren<IFormPanelProps>) {
  return (
    <div className={clsx(styles.root, props.className, props.heightToContent && styles["root--height-to-content"])}>
      <div className={clsx(styles.content, props.contentClassName)}>
        {props.children}
      </div>
      <div className={styles.footer}>
          {props.errorMessage && (
              <div className={styles["error-message"]}>
                  {props.errorMessage}
              </div>
          )}
          <div className={styles["footer-buttons"]}>
            {props.cancelButtonClick && (
              <Button
                testId="back-button"
                colors="link"
                onClick={props.cancelButtonClick}
              >
                {props.cancelButtonText || "Cancel"}
              </Button>
            )}
            {props.okButtonClick && (
              <Button
                testId="next-button"
                className={styles["next-button"]}
                colors="primary"
                disabled={props.okButtonDisabled}
                onClick={props.okButtonClick}
              >
                {props.okButtonText || "Save"}
              </Button>
            )}
          </div>
      </div>
    </div>
  );
}
