import React from "react";
import clsx from "clsx";
import IComponentProps from "../common/component-props";
import styles from "./compact-table.module.scss";
import { LocalLoader } from "../loader";
import { IColumnDefinition } from "../table/table";
import Button from "../button";
import { IEntity } from "../../../api/entities/id-name";

export interface ICompactTableProps<TRowData> extends IComponentProps {
  columns: IColumnDefinition<TRowData, null>[];
  rows: TRowData[];
  loading?: boolean;
  onAddClick?: () => void;
  addButtonCaption?: string;
  rowId?: (data: TRowData, rowIndex: number) => string | number
}

export function CompactTable<TRowData>(props: ICompactTableProps<TRowData>) {
  return (
    <div className={clsx(styles.root, props.className)}>
      <LocalLoader isLoading={props.loading}>
        <table data-testid={props.testId || "compact-table"} className={styles.table}>
          <thead>
            <tr>
              {props.columns.map((col, colIndex) => (
                <th className={col.className} key={colIndex} data-testid={col.testId}
                >
                  {col.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.rows.map((row, rowIndex) => {
              const rowId = props.rowId ? props.rowId(row, rowIndex) : (row as any as IEntity).id;
              return (
                <tr data-testid={rowId} className={styles.row} key={rowId}>
                  {props.columns.map((col, colIndex) => (
                    <td className={col.className} key={colIndex} data-testid={col.testId}>
                      {col.formatter(row)}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          {props.onAddClick && (
            <tfoot>
              <tr>
                <td>
                  <Button
                    testId="add-button"
                    colors="primary" 
                    onClick={props.onAddClick}
                  >
                    {props.addButtonCaption ?? "+ ADD"}
                  </Button>
                </td>
                {props.columns.splice(1).map((col, idx) => (
                  <td key={idx}></td>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
      </LocalLoader>
    </div>
  );
}
