import { IUploadFileResponse } from "../entities/storage";
import { CitrixShareFileService } from "./citrix-share-file-service";

export class StorageService extends CitrixShareFileService {
  constructor() {
    super(null, "");
  }

  uploadFile(chunkUri: string, file: File, onProgress?: (percentage: number, loaded: number, total: number) => void): Promise<IUploadFileResponse> {
    return this.api.upload<IUploadFileResponse>(`${chunkUri}&fmt=json`, [file], onProgress, false);
  }
}
