import { CONFIG } from "../../config/config";
import version from "../../version.json";
import { ApiService, FetchInterceptor } from "./api-service";

export type ApiVersionNumber = 1 | 2;

export enum Scope {
    Admin = "https://cs.myota.cloud/admin"
}

export abstract class DataService {
    protected readonly api: ApiService;

    addFetchInterceptor(fetchInterceptor: FetchInterceptor): void {
        this.api.addFetchInterceptor(fetchInterceptor);
    }
    
    removeFetchInterceptor(fetchInterceptor: FetchInterceptor): void {
        this.api.removeFetchInterceptor(fetchInterceptor);
    }

    protected encodeUri(uri?: string): string | null {
        if (!uri) {
            return null;
        }
        return window.btoa(uri);
    }

    constructor(urlPrefix?: string, apiVersion: ApiVersionNumber = 1) {
        this.api = new ApiService(`${CONFIG.apiURL}/v${apiVersion}`, urlPrefix, { myota_agent: `os: aws; version: ${version?.version}`});
    }
}
