import React from "react";
import MoreOptions from "../../../../shared-ui/more-options";
import { IPopupMenuOption } from "../../../../shared-ui/popup-menu";
import { useApi } from "../../../../../utils/hooks/use-api";
import { IInvitation, InvitationStatus, InviteEmailRequest } from "../../../../../api/entities/invitation";
import { invitationService } from "../../../../../api/services/invitation-service";

export interface IMoreOptionProps {
    data: IInvitation;
}

export function MoreOption(props: IMoreOptionProps) {
    const [api] = useApi()

    function onResend(revertIsAdminFlag: boolean = false) {
        let request: Omit<InviteEmailRequest, 'permission'>[] = [
            {
                email: props.data.email,
                isAdmin: revertIsAdminFlag ? props.data.isAdmin : !props.data.isAdmin,
            }
        ]
        api(invitationService.resendInvitations(request));
    }

    function onDelete() {
        api(invitationService.delete(props.data.id));
    }

    function getOptions(): IPopupMenuOption[] {
        const result = [];

        if (props.data.status !== InvitationStatus.Accepted) {
            result.push({
                label: "Resend",
                onClick: onResend
            });
            result.push({
                label: `Change to ${props.data.isAdmin ? 'Employee' : 'Admin'} permission and resend`,
                onClick: () => onResend(true)
            })
        }
        result.push({
            label: "Dismiss",
            onClick: onDelete
        });
        return result;
    }

    return (
        <MoreOptions
            options={getOptions()}
        />
    );
}
