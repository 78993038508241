import React, { useState, useEffect } from "react";
import styles from "../manage-users.module.scss";
import { IUserFilterParams } from "../../../../api/entities/user";
import SearchInput from "../../../shared-ui/input/search-input/search-input";
import { UserGroupFilter } from "../../../shared-ui/filters/user-group-filter";

interface IFilterBarProps {
  onChange: (filters: IUserFilterParams) => void;
}

const FilterBar = (props: IFilterBarProps) => {
  const [filters, setFilters] = useState({} as IUserFilterParams);

  const updateFilters = (p: IUserFilterParams) => {
    setFilters({
      ...filters,
      ...p,
    });
  };

  useEffect(() => {
    props.onChange(filters);
    // eslint-disable-next-line
  }, [filters]);

  return (
    <>
      <div className={styles.filters}>
        <div data-testid="users-table-title" className={styles["list-title"]}>
          YOUR IDP USERS
        </div>
        <div className={styles.filters__bar}>
          <SearchInput
            className={styles.filters__search}
            value={filters.q}
            onChange={q => updateFilters({ q })}
          />
          <UserGroupFilter
            className={styles.filters__profile}
            value={filters.userGroupFilter}
            onChange={(userGroupFilter: number) => updateFilters({ userGroupFilter })}
            autoWidth
          />
        </div>
      </div>
    </>
  );
};

export default FilterBar;
