export enum DialogActionType {
  OpenDialog = "OPEN_DIALOG",
  CloseDialog = "CLOSE_DIALOG",
}

export function formatAction(entityName: string, dialogName: string, actionType: DialogActionType): string {
  return `${entityName}_${dialogName}_${actionType}`;
}

export type OpenActionType<TDialogData> = {
  type: string;
  data: TDialogData;
}

export type CloseActionType = {
  type: string;
}

export class DialogActions<TDialogData> {

  constructor(
    private readonly entityName: string,
    private readonly dialogName: string) {
  }

  open(data: TDialogData): OpenActionType<TDialogData> {
    return {
      type: formatAction(this.entityName, this.dialogName, DialogActionType.OpenDialog),
      data
    };
  }

  close(): CloseActionType {
    return {
      type: formatAction(this.entityName, this.dialogName, DialogActionType.CloseDialog)
    };
  }
}