import { IPage } from "../entities/page";
import { IBucketCreateRequest, IBucketDetailsItem, IBucketListItem, IBucketPageRequestParams, IBucketUpdateRequest } from "../entities/bucket";
import { DataService } from "./data-service";

class BucketService extends DataService {
  constructor() {
    super("bucket", 2);
  }

  getPage(requestParams: IBucketPageRequestParams): Promise<IPage<IBucketListItem>> {
    return this.api.get<IPage<IBucketListItem>>(null, requestParams);
  }

  get(bucketId: string): Promise<IBucketDetailsItem> {
    return this.api.get<IBucketDetailsItem>(bucketId, null);
  }

  create(request: IBucketCreateRequest) {
    return this.api.post<IBucketListItem>(null, request);
  }

  update(id: string, request: IBucketUpdateRequest) {
    return this.api.put<IBucketListItem>(id, request)
  }

  delete(id: string) {
    return this.api.delete<IBucketListItem>(id);
  }
}

export const bucketService = new BucketService();
