import { DataMigrationStatus, IDataMigrationBase } from "../api/entities/data-migration";

interface IMigrationEntity<TMigration extends IDataMigrationBase = IDataMigrationBase> {
    dataMigrations?: TMigration[];
}

class DataMigrationUtils {
    hasActiveMigrations(entity: IMigrationEntity): boolean {
        return !!this.getActiveMigration(entity);
    }
    getActiveMigration<TMigration  extends IDataMigrationBase>(entity: IMigrationEntity<TMigration>): TMigration {
        return entity.dataMigrations && 
            entity.dataMigrations.find(migration => migration.dataMigrationStatus === DataMigrationStatus.InProgress);
    }
}

export const dataMigrationUtils = new DataMigrationUtils();