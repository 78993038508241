import { MAKE_DEFAULT_CONFIRMATION, StorageProfileAction, StorageProfileActionType, STORAGE_PROFILE_ENTITY_NAME } from "../actions/storage-profile";
import { IPolicy, IPoliciesRequestParams, PolicySortField, IPolicyUpdate } from "../../api/entities/policy";
import { IDialogState } from ".";
import { createDialogReducer, DIALOG_DEFAULT_STATE } from "./dialog";
import { createEntityReducer, getEntityDefaultState, IEntityState } from "./entity";
import { SortDirection } from "../../api/entities/page";

export interface IStorageProfileState extends IEntityState<IPolicy, PolicySortField, IPoliciesRequestParams> {
  profileDetails: IPolicy;
  updateProfileData: IPolicyUpdate;
  [MAKE_DEFAULT_CONFIRMATION]: IDialogState<IPolicy>;
}

export const STORAGE_PROFILE_DEFAULT_STATE: IStorageProfileState = {
  ...getEntityDefaultState(PolicySortField.Name, SortDirection.Ascending),
  profileDetails: null,
  [MAKE_DEFAULT_CONFIRMATION]: DIALOG_DEFAULT_STATE,
  updateProfileData: null,
};

const makeDefaultConfirmationReducer = createDialogReducer(STORAGE_PROFILE_ENTITY_NAME, MAKE_DEFAULT_CONFIRMATION);
const entityReducer = createEntityReducer(STORAGE_PROFILE_ENTITY_NAME);

export default function storageProfile(
  state: IStorageProfileState = STORAGE_PROFILE_DEFAULT_STATE,
  action: StorageProfileAction
): IStorageProfileState {

  state = makeDefaultConfirmationReducer(state, action);
  state = entityReducer(state, action);

  switch (action.type) {
    case StorageProfileActionType.SetStorageProfileDetail:
      return {
        ...state,
        profileDetails: action.data,
      };
    case StorageProfileActionType.SetStorageProfileUpdateData:
      return {
        ...state,
        updateProfileData: action.data,
      };
    default:
      return state;
  }
}
