import React from "react";
import { ApplicationCredentialsSortField, IApplicationCredentialsListItem, IApplicationCredentialsPageRequestParams } from "../../../../api/entities/application";
import { IPage } from "../../../../api/entities/page";
import Table, { IColumnDefinition, TableSelected } from "../../../shared-ui/table/table";
import tableStyles from "../../../shared-ui/table/table.module.scss";
import styles from "./application-credentials-table.module.scss";
import { MoreOption } from "./moreoption";
import { getOwnerColumn } from "../../common/sections/owner-column/owner-column";
import { AccountType } from "../../../../api/entities/user";
import { IAppState } from "../../../../redux/reducers";
import { connect } from "react-redux";

const mapStateToProps = (state: IAppState) => ({
  accountType: state.users.currentUser.accountType,
});

export interface IApplicationTableProps
    extends ReturnType<typeof mapStateToProps> {

  isLoading: boolean;
  page: IPage<IApplicationCredentialsListItem>;
  requestParams: IApplicationCredentialsPageRequestParams;
  onRequestParamsChanged: (data: Partial<IApplicationCredentialsPageRequestParams>) => void;

  selection?: TableSelected<IApplicationCredentialsListItem>;
  onSelectionChanged?: (selection: TableSelected<IApplicationCredentialsListItem>) => void;
  onSelectAll?: () => void;

  hasManageColumn?: boolean;
}

export const ApplicationCredentialsTable = connect(mapStateToProps, null)((props: IApplicationTableProps) => {
  const columns: IColumnDefinition<IApplicationCredentialsListItem, string>[] = [
    {
      label: "Name",
      className: styles["col--name"],
      formatter: row => row.name,
      sortKey: ApplicationCredentialsSortField.Name
    },
    {
      label: "Scope",
      className: styles["col--scope"],
      formatter: row => row.scope,
      sortKey: ApplicationCredentialsSortField.Scope
    },
    {
      label: "Bucket Name",
      className: styles["col--bucket-name"],
      formatter: row => row.bucket.name,
      sortKey: ApplicationCredentialsSortField.Bucket
    },
    {
      label: "Application Name",
      className: styles["col--application-name"],
      formatter: row => row.application.name,
      sortKey: ApplicationCredentialsSortField.Application
    },
  ];

  if (props.accountType === AccountType.Enterprise) {
    columns.push(getOwnerColumn(null));
  }

  if (props.hasManageColumn) {
    columns.push(
        {
          label: "Manage",
          className: tableStyles["col--manage"],
          formatter: row => (<MoreOption data={row}/>),
        });
  }

  return (
    <Table
      testId="application-credentials-table"
      hideCheckbox={!props.selection}
      columns={columns}
      rows={props.page.data}
      totalRows={props.page.count}
      loading={props.isLoading}
      requestParams={props.requestParams}
      onRequestParamsChanged={props.onRequestParamsChanged}

      selected={props.selection}
      onSetSelected={props.onSelectionChanged}
      onSelectAll={props.onSelectAll}
    />
  );
});
