import { ISharingInstanceFilterParams } from '../api/entities/sharing-instance';
import { ApiVersionNumber } from "../api/services/data-service";
import { APPLICATION_CREDENTIALS_V2_ROUTE, APPLICATIONS_V2_ROUTE, ApplicationV2RouteAction, BucketRouteAction, BUCKETS_V2_ROUTE, DEVICES_ROUTE, DevicesRouteAction, LOGIN_ROUTE, LOGOUT_SUCCESS_ROUTE, SCAN_HISTORY_ROUTE, SETTINGS_ROUTE, SettingsRouteAction, SettingsRouteSection, SHARING_INSTANCES_ROUTE, SHARING_PROFILES_ROUTE, SharingInstanceRouteAction, SharingProfileRouteAction, STORAGE_NODES_V2_ROUTE, STORAGE_POOLS_ROUTE, STORAGE_POOLS_V2_ROUTE, STORAGE_PROFILES_ROUTE, StorageNodeRouteAction, StoragePoolRouteAction, StorageProfileRouteAction, USER_GROUPS_ROUTE, USERS_ROUTE, UsersRouteAction } from '../constants/routes';
import { queryUtils } from './query-utils';

export class RouteUtils {
    getRouteToActiveDirectorySettings(): string {
        return `${SETTINGS_ROUTE}/${SettingsRouteSection.ActiveDirectorySettings}`;
    }

    getRouteToCliCredentials(): string {
        return `${SETTINGS_ROUTE}/${SettingsRouteSection.CliCredentials}`;
    }

    getRouteToCertificateSettings(certificateId: number): string {
        return `${SETTINGS_ROUTE}/${SettingsRouteSection.AutoApprovalCertificate}/${certificateId}`;
    }

    getRouteToCertificateGuide(certificateId?: number): string {
        if (certificateId || certificateId === 0) {
            return `${SETTINGS_ROUTE}/${SettingsRouteSection.AutoApprovalCertificate}/${certificateId}/${SettingsRouteAction.Guide}`;
        }
        else {
            return `${SETTINGS_ROUTE}/${SettingsRouteSection.AutoApprovalCertificateGuide}`;
        }
    }

    getRouteToStorageNode(storageNodeId: number): string {
        return `${STORAGE_POOLS_ROUTE}/${StoragePoolRouteAction.ManageNode}/${storageNodeId}`;
    }

    getRouteToDevice(deviceId: number) {
        return `${DEVICES_ROUTE}/${deviceId}`;
    }

    getRouteToDeviceLoginHistory(deviceId: number) {
        return `${DEVICES_ROUTE}/${deviceId}/${DevicesRouteAction.LoginHistory}`;
    }

    getRouteToStorageProfile(policyId: number) {
        return `${STORAGE_PROFILES_ROUTE}/${policyId}`;
    }

    getRouteToStorageProfileManageUsers(policyId: number) {
        return `${STORAGE_PROFILES_ROUTE}/${policyId}/${StorageProfileRouteAction.ManageUsers}`;
    }

    getRouteToStorageProfileManageExceededUsers(policyId: number) {
        return `${STORAGE_PROFILES_ROUTE}/${policyId}/${StorageProfileRouteAction.ManageExceededUsers}`;
    }

    getRouteToLogoutSuccessPage() {
        return LOGOUT_SUCCESS_ROUTE;
    }

    getRouteToScanHistoryDetails(scanId: string) {
        return `${SCAN_HISTORY_ROUTE}/${scanId}`;
    }

    getRouteToScanHistoryUserDetails(scanId: string, userId: number) {
        return `${SCAN_HISTORY_ROUTE}/${scanId}/${userId}`;
    }

    getRouteToSharingProfileDetails(sharingProfileId: number) {
        return `${SHARING_PROFILES_ROUTE}/${sharingProfileId}`;
    }

    getRouteToSharingInstanceDetails(sharingInstanceId: number) {
        return `${SHARING_INSTANCES_ROUTE}/${sharingInstanceId}`;
    }

    getRouteToSharingInstanceManageUsers(sharingInstanceId: number) {
        return `${SHARING_INSTANCES_ROUTE}/${sharingInstanceId}/${SharingInstanceRouteAction.ManageUsers}`;
    }

    getRouteToSharingProfileManageUsers(sharingProfileId: number) {
        return `${SHARING_PROFILES_ROUTE}/${sharingProfileId}/${SharingProfileRouteAction.ManageUsers}`;
    }

    getRouteToStoragePoolList(version: ApiVersionNumber) {
        return this.getStoragePoolRoutePrefix(version);
    }

    getRouteToStoragePoolDetails(storagePoolId: number | string, version: ApiVersionNumber) {
        return `${this.getStoragePoolRoutePrefix(version)}/${storagePoolId}`;
    }

    getRouteToNewStoragePool(version: ApiVersionNumber): string {
        let prefix = this.getStoragePoolRoutePrefix(version);
        if (version === 1) {
            return `${prefix}/0`
        }
        return `${prefix}/${StoragePoolRouteAction.New}`;
    }

    private getStoragePoolRoutePrefix(version: ApiVersionNumber): string {
        return version === 1 ? STORAGE_POOLS_ROUTE : STORAGE_POOLS_V2_ROUTE;
    }

    getRouteToUserDetails(uid: string) {
        return `${USERS_ROUTE}/${uid}`;
    }

    getRouteToUserMigrateFiles(uid: string) {
        return `${USERS_ROUTE}/${uid}/${UsersRouteAction.MigrateFiles}`;
    }

    getRouteToUserGroupDetails(id: number) {
        return `${USER_GROUPS_ROUTE}/${id}`;
    }

    getRouteToUserRestoreFiles(userUid: string) {
        return `${USERS_ROUTE}/${userUid}/${UsersRouteAction.RestoreFiles}`;
    }

    getRouteToUsersRestoreFiles() {
        return `${USERS_ROUTE}/${UsersRouteAction.RestoreFiles}`;
    }

    getRouteToStoragePoolsRestoreFiles() {
        return `${STORAGE_POOLS_ROUTE}/${StoragePoolRouteAction.RestoreFiles}`;
    }

    getRouteToStoragePoolRestoreFiles(storagePoolId: number) {
        return `${STORAGE_POOLS_ROUTE}/${storagePoolId}/${StoragePoolRouteAction.RestoreFiles}`;
    }

    getRouteToSharingInstances(filter: ISharingInstanceFilterParams) {
        return `${SHARING_INSTANCES_ROUTE}?${queryUtils.toQuery(filter)}`;
    }

    getRouteToSharingInstancesRestoreFiles() {
        return `${SHARING_INSTANCES_ROUTE}/${SharingInstanceRouteAction.RestoreFiles}`;
    }

    getRouteToSharingInstanceRestoreFiles(sharingInstanceId: number) {
        return `${SHARING_INSTANCES_ROUTE}/${sharingInstanceId}/${SharingInstanceRouteAction.RestoreFiles}`;
    }

    getRouteToNewApplicationV2(): string {
        return `${APPLICATIONS_V2_ROUTE}/${ApplicationV2RouteAction.New}`;
    }

    getRouteToNewApplicationCredentialsV2FromApplicationTab(applicationId: string): string {
        return `${APPLICATIONS_V2_ROUTE}/${applicationId}/${ApplicationV2RouteAction.AddCredentials}`;
    }

    getRouteToNewApplicationCredentialsV2FromApplicationCredentialsTab(): string {
        return `${APPLICATION_CREDENTIALS_V2_ROUTE}/${ApplicationV2RouteAction.AddCredentials}`;
    }

    getRouteToApplicationDetails(applicationId: string) {
        return `${APPLICATIONS_V2_ROUTE}/${applicationId}`;
    }

    getRouteToNewBucket(): string {
        return [BUCKETS_V2_ROUTE, BucketRouteAction.New].join('/');
    }

    getRouteToBucketDetails(bucketId: string) {
        return [BUCKETS_V2_ROUTE, bucketId].join('/');
    }

    getRouteToNewStorageNode(): string {
        return [STORAGE_NODES_V2_ROUTE, StorageNodeRouteAction.New].join('/');
    }

    getRouteToStorageNodeDetails(storageNodeId: string) {
        return [STORAGE_NODES_V2_ROUTE, storageNodeId].join('/');
    }

    getRouteToSignInForm() {
        return LOGIN_ROUTE;
    }
}

export const routeUtils = new RouteUtils();
