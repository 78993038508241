import { InitialSetupStep } from "../../components/initialSetup";
import { PickActions } from "./common";

export enum InitialSetupActionType {
  SetStep = 'INITIAL_SETUP_SET_STEP'
}

class InitialSetupActions {
  setStep(step: InitialSetupStep) {
    return {
      type: InitialSetupActionType.SetStep as typeof InitialSetupActionType.SetStep,
      step
    };
  }
}

export const initialSetupActions = new InitialSetupActions();

export type InitialSetupAction = PickActions<InitialSetupActions>;