import React from "react";
import ButtonGroup from "../button-group";
import styles from "./yes-no-button-group.module.scss";

interface IYesNoButtonGroupProps {
  autoFocus?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

export default function YesNoButtonGroup(props: IYesNoButtonGroupProps) {
  return (
    <ButtonGroup
      autoFocus={props.autoFocus}
      value={props.value}
      buttonClassName={styles.button}
      options={[
        { label: "YES", value: true },
        { label: "NO", value: false }
      ]}
      colors="secondary"
      disabled={props.disabled}
      onChange={props.onChange}
    />
  );
}