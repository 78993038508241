import React from "react";
import { ApplicationSortField, IApplicationListItem, IApplicationPageRequestParams } from "../../../../api/entities/application";
import Table, { IColumnDefinition, TableSelected } from "../../../shared-ui/table/table";
import tableStyles from "../../../shared-ui/table/table.module.scss";
import styles from "./application-table.module.scss";
import { IPage } from "../../../../api/entities/page";
import { routeUtils } from "../../../../utils/route-utils";
import { useNavigate } from "../../../../utils/hooks/use-navigate";
import { MoreOption } from "./moreoption";
import { IAppState } from "../../../../redux/reducers";
import { connect } from "react-redux";
import { AccountType } from "../../../../api/entities/user";
import { getOwnerColumn } from "../../common/sections/owner-column/owner-column";


const mapStateToProps = (state: IAppState) => ({
    accountType: state.users.currentUser.accountType,
});

export interface IApplicationTableProps
    extends ReturnType<typeof mapStateToProps> {

    isLoading: boolean;
    page: IPage<IApplicationListItem>;
    requestParams: IApplicationPageRequestParams;
    onRequestParamsChanged: (data: Partial<IApplicationPageRequestParams>) => void;

    selection?: TableSelected<IApplicationListItem>;
    onSelectionChanged?: (selection: TableSelected<IApplicationListItem>) => void;
    onSelectAll?: () => void;

    isRowClickable?: boolean;
    hasManageColumn?: boolean;
}

export const ApplicationTable = connect(mapStateToProps, null)((props: IApplicationTableProps) => {
    const navigate = useNavigate();
    const columns: IColumnDefinition<IApplicationListItem, string>[] = [
        {
            label: "Name",
            className: styles["col--name"],
            formatter: row => row.name,
            sortKey: ApplicationSortField.ApplicationName
        },
        {
            label: "Bucket IDs",
            className: styles["col--bucket-ids"],
            formatter: row => row.buckets && row.buckets.map(x => <div>{x.id}</div>),
        },
    ];

    if (props.accountType === AccountType.Enterprise) {
        columns.push(getOwnerColumn(null));
    }

    if (props.hasManageColumn) {
        columns.push(
            {
                label: "Manage",
                className: tableStyles["col--manage"],
                formatter: row => (<MoreOption data={row}/>),
            });
    }

    return (
        <Table
            testId="application-table"
            hideCheckbox={!props.selection}
            columns={columns}
            rows={props.page.data}
            totalRows={props.page.count}
            loading={props.isLoading}
            requestParams={props.requestParams}
            onRequestParamsChanged={props.onRequestParamsChanged}
            onRowClick={row => !!props.isRowClickable && navigate(routeUtils.getRouteToApplicationDetails(row.id))}

            selected={props.selection}
            onSetSelected={props.onSelectionChanged}
            onSelectAll={props.onSelectAll}
        />
    );
});
