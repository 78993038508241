import {
  IApplicationCreateRequest, IApplicationDetailsItem, IApplicationListItem, IApplicationPageRequestParams, IApplicationUpdateRequest, IApplicationCredentialsCreateRequest, IApplicationCredentialsCreateResponse, IApplicationCredentialsListItem, IApplicationCredentialsPageRequestParams
} from "../entities/application";
import { IPage } from "../entities/page";
import { DataService } from "./data-service";

class ApplicationService extends DataService {
  constructor() {
    super("app", 2);
  }

  getPage(requestParams: IApplicationPageRequestParams): Promise<IPage<IApplicationListItem>> {
    return this.api.get<IPage<IApplicationListItem>>(null, requestParams);
  }

  get(applicationId: string): Promise<IApplicationDetailsItem> {
    return this.api.get<IApplicationDetailsItem>(applicationId, null);
  }

  create(request: IApplicationCreateRequest) {
    return this.api.post<IApplicationListItem>(null, request)
  }

  update(request: IApplicationUpdateRequest) {
    return this.api.put<IApplicationListItem>(request.id, request)
  }

  delete(id: string) {
    return this.api.delete<IApplicationListItem>(id);
  }
}

class ApplicationCredentialsService extends DataService {
  constructor() {
    super("app/credential", 2);
  }

  getPage(pageRequestParams: IApplicationCredentialsPageRequestParams): Promise<IPage<IApplicationCredentialsListItem>> {
    return this.api.get<IPage<IApplicationCredentialsListItem>>(null, pageRequestParams);
  }

  nameValidator(applicationCredentialsName: string): Promise<boolean> {
    return this.getPage({ q: applicationCredentialsName, strict: true, fields: ['id', 'name'], deleted: true })
        .then(page => page.count === 0 || page.data.filter(x => x.name === applicationCredentialsName).length === 0);
  }

  create(request: IApplicationCredentialsCreateRequest) {
    return this.api.post<IApplicationCredentialsCreateResponse>(null, request);
  }

  delete(id: string) {
    return this.api.delete<IApplicationCredentialsListItem>(id);
  }
}

export const applicationService = new ApplicationService();
export const applicationCredentialsService = new ApplicationCredentialsService();
