import React from "react";
import { IStyleableComponentProps } from "../common/component-props";
import clsx from "clsx";

import styles from "./progress-bar.module.scss";

interface IProgressBarProps extends IStyleableComponentProps {
  value: number;
  height?: number;
  color?: string;
  barClassName?: string;
}

export default function ProgressBar(props: IProgressBarProps) {
  return (
    <div
      data-testid="progress-bar"
      className={clsx(styles.root, props.className)}
      style={{
        height: props.height,
        color: props.color,
      }}
    >
      <div
        className={clsx(styles.bar, props.barClassName)}
        style={{ width: `${props.value}%` }}
      ></div>
    </div>
  );
}
