import React from "react";
import IComponentProps from "../common/component-props";
import styles from "./linkButton.module.scss";
import clsx from "clsx";

export enum LinkButtonSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export interface ILinkButtonProps extends IComponentProps {
  onClick?: () => void;
  size?: LinkButtonSize;
  children: React.ReactNode;
  testId: string;
}

export function LinkButton(props: ILinkButtonProps) {
  return (
    <button
      className={clsx(
        styles.root,
        props.className,
        props.size === LinkButtonSize.Small && styles.small,
        props.size === LinkButtonSize.Medium && styles.medium,
        props.size === LinkButtonSize.Large && styles.large,
      )}
      disabled={props.disabled}
      style={props.style}
      onClick={props.onClick}
      data-testid={props.testId || "link-button"}
    >
      {props.children}
    </button>
  );
}
