import { DataService } from "./data-service";
import { IInvitation, IInvitationRequestParams, InviteEmailRequest, UserInvitationResponse } from "../entities/invitation";
import { IPage } from "../entities/page";

class InvitationService extends DataService {
    constructor() {
        super("account/invitation", 2);
    }

    getPage(requestParams: IInvitationRequestParams): Promise<IPage<IInvitation>> {
        return this.api.get<IPage<IInvitation>>(null, requestParams);
    }

    inviteUsers(invitationRequest: InviteEmailRequest[]): Promise<UserInvitationResponse> {
        return this.api.post<UserInvitationResponse>(null, invitationRequest);
    }

    resendInvitations(invitationRequest: Omit<InviteEmailRequest, 'permission'>[]): Promise<UserInvitationResponse> {
        return this.api.post<UserInvitationResponse>('resend', invitationRequest);
    }

    delete(invitationId: string): Promise<void> {
        return this.api.delete(invitationId);
    }
}

export const invitationService = new InvitationService();
