export enum ExpirationDays {
  Never = -1,
  AfterYear = 365,
  AfterSixMonth = 180,
  AfterThreeMonth = 90,
  AfterThirtyDays = 30,
  AfterWeek = 7,
  AfterDay = 1
}

export enum MaxDownloads {
  Unlimited = -1
}

export const maxDownloadsToTextMap = {
  [MaxDownloads.Unlimited]: "unlimited"
};

export const expirationDaysToTextMap = {
  [ExpirationDays.Never]: "never",
  [ExpirationDays.AfterYear]: "after a year",
  [ExpirationDays.AfterSixMonth]: "after 180 days",
  [ExpirationDays.AfterThreeMonth]: "after three months",
  [ExpirationDays.AfterThirtyDays]: "after 30 days",
  [ExpirationDays.AfterWeek]: "after a week",
  [ExpirationDays.AfterDay]: "after a day",
};

export enum AccessRightType {
  ViewOnline = "ViewOnline",
  ViewWatermarked = "ViewWatermarked",
  DownloadWatermarked = "DownloadWatermarked",
  FullControl = "FullControl"
}

export const accessRightTypeToTextMap = {
  [AccessRightType.ViewOnline]: "view online only",
  [AccessRightType.ViewWatermarked]: "view online with watermark",
  [AccessRightType.DownloadWatermarked]: "view and download with watermark",
  [AccessRightType.FullControl]: "have full control",
}

export interface IShareAccessRight {
  AccessRightType: AccessRightType;
  DisplayText: string;
  Id: string;
}

export interface IShareFilesEmailRequest {
  Items: string[];
  Emails: string[];
  Subject: string;
  Body: string;
  CcSender: boolean;
  NotifyOnDownload: boolean;
  RequireLogin: boolean;
  ShareAccessRight: IShareAccessRight;
  ExpirationDays: ExpirationDays;
  MaxDownloads: number;
}

export interface IShareFilesEmailResponse {
  Uri: string;
}