import React from "react";
import { ConfirmDialog } from "../../../shared-ui/dialogs/common/confirm-dialog/confirm-dialog";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "../../../../redux/reducers";
import { Action } from "redux"; 
import { connect } from "react-redux";
import styles from "./replace-storage-confirmation.module.scss";
import { storagePoolV1Actions } from "../../../../redux/actions/storage-pool";

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    close: () =>
      dispatch(storagePoolV1Actions.replaceStorageConfirmation.close()),
  };
};

const mapStateToProps = (state: IAppState) => ({
  isOpen: state.storagePoolV1.replaceStorageConfirmation.isOpen,
  data: state.storagePoolV1.replaceStorageConfirmation.data,
});

export interface IReplaceStorageConfirmationProps extends 
    ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {

  onConfirm: () => void
}

export const ReplaceStorageConfirmation = connect(mapStateToProps, mapDispatchToProps)((props: IReplaceStorageConfirmationProps) => {

  return (
    <ConfirmDialog
      testId="replace-storage-confirmation"
      title="Replace Storage Space"
      confirmText="Replace"
      open={props.isOpen}
      onClose={props.close}
      onConfirm={props.onConfirm}
    >
      <div className={styles.content}>
        Are you sure you want to replace <strong>{props.data?.name}</strong> with this new storage configuration?
      </div>
    </ConfirmDialog>
  );
});
