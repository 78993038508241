import React, { PropsWithChildren, MouseEvent } from "react";
import IComponentProps from "../common/component-props";
import styles from "./button.module.scss";
import clsx from "clsx";
import { useNavigate } from "../../../utils/hooks/use-navigate";

export type ButtonColors = "primary" | "secondary" | "extreme" | "link" | "inherit";

export interface IButtonProps extends IComponentProps {
  colors?: ButtonColors;
  href?: string;
  download?: string;
  selected?: boolean;
  responsive?: boolean;
  leftMargin?: boolean;
  onClick?: (e: MouseEvent) => void;
  inline?: boolean;
  openLinkInNewWindow?: boolean;
  testId: string;
  autoFocus?: boolean;
}

export default function Button(props: PropsWithChildren<IButtonProps>) {
  const navigate = useNavigate();

  function isExternalURL(url: string): boolean {
    return (url.startsWith("http://") || url.startsWith("https://")) && new URL(url).origin !== window.location.origin;     
  }

  function isLocalLink() {
    return props.href && !isExternalURL(props.href);
  }

  function handleClick(e: MouseEvent): void {
    if (props.onClick) {
      e.preventDefault();
      props.onClick(e);
    }
    else if (isLocalLink() && !props.download) {
      e.preventDefault();
      navigate(props.href);
    }
  }
  
  const Tag = props.href ? "a" : "button",
    attr = {
      className: clsx(
        styles.root,
        props.inline && styles["root--inline"],
        props.colors && styles[props.colors],
        props.selected ? styles.selected : "",
        props.responsive ? styles.resposive : "",
        props.leftMargin ? styles["root--left-margin"] : "",
        props.className
      ),
      disabled: props.disabled,
      href: props.href,
      download: props.download,
      style: props.style,
      autoFocus: props.autoFocus,
      target: props.openLinkInNewWindow && "_blank",
      "data-testid": props.testId,
      "data-selected": props.selected,
      onClick: handleClick
    };
  return <Tag {...attr}>{props.children}</Tag>;
}
