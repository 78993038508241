import React from "react";
import { StorageProviderType } from "../../../../api/entities/storage-node-v2";
import IOption from "../../../shared-ui/common/option";
import { FiltersBar } from "../../../shared-ui/filters-bar/filters-bar";
import SearchInput from "../../../shared-ui/input/search-input/search-input";
import { ProviderFilter } from "../provider-filter";
import styles from "./storage-node-list-filters.module.scss";

export const defaultFilters: IStorageNodeFilters = {
  q: null,
  provider: null
}

export enum StorageNodeListFilter {
  Search = "search",
  Provider = "provider"
}

export interface IStorageNodeFilters {
  q: string;
  provider: IOption<StorageProviderType>;
}

export interface IStorageNodeListFiltersProps {
  title?: string;
  visibleFilters?: StorageNodeListFilter[];
  filterSearchParams: IStorageNodeFilters;
  onFiltersChange: (filters: Partial<IStorageNodeFilters>) => void;
}

export function StorageNodeListFilters(props: IStorageNodeListFiltersProps) {
  const filters = props.visibleFilters || [StorageNodeListFilter.Search, StorageNodeListFilter.Provider];

  return (
    <FiltersBar>
      {filters.includes(StorageNodeListFilter.Search) && (
        <SearchInput
          className={styles.search}
          value={props.filterSearchParams.q}
          onChange={q => props.onFiltersChange({ q })}
        />
      )}
      {filters.includes(StorageNodeListFilter.Provider) && (
        <ProviderFilter
          className={styles.provider}
          value={props.filterSearchParams.provider}
          onChange={(provider) => props.onFiltersChange({ provider })}
          autoWidth
        />
      )}
    </FiltersBar>
  );
};
