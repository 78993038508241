import React from "react";
import { IColumnDefinition } from "../../table/table";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./columns.module.scss";
import Button from "../../button";

export function createRemoveColumn<TRowData>(onClick: (rowData: TRowData) => void, title?: string): IColumnDefinition<TRowData, null> {
  return {
    label: title || "Remove",
    className: styles["col-remove"],
    formatter: row => (
      <Button
        className={styles["remove-button"]}
        onClick={() => onClick(row)}
        testId="remove-button"
      >
        <CloseIcon classes={{root: styles["remove-icon"]}}/>
      </Button>
    )
  };
}