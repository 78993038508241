import React from "react";
import { makeStyles } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "../../../redux/reducers";
import { Action } from "redux";
import { commonActions } from "../../../redux/actions/common";
import { theme } from "../../../theme/theme";
const useStyles = makeStyles(() => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

export interface ISimpleSnackbarProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

function SimpleSnackbar(props: ISimpleSnackbarProps) {
  const classes = useStyles();

  const handleClose = () => {
    props.hideSnackBar();
  };

  return (
    <div>
      <Snackbar
        data-testid="snackbar"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={props.openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{props.message}</span>}
        action={[
          <IconButton
            data-testid="snackbar-close"
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}
const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    hideSnackBar: () => dispatch(commonActions.showSnackBar(false)),
  };
};
const mapStateToProps = (state: IAppState) => ({
  openSnackbar: state.common.snackBarFlag,
  message: state.common.snackBarMessage,
});
export default connect(mapStateToProps, mapDispatchToProps)(SimpleSnackbar);
