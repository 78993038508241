import { Entity } from "../../api/entities/id-name";
import { IPageRequestParams } from "../../api/entities/page";
import { IStorageNode, IStorageNodeCredentialsConfirmationData } from "../../api/entities/storage-node";
import { IStorageNodeV2 } from "../../api/entities/storage-node-v2";
import { IStoragePoolsV2RequestParams, IStoragePoolV2ListItem } from "../../api/entities/storage-pool-v2";
import { ApiVersionNumber } from "../../api/services/data-service";
import { StoragePoolService } from "../../api/services/storage-pool-service";
import { IDeleteBlockedConfirmationData, IDeleteBlockedConfirmationDataV1, IDeleteBlockedConfirmationDataV2 } from '../../components/storage-pools/dialogs/delete-blocked-confirmation/delete-blocked-confirmation';
import { storagePoolServiceFactory } from "../../utils/factories/storage-pool-service-factory";
import { IStoragePoolShort, IStoragePoolsRequestParams, StoragePoolSortField } from '../../api/entities/storage-pool';
import { commonActions, handleApi } from './common';
import { DialogActions } from './dialog';
import { EntityActions, EntityThunkActions } from './entity';

const STORAGE_POOL_ENTITY_NAME = "storagePool";
export const DELETE_CONFIRMATION_DIALOG_NAME = "deleteConfirmation";
export const DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME = "deleteBlockedConfirmation";
export const REPLACE_STORAGE_CONFIRMATION = "replaceStorageConfirmation";
export const CREDENTIALS_INFO = "credentialsConfirmation";

export const getStoragePoolEntityName = (apiVersion: ApiVersionNumber) => `${STORAGE_POOL_ENTITY_NAME}V${apiVersion}`;

class StoragePoolActions<TListItem extends Entity = IStoragePoolShort,
                          TDetailsNodeItem extends Entity = IStorageNode,
                          TRequestParams extends IPageRequestParams = IStoragePoolsRequestParams,
                          TDeleteBlockedConfirmationData extends IDeleteBlockedConfirmationData = IDeleteBlockedConfirmationDataV1> {
  readonly entity;
  readonly deleteConfirmation;
  readonly deleteBlockedConfirmation;
  readonly replaceStorageConfirmation;
  readonly credentialsInfo;

  constructor(apiVersion: ApiVersionNumber) {
    let entityName = `${STORAGE_POOL_ENTITY_NAME}V${apiVersion}`;
    this.entity = new EntityActions<TListItem, TRequestParams>(entityName);
    this.deleteConfirmation = new DialogActions<TListItem>(entityName, DELETE_CONFIRMATION_DIALOG_NAME);
    this.deleteBlockedConfirmation = new DialogActions<TDeleteBlockedConfirmationData>(entityName, DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME);
    this.replaceStorageConfirmation = new DialogActions<TDetailsNodeItem>(entityName, REPLACE_STORAGE_CONFIRMATION);
    if (apiVersion === 2) {
      this.credentialsInfo = new DialogActions<IStorageNodeCredentialsConfirmationData>(entityName, CREDENTIALS_INFO);
    }
  }
}

export const storagePoolV1Actions = new StoragePoolActions(1);
export const storagePoolV2Actions = new StoragePoolActions<IStoragePoolV2ListItem, IStorageNodeV2, IStoragePoolsV2RequestParams, IDeleteBlockedConfirmationDataV2>(2);

class StoragePoolThunkActions<TListItem extends Entity = IStoragePoolShort,
    TSortField = StoragePoolSortField,
    TRequestParams extends IPageRequestParams = IStoragePoolsRequestParams> {
  readonly storagePoolService;
  readonly entity;

  constructor(apiVersion: ApiVersionNumber) {
    this.storagePoolService = storagePoolServiceFactory.getStoragePoolService(apiVersion) as StoragePoolService;
    this.entity = new EntityThunkActions<TListItem, TSortField, TRequestParams>(
        getStoragePoolEntityName(apiVersion), this.storagePoolService);
  }

  delete(storagePoolId: number | string) {
    return (dispatch) => {
      return handleApi(this.storagePoolService
        .delete(storagePoolId)
        .then(() => {
          dispatch(
            commonActions.showSnackBar(
              true,
              `Storage Pool successfully deleted.`
            )
          );
          dispatch(this.entity.fetchPage());
        }), true);
    };
  }
}
export const storagePoolV1ThunkActions = new StoragePoolThunkActions(1);
export const storagePoolV2ThunkActions = new StoragePoolThunkActions(2);

