import React, { PropsWithChildren } from "react";
import { DialogTitle } from "../../../dialog/dialog-title";
import { LinkButton, LinkButtonSize } from "../../../linkButton/linkButton";
import { colors } from "../../../../../theme/colors";
import Button from "../../../button";
import { IStyleableComponentProps, ITestableComponent } from "../../../common/component-props";
import clsx from "clsx";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const useStyles = makeStyles({
  paper: {},
  paperCritical: {
    border: `2px solid ${colors.darkOrange}`,
  },
});

export enum DialogMode {
  None = "none",
  Critical = "critical",
}

export interface IConfirmDialogProps extends ITestableComponent, IStyleableComponentProps {
  mode?: DialogMode;
  title: string;
  confirmText?: string;
  isConfirmDisabled?: boolean;
  cancelText?: string;
  open: boolean;
  contentClassName?: string;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm: () => void;
}

export function ConfirmDialog(props: PropsWithChildren<IConfirmDialogProps>) {
  const classes = useStyles();
  const paperClass =
    props.mode === DialogMode.Critical ? classes.paperCritical : classes.paper;
  const confirmButtonColor =
    props.mode === DialogMode.Critical ? "extreme" : "primary";

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      data-testid={props.testId || "confirm-dialog"}
      classes={{ paper: clsx(paperClass, props.contentClassName) }}
      className={props.className}
      style={props.style}
    >
      <DialogTitle
        onClose={props.onClose}
      >
        {props.title}
      </DialogTitle>
      <DialogContent data-testid="alert-description">
        {props.children}
      </DialogContent>
      <DialogActions>
        <LinkButton
          size={LinkButtonSize.Large}
          onClick={props.onCancel || props.onClose}
          testId="alert-cancel"
        >
          {props.cancelText || "Cancel"}
        </LinkButton>
        <Button
          colors={confirmButtonColor}
          onClick={props.onConfirm}
          disabled={props.isConfirmDisabled}
          testId="alert-ok"
        >
          {props.confirmText || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
