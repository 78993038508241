import React from "react";
import styles from "./textarea.module.scss";
import IComponentProps from "../../common/component-props";
import clsx from "clsx";
import { IFormField } from "../../../../utils/form-utils";

interface ITextareaProps extends IComponentProps {
  testId: string;
  value?: string;
  name?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  field?: IFormField<string>;
  maxLength?: number;
}

export default function Textarea(props: ITextareaProps) {
  const onChange = props.field ? props.field.onChange : props.onChange;
  const onBlur = props.field ? props.field.onBlur : props.onBlur;

  return (
    <textarea
      autoComplete="off"
      maxLength={props.maxLength}
      name={props.name}
      value={props.field?.value ?? props.value ?? ""}
      onChange={(e) => { onChange(e.target.value)}}
      onBlur={(e) => { onBlur && onBlur(e.target.value)}}
      placeholder={props.placeholder}
      className={clsx(styles.textarea, props.className)}
      disabled={props.disabled}
      style={props.style}
    />
  );
}
