import { createEntityReducer, getEntityDefaultState, IEntityState } from './entity';
import { AnyAction } from 'redux';
import { SortDirection } from '../../api/entities/page';
import { IUserGroup, IUserGroupsRequestParams, UserGroupSortField } from '../../api/entities/user-group';
import { USER_GROUP_ENTITY_NAME } from '../actions/user-group';

export interface IUserGroupState extends IEntityState<IUserGroup, UserGroupSortField, IUserGroupsRequestParams> {
}

export const USER_GROUP_DEFAULT_STATE: IUserGroupState = {
  ...getEntityDefaultState(UserGroupSortField.Name, SortDirection.Ascending)
};

const entityReducer = createEntityReducer(USER_GROUP_ENTITY_NAME);

export function userGroup(
  state: IUserGroupState = USER_GROUP_DEFAULT_STATE,
  action: AnyAction
): IUserGroupState {
  state = entityReducer(state, action);
  return state;
}
