import { IRequestParams } from "./page";

export interface IUserGroup {
  id: number;
  name: string;
  default: boolean;
  type: UserGroupType;
  createdAt: string;
  updatedAt: string;
  usersCount: number;
}

export enum UserGroupType {
  Local = 1,
  ActiveDirectory = 2
}

export interface IUserGroupsFilterParams {
  typeFilter?: UserGroupType;
  q?: string;
}

export interface IUserGroupsRequestParams extends 
    IRequestParams<UserGroupSortField>, 
    IUserGroupsFilterParams {
}

export enum UserGroupSortField {
  Name = "name",
  Users = "users",
  CreationDate = "creationDate",
  LastUpdated = "lastUpdated",
}
