import { IUserCommon, UserStatus } from "../api/entities/user";
import { dataMigrationUtils } from "./data-migration-utils";

export class UserUtils {
    canBlockUser(user: IUserCommon, currentUserUid: string): boolean {
        return user.uid !== currentUserUid && user.status !== UserStatus.Blocked && !dataMigrationUtils.hasActiveMigrations(user);
    }

    canUnblockUser(user: IUserCommon, currentUserUid: string): boolean {
        return user.uid !== currentUserUid && user.status === UserStatus.Blocked && !dataMigrationUtils.hasActiveMigrations(user);
    }

    canLogoutUser(user: IUserCommon, currentUserUid: string): boolean {
        return user.uid !== currentUserUid && !dataMigrationUtils.hasActiveMigrations(user);
    }

    canSelectUser(user: IUserCommon): boolean {
        return !dataMigrationUtils.hasActiveMigrations(user); 
    }

    canMigrateFiles(user: IUserCommon): boolean {
        return user.totalFileCount > 0 && !dataMigrationUtils.hasActiveMigrations(user);
    }

    canRestoreFiles(user: IUserCommon): boolean {
        return !dataMigrationUtils.hasActiveMigrations(user);
    }

}

export const userUtils = new UserUtils();