import { IGCSConfigProps } from "../../components/storage-pools/sections/storage-node-section/storage-node-section";
import { ApplicationCredentialsScope, ApplicationCredentialsScopeName } from "./application";
import { IIdName } from "./id-name";
import { IRequestParams } from "./page";
import { StorageProviderType } from "./storage-node-v2";

//enum for storage provider
export enum StorageProvider {
  S3 = 1,
  GCS = 2,
  ABS = 3,
  S3Compatible = 4
}

export const storageProviderDict = {
  "S3": StorageProvider.S3,
  "GCS": StorageProvider.GCS,
  "ABS": StorageProvider.ABS,
  "s3p": StorageProvider.S3Compatible
}

export enum StorageNodeSortField {
  Status = "status",
  Name = "name",
  Content = "content",
  Metadata = "metadata",
  Key = "key",
  Region = "region",
  Provider = "provider",
  Usage = "usage",
  Bucket = "bucket"
}

export enum StorageNodeStatus {
  Inactive = 0,
  Active = 1,
  Failed = 2,
  MigrationInProgress = 3,
}

export enum StorageType {
  Content = 1,
  Key = 2,
  Meta = 3,
}

export interface IStorageNodeCompact extends IIdName {
  types: StorageType[];
}

export interface IStorageNode<T = number> extends IIdName<T> {
  ContentStoragePool?: any[];
  KeyStoragePool?: any[];
  MetaStoragePool?: any[];

  StorageType: IStorageType;
  StorageTypeID: StorageProvider;

  desc: string;
  props: string;
  region: string;
  space: number;
  status: StorageNodeStatus;

  use: number;
  usageBytes: number;
  replacedByStorageNodeID: number;
  replacementProgressPercentage?: number;

  createdAt: string;
  updatedAt?: string;
}

export interface IStorageType {
  id: StorageProvider;
  name: string;
  displayName: string;
  status: number;
  createdAt: string;
  updatedAt: string;
}

export type AllStorageProps = IS3StorageProps &
  IGoogleStorageProps &
  IAzureStorageV1Props &
  IAzureStorageV2Props;

export interface IS3StorageProps {
  key: string;
  roleArn?: string;
  bucket: string;
  region?: string;
  endpointURL?: string;
}

export interface IGoogleStorageProps {
  client_email: string;
  project_id: string;
  bucket: string;
  region: string;
  gcsConfigProps: IGCSConfigProps;
}

export interface IAzureStorageV1Props {
  region: string;
  container: string;
  account_name: string;
}

export interface IAzureStorageV2Props extends Pick<IAzureStorageV1Props, 'container'> {
  sasUri: string;
}

export interface IAddStorageNodeRequest
  extends Omit<IUpdateStorageNodeRequest, "id"> {
  isReplace?: boolean;
  oldStorageNodeId?: number | string;
}

export interface IUpdateStorageNodeRequest
  extends Omit<IStorageNodeTestConnectionRequest, "secret"> {
  id: number | string;
  name: string;
  secret?: string;
}

export interface IStorageNodeTestConnectionRequest
  extends Omit<AllStorageProps, "roleArn"> {
  type: StorageProvider;
  secret: string;
  arn: string;
}

export interface IStorageNodeRaw extends Partial<IStorageNodeTestConnectionRequest> {
  id?: string;
  name: string;
  props: string;
}

type ScopePermissionDictType = {
  [k in ApplicationCredentialsScopeName]: ApplicationCredentialsScopePermission[];
};

export enum ApplicationCredentialsScopePermission {
  Read = 'read',
  Write = 'write',
  Delete = 'delete',
}

export const scopePermissionDict: ScopePermissionDictType = {
  'read': [ApplicationCredentialsScopePermission.Read],
  'write': [ApplicationCredentialsScopePermission.Read, ApplicationCredentialsScopePermission.Write],
  'full': [ApplicationCredentialsScopePermission.Read, ApplicationCredentialsScopePermission.Write, ApplicationCredentialsScopePermission.Delete],
};

type OperationStatus = { [key in keyof typeof ApplicationCredentialsScope]: boolean };

export interface IStorageNodeTestConnectionResponse {
  msg: string;
  isConnectionSuccessful: boolean;
  operationStatus: OperationStatus;
}

export interface IStorageNodesRequestParams extends IRequestParams<StorageNodeSortField> {
  providers?: StorageProviderType[];
}

export interface IStorageNodesRequestParams extends IRequestParams<StorageNodeSortField> {
  providers?: StorageProviderType[];
}

export interface IStorageNodeCredentialsConfirmationData extends Pick<IStorageNodeTestConnectionResponse, 'isConnectionSuccessful' | 'operationStatus'> {
  scope: ApplicationCredentialsScopeName;
}
