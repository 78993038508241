import React from "react";
import logoPng from "../../../../../../assets/myota_logo_white_type.png";
import styles from "./myota-header.module.scss";

export interface IMyotaHeaderProps {
}

export function MyotaHeader(props: IMyotaHeaderProps) {
  return (
    <div className={styles.root}>
      <img className={styles["logo-image"]} src={logoPng} alt="Myota-logo" />
    </div>
  );
}