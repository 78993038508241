import React, { useContext, useEffect, useState } from "react";
import { IStorageNodeTestConnectionRequest, StorageProvider } from "../../../../api/entities/storage-node";
import { CONFIG } from "../../../../config/config";
import { catchException } from "../../../../redux/actions/common";
import { enumUtils } from "../../../../utils/enum-utils";
import { IFormField } from "../../../../utils/form-utils";
import { IImportError, importUtils } from "../../../../utils/import-utils";
import { StorageProviderField, storageProviderToRegionFieldLabelMap, storageProviderToSecretFieldLabelMap, storageUtils } from "../../../../utils/storage-utils";
import { IFile } from "../../../client/addons/citrix-share-file/components/files-list/files-list";
import { TestConnection } from "../../../initialSetup/storage-pool/test-connection/test-connection";
import Button from "../../../shared-ui/button";
import { FormFieldError } from "../../../shared-ui/form/form-field-error/form-field-error";
import { FormFieldLabel } from "../../../shared-ui/form/form-field-label/form-field-label";
import { FormFieldValue } from "../../../shared-ui/form/form-field-value/form-field-value";
import { FormField } from "../../../shared-ui/form/form-field/form-field";
import { FormSubSection } from "../../../shared-ui/form/form-sub-section/form-sub-section";
import TextInput from "../../../shared-ui/input/text-input/text-input";
import { Select } from "../../../shared-ui/select";
import SidePanelSection from "../../../shared-ui/side-panel-section/side-panel-section";
import { ImportResultDialog } from "../../../v2/common/dialogs/import-result-dialog/import-result-dialog";
import { ImportButton } from "../../../v2/common/import-button/import-button";
import { RegionSelect } from "../../region-select/region-select";
import { VersionContext } from "../../storage-pools";
import styles from "./storage-node-section.module.scss";

export interface IStorageNodeTypes {
  storeContent: boolean;
  storeMetadata: boolean;
  storeKey: boolean;
}

export interface IStorageNodeForm {
  type: IFormField<StorageProvider>;
  name: IFormField<string>;
  region: IFormField<string>;
  isCustomRegion: IFormField<boolean>;
  endpointURL: IFormField<string>;
  bucket: IFormField<string>;
  account_name: IFormField<string>;
  container: IFormField<string>;
  arn: IFormField<string>;
  project_id: IFormField<string>;
  key: IFormField<string>;
  secret: IFormField<string>;
  client_email: IFormField<string>;
  gcsConfigProps: IFormField<IGCSConfigProps>;
  sasUri: IFormField<string>;
}

export interface IStorageNodeFormValue extends IStorageNodeTestConnectionRequest {
  name: string;
  isCustomRegion: boolean;
  gcsConfigProps: IGCSConfigProps;
}

export interface IStorageNodeSectionProps {
  form: IStorageNodeForm;
  testConnectionRequest: IStorageNodeTestConnectionRequest;
  isTestConnectionSuccess: boolean;
  onTestConnection: (testConnectionRequest: IStorageNodeTestConnectionRequest) => void;
  showSensitiveFields: boolean;
}

export interface IGCSConfigProps {
  type: "service_account";
  project_id: string;
  private_key_id: string;
  private_key: string;
  client_email: string;
  client_id: string;
  auth_uri: string;
  token_uri: string;
  auth_provider_x509_cert_url: string;
  client_x509_cert_url: string;
}

export function StorageNodeSection(props: IStorageNodeSectionProps) {
  const { form, testConnectionRequest } = props;

  const version = useContext(VersionContext);

  const [gcsConfigFile, setGCSConfigFile] = useState<IFile>(null);
  const [isGCSConfigDialogOpen, setIsGCSConfigDialogOpen] = useState<boolean>(false);
  const [importErrors, setImportErrors] = useState<IImportError[]>([]);

  useEffect(() => {
    function isFieldVisible(field: StorageProviderField) {
      let gcsSensitiveFields = [StorageProviderField.ProjectId, StorageProviderField.SecretKey, StorageProviderField.ClientEmail];
      let absSensitiveFields = [StorageProviderField.AccountName, StorageProviderField.SecretKey];

      if (version === 2 &&
          ((form.type.value === StorageProvider.S3 && field === StorageProviderField.Arn) ||
              (form.type.value === StorageProvider.GCS && gcsSensitiveFields.includes(field)) ||
              (form.type.value === StorageProvider.ABS && absSensitiveFields.includes(field)))
      ) {
        return false;
      }

      return storageUtils.isFieldVisible(field, form.type.value, props.showSensitiveFields);
    }

    form.region.setIsVisible(isFieldVisible(StorageProviderField.Region));
    form.endpointURL.setIsVisible(isFieldVisible(StorageProviderField.EndpointURL));
    form.bucket.setIsVisible(isFieldVisible(StorageProviderField.Bucket));
    form.account_name.setIsVisible(isFieldVisible(StorageProviderField.AccountName));
    form.container.setIsVisible(isFieldVisible(StorageProviderField.Container));
    form.arn.setIsVisible(isFieldVisible(StorageProviderField.Arn));
    form.project_id.setIsVisible(isFieldVisible(StorageProviderField.ProjectId));
    form.key.setIsVisible(isFieldVisible(StorageProviderField.AccessKey));
    form.secret.setIsVisible(isFieldVisible(StorageProviderField.SecretKey));
    form.client_email.setIsVisible(isFieldVisible(StorageProviderField.ClientEmail));
    form.gcsConfigProps.setIsVisible(isFieldVisible(StorageProviderField.GCSConfigProps));
    form.sasUri.setIsVisible(isFieldVisible(StorageProviderField.SasUri));

    // eslint-disable-next-line
  }, [form.type.value]);

  useEffect(() => {
    if (version === 1 && form.type.value === StorageProvider.S3 && form.arn.isVisible === !!form.endpointURL.value) {
      form.arn.setIsVisible(!form.endpointURL.value);
    }
    // eslint-disable-next-line
  }, [form.endpointURL.value]);

  useEffect(() => {
    if (gcsConfigFile) {
      gcsConfigFile.fileData.text().then(data => {
        importUtils.importGCSConfiguration(data, setImportErrors)
            .then(gcsConfigProps => {
              form.gcsConfigProps.onChange(gcsConfigProps);
            })
            .catch(catchException());
      });
    }
    // eslint-disable-next-line
  }, [gcsConfigFile]);

  useEffect(() => {
    if (importErrors.length > 0) {
      setIsGCSConfigDialogOpen(true);
    }
    // eslint-disable-next-line
  }, [importErrors]);


  return (
      <>
        <SidePanelSection
            testId="storage-node-section"
            title="Storage Node Configuration"
            contentClass={styles.content}
        >
          <div>
            <FormField testId="provider" className={styles["field-provider"]} field={form.type}>
              <FormFieldLabel noLeftMargin={true}>
                {form.type.isDisabled ? "Storage provider:" : "Select a storage provider:"}
              </FormFieldLabel>
              <FormFieldValue>
                <Select
                    field={form.type}
                    options={enumUtils.values(StorageProvider)
                        .filter(x => x !== StorageProvider.S3Compatible)
                        .map((provider: StorageProvider) => ({
                          value: provider,
                          label: storageUtils.getStorageProviderName(provider)
                        }))}
                />
              </FormFieldValue>
            </FormField>
          </div>
          {form.type.value && (
              <FormSubSection className={styles["provider-properties"]}>
                <div className={styles.description}>
                  Please provide the following configuration details for your {storageUtils.getStorageProviderShortName(form.type.value)} account.<br/>
                  If you are unsure where to find this info, please refer to{" "}
                  <Button testId="guide-button" colors="link" openLinkInNewWindow={true} href={CONFIG.userGuideUrl}>our guide</Button>.
                </div>
                <div className={styles["provider-fields-container"]}>
                  <div className={styles["provider-fields"]}>

                    <FormField
                        testId="storage-name"
                        label="Storage Name"
                        field={form.name}
                    >
                      <TextInput
                          testId="storage-name-input"
                          autoFocus={true}
                          field={form.name}
                      />
                    </FormField>

                    {form.region.isDisabled && (
                        <FormField
                            testId="region"
                            label="Region"
                        >
                          <TextInput
                              testId="region-input"
                              field={form.region}
                          />
                        </FormField>
                    )}
                    {!form.region.isDisabled && (
                        <FormField
                            label={`Choose a ${(storageProviderToRegionFieldLabelMap[form.type.value] || "region").toLowerCase()}`}
                            testId="region"
                            field={form.region}
                        >
                          <RegionSelect
                              testId="region-select"
                              isCustomRegion={form.isCustomRegion.value}
                              provider={form.type.value}
                              value={form.region.value}
                              onChange={(region, isCustomRegion) => {
                                form.region.onChange(region);
                                form.isCustomRegion.onChange(isCustomRegion);
                              }}
                          />
                        </FormField>
                    )}

                    <FormField
                        label="Endpoint URL"
                        testId="endpoint-url"
                        field={form.endpointURL}
                    >
                      <TextInput
                          testId="endpoint-url-input"
                          field={form.endpointURL}
                      />
                    </FormField>

                    <FormField
                        label="Bucket Name"
                        testId="bucket-name"
                        field={form.bucket}
                    >
                      <FormFieldValue>
                        <TextInput
                            testId="bucket-name-input"
                            field={form.bucket}
                        />
                      </FormFieldValue>
                    </FormField>

                    <FormField
                        label="Account Name"
                        testId="account-name"
                        field={form.account_name}
                    >
                      <TextInput
                          testId="account-name-input"
                          field={form.account_name}
                      />
                    </FormField>

                    <FormField
                        label="Container"
                        testId="container"
                        field={form.container}
                    >
                      <TextInput
                          testId="container-input"
                          field={form.container}
                      />
                    </FormField>

                    <FormField
                        label="STS Role/Arn"
                        testId="idp-role"
                        field={form.arn}
                    >
                      <TextInput
                          testId="idp-role-input"
                          field={form.arn}
                      />
                    </FormField>

                    <FormField
                        label="Project Id"
                        testId="project-id"
                        field={form.project_id}
                    >
                      <TextInput
                          testId="project-id-input"
                          field={form.project_id}
                      />
                    </FormField>

                    <FormField
                        label="Access Key"
                        testId="access-key"
                        field={form.key}
                    >
                      <TextInput
                          testId="access-key-input"
                          field={form.key}
                      />
                    </FormField>

                    <FormField
                        label={storageProviderToSecretFieldLabelMap[form.type.value]}
                        testId="secret-key"
                        field={form.secret}
                    >
                      <TextInput
                          testId="secret-key-input"
                          field={form.secret}
                      />
                    </FormField>

                    <FormField
                        label="Blob SAS URI"
                        testId="abs-blob-sas-uri"
                        field={form.sasUri}
                    >
                      <TextInput
                          testId="abs-blob-sas-input"
                          field={form.sasUri}
                      />
                    </FormField>

                    <FormField
                        label="Client Email"
                        testId="client-email"
                        field={form.client_email}
                    >
                      <TextInput
                          testId="client-email-input"
                          field={form.client_email}
                      />
                    </FormField>

                    {props.showSensitiveFields && form.type.value === StorageProvider.GCS &&
                        <FormField
                            testId="gcs-config-file-name"
                            label="Configuration File"
                            field={form.name}
                        >
                          <section className={styles['import-gcs-config-section']}>
                            <TextInput
                                testId="gcs-config-file-name"
                                readOnly={true}
                                value={gcsConfigFile?.fileData.name ?? ''}
                            />

                            <ImportButton
                                testId='import-gcs-config-button'
                                colors="primary"
                                file={gcsConfigFile}
                                onChange={setGCSConfigFile}
                            />
                          </section>
                          {form.gcsConfigProps.error && <FormFieldError error={form.gcsConfigProps.error}/>}
                        </FormField>
                    }
                  </div>

                  {props.showSensitiveFields &&
                      <div className={styles["test-button-container"]}>
                        <TestConnection
                            testConnectionRequest={testConnectionRequest}
                            isTestConnectionSuccess={props.isTestConnectionSuccess}
                            testConnection={props.onTestConnection}
                        />
                      </div>
                  }
                </div>
              </FormSubSection>
          )}
        </SidePanelSection>

        <ImportResultDialog
            isOpen={isGCSConfigDialogOpen}
            errors={importErrors}
            onClose={() => {
              setIsGCSConfigDialogOpen(false);
              setImportErrors([]);
            }}
        />
      </>
  );
}

