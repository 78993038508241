import React, { useEffect, useState } from "react";
import styles from "./number-input.module.scss";
import clsx from "clsx";
import IComponentProps from "../../common/component-props";
import { IFormField } from "../../../../utils/form-utils";
import _ from "underscore";
import Button from "../../button";


interface INumberInputProps extends IComponentProps {
  testId: string;
  value?: number;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
  onBlur?: (value: number) => void;
  field?: IFormField<number>;
  showButtons?: boolean;
  formatFn?: (number: number) => string;
}

export function NumberInput(props: INumberInputProps) {
  const onChange = props.field ? props.field.onChange : props.onChange;
  const onBlur = props.field ? props?.field?.onBlur : props.onBlur;
  const value = props.field ? props.field.value : props.value;

  const [inputValue, setInputValue] = useState(formatValue(value));

  function formatValue(value: number): string {
    return props.formatFn ? props.formatFn(value) : String(value === null || value === undefined ? "" : value);
  }

  function onInputChange(inputValue: string) {
    const newValue = inputValue ? Number(inputValue) : null;
    setInputValue(inputValue);
    if (value !== newValue) {
      onChange(newValue);
    }
  }

  function increaseValue() {
    if (_.isUndefined(props.max)) {
      onChange(value + 1);
    }
    else {
      onChange(Math.min(props.max, value + 1));
    }
  }

  function decreaseValue() {
    if (_.isUndefined(props.min)) {
      onChange(value - 1);
    }
    else {
      onChange(Math.max(props.min, value - 1));
    }
  }

  function handleBlur() {
    let validatedValue = value;
    if (!_.isUndefined(props.min)) {
      validatedValue = Math.max(props.min, validatedValue);
    }
    if (!_.isUndefined(props.max)) {
      validatedValue = Math.min(props.max, validatedValue);
    }

    if (validatedValue !== value) {
      onChange(validatedValue);
    }
    setInputValue(formatValue(validatedValue));
    onBlur && onBlur(validatedValue);
  }

  useEffect(() => {
    const currVal = inputValue ? Number(inputValue) : null;
    if (value !== currVal) {
      setInputValue(formatValue(value));
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <div
      className={clsx(styles.root, props.showButtons && styles["root--buttons"],  props.className)}
      data-testid={props.testId}
      style={props.style}
    >
      {props.showButtons && (
        <Button 
          testId="decrease-button"
          className={styles["decrease-button"]}
          onClick={decreaseValue}
        >
          -
        </Button>
      )}
      <input
        value={inputValue || ""}
        type="number"
        min={props.min}
        max={props.max}
        className={styles.input}
        disabled={props.disabled}
        onChange={(e) => onInputChange(e.target.value)}
        onBlur={handleBlur}
      />
      {props.showButtons && (
        <Button 
          testId="increase-button"
          className={styles["increase-button"]}
          onClick={increaseValue}
        >
          +
        </Button>
      )}
    </div>
  );
}
