import { AnyAction } from "redux";
import { IApplicationCredentialsListItem } from "../../api/entities/application";
import { DELETE_CONFIRMATION_DIALOG_NAME, getApplicationCredentialsEntityName } from "../actions/application-credentials";
import { createDialogReducer, DIALOG_DEFAULT_STATE } from "./dialog";
import { IDialogState } from "./index";

export interface IApplicationCredentialsState {
    [DELETE_CONFIRMATION_DIALOG_NAME]: IDialogState<IApplicationCredentialsListItem>;
}

function getApplicationCredentialsDefaultState(): IApplicationCredentialsState {
    return {
        [DELETE_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE
    };
}
const getDeleteConfirmationReducer = () => createDialogReducer(getApplicationCredentialsEntityName(), DELETE_CONFIRMATION_DIALOG_NAME);

export function applicationCredentials(state: IApplicationCredentialsState = getApplicationCredentialsDefaultState(),
                                       action: AnyAction): IApplicationCredentialsState {
    state = getDeleteConfirmationReducer()(state, action);
    return state;
}
