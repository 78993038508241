import { TimeLengthUnit } from "./../api/entities/policy";
import { StorageSpaceUnit } from "../api/entities/policy";
import {
  ALLOTTED_SPACE_PRECISION as STORAGE_SPACE_PRECISION,
  OPERATIONS_COUNT_PRECISION,
  PERCENTAGE_PRECISION,
  YEAR_DAYS,
  MONTH_DAYS,
  MS_UNIT,
  DAY_SECONDS,
  HOUR_SECONDS,
  MINUTE_SECONDS
} from "../constants/common";
import { DateTime } from "luxon";
import _ from "underscore";
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from "../constants/formats";
import { RetentionPolicy, retentionPolicyToTextMap } from "../components/common/sections/retention-section/retention-section";

const UNIT_NAMES = Object.keys(MS_UNIT);

export class Formatters {
  static formatNumberUnit(
    value: number,
    customFormatter?: ((value: number) => string | number) | number
  ) {
    if (customFormatter === undefined || typeof customFormatter === "number") {
      const precision = customFormatter ? (customFormatter as number) : 0;
      customFormatter = v => this.formatNumber(v, precision);
    }
    const unitNameIdx = UNIT_NAMES.findIndex(k => value < MS_UNIT[k]),
      unitName =
        unitNameIdx === 0
          ? ""
          : UNIT_NAMES[
              unitNameIdx < 0 ? UNIT_NAMES.length - 1 : unitNameIdx - 1
            ],
      converted = unitName ? value / MS_UNIT[unitName] : value,
      formattedValue = customFormatter ? customFormatter(converted) : converted;
    return formattedValue + unitName;
  }

  static formatOperationsCount(count: number): string {
    if (count == null) {
      return "";
    }
    if (count < 10000) {
      return `${count}`;
    }
    return this.formatNumberUnit(count, OPERATIONS_COUNT_PRECISION);
  }

  static formatNetworkSpeed(bytesPerSecond: number): string {
    return `${Formatters.formatStorageSpace(bytesPerSecond)}/s`;
  }

  static formatNetworkSpeedKb(kbPerSecond: number): string {
    return Formatters.formatNetworkSpeed(kbPerSecond * 1000);
  }

  static formatStorageSpaceMb(sizeInMb?: number): string {
    return Formatters.formatStorageSpace(
      sizeInMb == null ? null : sizeInMb * MS_UNIT.M
    );
  }

  static formatStorageSpace(sizeInBytes?: number): string {
    if (sizeInBytes == null) {
      return "";
    }
    return (
      this.formatNumberUnit(
        sizeInBytes,
        sizeInBytes < MS_UNIT.K ? 0 : STORAGE_SPACE_PRECISION
      ) + "B"
    );
  }

  static formatStorageRecoveryLimit(recoveryLimit: string): string {
    const count = parseInt(recoveryLimit, 10);
    if (count > 0) {
      if (recoveryLimit.endsWith(TimeLengthUnit.Hours)) {
        return `${count} ${count === 1 ? "Hour" : "Hours"}`;
      }
      if (recoveryLimit.endsWith(TimeLengthUnit.Days)) {
        return `${count} ${count === 1 ? "Day" : "Days"}`;
      }
      if (recoveryLimit.endsWith(TimeLengthUnit.Months)) {
        return `${count} ${count === 1 ? "Month" : "Months"}`;
      }
      if (recoveryLimit.endsWith(TimeLengthUnit.Years)) {
        return `${count} ${count === 1 ? "Year" : "Years"}`;
      }
    }
    return "";
  }

  static formatStorageRetentionDays(days: number | null) {
    if (!days) {
      return "";
    }
    let divider = 1,
      unit = "Day";
    if (days && days % YEAR_DAYS === 0) {
      divider = YEAR_DAYS;
      unit = "Year";
    } else if (days && days % MONTH_DAYS === 0) {
      divider = MONTH_DAYS;
      unit = "Month";
    }
    const converted = days / divider,
      postfix = converted > 1 ? "s" : "";
    return Math.ceil(converted) + ` ${unit}${postfix}`;
  }

  static formatStorageRevisionLength(revisionlength: string): string {
    const count = parseInt(revisionlength, 10);
    if (count > 0) {
      if (revisionlength.endsWith(StorageSpaceUnit.Tb)) {
        return `${count}TB`;
      }
      if (revisionlength.endsWith(StorageSpaceUnit.Gb)) {
        return `${count}GB`;
      }
      if (revisionlength.endsWith(StorageSpaceUnit.Mb)) {
        return `${count}MB`;
      }
    }
    return "";
  }

  static formatPercentage(percentage: number): string {
    return `${Formatters.round(percentage || 0, PERCENTAGE_PRECISION)}%`;
  }

  static formatNumber(value: number, precision: number = 0): string {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: precision,
    }).format(value);
  }

  static round(value: number, precision: number = 0): number {
    const multiplier = Math.pow(10, precision);
    return Math.round(value * multiplier) / multiplier;
  }

  static calculateUsagePercentage(
    storageUsageMB: number,
    totalStorageMB: number
  ): number {
    let usageInBytes = storageUsageMB * MS_UNIT.M;
    let totalInBytes = totalStorageMB * MS_UNIT.M;
    let percentage = (usageInBytes / totalInBytes) * 100;
    return isNaN(percentage) ? 0 : parseInt(percentage.toFixed(2));
  }

  static formatDate(date: string | Date | DateTime, format?: string): string {
    let dateTime: DateTime = date as DateTime;
    if (date) {
      if (_.isString(date)) {
        dateTime = DateTime.fromISO(date);
      }
      else if (_.isDate(date)) {
        dateTime = DateTime.fromJSDate(date);
      }
      return dateTime.toFormat(format || DATE_FORMAT);
    }
    return "";
  }

  static formatDateTime(date: string | Date | DateTime, format?: string): string {
    return this.formatDate(date, format || DATE_TIME_FORMAT);
  }

  static formatTime(date: string | Date | DateTime, format?: string): string {
    return this.formatDate(date, format || TIME_FORMAT);
  }

  static formatStorageRetention(enabled: boolean, storageRetentionMegabytes: number, storageRetentionDays: number): string {
    if (enabled && (storageRetentionMegabytes !== null || storageRetentionDays !== null)) {
      const result = [];
      if (storageRetentionMegabytes) {
        result.push(
          Formatters.formatStorageSpaceMb(storageRetentionMegabytes)
        );
      }
      if (storageRetentionDays) {
        result.push(
          Formatters.formatStorageRetentionDays(storageRetentionDays)
        );
      }
      if (storageRetentionMegabytes === 0 && storageRetentionDays === 0) {
        return retentionPolicyToTextMap[RetentionPolicy.DeleteOnly];
      }
      return result.join("/");
    }
    return retentionPolicyToTextMap[RetentionPolicy.RetainOnly];
  };

  static formatEstimatedDuration(durationSeconds: number) {
    if (durationSeconds >= 0) {
      const days = Math.trunc(durationSeconds / DAY_SECONDS);
      const hours = Math.trunc((durationSeconds % DAY_SECONDS) / HOUR_SECONDS);
      const minutes = Math.trunc((durationSeconds % HOUR_SECONDS) / MINUTE_SECONDS);
      const seconds = durationSeconds % MINUTE_SECONDS;

      const result: string[] = [];
      if (days) {
        result.push(days === 1 ? "1 day" : `${days} days`);
      }
      if (hours) {
        result.push(hours === 1 ? "1 hour" : `${hours} hours`);
      }
      if (minutes && !days) {
        result.push(minutes === 1 ? "1 minute" : `${minutes} minutes`);
      }
      if (seconds && !days && !hours) {
        result.push(seconds === 1 ? "1 second" : `${seconds} seconds`);
      }
      if (durationSeconds === 0) {
        return "0 seconds";
      }

      return result.join(" ");
    }
    return "";
  }
}
