import React from "react";
import styles from "./paging.module.scss";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import TablePagination from "@mui/material/TablePagination";
import clsx from "clsx";

export interface IPagingProps {
  compact?: boolean;
  count: number;
  rowsPerPage: number;
  page: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  onRowsPerPageChange?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
}

export function Paging(props: IPagingProps) {
  return (
    <TablePagination
      classes={{
        root: clsx(styles.root, props.compact && styles["root--compact"]),
        spacer: styles.spacer,
        displayedRows: styles["displayed-rows"]
      }}
      ActionsComponent={
        props.count <= props.rowsPerPage ? () => null : TablePaginationActions
      }
      data-testid="paging"
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={props.count}
      rowsPerPage={props.rowsPerPage}
      page={props.page}
      backIconButtonProps={{
        "aria-label": "previous page",
      }}
      nextIconButtonProps={{
        "aria-label": "next page",
      }}
      onPageChange={props.onChangePage}
      onRowsPerPageChange={props.onRowsPerPageChange}
    />
  );
}
