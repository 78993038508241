import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationCredentialsScopeName } from "../../../../../api/entities/application";
import { AllStorageProps, IStorageNode, IStorageNodeCredentialsConfirmationData, IStorageNodeRaw, IStorageNodeTestConnectionRequest, IStorageNodeTestConnectionResponse, ApplicationCredentialsScopePermission, StorageProvider, scopePermissionDict } from "../../../../../api/entities/storage-node";
import { getStorageProvider, IStorageNodeV2 } from "../../../../../api/entities/storage-node-v2";
import { ApiVersionNumber } from "../../../../../api/services/data-service";
import { catchException, commonActions } from "../../../../../redux/actions/common";
import { storagePoolV1Actions, storagePoolV2Actions } from "../../../../../redux/actions/storage-pool";
import { IAppState } from "../../../../../redux/reducers";
import { enumUtils } from "../../../../../utils/enum-utils";
import { storageNodeServiceFactory } from "../../../../../utils/factories/storage-node-service-factory";
import { formUtils, useField } from "../../../../../utils/form-utils";
import { useApi } from "../../../../../utils/hooks/use-api";
import { storageUtils } from "../../../../../utils/storage-utils";
import { validators } from "../../../../../utils/validators";
import DetailsPanel from "../../../../shared-ui/details-panel/details-panel";
import { FormPanel } from "../../../../shared-ui/form/form-panel/form-panel";
import { ReplaceStorageConfirmation } from "../../../../storage-pools/dialogs/replace-storage-confirmation/replace-storage-confirmation";
import { IGCSConfigProps, IStorageNodeForm, IStorageNodeFormValue, IStorageNodeTypes, StorageNodeSection } from "../../../../storage-pools/sections/storage-node-section/storage-node-section";
import { IStoragePoolNode } from "../../../../storage-pools/sections/storage-nodes-section/storage-nodes-section";
import styles from "./storage-node-panel.module.scss";
import { OwnerInfoSection } from "../../sections/details-owner-info/details-owner-info";
import { Formatters } from "../../../../../helper/formatters";

function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, void, Action>) {
  return {
    showSnackBar: (message: string) => {
      return dispatch(commonActions.showSnackBar(true, message));      
    },
    openReplaceConfirmationV1: (storageNode: IStorageNode) => {
      return dispatch(storagePoolV1Actions.replaceStorageConfirmation.open(storageNode));
    },
    closeReplaceConfirmationV1: () => {
      return dispatch(storagePoolV1Actions.replaceStorageConfirmation.close());
    },
    openCredentialsInfo: (data: IStorageNodeCredentialsConfirmationData) =>
        dispatch(storagePoolV2Actions.credentialsInfo.open(data)),
  };
}

export enum StorageNodePanelMode {
  Add = 1,
  FullEdit = 2,
  LimitedEdit = 3,
  Replacement = 4,
  SensitiveFieldsUpdate = 5
} 

export interface IStorageNodePanelProps extends 
  ReturnType<typeof mapDispatchToProps> {
    storagePoolNode?: IStoragePoolNode;
    mode: StorageNodePanelMode;
    onClose: () => void;
    onNodeSaved: (node: IStorageNode | IStorageNodeV2 | IStorageNodeRaw, storageNodeTypes: IStorageNodeTypes, testConnectionResult: boolean) => void;

    applicationCredentialsScope?: ApplicationCredentialsScopeName;
    version: ApiVersionNumber;
}

const modeToTitleMap = {
  [StorageNodePanelMode.Add]: "Add a Storage Node",
  [StorageNodePanelMode.SensitiveFieldsUpdate]: "Update a Storage Node",
  [StorageNodePanelMode.FullEdit]: "Manage Storage Node",
  [StorageNodePanelMode.LimitedEdit]: "Manage Storage Node",
  [StorageNodePanelMode.Replacement]: "Replace Storage Node",
};

const modeToActionTitleMap = {
  [StorageNodePanelMode.Add]: "Add Storage Node",
  [StorageNodePanelMode.SensitiveFieldsUpdate]: "Done",
  [StorageNodePanelMode.FullEdit]: "Done",
  [StorageNodePanelMode.LimitedEdit]: "Done",
  [StorageNodePanelMode.Replacement]: "Replace Storage Node",
};

const formDefaultValue: IFormValue = {
  type: null,
  name: "",
  region: "",
  isCustomRegion: true,
  endpointURL: "",
  bucket: "",
  account_name: "",
  container: "",
  arn: "",
  project_id: "",
  key: "",
  secret: "",
  client_email: "",
  gcsConfigProps: null,
  sasUri: "",
};

interface IForm extends IStorageNodeForm {}
interface IFormValue extends IStorageNodeFormValue {}

export const StorageNodePanel = connect(null, mapDispatchToProps)((props: IStorageNodePanelProps) => {
  const { mode, storagePoolNode, applicationCredentialsScope, version } = props;
  const [api, isLoading] = useApi();
  const [testConnectionResult, setTestConnectionResult] = useState<boolean>(null);
  const [isTestConnectionRequired, setIsTestConnectionRequired] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [storageNode, setStorageNode] = useState<any>(null);
  const open = !!storagePoolNode || mode === StorageNodePanelMode.Add;
  const isNodeCreation = mode === StorageNodePanelMode.Add;

  const form: IForm = {
    type: useField<StorageProvider>(formDefaultValue.type, [validators.required()]),
    name: useField<string>(formDefaultValue.name, [validators.required()], [{ asyncValidator: validateName }], true),
    region: useField<string>(formDefaultValue.region, [validateS3Region]),
    isCustomRegion: useField<boolean>(formDefaultValue.isCustomRegion),
    endpointURL: useField<string>(formDefaultValue.endpointURL),
    bucket: useField<string>(formDefaultValue.bucket, [validators.required()], [{ asyncValidator: validateBucketName }], true),
    account_name: useField<string>(formDefaultValue.account_name, [validators.required()]),
    container: useField<string>(formDefaultValue.container, [validators.required()]),
    arn: useField<string>(formDefaultValue.arn),
    project_id: useField<string>(formDefaultValue.project_id, [validators.required()]),
    key: useField<string>(formDefaultValue.key, [validators.required()]),
    secret: useField<string>(formDefaultValue.secret, [(value) => isTestConnectionRequired ? validators.required()(value) : null]),
    client_email: useField<string>(formDefaultValue.client_email, [validators.required()]),
    gcsConfigProps: useField<IGCSConfigProps>(formDefaultValue.gcsConfigProps, [(value) => props.mode === StorageNodePanelMode.SensitiveFieldsUpdate ? validators.required()(value) : null]),
    sasUri: useField<string>(formDefaultValue.sasUri, [validators.required()]),
  };
  const testConnectionRequest = getTestConnectionRequest();

  useEffect(() => {
    setErrorMessage(null);

    if (version === 2 && mode !== StorageNodePanelMode.SensitiveFieldsUpdate) {
      setTestConnectionResult(null);
    }
    else {
      const connectionSensitiveFields = { ...form };
      delete connectionSensitiveFields.name;

      const isConnectionParamsChanged = formUtils.isChanged(connectionSensitiveFields);
      if (isConnectionParamsChanged) {
        setIsTestConnectionRequired(true);
        setTestConnectionResult(null);
      }
    }

    // eslint-disable-next-line
  }, Object.values(formUtils.getFormValue(form, true)));

  useEffect(() => {
    if (open) {
      
      if (storagePoolNode) {
        setStorageNode(storagePoolNode.node);
        setForm(storagePoolNode.node);
        formUtils.setIsDisabled(form, mode === StorageNodePanelMode.LimitedEdit);

        setIsTestConnectionRequired(false);
        setTestConnectionResult(null);

        if (mode === StorageNodePanelMode.LimitedEdit) {
          form.name.setIsDisabled(false);
          form.bucket.setIsDisabled(false);
          form.region.setIsDisabled(false);
          form.client_email.setIsDisabled(false);
          form.key.setIsDisabled(false);
          form.arn.setIsDisabled(false);
          form.secret.setIsDisabled(false);
        }
      }
      if (mode === StorageNodePanelMode.Add || mode === StorageNodePanelMode.Replacement) {
        formUtils.setFormValue<IFormValue>(form, formDefaultValue);
        formUtils.setIsDisabled(form, false);
        setIsTestConnectionRequired(version === 1);
        setTestConnectionResult(null);
      }
      if (mode === StorageNodePanelMode.SensitiveFieldsUpdate) {
        setIsTestConnectionRequired(true);
        setTestConnectionResult(null);
      }
    }

    // eslint-disable-next-line
  }, [open, storagePoolNode]);

  const storageNodeService: any = storageNodeServiceFactory.getStorageNodeService(version);

  const showSensitiveFields = version === 1 || props.mode === StorageNodePanelMode.SensitiveFieldsUpdate;

  function getTestConnectionRequest(): IStorageNodeTestConnectionRequest {
    let formValue = storageUtils.cleanNotApplicableFields(formUtils.getFormValue<IStorageNodeFormValue>(form), true);
    delete formValue.name;
    return updateStorageNodeType(formValue);
  }

  function validateBucketName(bucketName: string): Promise<string> {
    if (mode === StorageNodePanelMode.FullEdit) {
      const nodeProps = JSON.parse(props.storagePoolNode.node.props) as AllStorageProps;
      if (nodeProps.bucket === bucketName) {
        return Promise.resolve(null);
      }
    }

    if (mode === StorageNodePanelMode.SensitiveFieldsUpdate) {
      if (props.storagePoolNode.node['bucket'] === bucketName) {
        return Promise.resolve(null);
      }
    }

    let storageType = getStorageType(form.type.value, form.endpointURL.value);
    return storageNodeService.bucketNameValidator(bucketName, storageType)
      .catch(catchException())
      .then((success) => success ? null : "Bucket name should be unique");
  }

  function validateName(name: string): Promise<string> {
    if (mode === StorageNodePanelMode.FullEdit ||
        mode === StorageNodePanelMode.LimitedEdit ||
        mode === StorageNodePanelMode.SensitiveFieldsUpdate) {
      if (props.storagePoolNode.node.name === name) {
        return Promise.resolve(null);
      }
    }
    return storageNodeService.nameValidator(name)
      .catch(catchException())
      .then((success) => success ? null : "Storage name should be unique");
  }

  function validateS3Region(region: string): string {
    let storageType = getStorageType(form.type.value, form.endpointURL.value);
    if (storageType !== StorageProvider.S3) {
      return null;
    }

    if (!region) {
      return "Region is required for an S3 storage node";
    }
  }

  function setForm(storageNode: IStorageNode | IStorageNodeV2) {
    const nodeProps = JSON.parse(storageNode.props) as AllStorageProps;
    const type = storageUtils.getStorageProviderGeneralizedType(getStorageProvider(storageNode));

    const bucketPropName = type === StorageProvider.ABS ? "container" : "bucket";
    let bucketName = storageNode["bucket"];
    if (!bucketName && !!nodeProps) {
      bucketName = nodeProps[bucketPropName];
    }

    const formValue: IFormValue = {
      name: storageNode.name,
      [bucketPropName]: bucketName,
      arn: nodeProps?.roleArn,
      type,
      key: "",
      secret: "",
      isCustomRegion: storageUtils.isCustomRegion(
        type,
        storageNode.region
      ),
      region: storageNode.region,
      ...nodeProps,
      gcsConfigProps: null,
      sasUri: "",
    };
    formUtils.setFormValue(form, formValue);
  }

  function addNode(formValue: IFormValue) {
    const replaceOldNode = mode === StorageNodePanelMode.FullEdit || mode === StorageNodePanelMode.Replacement;
    return api(storageNodeService.addStorageNode({
      ...storageUtils.cleanNotApplicableFields(formValue, showSensitiveFields),
      oldStorageNodeId: replaceOldNode ? storagePoolNode.node.id : null, 
      isReplace: replaceOldNode
    })).then((savedNode: any) => {
      if (savedNode) {
        props.onNodeSaved(savedNode,
          {
            storeContent: true,
            storeKey: true,
            storeMetadata: true,
          },
          testConnectionResult
        );
        props.onClose();
      }
    });
  }

  function updateNode(formValue: IFormValue) {
    return api(storageNodeService.update({
      ...storageUtils.cleanNotApplicableFields(formValue, showSensitiveFields),
      id: storagePoolNode.node.id
    })).then((savedNode: any) => {
      if (savedNode) {
        props.onNodeSaved(savedNode, storagePoolNode.types, testConnectionResult);
        props.onClose();
      }
    });
  }

  function updateNodeSensitiveFields(formValue: IFormValue) {
    let nodeProps = null;
    if (!!formValue.endpointURL) {
      nodeProps = JSON.stringify({ endpointURL: formValue.endpointURL });
    }
    if (formValue.type === StorageProvider.GCS) {
      nodeProps = JSON.stringify(formValue.gcsConfigProps);
    }

    let node: IStorageNodeRaw = {
      ...storageUtils.cleanNotApplicableFields(formValue, showSensitiveFields),
      id: String(storagePoolNode.node.id),
      props: nodeProps
    };

    if (!!formValue.container) {
      node.bucket = formValue.container;
    }

    if (node) {
      const save = (isConnectionSuccessful?: boolean) => {
        props.onNodeSaved(node,
            {
              storeContent: true,
              storeKey: true,
              storeMetadata: true,
            },
            testConnectionResult ?? isConnectionSuccessful
        );
        props.onClose();
      }

      if (testConnectionResult !== null) {
        save();
      }
    }
  }

  function handleReplaceConfirmed() {
    addNode(formUtils.getFormValue<IFormValue>(form)).then(() => {
      if (version === 1) {
        props.closeReplaceConfirmationV1();
      }
    });
  }

  function getStorageType(type: StorageProvider, endpointURL?: string): StorageProvider {
    if (type === StorageProvider.S3 && !!endpointURL) {
      return StorageProvider.S3Compatible;
    }
    return type;
  }

  function updateStorageNodeType<T extends IStorageNodeFormValue>(formValue: T): T {
    return {...formValue, type: getStorageType(formValue.type, formValue.endpointURL) };
  }

  function save() {
    if (!formUtils.isChanged(form)) {
      props.onClose();
      return;
    }

    if (!form.type.value) {
      setErrorMessage("Please select provider");
      form.type.validate(form.type.value);
      return;
    }
    if (isTestConnectionRequired && !testConnectionResult) {
      setErrorMessage("Please test connection");
      return;
    }

    formUtils.validateAll(form).then((isValid) => {
      if (isValid) {
        const formValue = updateStorageNodeType(formUtils.getFormValue<IFormValue>(form));

        if (mode === StorageNodePanelMode.Replacement) {
          if (version === 1) {
            props.openReplaceConfirmationV1(storagePoolNode.node as IStorageNode);
          }
        }
        else if (mode === StorageNodePanelMode.LimitedEdit || (mode === StorageNodePanelMode.FullEdit && !isTestConnectionRequired)) {
          updateNode(formValue);
        }
        else if (mode === StorageNodePanelMode.Add || mode === StorageNodePanelMode.FullEdit) {
          addNode(formValue);
        }
        else if (mode === StorageNodePanelMode.SensitiveFieldsUpdate) {
          updateNodeSensitiveFields(formValue);
        }
      }
    });
  }

  function testConnection(testConnectionRequest: IStorageNodeTestConnectionRequest) {
    return api(storageNodeService.testConnection(testConnectionRequest)).then((result: IStorageNodeTestConnectionResponse) => {
      let connectionResult = result.isConnectionSuccessful;
      let isExcessivePermission = false;

      if (!!applicationCredentialsScope) {
        const allPermissions = enumUtils.values(ApplicationCredentialsScopePermission) as ApplicationCredentialsScopePermission[];

        connectionResult = scopePermissionDict[applicationCredentialsScope].every(p => result.operationStatus[p]);
        isExcessivePermission = applicationCredentialsScope !== 'full' && allPermissions.filter(x => !scopePermissionDict[applicationCredentialsScope].includes(x)).some(p => result.operationStatus[p]);
      }

      setTestConnectionResult(connectionResult);
      if (connectionResult) {
        setErrorMessage(null);
      }
      else {
        props.showSnackBar(result.msg);
      }

      if (isExcessivePermission || !connectionResult) {
        props.openCredentialsInfo({
          isConnectionSuccessful: connectionResult,
          operationStatus: result.operationStatus,
          scope: applicationCredentialsScope
        });
      }

      return result.isConnectionSuccessful;
    });
  }

  function isOkButtonDisabled(): boolean {
    if (version === 1) {
      return isTestConnectionRequired && !testConnectionResult;
    }
    if (mode === StorageNodePanelMode.SensitiveFieldsUpdate) {
      return isTestConnectionRequired && testConnectionResult === null;
    }
    return !storageUtils.isTestConnectionRequestValid(testConnectionRequest, false, version);
  }

  return (
    <>
      <DetailsPanel
        isLoading={isLoading}
        open={open}
        onCloseClick={props.onClose}
        title={modeToTitleMap[mode]}
        className={styles.panel}
      >
        {open && (
          <FormPanel
            okButtonText={modeToActionTitleMap[mode]}
            okButtonClick={save}
            okButtonDisabled={isOkButtonDisabled()}
            errorMessage={errorMessage}
          >
            {
                !isNodeCreation && !!storageNode && <>
                  <OwnerInfoSection entity={storageNode}/>
                  <div>Created At: {Formatters.formatDateTime(storageNode?.createdAt)}</div>
                </>
            }

            <StorageNodeSection
              form={form}
              isTestConnectionSuccess={testConnectionResult}
              testConnectionRequest={testConnectionRequest}
              onTestConnection={testConnection}
              showSensitiveFields={showSensitiveFields}
            />
          </FormPanel>
        )}
      </DetailsPanel>
      {mode === StorageNodePanelMode.Replacement && (
        <ReplaceStorageConfirmation onConfirm={handleReplaceConfirmed}/>
      )}
    </>
  );
});
