import React, { useContext, useEffect, useState } from "react";
import { IStorageNode } from "../../../api/entities/storage-node";
import { getStorageProviderType, IStorageNodeV2, setReplacedByStorageNodeId } from "../../../api/entities/storage-node-v2";
import { IStoragePoolCreateRequest, IStoragePoolUpdateRequest } from "../../../api/entities/storage-pool";
import { CONFIG } from "../../../config/config";
import { FormMode, formUtils, IFormField } from "../../../utils/form-utils";
import { routeUtils } from "../../../utils/route-utils";
import { storagePoolUtils } from "../../../utils/storage-pool-utils";
import { RestoreFilesSection } from "../../common/sections/restore-files-section/restore-files-section";
import { FormPanel } from "../../shared-ui/form/form-panel/form-panel";
import { TableSelected } from "../../shared-ui/table/table";
import { IStorageNodeListItem, SelectStorageNodesPanel, toStorageNodeListItem } from "../panels/select-storage-nodes-panel/select-storage-nodes-panel";
import { StorageNodePanel, StorageNodePanelMode } from "../../v2/common/panels/storage-node-panel/storage-node-panel";
import { PoolNameSection } from "../sections/pool-name-section/pool-name-section";
import { IStorageNodeTypes } from "../sections/storage-node-section/storage-node-section";
import { IStoragePoolNode, StorageNodesSection } from "../sections/storage-nodes-section/storage-nodes-section";
import { StorageReconstructionSection } from "../sections/storage-reconstruction-section/storage-reconstruction-section";
import { VersionContext } from "../storage-pools";
import styles from "./storage-pool-form.module.scss";

export interface IStoragePoolFormProps {
  storagePoolId?: number;
  form: IStoragePoolForm;
  mode: FormMode;
  onCreateStoragePool: (createRequest: IStoragePoolCreateRequest | IStoragePoolCreateRequest<string>) => Promise<any>;
  onUpdateStoragePool?: (updateRequest: IStoragePoolUpdateRequest) => Promise<any>;
}

export interface IStoragePoolFormValue {
  name: string;
  storagePoolNodes: IStoragePoolNode[];
  contentSecurityLevel: number;
  keySecurityLevel: number;
  metaSecurityLevel: number;
}

export interface IStoragePoolForm {
  name: IFormField<string>;
  storagePoolNodes: IFormField<IStoragePoolNode[]>;
  contentSecurityLevel: IFormField<number>;
  keySecurityLevel: IFormField<number>;
  metaSecurityLevel: IFormField<number>;
}

export const storagePoolFormDefaultValue: IStoragePoolFormValue = {
  name: "",
  storagePoolNodes: [],
  contentSecurityLevel: CONFIG.minSecurityLevel,
  keySecurityLevel: CONFIG.minSecurityLevel,
  metaSecurityLevel: CONFIG.minSecurityLevel,
};

type IdType = number | string;

export function StoragePoolForm(props: IStoragePoolFormProps) {
  const { form } = props;

  const [storageNodePanelMode, setStorageNodePanelMode] = useState<StorageNodePanelMode>(null);
  const [selectedStorageNode, setSelectedStorageNode] = useState<IStoragePoolNode>(null);

  const [lastAddedStorageNode, setLastAddedStorageNode] = useState<IStorageNode | IStorageNodeV2>(null);
  const [selectStorageNodesPanelOpen, setSelectStorageNodesPanelOpen] = useState<boolean>(false);

  const [selection, setSelection] = useState<TableSelected<IStorageNodeListItem>>({});

  const [nodesCount, setNodesCount] = useState({
    content: CONFIG.minSecurityLevel, 
    key: CONFIG.minSecurityLevel, 
    meta: CONFIG.minSecurityLevel
  });
  const formError = formUtils.getFormError(form);
  const version = useContext(VersionContext);

  function handleEditStorageNode(poolNode: IStoragePoolNode) {
    setStorageNodePanelMode(props.mode === FormMode.Create ? StorageNodePanelMode.FullEdit : StorageNodePanelMode.LimitedEdit);
    setSelectedStorageNode(poolNode);
  }

  function handleReplaceStorageNode(poolNode: IStoragePoolNode) {
    setStorageNodePanelMode(StorageNodePanelMode.Replacement);
    setSelectedStorageNode(poolNode);
  }
  
  const handleAddStorageNode = () => setStorageNodePanelMode(StorageNodePanelMode.Add);
  const handleCloseStorageNodePanel = () => {
    setStorageNodePanelMode(null);
    setSelectedStorageNode(null);
  }

  function handleOpenSelectStorageNodesPanel() {
    const nodes = form.storagePoolNodes.value;
    let initialSelection = {};

    for(let i = 0; i < nodes.length; i++) {
      const node = nodes[i].node;
      initialSelection[node.id] = toStorageNodeListItem(node);
    }

    setSelectStorageNodesPanelOpen(true);
    if (nodes.length > 0) {
      setTimeout(() => {
        setSelection(initialSelection);
      }, 0);
    }
  }

  function handleCloseSelectStorageNodesPanel() {
    setSelectStorageNodesPanelOpen(false);

    let nodes = form.storagePoolNodes.value;
    const selectedStorageNodeIds = Object.values(selection).map(x => x.id);
    const currentStorageNodeIds = nodes.map(x => x.node.id);

    nodes = nodes.filter(x => selectedStorageNodeIds.includes(String(x.node.id)));
    let newStorageNodeIds = subtract(selectedStorageNodeIds, currentStorageNodeIds);

    const types = {
      storeContent: false,
      storeKey: false,
      storeMetadata: false,
    };
    const mapNode = (x: IStorageNodeListItem): IStorageNodeV2 => {
      let storageType = getStorageProviderType(x.storageType);
      return { ...x, storageType } as IStorageNodeV2;
    };
    let newStorageNodes = newStorageNodeIds.map(x => ({ node: mapNode(selection[x]), types: Object.assign({}, types) }));
    form.storagePoolNodes.onChange([...nodes, ...newStorageNodes]);
  }

  function subtract(a: IdType[], b: IdType[]): IdType[] {
    let setB = new Set(b);
    let res: Set<IdType> = new Set();
    for (let i = 0; i < a.length; i++) {
      if (!setB.has(a[i])) {
        res.add(a[i]);
      }
    }
    return Array.from(res);
  }

  function handleStorageNodeSaved(storageNode: IStorageNode, storageNodeTypes: IStorageNodeTypes) {
    const nodes = form.storagePoolNodes.value;
    if (storageNodePanelMode === StorageNodePanelMode.FullEdit || storageNodePanelMode === StorageNodePanelMode.LimitedEdit) {
      nodes[nodes.indexOf(selectedStorageNode)] = { node: storageNode, types: storageNodeTypes };
      form.storagePoolNodes.onChange([...nodes]);
    }
    else if (storageNodePanelMode === StorageNodePanelMode.Add) {
      setLastAddedStorageNode(storageNode);
      form.storagePoolNodes.onChange([...nodes, { node: storageNode, types: storageNodeTypes }]);
    }
    else if (storageNodePanelMode === StorageNodePanelMode.Replacement) {
      setReplacedByStorageNodeId(selectedStorageNode.node, storageNode.id);
      form.storagePoolNodes.onChange([...nodes, { node: storageNode, types: storageNodeTypes }]);
    }
  }

  function save() {
    formUtils.validateAll(form).then((isValid) => {
      if (isValid) {
        const formValue = formUtils.getFormValue<IStoragePoolFormValue>(form);
        if (props.mode === FormMode.Create) {
          const getStorageNodeIds = (list: IStoragePoolNode[], storeTypeKey: keyof IStorageNodeTypes) => {
            let ids = list.filter(n => n.types[storeTypeKey]).map(n => n.node.id);
            return version === 2 ? ids as string[] : ids as number[];
          };

          let createRequest: any = {
            name: formValue.name,
            ContentSecurityLevel: formValue.contentSecurityLevel,
            KeySecurityLevel: formValue.keySecurityLevel,
            MetaSecurityLevel: formValue.metaSecurityLevel,
            contentStorages: getStorageNodeIds(formValue.storagePoolNodes, 'storeContent'),
            keyStorages: getStorageNodeIds(formValue.storagePoolNodes, 'storeKey'),
            metaStorages: getStorageNodeIds(formValue.storagePoolNodes, 'storeMetadata'),
          };

          props.onCreateStoragePool(createRequest);
        }
        else if (props.mode === FormMode.Edit) {
          props.onUpdateStoragePool({
            id: props.storagePoolId,
            name: formValue.name
          });
        }
      }
    });
  }

  useEffect(() => {
    const nodes = form.storagePoolNodes.value;
    const _nodesCount: typeof nodesCount = {
      content: nodes.filter(node => node.types.storeContent).length, 
      key: nodes.filter(node => node.types.storeKey).length, 
      meta: nodes.filter(node => node.types.storeMetadata).length, 
    };

    function updateSecurityLevel(securityLevelField: IFormField<number>, nodesCount: number) {
      const updatedValue = Math.max(Math.min(securityLevelField.value, nodesCount - 1), CONFIG.minSecurityLevel);
      if (updatedValue !== securityLevelField.value) {
        securityLevelField.onChange(updatedValue);  
      }
    }

    updateSecurityLevel(form.contentSecurityLevel, _nodesCount.content);
    updateSecurityLevel(form.keySecurityLevel, _nodesCount.key);
    updateSecurityLevel(form.metaSecurityLevel, _nodesCount.meta);

    setNodesCount(_nodesCount);

     // eslint-disable-next-line
  }, [form.storagePoolNodes.value]);
  
  useEffect(() => {
    form.storagePoolNodes.validate(form.storagePoolNodes.value);
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <FormPanel
        className={styles.root}
        okButtonText="Save"
        okButtonClick={save}
        okButtonDisabled={!!formError}
        errorMessage={formError}
      >
        <PoolNameSection name={form.name}/>
        <StorageNodesSection
          mode={props.mode}
          storagePoolNodes={form.storagePoolNodes} 
          onOpenSelectStorageNodesPanel={handleOpenSelectStorageNodesPanel}
          onEditStorageNode={handleEditStorageNode}
          onReplaceStorageNode={handleReplaceStorageNode}
        />
        <StorageReconstructionSection
          isReadOnly={props.mode === FormMode.Edit}
          contentSecurityLevel={form.contentSecurityLevel}
          keySecurityLevel={form.keySecurityLevel}
          metaSecurityLevel={form.metaSecurityLevel}
          maxContentSecurityLevel={Math.max(nodesCount.content - 1, CONFIG.minSecurityLevel)}
          maxKeySecurityLevel={Math.max(nodesCount.key - 1, CONFIG.minSecurityLevel)}
          maxMetaSecurityLevel={Math.max(nodesCount.meta - 1, CONFIG.minSecurityLevel)}
        />
        {!!props.storagePoolId && storagePoolUtils.canRestoreFiles(version) && (
          <RestoreFilesSection
            description="Restore files in this Storage Pool after a security breach or loss."
            restoreFilesRoute={routeUtils.getRouteToStoragePoolRestoreFiles(props.storagePoolId)}
          />
        )}
      </FormPanel>
      <SelectStorageNodesPanel
          open={selectStorageNodesPanelOpen}
          onOpenStorageNodePanel={handleAddStorageNode}
          onClose={handleCloseSelectStorageNodesPanel}
          lastAddedStorageNode={lastAddedStorageNode}
          selection={selection}
          setSelection={setSelection}
      />
      <StorageNodePanel
        mode={storageNodePanelMode}
        storagePoolNode={selectedStorageNode}
        onClose={handleCloseStorageNodePanel}
        onNodeSaved={handleStorageNodeSaved}
        version={version}
      />
    </>
  );
}
