import { DeviceStatus, IDeviceCompact } from '../api/entities/device';
import { v4 as uuidv4 } from 'uuid';
import * as randomBytes from 'randombytes';

class DeviceUtils {
    canForceLogout(device: IDeviceCompact): boolean {
        return !device.blocked && device.status === DeviceStatus.Active; 
    }

    generateDid(): string {
        let letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const at = `0123456789-_${letters}${letters.toLowerCase()}`;
        return this.generateString(at, 20);
    }

    generateKey(): string {
        return uuidv4();
    }

    private generateString(at: string, length: number) {
        const kb = randomBytes(length);
        let r: string[] = new Array<string>(length);
        for (let i = 0; i < length; i++) {
            r[i] = at[kb[i] % at.length];
        }
        return r.join('');
    }
}

export const deviceUtils = new DeviceUtils();
