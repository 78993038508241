import React, { useEffect, useState } from "react";
import { useApi } from "../../../../../../utils/hooks/use-api";
import { getDefaultRequestParams, getEmptyPage } from "../../../../../../redux/reducers/entity";
import { IPage, IRequestParams } from "../../../../../../api/entities/page";
import { TableSelected } from "../../../../../shared-ui/table/table";
import { IEntity } from "../../../../../../api/entities/id-name";

interface IGetPageService<TEntity, TRequestParams> {
    getPage(requestParams: TRequestParams): Promise<IPage<TEntity>>;
}

interface ITableContainerProps<TEntity, TRequestParams> {
    tableElement: React.FunctionComponent<any>;
    service: IGetPageService<TEntity, TRequestParams>;

    selection?: TableSelected<TEntity>;
    onSelectionChanged?: (selection: TableSelected<TEntity>) => void;
}

export function TableContainer<TEntity extends IEntity<string>, TRequestParams extends IRequestParams>(props: ITableContainerProps<TEntity, TRequestParams>) {
    const [api, isLoading] = useApi();
    const [page, setPage] = useState<IPage<TEntity>>(getEmptyPage());
    const [requestParams, setRequestParams] = useState<TRequestParams>(getDefaultRequestParams() as TRequestParams);
    const {selection, onSelectionChanged} = props;

    function fetchData(requestParamsUpdate: Partial<TRequestParams>) {
        const updatedRequestParams = { ...requestParams, ...requestParamsUpdate };
        setRequestParams(updatedRequestParams);
        api(props.service.getPage(updatedRequestParams)).then(setPage);
    }

    function onSelectAll() {
        const newSelected: TableSelected<TEntity> = {};
        page?.data.forEach(u => {
            newSelected[u.id] = u;
        });
        onSelectionChanged(newSelected);
    }

    useEffect(() => {
        fetchData({});
        // eslint-disable-next-line
    }, []);

    const TableElement = props.tableElement;

    return (
        <TableElement
            isLoading={isLoading}
            page={page}
            requestParams={requestParams}
            setRequestParams={fetchData}

            selection={selection}
            onSelectionChanged={onSelectionChanged}
            onSelectAll={onSelectAll}

            isRowClickable={false}
        />
    );
}