import { useNavigate } from "../../../../utils/hooks/use-navigate";
import React, { useEffect } from "react";
import { IAppState } from "../../../../redux/reducers";
import { commonActions } from "../../../../redux/actions/common";
import { enterpriseAccountSetupActions } from "../../../../redux/actions/enterpriseAccountSetup";
import { connect } from "react-redux";
import { LeftNav } from "./navigation/left-nav";
import OrganizationWizardStep from "./steps/organization/organization";
import UserInvitationWizardStep from "./steps/user-invitation/user-invitation";
import { AccountType } from "../../../../api/entities/user";
import DataMigrationWizardStep from "./steps/data-migration/data-migration";

export enum EnterpriseAccountSetupStep {
    Organization = 1,
    UserInvitation = 2,
    DataMigration = 3,
    Finish = 4
}

interface IEnterpriseAccountSetupContainerProps extends
    ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {}

function EnterpriseAccountSetupContainer(props: IEnterpriseAccountSetupContainerProps) {
    const navigate = useNavigate();

    useEffect(() => {
        if (props.user.accountType === AccountType.Enterprise) {
            props.setStep(EnterpriseAccountSetupStep.UserInvitation);
        }

        if (props.enterpriseAccountSetupSteps === EnterpriseAccountSetupStep.Finish) {
            navigate("/");
        }
    // eslint-disable-next-line
    }, []);

    return (
        <>
            <LeftNav />
            <div className="main-content">
                {props.enterpriseAccountSetupSteps === EnterpriseAccountSetupStep.Organization && <OrganizationWizardStep />}
                {props.enterpriseAccountSetupSteps === EnterpriseAccountSetupStep.UserInvitation && <UserInvitationWizardStep />}
                {props.enterpriseAccountSetupSteps === EnterpriseAccountSetupStep.DataMigration && <DataMigrationWizardStep />}
            </div>
        </>
    );
}

const mapStateToProps = (state: IAppState) => ({
    enterpriseAccountSetupSteps: state.enterpriseAccountSetup.enterpriseAccountSetupStep,
    user: state.users.currentUser,
});

const mapDispatchToProps = dispatch => {
    return {
        setStep: (step: EnterpriseAccountSetupStep) => {
            dispatch(enterpriseAccountSetupActions.setStep(step));
        },
        startLoading: () => {
            dispatch(commonActions.startLoading());
        },
        stopLoading: () => {
            dispatch(commonActions.stopLoading());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseAccountSetupContainer);