import { IPage } from '../entities/page';
import { IUserGroup, IUserGroupsRequestParams } from '../entities/user-group';
import { DataService } from "./data-service";

class UserGroupService extends DataService {
    constructor() {
        super("usergroup");
    }

    get(id: number): Promise<IUserGroup> {
        return this.api.get<IUserGroup>(id);
    }

    getPage(requestParams: IUserGroupsRequestParams): Promise<IPage<IUserGroup>> {
        return this.api.get<IPage<IUserGroup>>(null, requestParams);
    }
}

export const userGroupService = new UserGroupService();