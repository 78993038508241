import React from "react";
import { AllottedSpaceSection, IAllottedSpaceSectionProps } from "../../../common/sections/alloted-space-section/alloted-space-section";

interface IProfileAllottedSpaceSectionProps extends 
  Omit<IAllottedSpaceSectionProps, "valueLabel" | "valueTooltip"> {
}

export function ProfileAllottedSpaceSection(props: IProfileAllottedSpaceSectionProps) {
  return (
    <AllottedSpaceSection
      valueLabel="Storage space allotted for each user"
      valueTooltip="You can configure storage space up to 10 TB per user"
      {...props}
    />
  );
}

