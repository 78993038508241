import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ApiException } from "../../../../../../api/services/api-service";
import { SOMETHING_WRONG } from "../../../../../../constants/messages";
import { commonActions } from "../../../../../../redux/actions/common";
import { IAppState } from "../../../../../../redux/reducers";
import { formUtils, useField } from "../../../../../../utils/form-utils";
import { validators } from "../../../../../../utils/validators";
import Button from "../../../../../shared-ui/button";
import { IAuthParams } from "../../api/entities/auth";
import { UploadMethod } from "../../api/entities/item";
import { AccessRightType, accessRightTypeToTextMap, ExpirationDays, IShareFilesEmailRequest, MaxDownloads } from "../../api/entities/shares";
import { ItemsService } from "../../api/services/items-service";
import { SharesService } from "../../api/services/shares-service";
import { StorageService } from "../../api/services/storage-service";
import { EmailSettingsPanel, IEmailSettings } from "../email-settings-panel/email-settings-panel";
import { EmailForm, IEmailForm } from "../email-form/email-form";
import { FilesList, IFile } from "../files-list/files-list";
import styles from "./share-files.module.scss";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { ConfirmDialog } from "../../../../../shared-ui/dialogs/common/confirm-dialog/confirm-dialog";
import { MyotaHeader } from "../myota-header/myota-header";
import { SentFiles } from "../sent-files/sent-files";
import { Formatters } from "../../../../../../helper/formatters";
import { auth2Utils } from "../../../../../../utils/auth2-utils";
import { AccountsService } from "../../api/services/accounts-service";
import { localStorageService } from "../../api/services/local-storage-service";
import { HttpCode } from "../../../../../../api/http-code";
import { DateTime } from "luxon";
import { CONFIG } from "../../../../../../config/config";

function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, void, Action>) {
  return {
    startLoading: () => {
      return dispatch(commonActions.startLoading());
    },
    stopLoading: () => {
      return dispatch(commonActions.stopLoading());
    },
    showSnackBar: (message: string) => {
      return dispatch(commonActions.showSnackBar(true, message));
    },
    hideSnackBar: () => {
      return dispatch(commonActions.showSnackBar(false));
    },
  };
}

export interface IShareFilesProps extends ReturnType<typeof mapDispatchToProps> {
}

export const ShareFiles = connect(null, mapDispatchToProps)((props: IShareFilesProps) => {
  const emailForm: IEmailForm = {
    Emails: useField<string[]>([], [validators.required("Please enter at least 1 recipient email")]),
    Subject: useField<string>("", [validators.required("Please enter a subject line")]),
    Body: useField<string>("", [validators.required()]),
  };
  const [emailSettings, setEmailSettings] = useState<IEmailSettings>(localStorageService.getEmailSettings() || {
    ExpirationDays: ExpirationDays.AfterSixMonth,
    MaxDownloads: MaxDownloads.Unlimited,
    CcSender: false,
    NotifyOnDownload: false,
    RequireLogin: false,
    ShareAccessRight: { 
      AccessRightType: AccessRightType.FullControl,
      DisplayText: accessRightTypeToTextMap[AccessRightType.FullControl],
      Id: ""
    }
  });
  const [files, setFiles] = useState<IFile[]>([]);
  const [isEmailSettingsOpen, setIsEmailSettingsOpen] = useState(false);
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const [isFilesOver, setIsFilesOver] = useState(false);
  const dragAreaRef = useRef(null);
  const [sentFilesUrl, setSentFilesUrl] = useState(null);
  const [authParams, setAuthParams] = useState<IAuthParams>(localStorageService.getAuthParams() || {} as IAuthParams);
  const [homeFolderId, setHomeFolderId] = useState<string>(null);
  const [token, setToken] = useState(null);
  const apiUrl = `https://${authParams.subdomain}.${authParams.apicp}/sf/v3`;
  const appUrl = `https://${authParams.subdomain}.${authParams.appcp}`;
  const itemsService = new ItemsService(token, apiUrl); 
  const sharesService = new SharesService(token, apiUrl);
  const accountsService = new AccountsService(token, apiUrl);
  const storageService = new StorageService();
  const pageParams = getPageParams();

  function openCitrixApplication() {
    window.location.href = appUrl;
  }

  function isTokenExpired() {
    const expirationDate = localStorageService.getTokenExpirationDate();
    return !expirationDate || expirationDate <= new Date();
  }

  function handleApiException(e: ApiException | any) {
    if (e instanceof ApiException) {
      if (e.status === HttpCode.UNAUTHORIZED_ERROR && authParams.client_id) {
        redirectToAuthServer(authParams.client_id);
      }
      else if (e.data?.message?.value) {
        props.showSnackBar(e.data.message.value);
      }
      else {
        props.showSnackBar(e.message);
      }
    }
    else {
      props.showSnackBar(SOMETHING_WRONG);
    }
    console.error(JSON.stringify(e));
  }

  function withLoading<T>(fn: () => Promise<T>): Promise<T> {
    return new Promise((resolve) => {
      props.startLoading();
      fn().then(resolve).catch(handleApiException).finally(props.stopLoading);
    });
  }

  //this function is modified copy of parseUrlParams from Citrix SDK https://github.com/citrix/ShareFile-JavaScript
  function getPageParams(): IAuthParams {
      // if location hashtag is empty then exit with empty object
      if (window.location.hash.length === 0) {
          return {} as IAuthParams;
      }

      // initialize hashParams and paramObj local variables
      // hashparams is array having key/value of url hashtag parameter
      const hashParams = window.location.hash.substr(1).split("&"),
        paramObj = {} as IAuthParams;

      // popuplating paramObj object with hashtag parameters
      for (var i = 0; i < hashParams.length; i++) {
          // split the key/value object
          var hashTag = hashParams[i].split("="),
              key = decodeURIComponent(hashTag[0]),
              value = decodeURIComponent(hashTag[1]);

          paramObj[key] = value;
      }

      window.location.hash = "";
      return paramObj;
  }

  function redirectToAuthServer(clientId: string) {
    const state = auth2Utils.generateState();
    const currentPageUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;

    localStorageService.setAuthParams({...authParams, state, client_id: clientId });

    window.location.href = 
      `${CONFIG.citrixAuthUrl}?response_type=token&client_id=${clientId}&redirect_uri=${currentPageUrl}&state=${state}`;
}

  function loadFolderId() {
    const itemsService = new ItemsService(token, apiUrl);
    withLoading(() => itemsService.getHomeFolder()).then(folder => {
      if (folder && folder.Id) {
        setHomeFolderId(folder.Id);
      }
      else {
        raiseFailToGetHomeFolderError();
      }
    });
  }

  function raiseFailToGetHomeFolderError() {
    throw new Error("Failed to get home folder. Please check that My Files & Folders feature is enabled.");
  }

  function handleUploadProgress(file: IFile, progress: number) {
    file.uploadProgress = progress;
    props.showSnackBar(`Uploading file ${file.fileData.name} (${Formatters.formatPercentage(progress)})`);
  }
  
  function uploadFiles(fileIndex: number) {
    const file = files[fileIndex];
    withLoading(() => itemsService.upload2(homeFolderId, {
      FileName: file.fileData.name,
      FileSize: file.fileData.size,
      Method: UploadMethod.Standart
    })).then((response) => {
      handleUploadProgress(file, 0);
      withLoading(() => storageService.uploadFile(response.ChunkUri, file.fileData, (progress) => handleUploadProgress(file, progress))).then((uploadResult) => {
        if (uploadResult.error) {
          props.showSnackBar(`Fail to upload ${file.fileData.name}`);
          console.error(JSON.stringify(uploadResult));
        }
        else {
          file.fileId = uploadResult.value[0].id;
          if (fileIndex === files.length - 1) {
            sendShareEmail();
          }
          else {
            uploadFiles(fileIndex + 1);
          }
        }
      });
    });
  }

  function sendShareEmail() {
    const shareFileEmail = {
      ...formUtils.getFormValue(emailForm),
      ...emailSettings
    } as IShareFilesEmailRequest;
      
    shareFileEmail.Items = files.map(file => file.fileId);
    props.showSnackBar(`Sending email...`);
    withLoading(() => sharesService.sendShareEmail(shareFileEmail)).then((response) => {
      props.hideSnackBar();
      setSentFilesUrl(response.Uri);
    });
  }

  function uploadAndSend() {
    formUtils.validateAll(emailForm).then((isValid) => {
      if (isValid) {
        uploadFiles(0);
      }
    })
  }

  function handleDragEnter(e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    dragAreaRef.current = e.target;
    setIsFilesOver(true);
  }

  function handleDragOver(e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDragLeave(e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (dragAreaRef.current === e.target) {
      setIsFilesOver(false);
    }
  }

  function handleDrop(e: React.DragEvent) {
    e.preventDefault();
    if (e.dataTransfer.files) {
      setFiles([...files, ...[...e.dataTransfer.files].map(fileData => ({ fileData }))]);
    }
    setIsFilesOver(false);
  }

  useEffect(() => {
    if (pageParams.access_token && pageParams.state === localStorageService.getAuthParams().state) {
      const updatedAuthParams = {...authParams, ...pageParams};
      localStorageService.setAuthParams(updatedAuthParams);
      localStorageService.setTokenExpirationDate(DateTime.now().plus({ seconds: Number(pageParams.expires_in)}).toJSDate());
      setAuthParams(updatedAuthParams);
      setToken(pageParams.access_token);
    }
    else if (authParams.access_token) {
      if (isTokenExpired()) {
        redirectToAuthServer(authParams.client_id);  
      }
      else {
        setToken(authParams.access_token);
      }
    }
    else if (pageParams.client_id) {
      redirectToAuthServer(pageParams.client_id);
    }
    else {
      throw new Error(`Missing client_id parameter.`);
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token) {
      loadFolderId();
    }
  // eslint-disable-next-line
  }, [token])

  if (!token) {
    return null;
  }

  return (
    <>
      <EmailSettingsPanel
        open={isEmailSettingsOpen}
        emailSettings={emailSettings}
        onClose={() => setIsEmailSettingsOpen(false)}
        onSave={(emailSettings) => {
          localStorageService.setEmailSettings(emailSettings);
          setEmailSettings(emailSettings);
          setIsEmailSettingsOpen(false); 
        }}
      />
      <div 
        className={styles.root}
        onDragEnter={handleDragEnter} 
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave} 
        onDrop={handleDrop}
      >
        <MyotaHeader/>
        {sentFilesUrl && (<SentFiles url={sentFilesUrl}/>)}
        {!sentFilesUrl && (
          <div className={styles.form}>
            <div className={styles["form-columns"]}>
              <div className={styles["email-form-container"]}>
                <div className={styles["email-form"]}>
                  <h1 className={styles["email-form-header"]}>
                    Share with Citrix ShareFile
                  </h1>
                  <EmailForm form={emailForm} accountsService={accountsService}/>
                  <div className={styles["footer"]}>
                    <div className={styles["footer-content"]}>
                      <Button
                        testId="options-button"
                        colors="link"
                        className={styles["email-settings-button"]}
                        onClick={() => setIsEmailSettingsOpen(true)}
                      >
                        <SettingsOutlinedIcon/> 
                        Edit Message Options
                      </Button>
                      <div className={styles["footer-actions"]}>
                        <Button
                          testId="cancel-button"
                          colors="link"
                          onClick={() => setIsCancelConfirmationOpen(true)}
                        >
                          Cancel
                        </Button>
                        <Button
                          testId="send-button"
                          colors="primary"
                          disabled={files.length === 0}
                          onClick={uploadAndSend}
                        >
                          SEND
                        </Button>
                      </div>
                    </div>
                  </div>              
                </div>
              </div>
              <div className={styles["files-list"]}>
                <FilesList
                  files={files}
                  onChange={setFiles}
                />
              </div>
            </div>
          </div>
        )}
        {isFilesOver && (
          <div className={styles["files-over-indicator"]}/>
        )}
      </div>
      <ConfirmDialog
        testId={"cancel-confirmation"}
        title={"Cancel Message"}
        confirmText="Cancel"
        cancelText="Nevermind"
        open={isCancelConfirmationOpen}
        onClose={() => setIsCancelConfirmationOpen(false)}
        onConfirm={openCitrixApplication}
      >
        Are you sure you want to cancel this message?
      </ConfirmDialog>
    </>
  );
});