import React from "react";
import SpaceSelector from "../../../shared-ui/input/space-selector";
import TooltipQuestion from "../../../shared-ui/tooltip-question";
import SidePanelSection from "../../../shared-ui/side-panel-section/side-panel-section";
import { FormField } from "../../../shared-ui/form/form-field/form-field";
import { FormFieldLabel } from "../../../shared-ui/form/form-field-label/form-field-label";
import { FormFieldValue } from "../../../shared-ui/form/form-field-value/form-field-value";
import { ALLOTTED_SPACE_MAX_MB, ALLOTTED_SPACE_MIN_MB } from "../../../../constants/common";
import { Formatters } from "../../../../helper/formatters";

export interface IAllottedSpaceErrors {
  allottedSpaceOutOfRange?: string;
  allottedSpaceUsageExceeds?: string;
}

export interface IAllottedSpaceSectionProps {
  autoFocus?: boolean;
  value: number;
  disabled?: boolean;
  className?: string;
  errors?: IAllottedSpaceErrors;
  valueTooltip: string;
  valueLabel: string;

  onChange: (value: number) => void;
  onError: (errors: Partial<IAllottedSpaceErrors>) => void;
}

export function AllottedSpaceSection(props: IAllottedSpaceSectionProps) {
  function onChange(value: number) {
    props.onChange(value);
    if (value < ALLOTTED_SPACE_MIN_MB || value > ALLOTTED_SPACE_MAX_MB) {
      props.onError({allottedSpaceOutOfRange: `value should be in range 
        ${Formatters.formatStorageSpaceMb(ALLOTTED_SPACE_MIN_MB)} - ${Formatters.formatStorageSpaceMb(ALLOTTED_SPACE_MAX_MB)}`});
    }
    else if (props.errors.allottedSpaceOutOfRange) {
      props.onError({allottedSpaceOutOfRange: null});
    }
  }

  return (
    <SidePanelSection
      title="Allotted Space"
      className={props.className}
      testId="alloted-space"
    >
      <FormField
        testId="allotted-space-field"
        error={props.errors?.allottedSpaceOutOfRange || props.errors?.allottedSpaceUsageExceeds}
      >
        <FormFieldLabel testId="allotted-space-input-label" noLeftMargin={true}>
          {props.valueLabel}
          <TooltipQuestion>{props.valueTooltip}</TooltipQuestion>
        </FormFieldLabel>
        <FormFieldValue>
          <SpaceSelector
            testId="alloted-space-selector"
            autoFocus={props.autoFocus}
            hasError={!!(props.errors?.allottedSpaceOutOfRange || props.errors?.allottedSpaceUsageExceeds)}
            value={props.value}
            hideMb={true}
            disabled={props.disabled}
            tooltip={props.valueTooltip}
            onChange={onChange}
          />
        </FormFieldValue>
      </FormField>
    </SidePanelSection>
  );
}