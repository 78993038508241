import { IDeletedParam, IQueryStrictParams, IRequestParams } from "./page";
import { IIdNameDateTime } from "./id-name";

export interface IInvitation extends Omit<IIdNameDateTime<string>, 'name'> {
    email: string;
    status: InvitationStatus;
    isAdmin: boolean;
}

export enum InvitationSortField {
    Id  = 'id',
    Email = 'email',
    Status = 'status',
    SentDate = 'sentDate'
}

export enum InvitationStatus {
    Pending = 1,
    Accepted = 2,
    Expired = 3,
}

export const invitationStatusDict = {
    [InvitationStatus.Pending]: "Pending",
    [InvitationStatus.Accepted]: "Accepted",
    [InvitationStatus.Expired]: "Expired",
}

export interface IInvitationFilterParams extends IQueryStrictParams {
    status?: InvitationStatus;
}

export interface IInvitationRequestParams extends
    IRequestParams<InvitationSortField>,
    IInvitationFilterParams,
    IDeletedParam {
    fields?: (keyof IInvitation)[];
}

export enum EnterpriseConsoleUserPermission {
    Read = 8,
    Create = 4,
    Modify = 2,
    Delete = 1,
    None = 0
}

export class InviteEmailRequest {
    email: string;
    permission: EnterpriseConsoleUserPermission;
    isAdmin: boolean;
}

export class UserInvitationRequest {
    emails: InviteEmailRequest[];
}

export class UserInvitationResponse {
    msg: string;
    errorEmails: string[] | undefined;
}
