import { IFileStats } from "../entities/file-stats";
import { IPage } from "../entities/page";
import {
  ISharingInstance, ISharingInstanceDeleteRequest, ISharingInstanceFilterParams, ISharingInstanceShort, ISharingInstancesRequestParams,
  ISharingInstanceUpdateRequest
} from '../entities/sharing-instance';
import { ApiVersion } from "./api-service";
import { DataService } from "./data-service";

class SharingInstanceService extends DataService {

  constructor() {
    super("sharing/instance");
  }


  getPage(requestParams: ISharingInstancesRequestParams): Promise<IPage<ISharingInstanceShort>> {
    return this.api.get<IPage<ISharingInstanceShort>>(null, requestParams, ApiVersion.V1_1);
  }

  get(id: number, returnUsedSpaceBytes?: boolean): Promise<ISharingInstance> {
    return this.api.get<ISharingInstance>(id, { returnUsedSpaceBytes });
  }

  getFileStats(id: number) {
    return this.api.get<IFileStats>(`${id}/fileStats`);
  }

  update(id: number, updateRequest: ISharingInstanceUpdateRequest): Promise<ISharingInstance> {
    return this.api.putConditionally<ISharingInstance>(id, updateRequest);
  }

  delete(deleteRequest: ISharingInstanceDeleteRequest): Promise<void> {
    return this.api.post("delete", deleteRequest);
  }

  getExportUrl(filterParams?: ISharingInstanceFilterParams): string {
    return this.api.getFullUrl("csv", filterParams);
  }
}

export const sharingInstanceService = new SharingInstanceService();
