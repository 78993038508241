import { IAppState } from "../../../../../../redux/reducers";
import { enterpriseAccountSetupActions } from "../../../../../../redux/actions/enterpriseAccountSetup";
import { commonActions } from "../../../../../../redux/actions/common";
import { EnterpriseAccountSetupStep } from "../../index";
import { connect } from "react-redux";
import PageTitle from "../../../../../shared-ui/pageTitle";
import React, { useState } from "react";
import styles from "../../../../../storage-pools/sections/pool-name-section/pool-name-section.module.scss";
import FormSection from "../../../../../shared-ui/form/form-section/form-section";
import { FormPanel } from "../../../../../shared-ui/form/form-panel/form-panel";
import { BucketTable } from "../../../../buckets/bucket-table/bucket-table";
import { IBucketListItem } from "../../../../../../api/entities/bucket";
import { bucketService } from "../../../../../../api/services/bucket-service";
import { TableSelected } from "../../../../../shared-ui/table/table";
import { TableContainer } from "./table-container";
import { ApplicationTable } from "../../../../applications/application-table/application-table";
import { applicationCredentialsService, applicationService } from "../../../../../../api/services/application-service";
import { IApplicationCredentialsListItem, IApplicationListItem } from "../../../../../../api/entities/application";
import { ApplicationCredentialsTable } from "../../../../application-credentials/application-credentials-table/application-credentials-table";
import { StoragePoolsV2Table } from "../../../../../storage-pools/storage-pools-table/storage-pools-v2-table";
import { storagePoolServiceFactory } from "../../../../../../utils/factories/storage-pool-service-factory";
import { StoragePoolV2Service } from "../../../../../../api/services/storage-pool-v2-service";
import { IStoragePoolV2ListItem } from "../../../../../../api/entities/storage-pool-v2";
import { StorageNodeTable } from "../../../../storage-nodes/storage-node-table/storage-node-table";
import { storageNodeServiceFactory } from "../../../../../../utils/factories/storage-node-service-factory";
import { StorageNodeV2Service } from "../../../../../../api/services/storage-node-v2-service";
import { IStorageNodeV2 } from "../../../../../../api/entities/storage-node-v2";

const mapStateToProps = (state: IAppState) => ({
    enterpriseAccountSetupSteps: state.enterpriseAccountSetup.enterpriseAccountSetupStep,
    user: state.users.currentUser
});
const mapDispatchToProps = dispatch => {
    return {
        setStep: (step: EnterpriseAccountSetupStep) => {
            dispatch(enterpriseAccountSetupActions.setStep(step));
        },
        startLoading: () => {
            dispatch(commonActions.startLoading());
        },
        stopLoading: () => {
            dispatch(commonActions.stopLoading());
        }
    };
};

// interface IOrganizationForm {
//     name: IFormField<string>;
// }

interface IOrganizationProps extends ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {
}

function DataMigrationWizardStep(props: IOrganizationProps) {

    const [bucketSelection, setBucketSelection] = useState<TableSelected<IBucketListItem>>({});
    const [applicationSelection, setApplicationSelection] = useState<TableSelected<IApplicationListItem>>({});
    const [applicationCredentialsSelection, setApplicationCredentialsSelection] = useState<TableSelected<IApplicationCredentialsListItem>>({});
    const [storagePoolSelection, setStoragePoolSelection] = useState<TableSelected<IStoragePoolV2ListItem>>({});
    const [storageNodeSelection, setStorageNodeSelection] = useState<TableSelected<IStorageNodeV2>>({});

    function goToPreviousStep() {
        props.setStep(EnterpriseAccountSetupStep.UserInvitation);
    }

    // TODO hide moreoptions
    function save() {
        console.log('selection', bucketSelection);
        // formUtils.validateAll(form).then((isValid) => {
        // if (isValid) {
//                api(accountService.changeAccountType({
//                    accountType: AccountType.Enterprise,
//                    email: props.user.email,
//                    organizationName: form.name.value
//                })).then(() => props.setStep(EnterpriseAccountSetupStep.UserInvitation));
        // }
        // });
    }

    return (
        <>
            <PageTitle title="Specify Entities for Migration To Your Enterprise Account"/>
            <FormPanel
                className={styles.root}
                okButtonText="Finish"
                okButtonClick={save}
                okButtonDisabled={false}
                cancelButtonText={"Previous"}
                cancelButtonClick={goToPreviousStep}
            >
                <FormSection testId="organization-name-section" title="Buckets" className={styles.section}>
                    <TableContainer
                        tableElement={BucketTable}
                        service={bucketService}
                        selection={bucketSelection}
                        onSelectionChanged={setBucketSelection}
                    />
                </FormSection>
                <FormSection testId="organization-name-section" title="Applications" className={styles.section}>
                    <TableContainer
                        tableElement={ApplicationTable}
                        service={applicationService}
                        selection={applicationSelection}
                        onSelectionChanged={setApplicationSelection}
                    />
                </FormSection>
                <FormSection testId="organization-name-section" title="Access Credentials" className={styles.section}>
                    <TableContainer
                        tableElement={ApplicationCredentialsTable}
                        service={applicationCredentialsService}
                        selection={applicationCredentialsSelection}
                        onSelectionChanged={setApplicationCredentialsSelection}
                    />
                </FormSection>
                <FormSection testId="organization-name-section" title="Storage Pools" className={styles.section}>
                    <TableContainer
                        tableElement={StoragePoolsV2Table}
                        service={storagePoolServiceFactory.getStoragePoolService(2) as StoragePoolV2Service}
                        selection={storagePoolSelection}
                        onSelectionChanged={setStoragePoolSelection}
                    />
                </FormSection>
                <FormSection testId="organization-name-section" title="Storage Nodes" className={styles.section}>
                    <TableContainer
                        tableElement={StorageNodeTable}
                        service={storageNodeServiceFactory.getStorageNodeService(2) as StorageNodeV2Service}
                        selection={storageNodeSelection}
                        onSelectionChanged={setStorageNodeSelection}
                    />
                </FormSection>
            </FormPanel>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DataMigrationWizardStep);
