import { useState } from "react";
import _ from "underscore";

type SyncValidator<TValue> = (value: TValue) => string;
type AsyncValidator<TValue> = { asyncValidator: (value: TValue) => Promise<string> };

interface IFieldState<TValue> {
  value: TValue;
  isTouched: boolean;
  isChanged: boolean;
  error: string;
  controlError: string;
  isValid?: boolean;
  isValidationInProgress: boolean;
  isVisible: boolean;
  isDisabled: boolean;
}

const fieldDefaultState: IFieldState<any> = {
  value: null,
  isTouched: false,
  isChanged: false,
  isValidationInProgress: false, 
  error: null,
  controlError: null,
  isVisible: true,
  isDisabled: false
};

export interface IFormField<TValue> extends IFieldState<TValue> {
  init: (value: TValue) => void;
  validate(value: TValue): Promise<boolean>;
  onChange: (value: TValue) => void;
  onBlur: (value: TValue) => void;
  onError: (error: string) => void;
  setIsVisible: (isVisible: boolean) => void;
  setIsDisabled: (isDisabled: boolean) => void;
}

export function useField<TValue = string>(
  value: TValue, 
  syncValidators?: SyncValidator<TValue>[], 
  asyncValidators?: AsyncValidator<TValue>[],
  validateAsyncOnBlur?: boolean): IFormField<TValue> {

  const [fieldState, setFieldState] = useState<IFieldState<TValue>>({...fieldDefaultState, value});

  function init(value: TValue, isVisible?: boolean, isDisabled?: boolean): void {
    setFieldState({
      ...fieldDefaultState,
      value,
      isVisible: _.isUndefined(isVisible) ? fieldState.isVisible : isVisible,
      isDisabled: _.isUndefined(isDisabled) ? fieldState.isDisabled : isDisabled
    });
  }

  function setIsVisible(isVisible: boolean): void {
    setFieldState((fieldState) => ({...fieldState, isVisible}));
  }

  function setIsDisabled(isDisabled: boolean): void {
    setFieldState((fieldState) => ({...fieldState, isDisabled}));
  }

  function onChange(value: TValue): void {
    if (fieldState.value !== value) {
      setFieldState((fieldState) => ({...fieldState, value, isChanged: true}));
    }
    validate(value, validateAsyncOnBlur);
  }

  function onBlur(value: TValue): void {
    validate(value);
  }

  function onError(controlError: string): void {
    setFieldState((fieldState) => ({...fieldState, isTouched: true, isValid: !controlError && !fieldState.error, controlError}));
  }

  function validate(value: TValue, ignoreAsync?: boolean): Promise<boolean> {
    setFieldState((fieldState) => ({...fieldState, isTouched: true }));
    if (syncValidators) {
      let error: string;
      for (let vindex = 0; vindex < syncValidators.length; vindex++) {
        error = syncValidators[vindex](value);
        if (error) {
          break;
        }
      }
      if (error) {
        setFieldState((fieldState) => ({...fieldState, isValid: false, error}));
        return Promise.resolve(false);
      }
    }

    if (asyncValidators && !ignoreAsync) {
      setFieldState((fieldState) => ({...fieldState, isValidationInProgress: true}));
      return new Promise((resolve, reject) => {
        Promise.all(asyncValidators.map(validator => validator.asyncValidator(value))).then(errors => {
          const error = errors.find(err => !!err);
          if (error) {
            setFieldState((fieldState) => ({...fieldState, isValid: false, error }));
            resolve(false);
          }
          else {
            setFieldState((fieldState) => ({...fieldState, isValid: true, error: null }));
            resolve(true);
          }
        }).catch(reject).finally(() => setFieldState((fieldState) => ({...fieldState, isValidationInProgress: false})));
      });
    }
    else {
      setFieldState((fieldState) => ({...fieldState, isValid: true, error: null}));
      return Promise.resolve(true);
    }
  }

  return {
    init,
    onChange,
    onBlur,
    onError,
    validate,
    setIsVisible,
    setIsDisabled,
    ...fieldState
  };
}

export class FormUtils {
  trimStringValues<T>(object: T): T {
    Object.keys(object).forEach(key => {
      if (_.isString(object[key])) {
        object[key] = object[key].trim();
      }
    });
    return object;
  }

  validateAll<TForm extends {[key in keyof TForm]: IFormField<any>}>(form: TForm): Promise<boolean> {
    return Promise.all(
        Object.values<IFormField<any>>(form).filter((field) => field.isVisible && !field.isDisabled).map((field) => field.validate(field.value)))
      .then(results => !results.some(result => !result));
  }

  isChanged<TFormValue>(form: {[key in keyof TFormValue]: IFormField<any>}): boolean {
    return Object.values<IFormField<any>>(form).some((field) => field.isVisible && field.isChanged);
  }

  getFormValue<TFormValue>(form: {[key in keyof TFormValue]: IFormField<any>}, includeHiddenFields?: boolean): TFormValue {
    const formValue = {} as TFormValue;
    Object.keys(form).filter((key) => includeHiddenFields || form[key].isVisible).forEach((key) => {
      formValue[key] = form[key].value    
    });

    return this.trimStringValues<TFormValue>(formValue);
  }

  setFormValue<TFormValue, TForm = any>(form: Record<string, IFormField<any>> | TForm, formValue: TFormValue): void {
    Object.keys(formValue).forEach((key) => {
      if (form[key]) {
        (form[key] as IFormField<any>).init(formValue[key]);
      }
    });
  }

  setIsDisabled<TFormValue>(form: {[key in keyof TFormValue]: IFormField<any>}, isDisabled: boolean) {
    Object.values<IFormField<any>>(form).forEach(field => field.setIsDisabled(isDisabled));
  }

  getFormError<TFormValue>(form: {[key in keyof TFormValue]: IFormField<any>}): string {
    return Object.values<IFormField<any>>(form).filter((field) => field.isVisible).find(field => !!field.error)?.error;
  }

  isValid<TFormValue>(form: {[key in keyof TFormValue]: IFormField<any>}): boolean {
    return !Object.values<IFormField<any>>(form).some(field => field.isVisible && field.isValid !== true);
  }
}

export const formUtils = new FormUtils();

export enum FormMode {
  Create = 1,
  Edit = 2,
  ReadOnly = 3
}
