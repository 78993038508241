const secondInMs = 1000;
const minuteInMs = secondInMs * 60;
const hourInMs = minuteInMs * 60;
const dayInMs = hourInMs * 24;
const yearInMs = dayInMs * 365.25;

export enum DateTimePeriod {
    Millisecond,
    Second,
    Minute,
    Hour,
    Day,
    Year,
}

class DateTimeHelper {
    public add(date: Date, periodValue: number, periodType: DateTimePeriod): Date {
        let periodMs = this.getPeriodMs(periodType);
        return new Date(date.getTime() + periodValue * periodMs);
    }

    private getPeriodMs(period: DateTimePeriod) {
        let periodMs = 1;
        switch (period) {
            case DateTimePeriod.Second:
                periodMs = secondInMs;
                break;
            case DateTimePeriod.Minute:
                periodMs = minuteInMs;
                break;
            case DateTimePeriod.Hour:
                periodMs = hourInMs;
                break;
            case DateTimePeriod.Day:
                periodMs = dayInMs;
                break;
            case DateTimePeriod.Year:
                periodMs = yearInMs;
                break;
        }
        return periodMs;
    }
}

export const dateTimeHelper = new DateTimeHelper();
