import React from "react";
import { fileUtils } from "../../../../../../utils/file-utils";
import styles from "./file-icon.module.scss";
import ImageIcon from '@mui/icons-material/Image';
import clsx from "clsx";

export interface IFileIconProps {
  fileName: string;
}

export function FileIcon(props: IFileIconProps) {
  let ext = fileUtils.getFileExtension(props.fileName).toLowerCase();

  function getShortExtention(extention: string) {
    if (extention === "xlsx") return "xls";
    if (extention === "docx") return "doc";
    if (extention === "pptx") return "ppt";
    return ext.substring(0, 3);
  }

  if (fileUtils.isImage(ext)) {
    return (<ImageIcon classes={{ root: styles["image-icon"]}}/>);
  }

  const shortExt = getShortExtention(ext);
  return (
    <div className={clsx(styles["file-icon"], styles[`file-icon--${shortExt}`])}>
      {shortExt}
    </div>
  );
}