import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { StoragePoolRouteAction, StoragePoolRouteParam } from "../../../constants/routes";
import { catchException, commonActions } from "../../../redux/actions/common";
import { storagePoolV1ThunkActions, storagePoolV2ThunkActions } from "../../../redux/actions/storage-pool";
import { IAppState } from "../../../redux/reducers";
import { storageNodeServiceFactory } from "../../../utils/factories/storage-node-service-factory";
import { useNavigate } from "../../../utils/hooks/use-navigate";
import { routeUtils } from "../../../utils/route-utils";
import { StorageNodePanel, StorageNodePanelMode } from "../../v2/common/panels/storage-node-panel/storage-node-panel";
import { IStoragePoolNode } from "../sections/storage-nodes-section/storage-nodes-section";
import { VersionContext } from "../storage-pools";

function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, void, Action>) {
  return {
    fetchStoragePoolsV1: () => dispatch(storagePoolV1ThunkActions.entity.fetchPage()),
    fetchStoragePoolsV2: () => dispatch(storagePoolV2ThunkActions.entity.fetchPage()),
    startLoading: () => dispatch(commonActions.startLoading()),
    stopLoading: () => dispatch(commonActions.stopLoading()),
  };
}

export interface IStorageNodePanelContainerProps extends 
  ReturnType<typeof mapDispatchToProps> {
}


export const StorageNodePanelContainer = connect(null, mapDispatchToProps)((props: IStorageNodePanelContainerProps) => {
  const navigate = useNavigate();
  const { idOrAction, nodeIdOrAction } = useParams<{[StoragePoolRouteParam.IdOrAction]: string, [StoragePoolRouteParam.NodeIdOrAction]: string}>();
  const [poolNode, setPoolNode] = useState<IStoragePoolNode>(null);

  const version = useContext(VersionContext);

  function close(): void {
    navigate(routeUtils.getRouteToStoragePoolList(version));
  }

  const storageNodeService: any = storageNodeServiceFactory.getStorageNodeService(version);

  useEffect(() => {
    if (idOrAction === StoragePoolRouteAction.ManageNode && Number(nodeIdOrAction) > 0) {
      props.startLoading();
      storageNodeService.get(Number(nodeIdOrAction)).then((storageNode) => {
        setPoolNode({
          node: storageNode,
          types: {
            storeContent: !!storageNode.ContentStoragePool.length,
            storeKey: !!storageNode.KeyStoragePool.length,
            storeMetadata: !!storageNode.MetaStoragePool.length,
          }
        });
      }).catch(catchException()).finally(props.stopLoading);
    } else {
      setPoolNode(null);
    }
    // eslint-disable-next-line
  }, [idOrAction, nodeIdOrAction]);

  function handleStorageNodeSaved() {
    close();
    if (version === 1) {
      props.fetchStoragePoolsV1();
    }
    else {
      props.fetchStoragePoolsV2();
    }
  }

  return (
    <StorageNodePanel
      mode={StorageNodePanelMode.LimitedEdit}
      storagePoolNode={poolNode}
      onClose={close}
      onNodeSaved={handleStorageNodeSaved}
      version={version}
    />
  );
});
