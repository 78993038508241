import { IPage } from '../entities/page';
import { IStoragePoolCreateRequest, IStoragePoolUpdateRequest } from "../entities/storage-pool";
import { IStoragePoolsV2RequestParams, IStoragePoolV2DetailsItem, IStoragePoolV2ListItem } from "../entities/storage-pool-v2";
import { DataService } from "./data-service";

export class StoragePoolV2Service extends DataService {
    constructor() {
        super("storage/pool", 2);
    }

    get(storagePoolId: string): Promise<IStoragePoolV2DetailsItem> {
        return this.api.get<IStoragePoolV2DetailsItem>(storagePoolId);
    }

    getPage(requestParams: IStoragePoolsV2RequestParams): Promise<IPage<IStoragePoolV2ListItem>> {
        return this.api.get<IPage<IStoragePoolV2ListItem>>(null, requestParams);
    }

    addStoragePool(addRequest: IStoragePoolCreateRequest<string>): Promise<IStoragePoolV2ListItem> {
        return this.api.post<IStoragePoolV2ListItem>(null, addRequest);
    }

    update(updateRequest: IStoragePoolUpdateRequest): Promise<IStoragePoolV2ListItem> {
        return this.api.put<IStoragePoolV2ListItem>(updateRequest.id, updateRequest);
    }

    delete(storagePoolId: string): Promise<void> {
        return this.api.delete(storagePoolId);
    }
}
