import { catchException, PickActions } from "./common";
import { IPolicyStorageUsage } from "../../api/entities/dashboard";
import { dashboardService } from "../../api/services/dashboard-service";
import { IAppState } from "../reducers";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

export enum DashboardActionType {
  DeleteSuccess = "DEVICES_DELETE_SUCCESS",
  StorageUsageFilterRequest = "DASHBOARD_FILTER_STORAGE_USAGE_REQUEST",
  StorageUsageFilterSuccess = "DASHBOARD_FILTER_STORAGE_USAGE_SUCCESS",
  StorageUsageFilterFail = "DASHBOARD_FILTER_STORAGE_USAGE_FAIL",
  PolicyStorageUsageRequest = "DASHBOARD_POLICY_STORAGE_USAGE_REQUEST",
  PolicyStorageUsageSuccess = "DASHBOARD_POLICY_STORAGE_USAGE_SUCCESS",
  PolicyStorageUsageFail = "DASHBOARD_POLICY_STORAGE_USAGE_FAIL",
}

class DashboardActions {
  policyStorageUsageRequest() {
    return {
      type: DashboardActionType.PolicyStorageUsageRequest as typeof DashboardActionType.PolicyStorageUsageRequest
    }
  }
  policyStorageUsageSuccess(data: IPolicyStorageUsage[]) {
    return {
      type: DashboardActionType.PolicyStorageUsageSuccess as typeof DashboardActionType.PolicyStorageUsageSuccess,
      data
    }
  }
  policyStorageUsageFail() {
    return {
      type: DashboardActionType.PolicyStorageUsageFail as typeof DashboardActionType.PolicyStorageUsageFail,
    }
  }
}

export const dashboardActions = new DashboardActions();

export type DashboardAction = PickActions<DashboardActions>;

export function fetchPolicyStorageUsage(): ThunkAction<void, IAppState, null, Action> {
  return (dispatch, getState) => {
    if (getState().dashboard.policyStorageUsage.loading) {
      return;
    }
    dispatch(dashboardActions.policyStorageUsageRequest());
    dashboardService.userStorageStatus().then(data => {
      dispatch(dashboardActions.policyStorageUsageSuccess(data));
    }).catch(error => {
      dispatch(dashboardActions.policyStorageUsageFail())
      catchException()(error);
    });
  }
}