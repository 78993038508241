import React from "react";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../../theme/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    padding: "14px 27px 0 34px",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    display: "flex",
    flex: 1,
    marginTop: 20,
  },
  closeIcon: {
    fontSize: 30,
    cursor: "pointer",
    color: colors.geminiGray,
  },
  closeIconContainer: {},
});

export interface IDialogTitleProps {
  children: string;
  onClose: () => void;
}

export function DialogTitle(props: IDialogTitleProps) {
  const classes = useStyles();
  return (
    <MuiDialogTitle classes={{ root: classes.root }}>
      <h3
        className={classes.title}
        data-testid="alert-title-text"
      >
        {props.children}
      </h3>
      <div
        className={classes.closeIconContainer}
        data-testid="alert-close"
      >
        <CloseIcon
          classes={{ root: classes.closeIcon }}
          onClick={props.onClose}
        />
      </div>
    </MuiDialogTitle>
  );
}
