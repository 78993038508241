import React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IApplicationListItem } from "../../../../api/entities/application";
import { applicationActions } from "../../../../redux/actions/application";
import { IAppState } from "../../../../redux/reducers";
import { useNavigate } from "../../../../utils/hooks/use-navigate";
import { routeUtils } from "../../../../utils/route-utils";
import MoreOptions from "../../../shared-ui/more-options";
import { IPopupMenuOption } from "../../../shared-ui/popup-menu";

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    openDeleteConfirmation: (application: IApplicationListItem) =>
        dispatch(applicationActions.deleteConfirmation.open(application))
  };
};

export interface IMoreOptionProps
    extends ReturnType<typeof mapDispatchToProps> {
  data: IApplicationListItem;
}

export const MoreOption = connect(null, mapDispatchToProps)((props: IMoreOptionProps) => {
  const navigate = useNavigate();

  function openDeleteConfirmation() {
    props.openDeleteConfirmation(props.data);
  }

  function getOptions(): IPopupMenuOption[] {
    const result = [
      {
        label: "Manage",
        onClick: () => navigate(routeUtils.getRouteToApplicationDetails(props.data.id))
      },
      {
        label: "Delete",
        onClick: openDeleteConfirmation
      },
    ];
    return result;
  }

  return (
    <MoreOptions
      options={getOptions()}
    />
  );
});
