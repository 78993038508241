import { ApiVersionNumber } from "../../api/services/data-service";
import { StorageNodeV1Service } from "../../api/services/storage-node-service";
import { StorageNodeV2Service } from "../../api/services/storage-node-v2-service";

class StorageNodeServiceFactory {
    private storageNodeService = new StorageNodeV1Service();
    private storageNodeV2Service = new StorageNodeV2Service();

    public getStorageNodeService(apiVersion?: ApiVersionNumber): StorageNodeV1Service | StorageNodeV2Service {
        switch (apiVersion) {
            case 1:
                return this.storageNodeService;
            case 2:
                return this.storageNodeV2Service;
            default:
                throw new Error('Incorrect API Version');
        }
    }
}

export const storageNodeServiceFactory = new StorageNodeServiceFactory();
