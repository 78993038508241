import React from "react";
import IComponentProps from "../common/component-props";
import Button from ".";
import AutorenewIcon from "@mui/icons-material/Autorenew";

interface IRefreshButtonProps extends IComponentProps {
  onClick: () => void;
}

export default function RefreshButton(props: IRefreshButtonProps) {
  return (
    <Button
      colors="primary"
      responsive={true}
      onClick={props.onClick}
      testId={props.testId || "refresh-button"}
    >
      <AutorenewIcon />
    </Button>
  );
}
