import React, { useRef } from "react";
import styles from "./checkbox.module.scss";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import IComponentProps from "../../common/component-props";
import clsx from "clsx";
import { IFormField } from "../../../../utils/form-utils";

interface ICheckboxProps extends IComponentProps {
  checked?: boolean | null;
  label?: string;
  field?: IFormField<boolean>;
  testId: string;

  onChange?: (value: boolean) => void;
}

export default function Checkbox(props: ICheckboxProps) {
  const checked = props.field ? props.field.value : props.checked;
  const disabled = props.field ? props.field.isDisabled : props.disabled;
  const onChange = props.field ? props.field.onChange : props.onChange;
  const checkBoxRef = useRef<HTMLInputElement>(null);
  const indeterminate = checked === null;


  if (checkBoxRef.current) {
    checkBoxRef.current.indeterminate = indeterminate;
  }

  const handleChange = () => {
    onChange(indeterminate ? false : !checked);
  };

  return (
    <label
      className={clsx(styles.root, props.className)}
      data-testid={`${props.testId}-root`}
      style={props.style}
      onClick={(e) => { e.stopPropagation(); }}
    >
      <input
        data-testid={props.testId}
        data-checked={checked}
        data-indeterminate={indeterminate}      
        className={styles.input}
        ref={checkBoxRef}
        type="checkbox"
        disabled={disabled}
        checked={checked === true}
        onChange={handleChange}
      />
      <div
        className={clsx(styles.icon, {
          [styles.checked]: checked === true,
          [styles.indeterminate]: indeterminate,
        })}
      >
        {checked ? (
          <CheckIcon />
        ) : indeterminate ? (
          <RemoveIcon />
        ) : null}
      </div>
      {props.label ? <div className={styles.label}>{props.label}</div> : null}
    </label>
  );
}
