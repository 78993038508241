import clsx from "clsx";
import React, { PropsWithChildren, ReactNode } from "react";
import { IStyleableComponentProps, ITestableComponent } from "../../common/component-props";
import { FormFieldError } from "../form-field-error/form-field-error";
import { FormFieldLabel } from "../form-field-label/form-field-label";
import styles from "./form-field.module.scss";
import { IFormField } from "../../../../utils/form-utils";
import { testUtils } from "../../../../utils/test-utils";

export interface IFormFieldProps extends ITestableComponent, IStyleableComponentProps {
  label?: string;
  error?: ReactNode;
  visible?: boolean;
  field?: IFormField<any>;
}

export function FormField(props: PropsWithChildren<IFormFieldProps>) {
  const visible = props.field ? props.field.isVisible : props.visible;
  const error = props.field ? props.field.error : props.error;


  if (visible === false) {
    return null;
  }
  return (
    <div 
      className={clsx(styles.field, props.className, error && styles["field--error"])}
      style={props.style}
      data-testid={props.testId || testUtils.stringToTestId(props.label)}
    >
      {props.label && <FormFieldLabel>{props.label}</FormFieldLabel>}
      {props.children}
      {error && <FormFieldError error={error}/>}
    </div>
  );
}
