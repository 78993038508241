import { DialogActions } from './dialog';
import { ApiErrorCode, ApiException } from './../../api/services/api-service';
import { SHARING_PROFILE_CHANGES_CONFLICT, SHARING_PROFILE_DELETE_CONFLICT } from './../../constants/messages';
import { sharingProfileService } from '../../api/services/sharing-profile-service';
import { ISharingProfile, ISharingProfileDeleteRequest, ISharingProfilesRequestParams, ISharingProfileListItem, IUpdateUsersRequest, IUserSharingInstances, SharingProfileSortField } from './../../api/entities/sharing-profile';
import { catchException, commonActions } from "./common";
import { IAppState } from '../reducers';
import { EntityActions, EntityThunkActions } from './entity';
import { HttpCode } from '../../api/http-code';
import { IDeleteBlockedConfirmationData } from '../reducers/sharing-profile';
import { sharingProfileUtils } from '../../utils/sharing-profile-utils';

export const SHARING_PROFILE_ENTITY_NAME = "sharingProfile";
export const DELETE_CONFIRMATION_DIALOG_NAME = "deleteConfirmation";
export const DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME = "deleteBlockedConfirmation";
export const FOLDER_EXISTS_CONFIRMATION_DIALOG_NAME = "folderExistsConfirmation";
export const LAST_MANAGER_INFO_DIALOG_NAME = "lastManagerInfo";

class SharingProfileActions {
  readonly entity = new EntityActions<ISharingProfileListItem, ISharingProfilesRequestParams>(SHARING_PROFILE_ENTITY_NAME);
  readonly deleteConfirmation = new DialogActions<ISharingProfileListItem[]>(SHARING_PROFILE_ENTITY_NAME, DELETE_CONFIRMATION_DIALOG_NAME);
  readonly deleteBlockedConfirmation = new DialogActions<IDeleteBlockedConfirmationData>(SHARING_PROFILE_ENTITY_NAME, DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME);
  readonly folderExistsConfirmation = new DialogActions<ISharingProfile>(SHARING_PROFILE_ENTITY_NAME, FOLDER_EXISTS_CONFIRMATION_DIALOG_NAME);
  readonly lastManagerInfo = new DialogActions<IUserSharingInstances[]>(SHARING_PROFILE_ENTITY_NAME, LAST_MANAGER_INFO_DIALOG_NAME);
}

export const sharingProfileActions = new SharingProfileActions();

class SharingProfileThunkActions {
  readonly entity = new EntityThunkActions<ISharingProfileListItem, SharingProfileSortField, ISharingProfilesRequestParams>(
    SHARING_PROFILE_ENTITY_NAME, sharingProfileService, (sharingProfile) => sharingProfileUtils.canSelectSharingProfile(sharingProfile))

  delete(deleteRequest: ISharingProfileDeleteRequest) {
    return (dispatch, getState: () => IAppState) => {
      dispatch(commonActions.startLoading());
      return sharingProfileService
        .delete(deleteRequest)
        .then(_ => {
          dispatch(
            commonActions.showSnackBar(
              true,
              `Sharing profile${deleteRequest.ids.length > 1 ? "s": ""} successfully deleted.`
            )
          );
          const selection = getState().sharingProfile.selection;
          deleteRequest.ids.forEach(idInfo => {
            delete selection[idInfo.id];
          })
          dispatch(sharingProfileActions.entity.setSelection(selection));
          dispatch(sharingProfileThunkActions.entity.fetchPage());
        })
        .catch(catchException(() => dispatch(commonActions.openChangesConflictInfo(SHARING_PROFILE_DELETE_CONFLICT))))
        .finally(() => {
          dispatch(commonActions.stopLoading())
        });
    };
  }

  save(sharingProfile: ISharingProfile, ignoreFolderName?: boolean) {
    return (dispatch) => {
      return sharingProfileService
        .save(sharingProfile, ignoreFolderName)
        .catch((error: ApiException | any) => {
          if (error instanceof ApiException && error.data && error.data.subCode === ApiErrorCode.FolderAlreadyExists) {
            dispatch(sharingProfileActions.folderExistsConfirmation.open(sharingProfile));
          }
          else {
            throw error;
          }
        })
        .catch(catchException(() => dispatch(commonActions.openChangesConflictInfo(SHARING_PROFILE_CHANGES_CONFLICT))));
    };
  }

  updateUsers(sharingProfileId: number, updateUsersRequest: IUpdateUsersRequest) {
    return (dispatch) => {
      return sharingProfileService
        .updateUsers(sharingProfileId, updateUsersRequest)
        .catch((exception: ApiException) => {
          if (exception instanceof ApiException && exception.status === HttpCode.UNPROCESSABLE_ENTITY_ERROR) {
            dispatch(sharingProfileActions.lastManagerInfo.open(exception.data));
          }
          else {
            throw exception;
          }
        })
        .catch(catchException(() => dispatch(commonActions.openChangesConflictInfo(SHARING_PROFILE_CHANGES_CONFLICT))));
    };
  }
}
export const sharingProfileThunkActions = new SharingProfileThunkActions();

