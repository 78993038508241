import React from "react";
import { IHasOwner } from "../../../../../api/entities/id-name";
import { IColumnDefinition } from "../../../../shared-ui/table/table";

export function getOwnerColumn<TRowData extends IHasOwner, TSort extends string | null>(
    sortKey: TSort,
    className?: string): IColumnDefinition<TRowData, TSort> {
    return {
        sortKey,
        label: "Created By",
        className,
        formatter: row => !!row.owner
            ? (
                <div>
                    {row.owner.email}
                    {!row.owner.isActivated && <span>&nbsp;(deactivated)</span>}
                </div>
            )
            : <></>
    };
}