import React, { PropsWithChildren, useState, Children } from "react";
import styles from "./filters-bar.module.scss";
import SearchInput from "../input/search-input/search-input";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../button";
import { FiltersBarItem } from "./filters-bar-item";
import { SidePanel, SidePanelPosition } from "../side-panel/side-panel";
import {
  ITestableComponent,
  IStyleableComponentProps,
  CompactViewScreen,
} from "../common/component-props";
import clsx from "clsx";
import ExportButton from "../button/export-button/export-button";

export interface IFiltersBarProps
  extends ITestableComponent,
    IStyleableComponentProps {
  searchValue?: string;
  classes?: {
    search?: string;
    searchBarItem?: string;
  };
  onSearchValueChanged?: (value: string) => void;
  exportUrl?: string;
  compactViewScreen?: CompactViewScreen;
}

export function FiltersBar(props: PropsWithChildren<IFiltersBarProps>) {
  const [open, setOpen] = useState(false);
  const compactViewScreen = props.compactViewScreen || CompactViewScreen.Small;

  return (
    <div
      className={clsx(
        styles.root, 
        props.className,
        compactViewScreen === CompactViewScreen.Small && styles["root--compact-on-small"],
        compactViewScreen === CompactViewScreen.Medium && styles["root--compact-on-medium"]
      )}
      style={props.style}
      data-testid={props.testId}
    >
      {!!props.onSearchValueChanged && (
        <FiltersBarItem className={props.classes?.searchBarItem}>
          <SearchInput
            testId="text-filter"
            value={props.searchValue}
            className={props.classes?.search}
            onChange={props.onSearchValueChanged}
          />
        </FiltersBarItem>
      )}
      <SidePanel
        side={SidePanelPosition.Top}
        open={open}
        className={styles.panel}
        rootClass={styles["side-panel"]}
        testId="filters-top-panel"
      >
        <Button
          testId="close-filters-button"
          className={styles["close-button"]}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </Button>
        <div className={styles.title}>Filters</div>
        <div className={styles.content}>
          {props.children}
          {!!props.exportUrl && (
            <FiltersBarItem className={styles["main-export-button-item"]}>
              <ExportButton 
                className={styles["main-export-button"]}
                url={props.exportUrl} 
                compactViewScreen={compactViewScreen}/>
            </FiltersBarItem>
          )}
        </div>
        <div className={styles.footer}>
          {!!props.exportUrl && (
              <ExportButton
                  className={styles["footer-export-button"]}
                  url={props.exportUrl}
              />
          )}
          <Button testId="close-filters-button" colors="primary" onClick={() => setOpen(false)}>
            Done
          </Button>
        </div>
      </SidePanel>
      {!!Children.count(props.children) && (
        <FiltersBarItem className={styles["open-button"]}>
          <Button testId="open-filters-button" onClick={() => setOpen(true)}>
            <TuneIcon />
          </Button>
        </FiltersBarItem>
      )}
    </div>
  );
}
