import { IDevicePerformance, IDevicePerformanceSearchParams } from './../entities/device-performance';
import { DataService } from "./data-service";

class DevicePerformanceService extends DataService {
  constructor() {
    super("device/performance");
  }

  getList(searchParams: IDevicePerformanceSearchParams): Promise<IDevicePerformance[]> {
    return this.api.get<IDevicePerformance[]>(null, searchParams);
  }
}

export const devicePerformanceService = new DevicePerformanceService();
