import React, { useEffect, useState } from "react";
import { IPage } from "../../../../api/entities/page";
import { getDefaultRequestParams, getEmptyPage } from "../../../../redux/reducers/entity";
import { useApi } from "../../../../utils/hooks/use-api";
import { useFilters } from "../../../../utils/hooks/use-filter";
import { useNavigate } from "../../../../utils/hooks/use-navigate";
import Button from "../../../shared-ui/button";
import RefreshButton from "../../../shared-ui/button/refresh-button";
import { FiltersData } from "../../../shared-ui/filters-data/filters-data/filters-data";
import PageTitle from "../../../shared-ui/pageTitle";
import styles from "./invitation.module.scss";
import { defaultFilters, filterNames, IInvitationFilters, InvitationFilters, invitationFilterTypeMap } from "./filters/filters";
import { IAppState } from "../../../../redux/reducers";
import { IInvitation, IInvitationRequestParams, InviteEmailRequest } from "../../../../api/entities/invitation";
import { invitationService } from "../../../../api/services/invitation-service";
import { InvitationTable } from "./invitation-table/invitation-table";
import { AccountType } from "../../../../api/entities/user";
import InvitePanel from "./panels/invite-user-panel/invite-panel";
import { FormMode } from "../../../../utils/form-utils";
import { connect } from "react-redux";


const mapStateToProps = (state: IAppState) => ({
    user: state.users.currentUser,
});

interface IInvitationProps
    extends ReturnType<typeof mapStateToProps> {

    embedded?: boolean;
    title?: string;
}

function Invitation(props: IInvitationProps) {
    const navigate = useNavigate();
    const [invitationApi, isInvitationLoading] = useApi();
    const [invitationPage, setInvitationPage] = useState<IPage<IInvitation>>(getEmptyPage());
    const [requestParams, setRequestParams] = useState<IInvitationRequestParams>({ ...getDefaultRequestParams(), deleted: false });
    const [invitationPanelFormMode, setInvitePanelFormMode] = useState<FormMode>(null);

    const [filters, , updateQuery] = useFilters(
        defaultFilters,
        requestData,
        invitationFilterTypeMap,
        filtersToQueryParams,
        queryParamsToFilters
    );

    function filtersToQueryParams(filters: IInvitationFilters): IInvitationRequestParams {
        return {
            q: filters.searchText,
            status: filters.statusFilter?.value
        };
    }

    function queryParamsToFilters(queryParams: Partial<IInvitationRequestParams>): IInvitationFilters {
        return {
            searchText: queryParams.q,
            statusFilter: queryParams.status ?
                { value: queryParams.status }
                : null
        };
    }

    function requestData(filters: IInvitationFilters) {
        fetchData({
            ...filtersToQueryParams(filters),
            offset: 0,
        });
    }

    function fetchData(requestParamsUpdate: Partial<IInvitationRequestParams>) {
        const updatedRequestParams = { ...requestParams, ...requestParamsUpdate };
        setRequestParams(updatedRequestParams);
        invitationApi(invitationService.getPage(updatedRequestParams)).then(setInvitationPage);
    }

    function handleInviteUsers(request: InviteEmailRequest[]) {
        return invitationApi(invitationService.inviteUsers(request)).then(() => {
            fetchData({});
        });
    }

    function getInviteNewUsersButton() {
        return (
            <Button
                testId="invite-new-users-button"
                colors="primary"
                onClick={() => setInvitePanelFormMode(FormMode.Create)}
            >
                Invite Users
            </Button>
        );
    }

    // TODO filter data blocks, invitations page
//    useEffect(() => {
//        if (!!filters.statusFilter && !filters.statusFilter.label) {
//            let statusFilter = { value: filters.statusFilter.value, label: invitationStatusDict[filters.statusFilter.value] };
//            setFilters({ ...filters, statusFilter });
//        }
//        // eslint-disable-next-line
//    }, [filters.statusFilter]);

    useEffect(() => {
        if (!props.embedded && props.user.accountType !== AccountType.Individual && !props.user.isAdmin) {
            navigate('/');
        }
        fetchData({});
        // eslint-disable-next-line
    }, []);


    return (
        <>
            {props.embedded &&
                <div className={styles.header}>
                    <PageTitle title={props.title ?? 'Invitations'}>
                        <RefreshButton onClick={() => fetchData(requestParams)}/>
                    </PageTitle>

                    {getInviteNewUsersButton()}
                </div>
            }

            <InvitationFilters
                filters={filters}
                onFiltersChange={updateQuery}
            >
                {!props.embedded
                    ? getInviteNewUsersButton()
                    : <></>
                }
            </InvitationFilters>
            <FiltersData
                filterNames={filterNames}
                defaultFilters={defaultFilters}
                filters={filters}
                onFiltersChange={updateQuery}
            />

            <InvitationTable
                isLoading={isInvitationLoading}
                invitationPage={invitationPage}
                requestParams={requestParams}
                onRequestParamsChanged={fetchData}
            />

            <InvitePanel
                fetchInvitations={() => fetchData({})}
                handleInviteUsers={handleInviteUsers}
                onClose={() => setInvitePanelFormMode(null)}
                mode={invitationPanelFormMode}
            />
        </>
    );
}

export default connect(mapStateToProps, null)(Invitation);