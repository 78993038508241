import React, { useEffect } from "react";
import { enumUtils } from "../../../../../../utils/enum-utils";
import { formUtils, IFormField, useField } from "../../../../../../utils/form-utils";
import DetailsPanelFooter from "../../../../../shared-ui/details-panel-footer/details-panel-footer";
import DetailsPanel from "../../../../../shared-ui/details-panel/details-panel";
import { FormFieldValue } from "../../../../../shared-ui/form/form-field-value/form-field-value";
import { FormField } from "../../../../../shared-ui/form/form-field/form-field";
import Checkbox from "../../../../../shared-ui/input/checkbox";
import { Select } from "../../../../../shared-ui/select";
import SidePanelSection from "../../../../../shared-ui/side-panel-section/side-panel-section";
import { AccessRightType, accessRightTypeToTextMap, ExpirationDays, expirationDaysToTextMap, IShareAccessRight, MaxDownloads, maxDownloadsToTextMap } from "../../api/entities/shares";
import styles from "./email-settings-panel.module.scss";

export interface IEmailSettingsPanelProps {
  open: boolean;
  emailSettings: IEmailSettings;
  onSave: (emailSettings: IEmailSettings) => void;
  onClose: () => void;
}

export interface IEmailSettings {
  CcSender: boolean;
  NotifyOnDownload: boolean;
  RequireLogin: boolean;
  ShareAccessRight: IShareAccessRight;
  ExpirationDays: ExpirationDays;
  MaxDownloads: number;
}

export interface IEmailSettingsForm {
  CcSender: IFormField<boolean>;
  NotifyOnDownload: IFormField<boolean>;
  RequireLogin: IFormField<boolean>;
  ShareAccessRight: IFormField<IShareAccessRight>;
  ExpirationDays: IFormField<ExpirationDays>;
  MaxDownloads: IFormField<number>;
}


export function EmailSettingsPanel(props: IEmailSettingsPanelProps) {
  const form: IEmailSettingsForm = {
    ExpirationDays: useField<ExpirationDays>(props.emailSettings.ExpirationDays),
    MaxDownloads: useField<number>(props.emailSettings.MaxDownloads),
    CcSender: useField<boolean>(props.emailSettings.CcSender),
    NotifyOnDownload: useField<boolean>(props.emailSettings.NotifyOnDownload),
    RequireLogin: useField<boolean>(props.emailSettings.RequireLogin),
    ShareAccessRight: useField<IShareAccessRight>(props.emailSettings.ShareAccessRight)
  };

  function save() {
    props.onSave(formUtils.getFormValue<IEmailSettings>(form));
  }

  useEffect(() => {
    if (props.open) {
      formUtils.setFormValue<IEmailSettings, IEmailSettingsForm>(form, props.emailSettings);
    }
  // eslint-disable-next-line
  }, [props.open]);

  return (
    <DetailsPanel
      testId="message-options"
      open={props.open}
      onCloseClick={props.onClose}
      title={"Message Options"}
    >
      <SidePanelSection className={styles.notifications} testId="notifications" title="Notifications">
        <FormField>
          <Checkbox
            testId="send-copy"
            field={form.CcSender}
            label="Send me a copy of this email"
          />
        </FormField>

        <FormField>
          <Checkbox
            testId="notify-access"
            field={form.NotifyOnDownload}
            label="Email me when files are accessed"
          />
        </FormField>
      </SidePanelSection>

      <SidePanelSection 
        contentClass={styles["access-options-content"]} 
        testId="access-options" 
        title="Access Options"
      >
        <FormField>
          <Checkbox
            testId="require-login"
            field={form.RequireLogin}
            label="Require recipients to log in"
          />
        </FormField>

        <FormField label="Allow recipients to:">
          <FormFieldValue>
            <Select
              testId="access-type"
              value={form.ShareAccessRight.value.AccessRightType}
              options={Object.values(AccessRightType).map((accessRightType) => ({
                value: accessRightType,
                label: accessRightTypeToTextMap[accessRightType]
              }))}
              onChange={(accessRightType: AccessRightType) => form.ShareAccessRight.onChange({
                AccessRightType: accessRightType,
                DisplayText: accessRightTypeToTextMap[accessRightType],
                Id: "",
              })}
            />
          </FormFieldValue>
        </FormField>

        <FormField label="Access expires:">
          <FormFieldValue>
            <Select
              testId="access-expires"
              field={form.ExpirationDays}
              options={enumUtils.values(ExpirationDays).map((expirationDays) => ({
                value: expirationDays,
                label: expirationDaysToTextMap[expirationDays]
              }))}
            />
          </FormFieldValue>
        </FormField>

        <FormField label="Downloads per user:">
          <FormFieldValue>
            <Select
              testId="max-downloads"
              field={form.MaxDownloads}
              options={[MaxDownloads.Unlimited, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((maxDownloads) => ({
                value: maxDownloads,
                label: maxDownloadsToTextMap[maxDownloads] || maxDownloads.toString()
              }))}
            />
          </FormFieldValue>
        </FormField>
      </SidePanelSection>

      <DetailsPanelFooter
        saveButtonText="SAVE & UPDATE"
        onCancelButtonClick={props.onClose}
        onSaveButtonClick={save}
      />
    </DetailsPanel>
  );
}