import { IPage } from '../entities/page';
import { IAddStorageNodeRequest, IUpdateStorageNodeRequest, StorageProvider } from "../entities/storage-node";
import { getStorageProviderType, IStorageNodesV2RequestParams, IStorageNodeV2 } from "../entities/storage-node-v2";
import { StorageNodeService } from "./storage-node-service";

export class StorageNodeV2Service extends StorageNodeService {
    constructor() {
        super("storage/node", 2);
    }

    get(storageNodeId: string): Promise<IStorageNodeV2> {
        return this.api.get<IStorageNodeV2>(storageNodeId);
    }

    getPage(requestParams: IStorageNodesV2RequestParams): Promise<IPage<IStorageNodeV2>> {
        return this.api.get<IPage<IStorageNodeV2>>(null, requestParams);
    }

    delete(storageNodeId: string): Promise<void> {
        return this.api.delete(storageNodeId);
    }

    addStorageNode(addRequest: IAddStorageNodeRequest): Promise<IStorageNodeV2> {
        return this.api.post<IStorageNodeV2>(null, this.encodeEndpointUrl(addRequest));
    }

    update(updateRequest: IUpdateStorageNodeRequest): Promise<IStorageNodeV2> {
        return this.api.put<IStorageNodeV2>(updateRequest.id, this.encodeEndpointUrl(updateRequest));
    }

    bucketNameValidator(bucketName: string, storageProvider: StorageProvider): Promise<boolean> {
        let providers = [getStorageProviderType(storageProvider)];
        return this.getPage({ q: bucketName, strict: true, fields: ['id', 'name'], providers, deleted: true })
            .then(page => page.count === 0 || page.data.filter(x => bucketName !== x.name).length === 0);
    }

    nameValidator(name: string): Promise<boolean> {
        return this.getPage({ q: name, strict: true, fields: ['id', 'name'], deleted: true })
            .then(page => page.count === 0 || page.data.filter(x => name !== x.bucket).length === 0);
    }
}
