import React from "react";
import TitleWithDivider from "../../shared-ui/titleWithDivider";
import PageTitle from "../../shared-ui/pageTitle";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import logoPng from "../../../assets/logo.png";

const useStyles = makeStyles(theme => ({
  versionsRow: {
    display: "flex",
    paddingTop: "25px",
  },
  myotalogo: {
    width: "20%",
    textAlign: "center",
    overflow: "hidden",
  },
  versionsInfo: {
    width: "100%",
    maxWidth: "600px",
    "& a": {
      fontSize: "14px",
      lineHeight: 1.36,
      textAlign: "left",
      textDecoration: "underline",
      padding: "0 13px",
      color: "#6c969d",
      fontWeight: "400",
    },
  },
  infoList: {
    padding: 0,
    margin: 0,
    textAlign: "center",
    "& :first-child": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      fontWeight: "600",
    },
    "& li": {
      display: "flex",
      justifyContent: "center",
      fontSize: "14px",
      lineHeight: 1.36,
      textAlign: "left",
      color: "#292929",
      paddingBottom: "25px",
      marginBottom: "25px",
      fontWeight: "400",
      "& :first-child": {
        borderBottom: "none",
        fontWeight: "400",
      },
    },
    "& span": {
      padding: "0 13px",
    },
  },
  finishRow: {
    display: "flex",
    marginTop: "50px",
  },
  finishBtn: {
    height: "40px",
    borderRadius: "25px",
    backgroundColor: "#6c969d",
    border: "1px solid #6c969d",
    textAlign: "center",
    padding: "0 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1,
    color: "#fff",
    marginLeft: "auto",
    textTransform: "uppercase",
  },
}));

const ClientDeployment = () => {
  const classes = useStyles();
  return (
    <div>
      <PageTitle title="Get Users Started with Myota" />
      <p>Download the client and start getting your users set up.</p>
      <TitleWithDivider title="Download Client" />

      <div className={classes.versionsRow}>
        <div className={classes.myotalogo}>
          <img style={{ width: "200%" }} src={logoPng} alt="logo" />
        </div>

        <div className={classes.versionsInfo}>
          <ul className={classes.infoList}>
            <li>
              <span style={{ fontWeight: 600 }}>Current: Myota v 1.2</span> |
              <span>Release date: Feb 12, 2020</span> |<span>762kb</span>
              <Link to="">Windows</Link>
              <Link to="">Mac</Link>
            </li>
            <li>
              <span>Current: Myota v 1.2</span> |
              <span>Release date: Feb 12, 2020</span> |<span>762kb</span>
              <Link to="">Windows</Link>
              <Link to="">Mac</Link>
            </li>
            <li>
              <span>Current: Myota v 1.2</span> |
              <span>Release date: Feb 12, 2020</span> |<span>762kb</span>
              <Link to="">Windows</Link>
              <Link to="">Mac</Link>
            </li>
            <li>
              <span>Current: Myota v 1.2</span> |
              <span>Release date: Feb 12, 2020</span> |<span>762kb</span>
              <Link to="">Windows</Link>
              <Link to="">Mac</Link>
            </li>
          </ul>
          <div style={{ textAlign: "center" }}>
            <Link to="">Full version history</Link>
          </div>
          <div className={classes.finishRow}>
            <button className={classes.finishBtn}>I’m Finished</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDeployment;
