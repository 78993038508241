import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { StoragePoolRouteAction, StoragePoolRouteParam, STORAGE_POOLS_ROUTE, UsersRouteAction } from "../../../../constants/routes";
import { IAppState } from "../../../../redux/reducers";
import { RestoreFilesPanel } from "../../../common/restore-files-panel/restore-files-panel";
import { storagePoolV1ThunkActions } from "../../../../redux/actions/storage-pool";
import { useNavigate } from "../../../../utils/hooks/use-navigate";


function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, void, Action>) {
  return {
    fetchStoragePools: () => {
      return dispatch(storagePoolV1ThunkActions.entity.fetchPage());
    }
  };
}

const mapStateToProps = (state: IAppState) => ({
  selection: state.storagePoolV1.selection,
});

export interface IStoragePoolRestoreFilesPanelProps extends 
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps> {
}


export const StoragePoolRestoreFilesPanel = connect(mapStateToProps, mapDispatchToProps)((props: IStoragePoolRestoreFilesPanelProps) => {
  const navigate = useNavigate();
  const { idOrAction, nodeIdOrAction } = useParams<{
    [StoragePoolRouteParam.IdOrAction]: string, 
    [StoragePoolRouteParam.NodeIdOrAction]: string }>();
  const open = (idOrAction === StoragePoolRouteAction.RestoreFiles || nodeIdOrAction === UsersRouteAction.RestoreFiles);
  const [storagePoolIds, setStoragePoolIds] = useState<number[]>();

  function close(): void {
    navigate(STORAGE_POOLS_ROUTE);
  }

  function getStoragePoolIds(): number[] {
    return idOrAction === StoragePoolRouteAction.RestoreFiles ? 
      Object.values(props.selection).map(pool => pool.id):
      [Number(idOrAction)];
  }

  useEffect(() => {
      setStoragePoolIds(open ? getStoragePoolIds() : null);
  // eslint-disable-next-line
  }, [open]);

  return (
    <RestoreFilesPanel
      open={open}
      storagePoolIds={storagePoolIds}
      onClose={close}
      onFilesRestored={props.fetchStoragePools}
    />
  );
});
