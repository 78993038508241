import { IUserShort } from './user';
import { IRequestParams } from './page';
import { IConditionalUpdateRequest } from '../services/api-service';
import { IIdName } from './id-name';
import { IRepoDataMigration } from './data-migration';
import { IRetentionPolicy, ISpacePolicy } from './retention-policy';

export interface ISharingProfileListItem extends ISpacePolicy, IRetentionPolicy {
    id: number;
    StoragePool: IIdName;
    consoleName: string;
    folderName: string;
    canEdit: boolean;
    usersCount?: number;
    sharingInstancesCount?: number;
    createdAt: string;
    updatedAt: string;
    dataMigrations?: IRepoDataMigration[];
}

export interface ISharingProfile extends ISharingProfileListItem, ISpacePolicy, IRetentionPolicy {
    users: IUserShort[];
}

export interface ISharingProfileFilterParams {
    q?: string;
    storagePoolFilter?: number;
    exclude?: number[];
}

export interface ISharingProfilesRequestParams extends 
    IRequestParams<SharingProfileSortField>,
    ISharingProfileFilterParams {
}

export interface ISharingProfileCreateRequest extends ISpacePolicy, IRetentionPolicy {
    storagePoolId: number;
    consoleName: string;
    folderName: string;
    canEdit: boolean;
    ignoreFolderName?: boolean;
    users: { id: number }[];
}

export interface ISharingProfileUpdateRequest extends ISharingProfileCreateRequest, IConditionalUpdateRequest {
    id: number;
}

export interface IUpdateUsersRequest extends IConditionalUpdateRequest, Pick<ISharingProfileCreateRequest, "users"> { }

export interface ISharingProfileDeleteRequest {
    ids: { id: number, lastUpdated: string }[];
}

export enum SharingProfileSortField {
    ConsoleName = "consoleName",
    UsersCount = "usersCount",
    FolderName = "folderName",
    SpaceLimitMegabytes = "spaceLimitMegabytes",
    StoragePool = "storagepool",
    Storage="storage",
}

export interface IUserSharingInstances {
    user: IUserShort;
    sharingInstances: { id: number, folderName: string }[];
}