import { DEFAULT_PAGE_SIZE } from './../../constants/common';
import { IEntitiesPageState } from ".";
import { IUserDataMigration, IDataMigrationPageRequestParams } from '../../api/entities/data-migration';
import { DataMigrationAction, DataMigrationActionType } from '../actions/data-migration';

export interface IDataMigrationState {
  page: IEntitiesPageState<IUserDataMigration, void, IDataMigrationPageRequestParams>;
}

export const DATA_MIGRATION_DEFAULT_STATE: IDataMigrationState = {
  page: {
    entities: [],
    searchParams: {
      offset: 0,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    count: 0,
    isLoading: false,
  },
};

export function dataMigration(
  state: IDataMigrationState = DATA_MIGRATION_DEFAULT_STATE,
  action: DataMigrationAction
): IDataMigrationState {
  switch (action.type) {
    case DataMigrationActionType.FetchPageRequest:
      return {
        ...state,
        page: {
          ...state.page,
          searchParams: {
            ...state.page.searchParams,
            ...action.searchParams,
          },
          isLoading: true,
        }
      };
    case DataMigrationActionType.FetchPageSuccess:
      return {
        ...state,
        page: {
          ...state.page,
          entities: action.payload.data,
          count: action.payload.count,
          isLoading: false,
        },
      };
    case DataMigrationActionType.FetchPageFail:
      return {
        ...state,
        page: {
          ...state.page,
          isLoading: false,
        },
      };  
    default:
      return state;
  }
}
