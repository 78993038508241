import { IAddStorageNodeRequest, IStorageNodeTestConnectionRequest, IUpdateStorageNodeRequest, StorageProvider } from "../api/entities/storage-node";
import { ApiVersionNumber } from "../api/services/data-service";
import { ABSRegions, AWSRegions, GoogleRegions } from "../helper/regions";

export const storageProviderToNameMap = {
  [StorageProvider.S3]: "S3",
  [StorageProvider.GCS]: "Google Cloud Storage",
  [StorageProvider.ABS]: "Azure Blob Storage"
};

const storageProviderCategoryMap = {
  [StorageProvider.S3]: StorageProvider.S3,
  [StorageProvider.S3Compatible]: StorageProvider.S3,
  [StorageProvider.GCS]: StorageProvider.GCS,
  [StorageProvider.ABS]: StorageProvider.ABS,
};

const storageProviderToShortNameMap = {
  [StorageProvider.S3]: "S3",
  [StorageProvider.GCS]: "Google",
  [StorageProvider.ABS]: "Azure",
};

export const storageProviderToSecretFieldLabelMap = {
  [StorageProvider.S3]: "Access Key Secret",
  [StorageProvider.GCS]: "Private Key",
  [StorageProvider.ABS]: "Account Key",
};

export const storageProviderToRegionFieldLabelMap = {
  [StorageProvider.S3]: "Region",
  [StorageProvider.GCS]: "Region",
  [StorageProvider.ABS]: "Location",
};

export enum StorageProviderField {
  StorageName = "storageName",
  Region = "region",
  Bucket = "bucket",
  AccountName = "account_name",
  Container = "container",
  Arn = "arn",
  ProjectId = "project_id",
  AccessKey = "key",
  SecretKey = "secret",
  ClientEmail = "client_email",
  EndpointURL = "endpointURL",
  GCSConfigProps = "gcsConfigProps",
  SasUri = "sasUri",
}

type StorageProviderFieldInfo = {
  providers?: StorageProvider[];
  optional?: boolean;
  sensitive?: boolean;
  v1?: VersionFieldEnabledInfo;
  v2?: VersionFieldEnabledInfo;
};

type VersionFieldEnabledInfo = {
  providers?: StorageProvider[];
  enabled: boolean;
}

type StorageNodeRequest = IAddStorageNodeRequest | IUpdateStorageNodeRequest | IStorageNodeTestConnectionRequest;

const storageProviderFieldsInfo: {[key in StorageProviderField]: StorageProviderFieldInfo} = {
  [StorageProviderField.StorageName]: { },
  [StorageProviderField.Region]: {
    providers: [
      StorageProvider.S3,
      StorageProvider.S3Compatible,
      StorageProvider.ABS,
      StorageProvider.GCS
    ],
    optional: true
  },
  [StorageProviderField.Bucket]: {
    providers: [
      StorageProvider.S3,
      StorageProvider.S3Compatible,
      StorageProvider.GCS
    ]
  },
  [StorageProviderField.AccountName]: {
    providers: [
      StorageProvider.ABS,
    ],
    sensitive: true,
    v2: {
      enabled: false
    }
  },
  [StorageProviderField.Container]: {
    providers: [
      StorageProvider.ABS,
    ]
  },
  [StorageProviderField.SasUri]: {
    providers: [
      StorageProvider.ABS,
    ],
    sensitive: true,
    v1: {
      enabled: false
    }
  },
  [StorageProviderField.Arn]: {
    providers: [
      StorageProvider.S3,
    ],
    optional: true,
    v2: {
      enabled: false
    }
  },
  [StorageProviderField.ProjectId]: {
    providers: [
      StorageProvider.GCS
    ],
    sensitive: true,
    v2: {
      enabled: false
    }
  },
  [StorageProviderField.AccessKey]: {
    providers: [
      StorageProvider.S3,
      StorageProvider.S3Compatible,
    ],
    sensitive: true
  },
  [StorageProviderField.SecretKey]: {
    sensitive: true,
    v2: {
      providers: [
          StorageProvider.GCS,
          StorageProvider.ABS
      ],
      enabled: false
    }
  },
  [StorageProviderField.ClientEmail]: {
    providers: [
      StorageProvider.GCS
    ],
    sensitive: true,
    v2: {
      enabled: false
    }
  },
  [StorageProviderField.EndpointURL]: {
    providers: [
      StorageProvider.S3,
      StorageProvider.S3Compatible,
    ],
    optional: true
  },
  [StorageProviderField.GCSConfigProps]: {
    providers: [
        StorageProvider.GCS
    ],
    sensitive: true
  }
};

export class StorageUtils {
  getStorageProviderName(storageProvider: StorageProvider): string {
    const providerCategory = this.getStorageProviderGeneralizedType(storageProvider);
    return storageProviderToNameMap[providerCategory];
  }

  getStorageProviderShortName(storageProvider: StorageProvider): string {
    const providerCategory = this.getStorageProviderGeneralizedType(storageProvider);
    return storageProviderToShortNameMap[providerCategory];
  }

  getStorageProviderGeneralizedType(storageProvider: StorageProvider): StorageProvider {
    return storageProviderCategoryMap[storageProvider];
  }

  getProviderRegions(provider: StorageProvider): string[] {
    switch (provider) {
      case StorageProvider.S3:
        return [...AWSRegions];
      case StorageProvider.ABS:
        return [...ABSRegions];
      case StorageProvider.GCS:
        return [...GoogleRegions];
      default: 
        return [];
    }
  }

  isCustomRegion(provider: StorageProvider, region: string): boolean {
    return this.getProviderRegions(provider).indexOf(region) < 0;
  }

  isFieldVisible(field: StorageProviderField, provider: StorageProvider, showSensitiveFields: boolean): boolean {
    const info = storageProviderFieldsInfo[field];
    return (info && (!info.providers || info.providers.includes(provider)) && ((!showSensitiveFields && !info.sensitive) || showSensitiveFields));
  }

  isFieldOptional(field: StorageProviderField): boolean {
    const info = storageProviderFieldsInfo[field];
    return (info && !!info.optional);
  }

  isFieldEnabled(field: StorageProviderField, provider: StorageProvider, version: ApiVersionNumber): boolean {
    const info = storageProviderFieldsInfo[field];

    const includesProvider = (p: StorageProvider, version: string) =>
        (!!p && info[version].providers &&
            ((info[version].providers.includes(p) && info[version].enabled) ||
                (!info[version].providers.includes(p) && !info[version].enabled))) ||
        (!info[version].providers && info[version].enabled);

    const versionPropName = `v${version}`;
    return (!!info && (!info[versionPropName] || includesProvider(provider, versionPropName)));
  }

  cleanNotApplicableFields<TRequest extends StorageNodeRequest>(request: TRequest, showSensitiveFields: boolean): TRequest {
    Object.keys(storageProviderFieldsInfo).forEach((field: StorageProviderField) => {
      if (request[field] && !this.isFieldVisible(field, request.type, showSensitiveFields)) {
        request[field] = null;
      }
    });
    return request;
  }

  isTestConnectionRequestValid(request: IStorageNodeTestConnectionRequest, showSensitiveFields: boolean, version: ApiVersionNumber): boolean {
    return Object.keys(storageProviderFieldsInfo)
        .filter((field: StorageProviderField) => this.isFieldEnabled(field, request.type, version))
        .every((field: StorageProviderField) => {
          return field === StorageProviderField.StorageName || request[field] ||
              !this.isFieldVisible(field, request.type, showSensitiveFields) ||
              this.isFieldOptional(field);
        });
  }
}

export const storageUtils = new StorageUtils();
