import React, { PropsWithChildren, ReactNode } from "react";
import { SidePanel, SidePanelPosition } from "../side-panel/side-panel";
import styles from "./details-panel.module.scss";
import Button from "../button";
import CloseIcon from "@mui/icons-material/Close";
import {
  IStyleableComponentProps,
  ITestableComponent,
} from "../common/component-props";
import clsx from "clsx";
import { LocalLoader } from "../loader";

interface IDetailsPanelProps
  extends IStyleableComponentProps,
    ITestableComponent {
  isLoading?: boolean;
  footer?: boolean;
  open: boolean;
  title?: ReactNode;
  description?: ReactNode;
  backLinkText?: string;
  contentClassName?: string;
  autoFocus?: boolean;
  onBackLinkClick?: () => void;
  onCloseClick: () => void;
}

export default function DetailsPanel(
  props: PropsWithChildren<IDetailsPanelProps>
) {

  return (
    <SidePanel
      open={props.open}
      side={SidePanelPosition.Right}
      className={clsx(styles.root, props.className, props.footer && styles["root--with-footer"])}
      rootClass={styles["side-panel"]}
      autoFocus={props.autoFocus}
      onOutsideClick={props.onCloseClick}
      onEscape={props.onCloseClick}
      style={props.style}
      testId={props.testId}
    >
      {props.backLinkText && (
        <Button
          testId="back-link"
          className={styles["back-link"]}
          colors="link"
          onClick={props.onBackLinkClick}
        >
          {props.backLinkText}
        </Button>
      )}
      <Button
        className={styles.close}
        onClick={props.onCloseClick}
        testId="close"
      >
        <CloseIcon viewBox="5 5 14 14" />
      </Button>
      {props.title && (
        <h2 className={styles.title} data-testid="title">
          {props.title}
        </h2>
      )}
      {props.description && (
        <div data-testid="description" className={styles.description}>
          {props.description}
        </div>
      )}
      {props.open &&
          <div className={clsx(styles.content, props.contentClassName)}>
            {props.children}
          </div>
      }

      {props.isLoading && (
        <LocalLoader className={styles.loader} isLoading={true}>
          {" "}
        </LocalLoader>
      )}

    </SidePanel>
  );
}
