import React, { ReactNode, useState } from "react";
import styles from "./tooltip-input.module.scss";
import IComponentProps from "../../common/component-props";
import clsx from "clsx";
import Tooltip from "../../tooltip";
import { InputBase } from "../input-base/input-base";

interface ITooltipInputProps extends IComponentProps {
  autoFocus?: boolean;
  value: string | number;
  tooltip?: ReactNode;
  type?: "text" | "number";
  min?: number;
  max?: number;
  hasError?: boolean;

  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
}

export default function TooltipInput(props: ITooltipInputProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <div
      className={clsx(
        styles.root,
        {
          [styles["root--tooltip"]]: !!props.tooltip,
        },
        props.className
      )}
      style={props.style}
      data-testid={props.testId}
    >
      <InputBase
        testId={props.testId}
        type={props.type || "text"}
        min={props.min}
        max={props.max}
        className={styles.input}
        hasError={props.hasError}
        value={props.value ?? ""}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
      {props.tooltip && (
        <>
          <Tooltip title={props.tooltip} open={tooltipOpen}>
            <div
              className={styles.question}
              onClick={() => setTooltipOpen(true)}
            >
              ?
            </div>
          </Tooltip>
          {tooltipOpen && (
            <div
              className={styles.overlay}
              onClick={() => setTooltipOpen(false)}
            ></div>
          )}
        </>
      )}
    </div>
  );
}
