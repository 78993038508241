import React, { PropsWithChildren } from "react";
import styles from "./footer.module.scss";
import { IStyleableComponentProps } from "../../shared-ui/common/component-props";
import clsx from "clsx";

interface IFooterProps extends IStyleableComponentProps {}

export default function Footer(props: PropsWithChildren<IFooterProps>) {
  return (
    <div className={clsx(styles.root, props.className)} style={props.style}>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
}
