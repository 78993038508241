import React from "react";
import IComponentProps, { CompactViewScreen } from "../../common/component-props";
import Button from "../index";
import GetAppIcon from '@mui/icons-material/GetApp';
import styles from "./export-button.module.scss";
import clsx from "clsx";
import { fileUtils } from "../../../../utils/file-utils";

interface IExportButtonProps extends IComponentProps {
  url: string;
  compactViewScreen?: CompactViewScreen;
}

export default function ExportButton(props: IExportButtonProps) {
  function exportToCsv() {
    fileUtils.downloadFile(props.url);
  }

  return (
    <Button
      colors="secondary"
      responsive={props.compactViewScreen && props.compactViewScreen !== CompactViewScreen.None}
      className={clsx(
        props.className, 
        props.compactViewScreen === CompactViewScreen.Small && styles["root--compact-on-small"],
        props.compactViewScreen === CompactViewScreen.Medium && styles["root--compact-on-medium"]
      )}
      onClick={exportToCsv}
      testId={props.testId || "export-button"}
    >
      <GetAppIcon className={styles.icon}/>
      <span className={styles.text}>EXPORT CSV</span>
    </Button>
  );
}
