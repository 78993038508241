import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { EmailResendRequest, IAuthenticationRequest, ICreateAccountRequest, ResetPasswordRequest } from "../../api/entities/user";
import { accountService } from "../../api/services/account-service";
import { commonActions, handleApi, PickActions } from "./common";
import { usersActions } from "./users";

export enum AccountActionType {
  Login = "ACCOUNT_LOGIN",
  LoginError = "ACCOUNT_LOGIN_ERROR",
  RemoveLoginError = "ACCOUNT_REMOVE_LOGIN_ERROR",

  SignUp = "ACCOUNT_SIGNUP",
  SignUpError = "ACCOUNT_SIGNUP_ERROR",
  RemoveSignUpError = "ACCOUNT_REMOVE_SIGNUP_ERROR",

  RequestResetPassword = "ACCOUNT_REQUEST_RESET_PASSWORD",
  AccountConfirmationEmailSending = "ACCOUNT_CONFIRMATION_EMAIL_SENDING",
  ClearAccountConfirmationEmailSendingStatus = "ACCOUNT_CLEAR_CONFIRMATION_EMAIL_SENDING_STATUS",
}

class AccountActions {
  loginErrorAction(error?: string) {
    return {
      type: AccountActionType.LoginError as typeof AccountActionType.LoginError,
      payload: error
    };
  }
  removeLoginErrorAction() {
    return {
      type: AccountActionType.RemoveLoginError as typeof AccountActionType.RemoveLoginError,
    };
  }
  signUpErrorAction(error?: string) {
    return {
      type: AccountActionType.SignUpError as typeof AccountActionType.SignUpError,
      payload: error
    };
  }
  removeSignUpErrorAction() {
    return {
      type: AccountActionType.RemoveSignUpError as typeof AccountActionType.RemoveSignUpError,
    };
  }
  accountConfirmationEmailSendingAction() {
    return {
      type: AccountActionType.AccountConfirmationEmailSending as typeof AccountActionType.AccountConfirmationEmailSending,
    };
  }
  clearAccountConfirmationEmailSendingStatusAction() {
    return {
      type: AccountActionType.ClearAccountConfirmationEmailSendingStatus as typeof AccountActionType.ClearAccountConfirmationEmailSendingStatus,
    };
  }
}

export const accountActions = new AccountActions();
export type AccountAction = PickActions<AccountActions>;

class AccountThunkActions {
  login(accountRequest: IAuthenticationRequest): ThunkAction<void, unknown, unknown, Action> {
    return (dispatch) => {
      dispatch(commonActions.startLoading());
      return accountService
          .login(accountRequest)
          .then((loginInfo) => {
            if (!!loginInfo) {
              dispatch(usersActions.userLoggedInAction(loginInfo))
            }
          })
          .catch((err) => {
            const message = "data" in err ? err.message : undefined;
            dispatch(accountActions.loginErrorAction(message));
          })
          .finally(() => {
            dispatch(commonActions.stopLoading());
          });
    };
  }
  logout() {
    return (dispatch) => {
      return handleApi(accountService
          .logout()
          .then((_) => {
            dispatch(usersActions.logoutCurrentUserAction());
          }), true);
    };
  }
  signUp(accountRequest: ICreateAccountRequest): ThunkAction<void, unknown, unknown, Action> {
    return (dispatch) => {
      dispatch(commonActions.startLoading());
      return accountService
          .signUp(accountRequest)
          .then((info) => {
            if (!!info) {
              dispatch(accountActions.accountConfirmationEmailSendingAction());
            }
          })
          .catch((err) => {
            const message = "data" in err ? err.message : undefined;
            dispatch(accountActions.signUpErrorAction(message));
          })
          .finally(() => {
            dispatch(commonActions.stopLoading());
          });
    };
  }
  requestResetPassword(request: ResetPasswordRequest): ThunkAction<void, unknown, unknown, Action> {
    return (dispatch) => {
      dispatch(commonActions.startLoading());
      return accountService
          .requestResetPassword(request)
          .finally(() => {
            dispatch(commonActions.stopLoading());
          });
    };
  }
  resendEmailResetPassword(request: EmailResendRequest): ThunkAction<void, unknown, unknown, Action> {
    return (dispatch) => {
      dispatch(commonActions.startLoading());
      return accountService
          .resendEmailResetPassword(request)
          .finally(() => {
            dispatch(commonActions.stopLoading());
          });
    };
  }
  resendEmailAccountConfirmation(request: EmailResendRequest): ThunkAction<void, unknown, unknown, Action> {
    return (dispatch) => {
      dispatch(commonActions.startLoading());
      return accountService
          .resendEmailAccountConfirmation(request)
          .finally(() => {
            dispatch(commonActions.stopLoading());
          });
    };
  }
}

export const accountThunkActions = new AccountThunkActions();
