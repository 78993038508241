import { DataService } from "./data-service";
import { IUsersRequestParams } from '../entities/user';
import { IDevicesRequestParams } from "../entities/device";
import { IPoliciesRequestParams } from "../entities/policy";
import { ISharingProfilesRequestParams } from "../entities/sharing-profile";
import { ISharingInstancesRequestParams } from "../entities/sharing-instance";
import { IStorageNodesRequestParams } from "../entities/storage-node";
import { IStoragePoolsRequestParams } from "../entities/storage-pool";
import { IUserGroupsRequestParams } from "../entities/user-group";

class ExportService extends DataService {
  constructor() {
    super("export");
  }

  getUserUrl(requestParams?: IUsersRequestParams): string {
    return this.api.getFullUrl("user", requestParams);
  }

  getDeviceUrl(requestParams?: IDevicesRequestParams): string {
    return this.api.getFullUrl("device", requestParams);
  }

  getPolicyUrl(requestParams?: IPoliciesRequestParams): string {
    return this.api.getFullUrl("policy", requestParams);
  }

  getSharingProfileUrl(requestParams?: ISharingProfilesRequestParams): string {
    return this.api.getFullUrl("sharing/profile", requestParams);
  }

  getSharingInstanceUrl(requestParams?: ISharingInstancesRequestParams): string {
    return this.api.getFullUrl("sharing/instance", requestParams);
  }

  getStoragePoolUrl(requestParams?: IStoragePoolsRequestParams): string {
    return this.api.getFullUrl("storagepool", requestParams);
  }

  getStorageNodeUrl(requestParams?: IStorageNodesRequestParams): string {
    return this.api.getFullUrl("storagenode", requestParams);
  }
  getUserGroupUrl(requestParams?: IUserGroupsRequestParams): string {
    return this.api.getFullUrl("usergroup", requestParams);
  }
} 

export const exportService = new ExportService();
