import { IStorageNodeIssue } from "./../../api/entities/storage-node-issue";
import { StorageActionType, StorageAction, REPLACE_STORAGE_CONFIRMATION, STORAGE_ENTITY_NAME } from "../actions/storage";
import { IStorageType, IStorageNode } from "../../api/entities/storage-node";
import { IStoragePool } from "../../api/entities/storage-pool";
import { IDialogState } from ".";
import { createDialogReducer, DIALOG_DEFAULT_STATE } from "./dialog";

export interface IStorageState {
  providerType: IStorageType[];
  storageList: IStorageNode[];
  storageNodeIssues: IStorageNodeIssue[];
  uniqueNameCheck: boolean;
  testConnectionFlag: boolean;
  showTable: boolean;
  storageNodeDetail: IStorageNode;
  addStoragePoolFlag: boolean;
  defaultStoragePool: IStoragePool[];
  [REPLACE_STORAGE_CONFIRMATION]: IDialogState<IStorageNode>;
}

export const STORAGE_DEFAULT_STATE: IStorageState = {
  providerType: [],
  storageList: [],
  uniqueNameCheck: false,
  testConnectionFlag: null,
  showTable: null,
  storageNodeDetail: null,
  addStoragePoolFlag: false,
  defaultStoragePool: [],
  storageNodeIssues: [],
  [REPLACE_STORAGE_CONFIRMATION]: DIALOG_DEFAULT_STATE
};

const replaceStorageConfirmationReducer = createDialogReducer(STORAGE_ENTITY_NAME, REPLACE_STORAGE_CONFIRMATION);

export default function storage(
  state = STORAGE_DEFAULT_STATE,
  action: StorageAction
): IStorageState {
  
  state = replaceStorageConfirmationReducer(state, action);

  switch (action.type) {
    case StorageActionType.PROVIDER_TYPE:
      return {
        ...state,
        providerType: action.payload,
      };
    case StorageActionType.STORAGE_LISTING:
      return {
        ...state,
        storageList: action.payload,
      };
    case StorageActionType.CHECK_UNIQUE_NAME:
      return {
        ...state,
        uniqueNameCheck: action.data,
      };

    case StorageActionType.SET_TEST_CONNECTION_FLAG:
      return {
        ...state,
        testConnectionFlag: action.flag,
      };
    case StorageActionType.SET_STORAGE_NODE_DETAIL:
      return {
        ...state,
        storageNodeDetail: action.data,
      };
    case StorageActionType.STORAGE_ISSUES_FETCH_SUCCESS:
      return {
        ...state,
        storageNodeIssues: action.payload,
      };
    default:
      return state;
  }
}
