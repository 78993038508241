import React from "react";
import IOption from "../common/option";
import Button, { ButtonColors } from "../button";
import IComponentProps from "../common/component-props";
import styles from "./button-group.module.scss";
import clsx from "clsx";
import { testUtils } from "../../../utils/test-utils";

interface IButtonGroupProps extends IComponentProps {
  autoFocus?: boolean;
  value: any;
  options: IOption[];
  colors?: ButtonColors;
  buttonClassName?: string;

  onChange: (value) => void;
}

export default function ButtonGroup(props: IButtonGroupProps) {
  const buttons = props.options.map((option) => {
    return (
      <Button
        testId={testUtils.stringToTestId(option.label) || "group-button"}
        key={option.label || option.value}
        colors={props.colors}
        onClick={() => props.onChange(option.value)}
        disabled={props.disabled || option.disabled}
        selected={option.value === props.value}
        className={clsx(styles.button, props.buttonClassName)}
        autoFocus={props.autoFocus}
      >
        {option.label == null ? option.value : option.label}
      </Button>
    );
  });

  return (
    <div
      className={clsx(styles.root, props.className)}
      data-testid={props.testId || "button-group"}
    >
      {buttons}
    </div>
  );
}
