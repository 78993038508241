import React, { PropsWithChildren } from "react";
import styles from "./table-toolbar.module.scss";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { ITestableComponent } from "../common/component-props";

export interface ITableToolbarProps extends ITestableComponent {
  text: string;
  open: boolean;
  onClose: () => void;
}

export function TableToolbar(props: PropsWithChildren<ITableToolbarProps>) {
  return props.open && (
    <div className={styles.root} data-testid={props.testId || "table-toolbar"}>
      <div className={styles.content}>
        <span className={styles.text}>
          {props.text}
        </span>
        {props.children}
      </div>
      <IconButton
        data-testid="close-button"
        aria-label="ClearIcon"
        style={{ color: "white" }}
        onClick={props.onClose}
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
}
