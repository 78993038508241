import React, { useState, useEffect } from "react";
import styles from "../manage-users.module.scss";
import DataTable from "./userTable";
import { IUser, UserSortField } from "../../../../api/entities/user";
import Table, { IColumnDefinition, TableSelected } from "../../../shared-ui/table/table";
import Button from "../../../shared-ui/button";
import Footer from "../../footer/footer";
import { Formatters } from "../../../../helper/formatters";
import { IPolicyUser } from "../../../../api/entities/policy";

export interface IAddUsersProps {
  addedUserArr: IPolicyUser[];
  spaceLimitMb: number;
  profileName?: string;
  selectedNewUsers?: TableSelected<IUser>;

  handleAddUsers?: (selected: IUser[]) => void;
  handleRemoveUser?: (selected: IUser[]) => void;
  setSelectedNewUsers?: (selected: TableSelected<IUser>) => void;
}

const AddUsers = (props: IAddUsersProps) => {
  const [tableSelected, setTableSelected] = useState<TableSelected<IUser>>({}),
    [addedSelected, setAddedSelected] = useState<TableSelected<IPolicyUser>>({}),
    [addedTitle, setAddedTitle] = useState(""),
    [addedUserIds, setAddedUserIds] = useState<number[]>([]),
    [addedColumns, setAddedColumns] = useState<
      IColumnDefinition<IPolicyUser, UserSortField>[]
    >([]);

  useEffect(() => {
    setAddedUserIds(props.addedUserArr.map(u => u.id));
  }, [props.addedUserArr]);

  useEffect(() => {
    let result = "NEW PROFILE USERS",
      columns: IColumnDefinition<IPolicyUser, UserSortField>[] = [
        {
          label: "Users",
          formatter: u => u.email,
        },
      ];
    if (!!props.profileName) {
      result = `USERS IN '${props.profileName}'`;
      columns.push({
        label: "Space Used",
        className: styles["col--size"],
        formatter: row => Formatters.formatStorageSpace(row.usedSpaceBytes),
      });
    }
    setAddedTitle(result);
    setAddedColumns(columns);
  }, [props.profileName]);

  useEffect(() => {
    if (props.selectedNewUsers) {
      setTableSelected(props.selectedNewUsers);
    }
  }, [props.selectedNewUsers]);

  return (
    <div className={styles.root} data-testid="add-users">
      <div className={styles.col}>
        <div className={styles.border}>
          <DataTable
            selected={tableSelected}
            spaceLimitMb={props.spaceLimitMb}
            setSelected={map => {
              setTableSelected(map);
              if (props.setSelectedNewUsers) {
                props.setSelectedNewUsers(map);
              }
            }}
            hideUsers={addedUserIds}
          />
        </div>
        {!props.profileName && (
          <Footer className={styles.footer}>
            <div data-testid="users-selected">
              {`${Object.keys(tableSelected).length} selected`}
            </div>
            <Button
              colors="link"
              testId="add-users-button"
              onClick={() => {
                props.handleAddUsers(Object.values(tableSelected) as IUser[]);
                setTableSelected({});
              }}
            >
              + Add
            </Button>
          </Footer>
        )}
      </div>
      <div className={styles.col}>
        <div className={styles.border}>
          <div className={styles["added-title"]}>{addedTitle}</div>
          <Table
            testId="add-users-table"
            columns={addedColumns}
            rows={props.addedUserArr}
            selected={addedSelected}
            hidePaging
            hideCheckbox={!!props.profileName}
            onSetSelected={map => setAddedSelected(map)}
            requestParams={{}}
          />
        </div>
        <Footer className={styles.footer}>
          <div data-testid="profile-users-selected">
            {`${
              Object.keys(props.profileName ? tableSelected : addedSelected)
                .length
            } selected`}
          </div>
          {!props.profileName && (
            <Button
              colors="link"
              testId="remove-users-button"
              onClick={() => {
                props.handleRemoveUser(Object.values(addedSelected) as IUser[]);
                setAddedSelected({});
              }}
            >
              - Remove
            </Button>
          )}
        </Footer>
      </div>
    </div>
  );
};

export default AddUsers;
