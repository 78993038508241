import { InitialSetupStep } from "../../components/initialSetup";
import {
  InitialSetupAction,
  InitialSetupActionType,
} from "../actions/intialSetup";

export interface IInitialSetupState {
  initialSetupStep: InitialSetupStep;
}

export const INITIAL_SETUP_DEFAULT_STATE: IInitialSetupState = {
  initialSetupStep: InitialSetupStep.StoragePool,
};

export default function initialSetup(
  state = INITIAL_SETUP_DEFAULT_STATE,
  action: InitialSetupAction
): IInitialSetupState {
  switch (action.type) {
    case InitialSetupActionType.SetStep:
      return {
        ...state,
        initialSetupStep: action.step,
      };
    default:
      return state;
  }
}
