import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { accountThunkActions } from "../../redux/actions/account";
import { IAppState } from "../../redux/reducers";
import { useNavigate } from "../../utils/hooks/use-navigate";
import { routeUtils } from "../../utils/route-utils";
import Loader from "../shared-ui/loader/index";

const mapStateToProps = (state: IAppState) => ({
  user: state.users.currentUser,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    logout: () => {
      return dispatch(accountThunkActions.logout());
    },
  };
};

interface ILogoutProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps>,
    RouteComponentProps {}

export const Logout = connect(mapStateToProps, mapDispatchToProps)((props: ILogoutProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.logout().then(() => {
      navigate(routeUtils.getRouteToLogoutSuccessPage());
    });
    // eslint-disable-next-line
  }, []);

  return <Loader />;
});
