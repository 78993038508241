interface IEnvironment {
  API_URL: string,

  SANDBOX?: boolean;
  MIN_SECURITY_LEVEL?: number;
  MIN_STORAGE_COUNT?: number;
  MAX_STORAGE_COUNT?: number;
  CITRIX_AUTH_URL?: string;
  CITRIX_API_KEYS_URL?: string;
  USER_GUIDE_URL?: string;
}

let env: IEnvironment;

if (process.env.REACT_APP_ENV === "localapi") {
  env = {
   API_URL: "http://localhost:3030/api",
 };
}
else {
  // loaded at runtime from env.js
  env = (window as any)._env_;
}

let apiVersionURLIndex = env.API_URL.indexOf('/v1');
let apiURL = apiVersionURLIndex > -1 ? env.API_URL.substring(0, env.API_URL.indexOf('/v1')) : env.API_URL;

export const CONFIG = {
  // these will always be environment specific
  apiURL: apiURL,
  sandbox: env.SANDBOX || false,

  // these will rarely be different thus have common defaults
  minSecurityLevel: env.MIN_SECURITY_LEVEL || 2,
  minStoragesCount: env.MIN_STORAGE_COUNT || 3,
  maxStoragesCount: env.MAX_STORAGE_COUNT || 6,
  citrixAuthUrl: env.CITRIX_AUTH_URL || "https://secure.sharefile.com/oauth/authorize",
  citrixApiKeysUrl: env.CITRIX_API_KEYS_URL || "https://api.sharefile.com/login?displayMessage=1&page=oauthclient",
  userGuideUrl: env.USER_GUIDE_URL || "https://myota.io/wp-content/uploads/2021/01/myota_console-user-guide.pdf",
};
