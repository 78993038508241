import React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IBucketListItem } from "../../../../api/entities/bucket";
import { applicationService } from "../../../../api/services/application-service";
import { catchException, commonActions } from "../../../../redux/actions/common";
import { bucketActions } from "../../../../redux/actions/bucket";
import { IAppState } from "../../../../redux/reducers";
import { useNavigate } from "../../../../utils/hooks/use-navigate";
import { routeUtils } from "../../../../utils/route-utils";
import MoreOptions from "../../../shared-ui/more-options";
import { IPopupMenuOption } from "../../../shared-ui/popup-menu";
import { IDeleteBlockedConfirmationData } from "../dialogs/delete-blocked-confirmation/delete-blocked-confirmation";

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    openDeleteConfirmation: (bucket: IBucketListItem) =>
        dispatch(bucketActions.deleteConfirmation.open(bucket)),
    openDeleteBlockedConfirmation: (deleteBlockedData: IDeleteBlockedConfirmationData) =>
        dispatch(bucketActions.deleteBlockedConfirmation.open(deleteBlockedData)),
    startLoading: () =>
        dispatch(commonActions.startLoading()),
    stopLoading: () =>
        dispatch(commonActions.stopLoading())
  };
};

export interface IMoreOptionProps
    extends ReturnType<typeof mapDispatchToProps> {
  data: IBucketListItem;
}

export const MoreOption = connect(null, mapDispatchToProps)((props: IMoreOptionProps) => {
  const navigate = useNavigate();

  function openDeleteConfirmation() {
    props.startLoading();

    applicationService.getPage({ bucket: props.data.id }).then(page => {
      if (page.count > 0) {
        props.openDeleteBlockedConfirmation({ applications: page.data.map(x => ({ id: x.id, name: x.name })), bucket: props.data })
      }
      else {
        props.openDeleteConfirmation(props.data);
      }
    }).catch(catchException()).finally(props.stopLoading);
  }

  function getOptions(): IPopupMenuOption[] {
    const result = [
      {
        label: "Manage",
        onClick: () => navigate(routeUtils.getRouteToBucketDetails(props.data.id))
      },
      {
        label: "Delete",
        onClick: openDeleteConfirmation
      },
    ];
    return result;
  }

  return (
    <MoreOptions
      options={getOptions()}
    />
  );
});
