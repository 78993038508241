import { DateTime } from "luxon";
import _ from "underscore";
import { ISharingInstance, SharingInstancePermission } from "../api/entities/sharing-instance";

class Validators {
    validateSharingInstance(sharingInstance: ISharingInstance): string {
        if (!sharingInstance.users.some(user => user.permission === SharingInstancePermission.Manager)) {
            return "Please give at least one user the manage permission.";
        }
        return null;
    }

    isEmail(email: string): boolean {
        return /^[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+$/.test(email);
    }

    isPassword(password: string): boolean {
        return /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!@#$%^&*_=+.,/?~]+)/.test(password);
    }

    validDate(date: DateTime): string {
        return (!date || date.isValid) ? null : "Invalid date";
    }

    required(message?: string): (value: any) => string {
        let isEmpty: boolean;
        return (value) => {
            if (_.isString(value)) {
                value = value.trim();
                isEmpty = !value;
            }
            else if (_.isArray(value)) {
                isEmpty = value.length === 0;
            }
            else {
                isEmpty = !value;
            } 
            return isEmpty ? (message || "This field is required") : null;
        }
    }
}

export const validators = new Validators();
