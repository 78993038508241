import { DataService } from "./data-service";
import {
  IPolicy,
  IUpdateUsersRequest,
  IDeletePoliciesRequest,
  IPolicyShort,
  IPolicyUpdate,
  IPoliciesRequestParams,
  IPolicyListItem,
} from "../entities/policy";
import { IPage } from "../entities/page";
import { ApiVersion } from "./api-service";

class PolicyService extends DataService {
  constructor() {
    super("policy");
  }
  getPage(requestParams: IPoliciesRequestParams): Promise<IPage<IPolicyListItem>> {
    return this.api.get<IPage<IPolicy>>(null, requestParams, ApiVersion.V1_1);
  }
  getCompactList() {
    return this.api.get<IPolicyShort[]>("compact");
  }
  get(policyId: number) {
    return this.api.get<IPolicy>(policyId);
  }
  create(policyData: Partial<IPolicy>) {
    return this.api.post(null, policyData);
  }
  update(policyData: IPolicyUpdate) {
    return this.api.put(policyData.id, policyData);
  }
  delete(data: IDeletePoliciesRequest) {
    return this.api.delete(null, data);
  }
  updateUsers(policyId: number, data: IUpdateUsersRequest) {
    return this.api.post(`update-users/${policyId}`, data);
  }
}

export const policyService = new PolicyService();
