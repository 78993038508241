import React, { PropsWithChildren } from "react";
import styles from "./details-panel-footer.module.scss";
import { IStyleableComponentProps } from "../common/component-props";
import clsx from "clsx";
import Button from "../button";

interface IDetailsPanelFooterProps extends IStyleableComponentProps {
  sticky?: boolean;
  errorMessage?: string;
  saveButtonText?: string;
  onSaveButtonClick?: () => void;
  saveButtonDisabled?: boolean;
  cancelButtonText?: string;
  onCancelButtonClick?: () => void;
}

export default function DetailsPanelFooter(props: PropsWithChildren<IDetailsPanelFooterProps>) {
  return (
    <div className={clsx(styles.root, props.className, props.sticky && styles["root--sticky"])} style={props.style}>
      <div className={styles.content}>
        {props.children}        
          {props.onCancelButtonClick && (
            <Button
              testId="cancel-button"
              colors="link"
              onClick={props.onCancelButtonClick}
            >
              {props.cancelButtonText || "Cancel"}
            </Button>
          )}
          {props.onSaveButtonClick && (
            <Button
              className={styles["save-button"]}
              testId="save-button"
              colors="primary"
              disabled={props.saveButtonDisabled}
              onClick={props.onSaveButtonClick}
            >
              {props.saveButtonText || "Save"}
            </Button>
          )}
      </div>
      {props.errorMessage && (
        <div className={styles.error}>{props.errorMessage}</div>
      )}
    </div>
  );
}
