import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { EmailResendRequest, ResetPasswordRequest } from "../../../../api/entities/user";
import { KEYS } from "../../../../constants/keys";
import { accountThunkActions } from "../../../../redux/actions/account";
import { IAppState } from "../../../../redux/reducers";
import { formUtils, IFormField, useField } from "../../../../utils/form-utils";
import { useNavigate } from "../../../../utils/hooks/use-navigate";
import { validators } from "../../../../utils/validators";
import { ButtonGroupCountdown } from "../../../shared-ui/button-group-countdown/button-group-countdown";
import { InfoDialog } from "../../../shared-ui/dialogs/common/info-dialog/info-dialog";
import { FormField } from "../../../shared-ui/form/form-field/form-field";
import { FormPanel } from "../../../shared-ui/form/form-panel/form-panel";
import TextInput from "../../../shared-ui/input/text-input/text-input";
import styles from "./reset-password-request.module.scss";

export interface IResetPasswordRequestForm {
  email: IFormField<string>;
}

export interface IResetPasswordRequestFormValue {
  email: string;
}

export const resetPasswordRequestFormDefaultValue: IResetPasswordRequestFormValue = {
  email: "",
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IAppState, void, Action>
) => ({
  requestResetPassword: (resetPasswordRequest: ResetPasswordRequest) =>
      dispatch(accountThunkActions.requestResetPassword(resetPasswordRequest)),
  resendEmailResetPassword: (request: EmailResendRequest) =>
      dispatch(accountThunkActions.resendEmailResetPassword(request)),
});

const mapStateToProps = (state: IAppState) => ({
  user: state.users.currentUser
});

interface IResetPasswordRequestProps
    extends ReturnType<typeof mapStateToProps>,
        ReturnType<typeof mapDispatchToProps> {
  open: boolean;
  onClose: () => void;
}

export const ResetPasswordRequestDialog = connect(mapStateToProps, mapDispatchToProps)((props: IResetPasswordRequestProps) => {
  const navigate = useNavigate();
  const [isResetPasswordRequestSent, setIsResetPasswordRequestSent] = useState<boolean>(false);
  const [isEmailResent, setIsEmailResent] = useState<boolean>(false);

  const form: IResetPasswordRequestForm = {
    email: useField<string>(resetPasswordRequestFormDefaultValue.email, [validators.required("Email is required"), (value: string) => validators.isEmail(value) ? "" : `${value} is not a valid email address`]),
  };
  const formError = formUtils.getFormError(form);

  function handleResetPasswordRequest() {
    formUtils.validateAll(form).then((isValid) => {
      if (isValid) {
        const formValue = formUtils.getFormValue<IResetPasswordRequestFormValue>(form);
        props.requestResetPassword(formValue);
        setIsResetPasswordRequestSent(true);
      }
    });
  }

  function handleResendResetPasswordRequest() {
    const formValue = formUtils.getFormValue<IResetPasswordRequestFormValue>(form);

    setIsEmailResent(true);
    props.resendEmailResetPassword(formValue);
  }

  function handleEmailEditButtonClick() {
    formUtils.setFormValue(form, resetPasswordRequestFormDefaultValue);
    setIsResetPasswordRequestSent(false);
  }

  useEffect(() => {
    if (props.user) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [props.user]);


  return (
      <InfoDialog
          testId='sign-in-popup'
          title='Reset password for your account'
          showOkButton={false}
          open={props.open}
          onClose={props.onClose}
      >
        <div
            className={styles.content}
            onKeyDown={(e) => {
              if (e.key === KEYS.Escape) {
                props.onClose();
              }
              if (e.key === KEYS.Enter && !formError) {
                handleResetPasswordRequest();
              }
            }}
        >
          {!isResetPasswordRequestSent ?
              <FormPanel
                  className={styles.root}
                  okButtonText="Send Email"
                  okButtonClick={handleResetPasswordRequest}
                  okButtonDisabled={!!formError}
                  errorMessage={formError}
              >
                <FormField label="Email" testId="email-field">
                  <TextInput
                      className={styles.email}
                      testId="email-input"
                      field={form.email}
                      placeholder="name@host.com"
                      autoFocus={true}
                  />
                </FormField>
              </FormPanel>

              :

              <div>
                Email with confirmation link was sent to specified address if you are registered. Please check your inbox.

                <ButtonGroupCountdown
                    className={styles['btn-group']}
                    shouldStartCountdown={isResetPasswordRequestSent || isEmailResent}
                    onCountdownButtonStarted={() => setIsEmailResent(false)}
                    onOkButtonClicked={handleResendResetPasswordRequest}
                    onCancelButtonClicked={handleEmailEditButtonClick}

                    okButtonText="Resend"
                    cancelButtonText="Edit email"
                />
              </div>
          }
        </div>
      </InfoDialog>
  );
});
