import React from "react";
import manageStorageProfileStyles from "./manage-storage-profile.module.scss";
import TooltipQuestion from "../../shared-ui/tooltip-question";
import TooltipInput from "../../shared-ui/input/tooltip-input/tooltip-input";
import SidePanelSection from "../../shared-ui/side-panel-section/side-panel-section";
import YesNoButtonGroup from "../../shared-ui/yes-no-button-group/yes-no-button-group";
import { CONFIG } from "../../../config/config";

const TOOLTIP =
  "Both user and admin can be alerted when the storage capacity defined above reaches a certain percentage.";

interface INotificationsSectionProps {
  notify: boolean;
  amount: number;
  autoFocus?: boolean;

  onChange: (value: boolean) => void;
  onAmountChange: (value: number) => void;
}

export default function NotificationsSection(
  props: INotificationsSectionProps
) {
  function onNotifyChange(notify: boolean) {
    props.onChange(notify);
    if (!notify) {
      props.onAmountChange(null);
    }
  }
  function onAmountChange(value: string) {
    const result = Number(value);
    props.onAmountChange(!result || isNaN(result) ? null : result);
  }

  return (
    <SidePanelSection
      title="Notifications"
      testId="notifications"
      className={manageStorageProfileStyles["input--default"]}
    >
      <div
        data-testid="notifications-description"
        className={manageStorageProfileStyles.question}
      >
        Would you like to send notifications via email when users near capacity?
      </div>
      <YesNoButtonGroup
        autoFocus={props.autoFocus}
        value={props.notify}
        onChange={onNotifyChange}
        disabled={CONFIG.sandbox}
      />
      {props.notify && (
        <>
          <div className={manageStorageProfileStyles["sub-section"]}>
            <div
              className={manageStorageProfileStyles.question}
              data-testid="capacity-percentage-label"
            >
              When usage reaches this capacity percentage:
              <TooltipQuestion>{TOOLTIP}</TooltipQuestion>
            </div>
            <TooltipInput
              testId="capacity-percentage-field"
              type="number"
              min={0}
              max={100}
              value={props.amount}
              disabled={CONFIG.sandbox}
              tooltip={TOOLTIP}
              onChange={onAmountChange}
            />
          </div>
        </>
      )}
    </SidePanelSection>
  );
}
