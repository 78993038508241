import React, { PropsWithChildren } from "react";
import { IStyleableComponentProps } from "../../common/component-props";
import IOption from "../../common/option";
import { FiltersDataItem } from "../filters-data-item/filters-data-item";
import _ from "underscore";
import { FiltersDataBar } from "../filters-data-bar/filters-data-bar";

export type FiltersType<T> = Record<keyof T, string | IOption>;

interface IFiltersDataProps<TFilters extends FiltersType<TFilters>> extends IStyleableComponentProps {
  defaultFilters: TFilters;
  filters: TFilters;
  filterNames: { [key in keyof TFilters]: string };
  onFiltersChange: (filters: TFilters) => void;
}

export function FiltersData<TFilters extends FiltersType<TFilters>>(props: PropsWithChildren<IFiltersDataProps<TFilters>>) {
  function clearFilters() {
    props.onFiltersChange(props.defaultFilters);
  }

  function removeFilter(item: keyof TFilters) {
    let listOfFilters = { ...props.filters };
    listOfFilters[item] = null;
    props.onFiltersChange(listOfFilters);
  }

  function hasFilters(): boolean {
    return Object.values(props.filters).some(v => !!v && (_.isString(v) || (v as IOption).value))
  }


  const renderChips = () => {
    return Object.keys(props.filters).map((k) => {
      const filterKey = k as keyof TFilters;
      const filter = props.filters[filterKey];
      if (filter) {
        if (_.isString(filter)) {
          return (
            <FiltersDataItem
              key={k}
              label={props.filterNames[k] ? `${props.filterNames[k]}: ${filter}` : filter as string}
              onDelete={() => removeFilter(filterKey)}
            />
          );
        }
        else {
          const filterOption = filter as IOption;
          return (
            <FiltersDataItem
              key={k}
              label={`${props.filterNames[k]}: ${filterOption.label || ""}`}
              onDelete={() => removeFilter(filterKey)}
            />
          );
        }
      } 
      return null;
    });
  };

  return (hasFilters() || null) && (
    <FiltersDataBar clearFilter={clearFilters}>
      {renderChips()}
    </FiltersDataBar>
  );
}
