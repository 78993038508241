import { IFileStats } from "./file-stats";
import { ISharingProfileListItem } from './sharing-profile';
import { IRequestParams } from "./page";
import { IPolicyShort, IPolicy } from "./policy";
import { IDevice } from "./device";
import { ISharingInstanceShort } from './sharing-instance';
import { IDataMigrationBase } from './data-migration';
import { IIdName } from './id-name';

export enum UserStatus {
  Active = "active",
  Blocked = "blocked",
  Pending = "pending",
}

export enum BlockUserStatus {
  Block = "block",
  Active = "active",
}

export enum UserSortField {
  UserStatus = "userstatus",
  Email = "email",
  UsedSpacePercentage = "usedSpacePercentage",
  FileStatus = "filestatus",
  Profile = "profile",
  Devices = "devices",
  Group = "group",
}

export enum UserDataStatus {
  Corrupt = 1,
  Recoverable = 2,
  Safe = 3,
  Recovered = 4,
}

export const userDataStatusToTextMap = {
  [UserDataStatus.Safe]: "Safe",
  [UserDataStatus.Recoverable]: "Recoverable",
  [UserDataStatus.Corrupt]: "Corrupt",
  [UserDataStatus.Recovered]: "Recovered",
};

export interface IUserLoginInfo {
  uid: string;
  name: string;
  email: string;
  organization?: string;
  accountType: AccountType;
  isAdmin?: boolean;
}

export interface IEntityOwnerInfo extends Pick<IUserLoginInfo, "name" | "email"> {
  id: string;
  isActivated: boolean;
}

export interface IUserShort extends IUserLoginInfo {
  id: number;
  status: UserStatus;
}

export interface IUserFileStats extends IFileStats {
  totalFileCount: number;
}

export interface IUserCommon extends IUserShort, IUserFileStats {
  admin: number;
  type: number;
  Devices: IDevice[];
  dataMigrations?: IDataMigrationBase[];
  Groups: IIdName[];
  repoURI: string;
}

export interface IUser extends IUserCommon {
  Policy: IPolicyShort;
}

export interface IUserDetails extends IUserCommon {
  Policy: IPolicy;
  SharingPolicies: ISharingProfileListItem[];
  SharingInstances: ISharingInstanceShort[];
}

export interface IUserFilterParams {
  statusFilter?: UserStatus;
  policyFilter?: number;
  sharingProfileFilter?: number;
  q?: string;
  excludeUserFilter?: number[];
  userGroupFilter?: number;
}

export interface IUsersRequestParams
  extends IRequestParams<UserSortField>,
    IUserFilterParams {
  fields?: (keyof IUser)[]
}

export const userListFields: (keyof IUser)[] = ["id", "uid", "email", "status", "dataMigrations", "Policy", "Devices", "usedSpacePercentage", "fileStatus"];

export interface IAuthenticationRequest {
  email: string;
  password: string;
}

export interface ICreateAccountRequest extends IAuthenticationRequest {
  email: string;
  name: string;
  password: string;
  passwordConfirmation: string;
}

export type ResetPasswordRequest = Pick<IAuthenticationRequest, 'email'>;

export type AccountConfirmationRequest = ResetPasswordRequest & {
  token: string;
}

export type AccountPasswordUpdateRequest = AccountConfirmationRequest & Pick<ICreateAccountRequest, 'password' | 'passwordConfirmation'>;

export type EnterpriseAccountConfirmationTokenRequest = AccountPasswordUpdateRequest & Pick<ICreateAccountRequest, 'name'>;

export type EmailResendRequest = ResetPasswordRequest;

export enum AccountType {
  Individual = 1,
  Enterprise = 2
}

export type ChangeAccountTypeRequest = ResetPasswordRequest & {
  accountType: AccountType;
  organizationName: string;
}