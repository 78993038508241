import { DEVICES_ROUTE } from './../../constants/routes';
import { catchException, commonActions, handleApi } from "./common";
import {
  IDevice,
  IDevicesRequestParams,
  IDeviceCompact,
  DeviceSortField,
  IDeviceListItem,
} from "../../api/entities/device";
import { devicesService } from "../../api/services/devices-service";
import { IDeviceDenyAccessConfirmationData } from "../../components/devices/dialogs/device-deny-access-confirmation/device-deny-access-confirmation";
import { devicePerformanceService } from "../../api/services/device-performance-service";
import { IDevicePerformanceSearchParams } from "../../api/entities/device-performance";
import { IAppState } from "../reducers";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { matchPath } from "react-router-dom";
import { USERS_FILTER_ROUTE, USERS_ROUTE } from "../../constants/routes";
import { usersThunkActions } from './users';
import { EntityActions, EntityThunkActions } from './entity';
import { DialogActions } from './dialog';
import { IDeviceAllowAccessConfirmationData } from '../../components/devices/dialogs/device-allow-access-confirmation/device-allow-access-confirmation';

export enum DevicesActionType {
  FetchDetailsRequest = "DEVICES_FETCH_DETAILS_REQUEST",
  FetchDetailsSuccess = "DEVICES_FETCH_DETAILS_SUCCESS",
  FetchDetailsFail = "DEVICES_FETCH_DETAILS_FAIL",
  ClearDetails = "DEVICES_CLEAR_DETAILS",
  DeleteSuccess = "DEVICES_DELETE_SUCCESS",
  FetchTypesRequest = "DEVICES_FETCH_TYPES_REQUEST",
  FetchTypesSuccess = "DEVICES_FETCH_TYPES_SUCCESS",
  FetchTypesFail = "DEVICES_FETCH_TYPES_FAIL",
}

export const DEVICE_ENTITY_NAME = "devices";
export const DENY_ACCESS_CONFIRMATION = "denyAccessConfirmation";
export const ALLOW_ACCESS_CONFIRMATION = "allowAccessConfirmation"
export const AUTHORIZE_CONFIRMATION = "authorizeConfirmation"
export const AUTHORIZED_INFO = "authorizedInfo"
export const ACCESS_ALLOWED_INFO = "accessAllowedInfo";
export const ACCESS_DENIED_INFO = "accessDeniedInfo";

class DevicesActions {
  readonly entity = new EntityActions<IDeviceListItem, IDevicesRequestParams>(DEVICE_ENTITY_NAME);
  readonly denyAccessConfirmation = new DialogActions<IDeviceDenyAccessConfirmationData>(DEVICE_ENTITY_NAME, DENY_ACCESS_CONFIRMATION);
  readonly allowAccessConfirmation = new DialogActions<IDeviceAllowAccessConfirmationData>(DEVICE_ENTITY_NAME, ALLOW_ACCESS_CONFIRMATION);
  readonly authorizeConfirmation = new DialogActions<IDeviceCompact>(DEVICE_ENTITY_NAME, AUTHORIZE_CONFIRMATION);
  readonly authorizedInfo = new DialogActions<IDeviceCompact>(DEVICE_ENTITY_NAME, AUTHORIZED_INFO);
  readonly accessAllowedInfo = new DialogActions<IDeviceCompact>(DEVICE_ENTITY_NAME, ACCESS_ALLOWED_INFO);
  readonly accessDeniedInfo = new DialogActions<IDeviceCompact>(DEVICE_ENTITY_NAME, ACCESS_DENIED_INFO);

  fetchDetailsRequest(deviceId: number) {
    return {
      type: DevicesActionType.FetchDetailsRequest as typeof DevicesActionType.FetchDetailsRequest,
      deviceId
    };
  }
  fetchDetailsSuccess(deviceDetail: IDevice) {
    return {
      type: DevicesActionType.FetchDetailsSuccess as typeof DevicesActionType.FetchDetailsSuccess,
      deviceDetail,
    };
  }
  fetchDetailsFail() {
    return {
      type: DevicesActionType.FetchDetailsFail as typeof DevicesActionType.FetchDetailsFail,
    };
  }
  clearDetails() {
    return {
      type: DevicesActionType.ClearDetails as typeof DevicesActionType.ClearDetails,
    };
  }
  deleteSuccessAction(deviceId: number) {
    return {
      type: DevicesActionType.DeleteSuccess as typeof DevicesActionType.DeleteSuccess,
      deviceId,
    };
  }
  fetchDeviceTypesRequestAction() {
    return {
      type: DevicesActionType.FetchTypesRequest as typeof DevicesActionType.FetchTypesRequest,
    };
  }
  fetchDeviceTypesSuccessAction(data: string[]) {
    return {
      type: DevicesActionType.FetchTypesSuccess as typeof DevicesActionType.FetchTypesSuccess,
      payload: data,
    };
  }
  fetchDeviceTypesFailAction() {
    return {
      type: DevicesActionType.FetchTypesFail as typeof DevicesActionType.FetchTypesFail,
    };
  }
}

export const devicesActions = new DevicesActions();

export type DevicesAction = 
  | ReturnType<typeof devicesActions.fetchDetailsRequest>
  | ReturnType<typeof devicesActions.fetchDetailsSuccess>
  | ReturnType<typeof devicesActions.fetchDetailsFail>
  | ReturnType<typeof devicesActions.clearDetails>
  | ReturnType<typeof devicesActions.deleteSuccessAction>
  | ReturnType<typeof devicesActions.fetchDeviceTypesRequestAction>
  | ReturnType<typeof devicesActions.fetchDeviceTypesSuccessAction>
  | ReturnType<typeof devicesActions.fetchDeviceTypesFailAction>
;

class DevicesThunkActions {
  readonly entity = new EntityThunkActions<IDeviceListItem, DeviceSortField, IDevicesRequestParams>(
    DEVICE_ENTITY_NAME, devicesService)

  fetchDeviceTypes() {
    return dispatch => {
      dispatch(devicesActions.fetchDeviceTypesRequestAction());
      handleApi(devicesService
        .getDeviceTypes()
        .then(deviceTypes => {
          dispatch(devicesActions.fetchDeviceTypesSuccessAction(deviceTypes));
        }), false, apiError => {
          dispatch(devicesActions.fetchDeviceTypesFailAction());
          catchException()(apiError);
        });
    };
  }

  fetchDevicePerformance(
    searchParams: IDevicePerformanceSearchParams
  ) {
    return dispatch => {
      return handleApi(devicePerformanceService.getList(searchParams));
    };
  }

  //-------------------Fetching Device details-------//
  fetchDeviceData(deviceId: number) {
    return dispatch => {
      dispatch(devicesActions.fetchDetailsRequest(deviceId));
      handleApi(devicesService
        .getDevice(deviceId)
        .then(data => {
          dispatch(devicesActions.fetchDetailsSuccess(data));
        }), false, (e) => {
          dispatch(devicesActions.fetchDetailsFail());
          catchException()(e);
        });
    };
  }

  //-----------------api call for unBlock devices-------------------//
  unBlockDevice(id: number) {
    return dispatch => {
      return handleApi(devicesService
        .unBlockDevice(id)
        .then(_ => {
          dispatch(this.refreshOpenedDevicesOrUsers());
          dispatch(commonActions.setConfirmationFlag(false));
        }), true);
    };
  }

  //-----------------api call for block devices-------------------//
  blockDevice(id: number) {
    return dispatch => {
      return handleApi(devicesService
        .blockDevice(id)
        .then(_ => {
          dispatch(this.refreshOpenedDevicesOrUsers());
          dispatch(commonActions.setConfirmationFlag(false));
        }), true);
    };
  }

  //-----------------api call for force logout device-------------------//
  logoutDevice(id: number) {
    return dispatch => {
      dispatch(commonActions.closeAlert());
      handleApi(devicesService
        .logoutDevice(id)
        .then(_ => {
          dispatch(commonActions.setConfirmationFlag(false));
          dispatch(this.refreshOpenedDevicesOrUsers());
        }), true);
    };
  }

  //-----------------api call for Delete device-------------------//
  deleteDevice(id: number) {
    return dispatch => {
      dispatch(commonActions.closeAlert());
      handleApi(devicesService
        .deleteDevice(id)
        .then(_ => {
          dispatch(
            commonActions.showSnackBar(true, "Device successfully deleted.")
          );
          dispatch(devicesActions.deleteSuccessAction(id));
          dispatch(this.refreshOpenedDevicesOrUsers());
        }), true);
    };
  }

  //----------------api call to approve Multiple Devices-----------//
  approveMultipleDevices(ids: number[]) {
    const POSTFIX = ids.length > 1 ? "s" : "";
    return dispatch => {
      handleApi(devicesService
        .approveDevice(ids)
        .then(_ => {
          dispatch(
            commonActions.showSnackBar(
              true,
              `Device${POSTFIX} successfully Approved.`
            )
          );
          dispatch(this.entity.fetchPage());
          dispatch(
            commonActions.dialogSuccess(
              true,
              `These device${POSTFIX} are now allowed to Access Myota.`,
              `Device${POSTFIX} Authorized`
            )
          );
          dispatch(commonActions.setConfirmationFlag(false));
        }), true);
    };
  }

  //----------------api call to approve/disapprove devices-----------//
  approveDevice(device: IDeviceCompact) {
    return dispatch => {
      return handleApi(devicesService
        .approveDevice(device.id)
        .then(_ => {
          dispatch(devicesActions.authorizedInfo.open(device))
          dispatch(this.refreshOpenedDevicesOrUsers());
        }), true);
    };
  }

  //-----------------api call for force logout Multiple devices-------------------//
  logoutMultipleDevices(ids: number[]) {
    return dispatch => {
      dispatch(commonActions.closeAlert());
      handleApi(devicesService
        .logoutDevice(ids)
        .then(_ => {
          dispatch(this.entity.fetchPage());
        }), true);
    };
  }

  //-----------------api call for unblock listing Multiple devices-------------------//
  unBlockMultipleDevice(ids: number[]) {
    return dispatch => {
      handleApi(devicesService
        .unBlockDevice(ids)
        .then(_ => {
          dispatch(this.entity.fetchPage());
          dispatch(
            commonActions.dialogSuccess(
              true,
              "The following devices now have access to Myota.",
              "Devices Allowed"
            )
          );
          dispatch(commonActions.setConfirmationFlag(false));
        }), true);
    };
  }

  //-----------------api call for block Multiple devices-------------------//
  blockMultipleDevice(ids: number) {
    return dispatch => {
      handleApi(devicesService
        .blockDevice(ids)
        .then(_ => {
          dispatch(this.entity.fetchPage());
          dispatch(
            commonActions.dialogSuccess(
              true,
              "These devices have been denied access to Myota.",
              "Devices Denied"
            )
          );
          dispatch(commonActions.setConfirmationFlag(false));
        }), true);
    };
  }

  //-----------------api call for Deleting Multiple devices-------------------//
  deleteMultipleDevice(ids: number) {
    return dispatch => {
      dispatch(commonActions.closeAlert());
      handleApi(devicesService
        .deleteDevice(ids)
        .then(_ => {
          dispatch(this.entity.fetchPage());
        }), true);
    };
  }

  refreshOpenedDevicesOrUsers(): ThunkAction<void, IAppState, void, Action> {
    return (dispatch, getState) => {
      const state = getState();
      if (state.users.userDetail?.uid) {
        dispatch(usersThunkActions.fetchUserDetail(state.users.userDetail.uid));
      }
      if (matchPath(window.location.pathname, [USERS_ROUTE, USERS_FILTER_ROUTE])) {
        dispatch(usersThunkActions.entity.fetchPage());
      }
      if (state.devices.deviceDetail?.id) {
        dispatch(this.fetchDeviceData(state.devices.deviceDetail.id));
      }
      if (matchPath(window.location.pathname, DEVICES_ROUTE)) {
        dispatch(this.entity.fetchPage());
      }
    };
  }
}

export const devicesThunkActions = new DevicesThunkActions();
