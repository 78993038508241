import { AnyAction } from "redux";
import { StorageNodeV2ListItem } from "../../api/entities/storage-node-v2";
import { IDeleteBlockedConfirmationData } from "../../components/v2/storage-nodes/dialogs/delete-blocked-confirmation/delete-blocked-confirmation";
import { DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME, DELETE_CONFIRMATION_DIALOG_NAME, getStorageNodeEntityName } from "../actions/storage-node";
import { createDialogReducer, DIALOG_DEFAULT_STATE } from "./dialog";
import { IDialogState } from "./index";

export interface IStorageNodeState {
    [DELETE_CONFIRMATION_DIALOG_NAME]: IDialogState<StorageNodeV2ListItem>;
    [DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME]: IDialogState<IDeleteBlockedConfirmationData>;
}

function getBucketDefaultState(): IStorageNodeState {
    return {
        [DELETE_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
        [DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
    };
}

const getDeleteConfirmationReducer = () => createDialogReducer(getStorageNodeEntityName(), DELETE_CONFIRMATION_DIALOG_NAME);
const getDeleteBlockedConfirmationReducer = () => createDialogReducer(getStorageNodeEntityName(), DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME);


export function storageNode(state: IStorageNodeState = getBucketDefaultState(),
                       action: AnyAction): IStorageNodeState {
    state = getDeleteConfirmationReducer()(state, action);
    state = getDeleteBlockedConfirmationReducer()(state, action);
    return state;
}
