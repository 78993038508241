import { AnyAction } from "redux";
import { IBucketListItem } from "../../api/entities/bucket";
import { IDeleteBlockedConfirmationData } from "../../components/v2/buckets/dialogs/delete-blocked-confirmation/delete-blocked-confirmation";
import { DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME, DELETE_CONFIRMATION_DIALOG_NAME, getBucketEntityName } from "../actions/bucket";
import { createDialogReducer, DIALOG_DEFAULT_STATE } from "./dialog";
import { IDialogState } from "./index";

export interface IBucketState {
    [DELETE_CONFIRMATION_DIALOG_NAME]: IDialogState<IBucketListItem>;
    [DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME]: IDialogState<IDeleteBlockedConfirmationData>;
}

function getBucketDefaultState(): IBucketState {
    return {
        [DELETE_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
        [DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
    };
}

const getDeleteConfirmationReducer = () => createDialogReducer(getBucketEntityName(), DELETE_CONFIRMATION_DIALOG_NAME);
const getDeleteBlockedConfirmationReducer = () => createDialogReducer(getBucketEntityName(), DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME);


export function bucket(state: IBucketState = getBucketDefaultState(),
                       action: AnyAction): IBucketState {
    state = getDeleteConfirmationReducer()(state, action);
    state = getDeleteBlockedConfirmationReducer()(state, action);
    return state;
}
