import React, { useState } from "react";
import IComponentProps from "../../../shared-ui/common/component-props";
import { MONTH_DAYS, YEAR_DAYS } from "../../../../constants/common";
import TooltipInput from "../../../shared-ui/input/tooltip-input/tooltip-input";
import { TimeLengthUnit } from "../../../../api/entities/policy";
import inputWithUnitsStyles from "../../../shared-ui/input/input-with-units/input-with-units.module.scss";
import manageStorageProfileStyles from "../manage-storage-profile.module.scss";
import clsx from "clsx";
import { Select } from "../../../shared-ui/select";

interface ITimeIntervalSelectorProps extends IComponentProps {
  tooltip: string;
  value: number;
  onChange: (value?: number) => void;
}

export default function TimeIntervalSelector(
  props: ITimeIntervalSelectorProps
) {
  const [multiplier, setMultiplier] = useState(getMultiplier(props.value));
  const timeSpan = getTimespan(props.value, multiplier);

  function getMultiplier(days: number): number {
    if (days && days % YEAR_DAYS === 0) {
      return YEAR_DAYS;
    } else if (days && days % MONTH_DAYS === 0) {
      return MONTH_DAYS;
    } else {
      return 1;
    }
  }

  function getTimespan(days: number, multiplier: number): number {
    return Math.ceil(days / multiplier);
  }

  function onMultiplierChanged(multiplier: number): void {
    setMultiplier(multiplier);
    if (timeSpan) {
      props.onChange(timeSpan * multiplier);
    }
  }

  return (
    <div data-testid={props.testId}>
      <TooltipInput
        className={clsx(
          inputWithUnitsStyles.input,
          manageStorageProfileStyles["input--default"]
        )}
        testId="tooltip-input"
        type="number"
        value={timeSpan > 0 ? timeSpan : ""}
        onChange={v => props.onChange(Number(v) * multiplier)}
        disabled={props.disabled}
        tooltip={props.tooltip}
      />
      <Select
        testId="select"
        value={multiplier}
        options={[
          { label: TimeLengthUnit.Days, value: 1 },
          { label: TimeLengthUnit.Months, value: MONTH_DAYS },
          { label: TimeLengthUnit.Years, value: YEAR_DAYS },
        ]}
        onChange={onMultiplierChanged}
        disabled={props.disabled}
        autoWidth
      />
    </div>
  );
}
