import { AccountConfirmationRequest, AccountPasswordUpdateRequest, ChangeAccountTypeRequest, EmailResendRequest, EnterpriseAccountConfirmationTokenRequest, IAuthenticationRequest, ICreateAccountRequest, IUserLoginInfo, ResetPasswordRequest } from "../entities/user";
import { DataService } from "./data-service";

class AccountService extends DataService {
  constructor() {
    super("account", 2);
  }

  login(accountRequest: IAuthenticationRequest) {
    return this.api.post<IUserLoginInfo>("session", accountRequest);
  }

  logout() {
    return this.api.post<{ msg: string }>("logout");
  }

  getCurrentUser() {
    return this.api.get<IUserLoginInfo>("get-current");
  }

  signUp(accountRequest: ICreateAccountRequest) {
    return this.api.post<{ msg: string }>("sign-up", accountRequest);
  }

  requestResetPassword(resetPasswordRequest: ResetPasswordRequest) {
    return this.api.post<{ msg: string }>("request-reset-password", resetPasswordRequest);
  }

  resetPassword(accountPasswordUpdateRequest: AccountPasswordUpdateRequest) {
    return this.api.post<{ msg: string }>("reset-password", accountPasswordUpdateRequest);
  }

  confirmAccount(accountConfirmationRequest: AccountConfirmationRequest) {
    return this.api.post<{ msg: string }>("confirmation", accountConfirmationRequest);
  }

  confirmEnterpriseAccount(request: EnterpriseAccountConfirmationTokenRequest) {
    return this.api.post<{ msg: string }>("confirmation-enterprise", request);
  }

  resendEmailResetPassword(request: EmailResendRequest) {
    return this.api.post<{ msg: string }>("email-resend/reset-password", request);
  }

  resendEmailAccountConfirmation(request: EmailResendRequest) {
    return this.api.post<{ msg: string }>("email-resend/confirmation", request);
  }

  changeAccountType(request: ChangeAccountTypeRequest) {
    return this.api.post<{ msg: string }>("change-account-type", request);
  }

  validateOrganization(name: string) {
    return this.api.post<{ msg: string }>("validate-organization", { name });
  }
}

export const accountService = new AccountService();
