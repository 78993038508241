import { CSSProperties } from "@mui/styles/withStyles";

export interface ITestableComponent {
  testId?: string;
}

export interface IStyleableComponentProps {
  className?: string;
  style?: CSSProperties;
}

export default interface IComponentProps
  extends ITestableComponent,
    IStyleableComponentProps {
  disabled?: boolean;
}

export enum CompactViewScreen {
  None = "none",
  Small = "small",
  Medium = "medium"
}
