import { AnyAction } from 'redux';
import { IDialogState } from '.';
import { SortDirection } from '../../api/entities/page';
import { IStorageNode, IStorageNodeCredentialsConfirmationData } from '../../api/entities/storage-node';
import { IStorageNodeV2 } from "../../api/entities/storage-node-v2";
import { IStoragePoolsV2RequestParams, IStoragePoolV2ListItem, StoragePoolV2SortField } from "../../api/entities/storage-pool-v2";
import { ApiVersionNumber } from '../../api/services/data-service';
import { IDeleteBlockedConfirmationDataV1, IDeleteBlockedConfirmationDataV2 } from '../../components/storage-pools/dialogs/delete-blocked-confirmation/delete-blocked-confirmation';
import { DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME, DELETE_CONFIRMATION_DIALOG_NAME, REPLACE_STORAGE_CONFIRMATION, getStoragePoolEntityName, CREDENTIALS_INFO } from '../actions/storage-pool';
import { IStoragePoolShort, IStoragePoolsRequestParams, StoragePoolSortField } from '../../api/entities/storage-pool';
import { createDialogReducer, DIALOG_DEFAULT_STATE } from './dialog';
import { createEntityReducer, getEntityDefaultState, IEntityState } from './entity';

export interface IStoragePoolV1State extends IEntityState<IStoragePoolShort, StoragePoolSortField, IStoragePoolsRequestParams> {
  [DELETE_CONFIRMATION_DIALOG_NAME]: IDialogState<IStoragePoolShort>;
  [DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME]: IDialogState<IDeleteBlockedConfirmationDataV1>;
  [REPLACE_STORAGE_CONFIRMATION]: IDialogState<IStorageNode>;
}

export interface IStoragePoolV2State extends IEntityState<IStoragePoolV2ListItem, StoragePoolV2SortField, IStoragePoolsV2RequestParams> {
  [DELETE_CONFIRMATION_DIALOG_NAME]: IDialogState<IStoragePoolV2ListItem>;
  [DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME]: IDialogState<IDeleteBlockedConfirmationDataV2>;
  [REPLACE_STORAGE_CONFIRMATION]: IDialogState<IStorageNodeV2>;
  [CREDENTIALS_INFO]: IDialogState<IStorageNodeCredentialsConfirmationData>;
}

function getStoragePoolDefaultState<T extends IStoragePoolV1State | IStoragePoolV2State>(sortField: string): T {
  return {
    [DELETE_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
    [REPLACE_STORAGE_CONFIRMATION]: DIALOG_DEFAULT_STATE,
    [DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME]: DIALOG_DEFAULT_STATE,
    ...getEntityDefaultState(sortField, SortDirection.Ascending)
  } as T;
}

function getStoragePoolV2DefaultState(sortField: string): IStoragePoolV2State {
  return {
    ...getStoragePoolDefaultState(sortField),
    [CREDENTIALS_INFO]: DIALOG_DEFAULT_STATE
  }
}

const getEntityReducer = (apiVersion: ApiVersionNumber) => createEntityReducer(getStoragePoolEntityName(apiVersion));
const getDeleteConfirmationReducer = (apiVersion: ApiVersionNumber) => createDialogReducer(getStoragePoolEntityName(apiVersion), DELETE_CONFIRMATION_DIALOG_NAME);
const getDeleteBlockedConfirmationReducer = (apiVersion: ApiVersionNumber) => createDialogReducer(getStoragePoolEntityName(apiVersion), DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME);
const getReplaceStorageConfirmationReducer = (apiVersion: ApiVersionNumber) => createDialogReducer(getStoragePoolEntityName(apiVersion), REPLACE_STORAGE_CONFIRMATION);
const getExcessiveCredentialsConfirmationReducer = () => createDialogReducer(getStoragePoolEntityName(2), CREDENTIALS_INFO);

export function storagePoolV1(
  state: IStoragePoolV1State = getStoragePoolDefaultState(StoragePoolSortField.Name),
  action: AnyAction
): IStoragePoolV1State {
  state = getEntityReducer(1)(state, action);
  state = getDeleteConfirmationReducer(1)(state, action);
  state = getDeleteBlockedConfirmationReducer(1)(state, action);
  state = getReplaceStorageConfirmationReducer(1)(state, action);
  return state;
}

export function storagePoolV2(
  state: IStoragePoolV2State = getStoragePoolV2DefaultState(StoragePoolV2SortField.Name),
  action: AnyAction
): IStoragePoolV2State {
  state = getEntityReducer(2)(state, action);
  state = getDeleteConfirmationReducer(2)(state, action);
  state = getDeleteBlockedConfirmationReducer(2)(state, action);
  state = getReplaceStorageConfirmationReducer(2)(state, action);
  state = getExcessiveCredentialsConfirmationReducer()(state, action);
  return state;
}
