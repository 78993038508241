import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IStoragePoolFilterParams, IStoragePoolsRequestParams } from "../../api/entities/storage-pool";
import { IStoragePoolsV2RequestParams } from "../../api/entities/storage-pool-v2";
import { ApiVersionNumber } from "../../api/services/data-service";
import { storagePoolV1ThunkActions, storagePoolV2ThunkActions } from "../../redux/actions/storage-pool";
import { IAppState } from "../../redux/reducers";
import { useFilters } from "../../utils/hooks/use-filter";
import { useNavigate } from "../../utils/hooks/use-navigate";
import { routeUtils } from "../../utils/route-utils";
import Button from "../shared-ui/button";
import RefreshButton from "../shared-ui/button/refresh-button";
import { FiltersData } from "../shared-ui/filters-data/filters-data/filters-data";
import PageTitle from "../shared-ui/pageTitle";
import { DeleteBlockedConfirmation } from "./dialogs/delete-blocked-confirmation/delete-blocked-confirmation";
import { DeleteConfirmation } from "./dialogs/delete-confirmation/delete-confirmation";
import { defaultFilters, filterNames, IStoragePoolFilters, StoragePoolFilters, storagePoolFilterTypeMap } from "./filters/filters";
import { StoragePoolPanel } from "./panels/storage-pool-panel/storage-pool-panel";
import { StoragePoolRestoreFilesPanel } from "./panels/storage-pool-restore-files-panel/storage-pool-restore-files-panel";
import { StorageNodePanelContainer } from "./storage-node-panel-container/storage-node-panel-container";
import { StoragePoolsTable } from "./storage-pools-table/storage-pools-table";
import styles from "./storage-pools.module.scss";

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IAppState, void, Action>
) => {
    return {
        fetchPageV1: (params: IStoragePoolsRequestParams) =>
            dispatch(storagePoolV1ThunkActions.entity.fetchPage(params)),
        fetchPageV2: (params: IStoragePoolsV2RequestParams) =>
            dispatch(storagePoolV2ThunkActions.entity.fetchPage(params)),
    };
};

interface IStoragePoolsProps
    extends ReturnType<typeof mapDispatchToProps> {
  version: ApiVersionNumber;
}

export const VersionContext = React.createContext(1 as ApiVersionNumber);

export const StoragePools = connect(null, mapDispatchToProps)((props: IStoragePoolsProps) => {
    const navigate = useNavigate();
    const [filters, , updateQuery] = useFilters(
        defaultFilters,
        requestData,
        storagePoolFilterTypeMap,
        filtersToQueryParams,
        queryParamsToFilters
    );

    const fetchPage = props.version === 1 ? props.fetchPageV1 : props.fetchPageV2;

    const handleAddStoragePoolFormPanelOpen = () => navigate(routeUtils.getRouteToNewStoragePool(props.version));
    const handleAddStoragePoolFormPanelClose = () => navigate(routeUtils.getRouteToStoragePoolList(props.version));

    function filtersToQueryParams(filters: IStoragePoolFilters): IStoragePoolFilterParams {
        return {
            q: filters.searchText
        };
    }

    function queryParamsToFilters(queryParams: Partial<IStoragePoolFilterParams>): IStoragePoolFilters {
        return {
            searchText: queryParams.q
        };
    }

    function requestData(filters: IStoragePoolFilters) {
        fetchPage({
            ...filtersToQueryParams(filters),
            offset: 0
        });
    }

    return (
        <>
            <div className={styles.header}>
                <PageTitle title={`Storage Pool Management`}>
                    <RefreshButton onClick={() => fetchPage({})} />
                </PageTitle>
                <Button
                    className={styles.add}
                    responsive={true}
                    testId="new-storage-pool-button"
                    colors="primary"
                    onClick={handleAddStoragePoolFormPanelOpen}
                >
                    <AddIcon />
                    <span className="hidden-on-small-screen">New Storage Pool</span>
                    <span className={styles["add__md"]}>Add New</span>
                </Button>
            </div>


            <VersionContext.Provider value={props.version}>
                <StoragePoolFilters
                    filters={filters}
                    onFiltersChange={updateQuery}
                />
                <FiltersData
                    filterNames={filterNames}
                    defaultFilters={defaultFilters}
                    filters={filters}
                    onFiltersChange={updateQuery}
                />
                <StoragePoolsTable/>
                <StoragePoolPanel
                    embedded={false}
                    onClose={handleAddStoragePoolFormPanelClose}
                    version={props.version}
                />
                <StoragePoolRestoreFilesPanel/>
                <StorageNodePanelContainer/>
                <DeleteConfirmation/>
                <DeleteBlockedConfirmation/>
            </VersionContext.Provider>
        </>
    );

});
