import React from "react";
import { IPage } from "../../../../api/entities/page";
import { StorageNodeSortField } from "../../../../api/entities/storage-node";
import { IStorageNodesV2RequestParams } from "../../../../api/entities/storage-node-v2";
import { useNavigate } from "../../../../utils/hooks/use-navigate";
import { routeUtils } from "../../../../utils/route-utils";
import { storageUtils } from "../../../../utils/storage-utils";
import Table, { IColumnDefinition, TableSelected } from "../../../shared-ui/table/table";
import tableStyles from "../../../shared-ui/table/table.module.scss";
import { IStorageNodeListItem } from "../../../storage-pools/panels/select-storage-nodes-panel/select-storage-nodes-panel";
import { MoreOption } from "./moreoption";
import { IAppState } from "../../../../redux/reducers";
import { connect } from "react-redux";
import { getOwnerColumn } from "../../common/sections/owner-column/owner-column";
import { AccountType } from "../../../../api/entities/user";

const mapStateToProps = (state: IAppState) => ({
    accountType: state.users.currentUser.accountType,
});

export interface IStorageNodeTableProps
    extends ReturnType<typeof mapStateToProps> {
    isLoading: boolean;
    page: IPage<IStorageNodeListItem>;
    requestParams: IStorageNodesV2RequestParams;
    setRequestParams: (data: Partial<IStorageNodesV2RequestParams>) => void;

    selection?: TableSelected<IStorageNodeListItem>;
    onSelectionChanged?: (selection: TableSelected<IStorageNodeListItem>) => void;
    onSelectAll?: () => void;

    isRowClickable?: boolean;
    hasManageColumn?: boolean;
}

export const StorageNodeTable = connect(mapStateToProps, null)((props: IStorageNodeTableProps) => {
    const navigate = useNavigate();
    const columns: IColumnDefinition<IStorageNodeListItem, StorageNodeSortField>[] = [
        {
            sortKey: StorageNodeSortField.Name,
            label: "Name",
            formatter: row => row.name
        } as IColumnDefinition<IStorageNodeListItem, StorageNodeSortField>,
        {
            sortKey: StorageNodeSortField.Provider,
            label: "Provider",
            formatter: row => storageUtils.getStorageProviderName(row.storageType)
        } as IColumnDefinition<IStorageNodeListItem, StorageNodeSortField>,
        {
            sortKey: StorageNodeSortField.Bucket,
            label: "Bucket / Container",
            formatter: row => row.bucket
        } as IColumnDefinition<IStorageNodeListItem, StorageNodeSortField>,
        {
            label: "Storage Pools",
            formatter: row => row.storagePools && row.storagePools.map(x => <div>{x.name}</div>),
        } as IColumnDefinition<IStorageNodeListItem, StorageNodeSortField>,
    ];

    if (props.accountType === AccountType.Enterprise) {
        columns.push(getOwnerColumn(null));
    }

    if (props.hasManageColumn) {
        columns.push(
            {
                label: "Manage",
                className: tableStyles["col--manage"],
                formatter: row => (<MoreOption data={row}/>),
            });
    }

    return (
        <Table
            testId="storage-node-table"
            hideCheckbox={!props.selection}
            columns={columns}
            rows={props.page.data}
            totalRows={props.page.count}
            loading={props.isLoading}
            requestParams={props.requestParams}
            onRequestParamsChanged={props.setRequestParams}
            onRowClick={row => !!props.isRowClickable && navigate(routeUtils.getRouteToStorageNodeDetails(row.id.toString()))}
            responsive={true}

            selected={props.selection}
            onSetSelected={props.onSelectionChanged}
            onSelectAll={props.onSelectAll}
        />
    );
});
