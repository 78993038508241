import React, { useState, useEffect, useRef } from "react";
import styles from "./select.module.scss";
import IComponentProps from "../common/component-props";
import IOption from "../common/option";
import clsx from "clsx";
import { IS_MOBILE } from "../../../constants/common";
import PopupMenu from "../popup-menu";
import { PopupType } from "../popup";
import { IFormField } from "../../../utils/form-utils";
import { testUtils } from "../../../utils/test-utils";
import { KEYS } from "../../../constants/keys";

type SelectValue = string | number | boolean;
type SelectOption<T> = SelectValue | IOption<T>;

export interface ISelectProps<T> extends IComponentProps {
  value?: T;
  options: SelectOption<T>[];
  label?: string;
  placeholder?: string;
  autoWidth?: boolean;
  autoFocus?: boolean;
  field?: IFormField<T>;

  onChange?: (value: T) => void;
}

export function Select<T extends SelectValue>(props: ISelectProps<T>) {
  const rootRef = useRef<HTMLFieldSetElement>(null);
  const value = props.field ? props.field.value : props.value;
  const disabled = props.field ? props.field.isDisabled : props.disabled;
  const onChange = props.field ? props.field.onChange : props.onChange;
  const [selectedOption, setSelectedOption] = useState<SelectOption<T>>(null),
    [showPopup, setShowPopup] = useState(false);

  const isOptionObject = (option: SelectOption<T>): option is IOption => {
      switch (typeof option) {
        case "string":
        case "number":
        case "boolean":
          return false;
      }
      return true;
    },
    getOptionLabel = (option: SelectOption<T>): string => {
      let label;
      if (isOptionObject(option)) {
        label = "label" in option ? option.label : option.value;
      } else {
        label = option;
      }
      return String(label);
    },
    getOptionValue = (option: SelectOption<T>): T => {
      if (isOptionObject(option)) {
        return option.value;
      } else {
        return option as T;
      }
    },
    toItemValue = (value: ReturnType<typeof getOptionValue>) => {
      return JSON.stringify(value ?? null);
    },
    fromItemValue = (value: string): T => {
      return value ? JSON.parse(value) : null;
    },
    onClick = () => {
      if (!disabled) {
        setShowPopup(true);
      }
    },
    onOptionClick = (option: SelectOption<T>) => {
      onChange(getOptionValue(option));
      closePopup();
    },
    onKeyDown = (e: React.KeyboardEvent<HTMLFieldSetElement>) => {
      if (!showPopup && (e.key === KEYS.ArrowDown || e.key === KEYS.Enter)) {
        setShowPopup(true);
      }
    },
    closePopup = () => {
      setShowPopup(false);
      rootRef.current.focus();
    };

  useEffect(() => {
    if (!props.options) {
      setSelectedOption(null);
      return;
    }
    const itemValue = toItemValue(value);
    setSelectedOption(
      props.options.find(o => toItemValue(getOptionValue(o)) === itemValue)
    );
    // eslint-disable-next-line
  }, [props.options, value]);

  useEffect(() => {
    if (props.autoFocus) {
      rootRef.current.focus();
    }
    // eslint-disable-next-line
  }, []);

  return IS_MOBILE ? (
    <select
      value={toItemValue(value)}
      className={clsx(
        styles.root,
        {
          [styles["root--fit-parent"]]: !props.autoWidth,
        },
        props.className
      )}
      style={props.style}
      disabled={disabled}
      data-testid={props.testId || testUtils.stringToTestId(props.label)}
      onChange={e => onChange(fromItemValue(e.target.value))}
    >
      {!selectedOption && <option>{props.placeholder}</option>}
      {props.options.map(option => {
        const value = getOptionValue(option);
        return (
          <option key={JSON.stringify(option)} value={toItemValue(value)}>
            {getOptionLabel(option)}
          </option>
        );
      })}
    </select>
  ) : (
    <fieldset
      className={clsx(
        styles.root,
        {
          [styles["root--disabled"]]: disabled,
          [styles["root--fit-parent"]]: !props.autoWidth,
          [styles["root--label"]]: !!props.label,
        },
        props.className
      )}
      data-testid={props.testId || testUtils.stringToTestId(props.label)}
      style={props.style}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={onKeyDown}
      ref={rootRef}
    >
      {!!props.label && (
        <legend className={styles.legend}>{props.label}</legend>
      )}
      <div 
        className={styles.content} 
      >
        <div
          className={clsx(styles.selected, {
            [styles["selected--placeholder"]]: !selectedOption,
          })}
        >
          {!!selectedOption
            ? getOptionLabel(selectedOption)
            : props.placeholder}
        </div>
        <div className={styles.icon}></div>
      </div>
      <PopupMenu
        show={showPopup}
        onClose={closePopup}
        popupType={PopupType.FitToParent}
        options={props.options.map(option => {
          return {
            value: getOptionValue(option),
            label: getOptionLabel(option),
            onClick: () => onOptionClick(option),
          };
        })}
        className={styles.popup}
      />
    </fieldset>
  );
}
