import { IPolicyStorageUsage } from "../../api/entities/dashboard";
import { DashboardAction, DashboardActionType } from "../actions/dashboard";
import { IAsyncDataState, ASYNC_DATA_DEFAULT_STATE } from "./common";

export interface IDashboardState {
  policyStorageUsage: IAsyncDataState<IPolicyStorageUsage[]>;
}

export const DASHBOARD_DEFAULT_STATE: IDashboardState = {
  policyStorageUsage: ASYNC_DATA_DEFAULT_STATE,
};

export default function dashboard(
  state = DASHBOARD_DEFAULT_STATE,
  action: DashboardAction
): IDashboardState {
  switch (action.type) {
    case DashboardActionType.PolicyStorageUsageRequest:
      return {
        ...state,
        policyStorageUsage: {
          ...ASYNC_DATA_DEFAULT_STATE,
          loading: true
        },
      }
    case DashboardActionType.PolicyStorageUsageSuccess:
      return {
        ...state,
        policyStorageUsage: {
          loading: false,
          data: action.data
        },
      }
    case DashboardActionType.PolicyStorageUsageFail:
      return {
        ...state,
        policyStorageUsage: {
          ...ASYNC_DATA_DEFAULT_STATE,
          loading: false,
        },
      }

    default:
      return state;
  }
}
