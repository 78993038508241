import React from "react";
import { IHasOwner } from "../../../../../api/entities/id-name";
import styles from "./details-owner-info.module.scss";

export interface IOwnerInfoSectionProps {
    entity: IHasOwner;
}

export function OwnerInfoSection(props: IOwnerInfoSectionProps) {
    const { entity } = props;

    return (
        !!entity.owner
            ? <section className={styles.root}>
                <label>Created By:</label>

                <div className={styles.email}>
                    {entity.owner.email}
                    {!entity.owner.isActivated && <span>&nbsp;(deactivated)</span>}
                </div>
                <div className={styles.name}>{entity.owner.name}</div>
            </section>
            : <></>
    )
}