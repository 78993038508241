import React, { PropsWithChildren, ReactNode } from "react";
import {
  IStyleableComponentProps,
  ITestableComponent,
} from "../../common/component-props";
import clsx from "clsx";
import styles from "./form-section.module.scss";

export interface IFormSectionProps
  extends IStyleableComponentProps,
    ITestableComponent {
  title: ReactNode;
  description?: ReactNode;
}

export default function FormSection(
  props: PropsWithChildren<IFormSectionProps>
) {
  return (
    <div
      className={clsx(styles.root, props.className)}
      style={props.style}
      data-testid={props.testId}
    >
      <div className={styles.header}>
        <h3 className={styles.title} data-testid="title">
          {props.title}
        </h3>
        <div className={styles.divider}></div>
      </div>
      {props.description && (
        <div className={styles.description}>
          {props.description}
        </div>
      )}
      {props.children}
    </div>
  );
}
