import React, { useState } from "react";
import styles from "./date-picker.module.scss";
import IComponentProps from "../../common/component-props";
import clsx from "clsx";
import { IFormField } from "../../../../utils/form-utils";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { DesktopDatePicker } from "@mui/lab";
import { IS_MOBILE } from "../../../../constants/common";
import { DateTime } from "luxon";
interface IDatePickerProps extends IComponentProps {
  testId: string;
  placeholder?: string;
  autoFocus?: boolean;
  field: IFormField<DateTime>;
  maxDate?: DateTime;
  minDate?: DateTime;
}

export function DatePicker(props: IDatePickerProps) {
  const value = props.field.value;
  const onChange = props.field.onChange;
  const [showPicker, setShowPicker] = useState<boolean>(false);

  function handleMobileDateChange(isoDate: string): void {
    onChange(DateTime.fromISO(isoDate));
  }

  return (
    <>
      {IS_MOBILE && (
        <input
          type="date"
          autoComplete="off"
          autoFocus={props.autoFocus}
          data-testid={props.testId}
          placeholder={props.placeholder}
          className={styles["mobile-input"]}
          disabled={props.disabled}
          style={props.style}
          value={props.field.value ? (props.field.value.toISODate() || "") : ""}
          min={props.minDate ? props.minDate.toISODate() : null}
          max={props.maxDate ? props.maxDate.toISODate() : null}
          onChange={e => handleMobileDateChange(e.target.value)}
        />
      )}

      {!IS_MOBILE && (
        <div className={clsx(styles.root, props.className)}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DesktopDatePicker
              minDate={props.minDate}
              maxDate={props.maxDate}
              value={value}
              onChange={onChange}
              open={showPicker}              
              onOpen={() => setShowPicker(true)}
              onClose={() => setShowPicker(false)}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <div className={styles["input-container"]}>
                  <input
                    className={styles.input}
                    ref={inputRef} 
                    {...inputProps} 
                    placeholder={props.placeholder}
                    autoFocus={props.autoFocus}                    
                    onClick={() => setShowPicker(true)}
                  />
                  <div className={styles.icon}>
                    {InputProps?.endAdornment}
                  </div>
                </div>
              )}
            />
          </LocalizationProvider>
        </div>
      )}
    </>
  );
}
