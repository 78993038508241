import { IPolicyListItem } from "../api/entities/policy";
import { dataMigrationUtils } from "./data-migration-utils";

export class PolicyUtils {
    canSelectPolicy(policy: IPolicyListItem): boolean {
        return !dataMigrationUtils.hasActiveMigrations(policy); 
    }
    canEditPolicy(policy: IPolicyListItem): boolean {
        return !dataMigrationUtils.hasActiveMigrations(policy); 
    }
    canManagePolicy(policy: IPolicyListItem): boolean {
        return !dataMigrationUtils.hasActiveMigrations(policy); 
    }
}

export const policyUtils = new PolicyUtils();