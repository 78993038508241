import React, { PropsWithChildren, useEffect, useRef } from "react";
import styles from "./side-panel.module.scss";
import clsx from "clsx";
import {
  IStyleableComponentProps,
  ITestableComponent,
} from "../common/component-props";
import { KEYS } from "../../../constants/keys";

export enum SidePanelPosition {
  Top = "top",
  Right = "right",
  Bottom = "bottom",
  Left = "left",
}

interface ISidePanelProps extends IStyleableComponentProps, ITestableComponent {
  open: boolean;
  side: SidePanelPosition;
  rootClass?: string;
  autoFocus?: boolean;
  testId: string;

  onOutsideClick?: () => void;
  onEscape?: () => void;
}

export function SidePanel(props: PropsWithChildren<ISidePanelProps>) {
  const backdropRef = useRef(null);
  const panelRef = useRef(null);

  useEffect(() => {
    if (props.autoFocus && props.open) {
      panelRef.current.focus();
    }
  }, [props.open, props.autoFocus]);

  return (
    <div
      ref={backdropRef}
      className={clsx(
        styles.root,
        {
          [styles["root--open"]]: props.open,
        },
        styles[`root--${props.side}`],
        props.rootClass
      )}
      style={props.style}
      data-testid={props.testId}
      onMouseDown={(e) => { 
        if (e.target === backdropRef.current) {
          props.onOutsideClick && props.onOutsideClick();
        }
      }}
    >
      <div
        className={clsx(styles.content, props.className)}
        ref={panelRef}
        onKeyDown={(e) => {
          props.onEscape && e.key === KEYS.Escape && props.onEscape();
        }}
        tabIndex={-1}
      >
        {props.children}
      </div>
    </div>
  );
}
