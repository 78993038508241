import { colors } from "./colors";
import { createTheme } from '@mui/material/styles'
import { Shadows } from "@mui/material/styles/shadows";
import type {} from '@mui/lab/themeAugmentation';

const FONT_FAMILY = {
  fontFamily: "inherit",
};

export const theme = createTheme({
  palette: {
    primary: {
      light: "#4b4b4b",

      main: colors.bluishGrey,
      dark: colors.smaltBlue,
      contrastText: colors.white
    },
    secondary: {
      main: "#8d8d8d",
    },
    text: {
      primary: colors.darkBlueGrey,
      secondary: colors.darkBlueGrey,
  },
  },
  typography: {
    fontFamily: "inherit",
  },
  shadows: Array(25).fill("none") as Shadows,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "Open Sans",
          fontSize: 14,
          lineHeight: "normal"
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: colors.pestoGray,
          color: colors.white,
          display: "flex",
          flexDirection: "row-reverse",
          height: 26,
        },
        deleteIcon: {
          width: 12,
          height: 12,
          backgroundColor: colors.white,
          borderRadius: 6,
          color: colors.geminiGray,
          margin: "auto -7px auto 7px",
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        elevation8: {
          boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)"
        } 
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "unset",
        },
        paperWidthSm: {
          maxWidth: "none"
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0 28px 24px 24px",
        },
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0 34px 26px 34px",
        },
        spacing: {
          "& > :not(:first-child)": {
            marginLeft: 23,
          }
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...FONT_FAMILY,
        },
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...FONT_FAMILY,
          "&:hover": {
            backgroundColor: colors.bluishGrey,
            color: colors.white,
          }
        },
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        menuItem: {
          "&.Mui-selected": {
            "&:hover": {
              backgroundColor: colors.bluishGrey,
              color: colors.white,
            },
          },
          "&:hover": {
            backgroundColor: colors.bluishGrey,
            color: colors.white,
          }
        }
      }
    }
  },
});
