import { useHistory, useLocation } from "react-router-dom";
import _ from "underscore";
import { queryUtils } from "../query-utils";

export function useNavigate(): <TQuery extends object>(pathname: string, query?: string | Partial<TQuery>) => void {
    const history = useHistory();
    const { search, pathname } = useLocation();

    function getPageName(pathname: string): string {
      const names = pathname.split("/", 2/**because first entry can be empty if pathname starts with '/' */).filter(name => !!name);
      return names.length && names[0];
    }

    function navigate<TQuery extends object>(url: string, query?: string | Partial<TQuery>) {
      var newPathname = url;

      if (url.indexOf("?") >= 0) {
        if (query) {
          throw new Error(`Query parameters specified twice for ${url}`);
        }
        const [urlPath, urlQuery] = newPathname.split("?");
        newPathname = urlPath;
        query = urlQuery;
      }

      const currentPage = getPageName(pathname);
      const newPage = getPageName(newPathname);

      if (query === undefined || newPage === currentPage) {
        history.push({ pathname: newPathname, search });
      }
      else {
        const queryStr: string = query ? (_.isString(query) ? query : queryUtils.toQuery(query)) : null
        history.push({ pathname: newPathname, search: queryStr });
      }
    }
  return navigate;
}