import React, { useEffect } from "react";
import styles from "./time-picker.module.scss";
import IComponentProps from "../../common/component-props";
import clsx from "clsx";
import { IFormField } from "../../../../utils/form-utils";
import { DAY_HOURS, HALF_DAY_HOURS, HOUR_MINUTES, IS_MOBILE } from "../../../../constants/common";
import { NumberInput } from "../number-input/number-input";
import Button from "../../button";
import { DateTime } from "luxon";

export interface ITime12 {
  hour: number;
  minute: number;
  isAm: boolean;
}

interface ITime24 {
  hour: number;
  minute: number;
}

export interface ITimePickerProps extends IComponentProps {
  testId: string;
  field: IFormField<ITime12>;
  mobileView?: boolean;
  maxTime?: ITime12;
  minTime?: ITime12;
}

function time24To12(time24: ITime24): ITime12 {
  const isAm = time24.hour < HALF_DAY_HOURS;
  if (time24.hour >= DAY_HOURS || time24.minute >= HOUR_MINUTES) { 
    throw new Error("Invalid time");
  }
  return { 
    isAm, 
    hour: isAm ? 
      (time24.hour === 0 ? HALF_DAY_HOURS : time24.hour) :
      (time24.hour === HALF_DAY_HOURS ? HALF_DAY_HOURS : time24.hour - HALF_DAY_HOURS), 
    minute: time24.minute
  };
}

export function time12To24(time12: ITime12): ITime24 {
  const hour = time12.isAm ? 
  time12.hour % HALF_DAY_HOURS : 
  time12.hour === HALF_DAY_HOURS ? HALF_DAY_HOURS : time12.hour + HALF_DAY_HOURS;
  return { hour, minute: time12.minute }
}

function parseTime24String(time24Str: string): ITime12 {
  if (time24Str) {
    const [hour, minute] = time24Str.split(":").map(Number);
    return time24To12({ hour, minute });
  }
  return null;
}

function formatTime(minuteOrHour: number): string {
  if (minuteOrHour < 10) {
    return `0${minuteOrHour}`;
  }
  return minuteOrHour.toString();
}

function toTime24String(time12: ITime12): string {
  if (time12) {
    const time24 = time12To24(time12);
    return `${formatTime(time24.hour)}:${formatTime(time24.minute)}`;
  }
  return "";
}

function getTotalMinutes(time12: ITime12): number {
  const time24 = time12To24(time12);
  return time24.hour * HOUR_MINUTES + time24.minute;
}

export function dateToTime12(date: Date): ITime12 {
  const dt = DateTime.fromJSDate(date);
  return time24To12({ hour: dt.hour, minute: dt.minute });
}

export function TimePicker(props: ITimePickerProps) {
  const value = props.field.value;
  const onChange = props.field.onChange;

  function handleMobileDateChanged(time24Str: string) {
    if (time24Str) {
      const time12 = parseTime24String(time24Str);
      onChange(applyMinMax(time12, false));
    }
    else {
      onChange(null);
    }
  }

  function applyMinMax(time12: ITime12, raiseOnChange: boolean): ITime12 {
    if (time12 && props.minTime) {
      if (getTotalMinutes(time12) < getTotalMinutes(props.minTime)) {
        const newTime = { ...props.minTime};
        raiseOnChange && onChange(newTime);
        return newTime;
      }
    }


    if (time12 && props.maxTime) {
      if (getTotalMinutes(time12) > getTotalMinutes(props.maxTime)) {
        const newTime = { ...props.maxTime};
        raiseOnChange && onChange(newTime);
        return newTime;
      }
    }
    return time12;
  }

  function canSwitchToPm() {
    return !props.maxTime || !props.maxTime.isAm;
  }

  function canSwitchToAm() {
    return !props.minTime || props.minTime.isAm;
  }

  function onAmPmChange(toAm: boolean) {
    const toPm = !toAm;
    if ((toPm && value.isAm && canSwitchToPm()) || 
        (toAm && !value.isAm && canSwitchToAm())) {
      onChange(applyMinMax({...value, isAm: toAm }, false));
    }
  }

  useEffect(() => {
    applyMinMax(value, true);
  // eslint-disable-next-line
  }, [props.maxTime, props.minTime])

  return (
    <>
      {IS_MOBILE && (
        <input
          type="time"
          className={styles["mobile-input"]}
          data-testid={props.testId}
          value={toTime24String(value)}
          onChange={(e) => handleMobileDateChanged(e.target.value)}
          min={toTime24String(props.minTime)}
          max={toTime24String(props.maxTime)}
        />
      )}
      {!IS_MOBILE && (
        <div className={clsx(styles.root, props.className)}>
          <div className={styles["time-inputs"]}>
            <NumberInput
              testId="hour"
              className={styles.hour}
              value={value.hour}
              formatFn={formatTime}
              min={1}
              max={HALF_DAY_HOURS}
              onChange={(hour) => onChange({...value, hour})}
              onBlur={(hour) => applyMinMax({...value, hour}, true)}
            />
            <span className={styles["time-splitter"]}>:</span>
            <NumberInput
              testId="minute"
              className={styles.minute}
              value={value.minute}
              formatFn={formatTime}
              min={0}
              max={HOUR_MINUTES - 1}
              onChange={(minute) => onChange({...value, minute})}
              onBlur={(minute) => applyMinMax({...value, minute}, true)}
            />
          </div>
          <div className={styles["am-pm"]}>
            <Button
              testId="am"
              colors="secondary"
              selected={value.isAm}
              onClick={() => onAmPmChange(true)}
            >
              AM
            </Button>
            <Button
              testId="pm"
              colors="secondary"
              className={styles["pm-button"]}
              selected={!value.isAm}
              onClick={() => onAmPmChange(false)}
            >
              PM
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
