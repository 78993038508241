import React from "react";
import Button from "../../../../../shared-ui/button";
import styles from "./sent-files.module.scss";

export interface ISentFilesProps {
  url: string;
}

export function SentFiles(props: ISentFilesProps) {
  return (
    <div>
      <h1 className={styles["email-sent-header"]}>
        Share with Citrix ShareFile
      </h1>
      <div className={styles["sent-files"]}>
        {"Your file(s) have been securely sent. "}
        <Button testId="view-files-link" colors="link" className={styles["sent-files-link"]} href={props.url}>
          View sent files
        </Button>
      </div>
    </div>
  );
}