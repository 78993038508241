import React from "react";
import { IStoragePoolsV2RequestParams, IStoragePoolV2ListItem, StoragePoolV2SortField } from "../../../api/entities/storage-pool-v2";
import { useNavigate } from "../../../utils/hooks/use-navigate";
import { routeUtils } from "../../../utils/route-utils";
import Table, { IColumnDefinition, TableSelected } from "../../shared-ui/table/table";
import tableStyles from "../../shared-ui/table/table.module.scss";
import { MoreOption } from "./moreoption";
import styles from "./storage-pools-table.module.scss";
import { IPage } from "../../../api/entities/page";

interface IStoragePoolTableProps {
    isLoading: boolean;
    page: IPage<IStoragePoolV2ListItem>;
    requestParams: IStoragePoolsV2RequestParams;
    setRequestParams: (data: Partial<IStoragePoolsV2RequestParams>) => void;

    selection?: TableSelected<IStoragePoolV2ListItem>;
    onSelectionChanged?: (selection: TableSelected<IStoragePoolV2ListItem>) => void;
    onSelectAll?: () => void;

    isRowClickable?: boolean;
    hasManageColumn?: boolean;
}

export function StoragePoolsV2Table(props: IStoragePoolTableProps) {
    const navigate = useNavigate();

    const columns: IColumnDefinition<IStoragePoolV2ListItem, StoragePoolV2SortField>[] = [
        {
            label: "Name",
            className: styles["col--name"],
            formatter: row => row.name,
            sortKey: StoragePoolV2SortField.Name
        },
        {
            label: "Id",
            className: styles["col--id"],
            formatter: row => row.id,
            sortKey: StoragePoolV2SortField.Id
        },
    ];

    if (props.hasManageColumn) {
        columns.push(
            {
                label: "Manage",
                className: tableStyles["col--manage"],
                formatter: row => (<MoreOption data={row}/>),
            });
    }


    return (
        <Table
            testId="storage-pool-table"
            hideCheckbox={!props.selection}
            columns={columns}
            rows={props.page.data}
            totalRows={props.page.count}
            loading={props.isLoading}
            requestParams={props.requestParams}
            onRequestParamsChanged={props.setRequestParams}
            onRowClick={row => !!props.isRowClickable && navigate(routeUtils.getRouteToBucketDetails(row.id))}
            responsive={true}

            selected={props.selection}
            onSetSelected={props.onSelectionChanged}
            onSelectAll={props.onSelectAll}

        />
    );
}
