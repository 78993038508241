import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { EmailResendRequest, ICreateAccountRequest } from "../../../../api/entities/user";
import { KEYS } from "../../../../constants/keys";
import { accountActions, accountThunkActions } from "../../../../redux/actions/account";
import { IAppState } from "../../../../redux/reducers";
import { formUtils, IFormField, useField } from "../../../../utils/form-utils";
import { useNavigate } from "../../../../utils/hooks/use-navigate";
import { validators } from "../../../../utils/validators";
import { ButtonGroupCountdown } from "../../../shared-ui/button-group-countdown/button-group-countdown";
import { InfoDialog } from "../../../shared-ui/dialogs/common/info-dialog/info-dialog";
import { FormField } from "../../../shared-ui/form/form-field/form-field";
import { FormPanel } from "../../../shared-ui/form/form-panel/form-panel";
import { PasswordInput } from "../../../shared-ui/input/password-input/password-input";
import TextInput from "../../../shared-ui/input/text-input/text-input";
import { signInFormDefaultValue } from "../signin/signin";
import styles from "./signup.module.scss";

export interface ISignUpForm {
  email: IFormField<string>;
  name: IFormField<string>;
  password: IFormField<string>;
  passwordConfirmation: IFormField<string>;
}

export interface ISignUpFormValue {
  email: string;
  name: string;
  password: string;
  passwordConfirmation: string;
}

export const signUpFormDefaultValue: ISignUpFormValue = {
  email: "",
  name: "",
  password: "",
  passwordConfirmation: ""
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IAppState, void, Action>
) => ({
  signUp: (accountRequest: ICreateAccountRequest) =>
      dispatch(accountThunkActions.signUp(accountRequest)),
  removeSignUpError: () =>
      dispatch(accountActions.removeSignUpErrorAction()),
  clearAccountConfirmationEmailSendingStatus: () =>
      dispatch(accountActions.clearAccountConfirmationEmailSendingStatusAction()),
  resendEmailAccountConfirmation: (request: EmailResendRequest) =>
      dispatch(accountThunkActions.resendEmailAccountConfirmation(request))
});

const mapStateToProps = (state: IAppState) => ({
  user: state.users.currentUser,
  signUpFailed: state.account.signUpFailed,
  signUpError: state.account.signUpError,
  accountConfirmationEmailSent: state.account.accountConfirmationEmailSent,
});

interface ISignInProps
    extends ReturnType<typeof mapStateToProps>,
            ReturnType<typeof mapDispatchToProps> {
  open: boolean;
  onClose: () => void;
}

export const SignUp = connect(mapStateToProps, mapDispatchToProps)((props: ISignInProps) => {
  const navigate = useNavigate();
  const [isEmailResent, setIsEmailResent] = useState<boolean>(false);

  const form: ISignUpForm = {
    email: useField<string>(signInFormDefaultValue.email, [
      validators.required("Email is required"),
      (value: string) => validators.isEmail(value) ? "" : `${value} is not a valid email address`
    ]),
    name: useField<string>(signUpFormDefaultValue.name, [validators.required("Name is required")]),
    password: useField<string>(signUpFormDefaultValue.password, [
      validators.required("Password is required"),
      (value: string) => validators.isPassword(value) ? "" : `Provided password is not valid`
    ]),
    passwordConfirmation: useField<string>(signUpFormDefaultValue.passwordConfirmation,
        [validators.required("Password confirmation is required"), comparePasswords]
    )
  };
  const formError = formUtils.getFormError(form);

  function comparePasswords(value: string): string {
    return form.password.value !== value ? "Passwords do not match" : ""
  }

  function handleSignUp() {
    formUtils.validateAll(form).then((isValid) => {
      if (isValid) {
        props.removeSignUpError();
        const formValue = formUtils.getFormValue<ISignUpFormValue>(form);
        props.signUp(formValue);
      }
    });
  }

  function handleResendAccountConfirmationRequest() {
    const formValue = formUtils.getFormValue<ISignUpFormValue>(form);

    setIsEmailResent(true);
    props.resendEmailAccountConfirmation(formValue);
  }

  function handleEmailEditButtonClick() {
    formUtils.setFormValue(form, signUpFormDefaultValue);
    props.clearAccountConfirmationEmailSendingStatus();
  }

  function handleClose() {
    props.clearAccountConfirmationEmailSendingStatus();
    props.removeSignUpError();
    props.onClose();

    formUtils.setFormValue(form, signUpFormDefaultValue);
  }

  useEffect(() => {
    if (props.user) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  useEffect(() => {
    if (props.signUpFailed || props.accountConfirmationEmailSent) {
      props.clearAccountConfirmationEmailSendingStatus();
      props.removeSignUpError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.email.value, form.name.value, form.password.value, form.passwordConfirmation.value]);

  return (
      <InfoDialog
          testId='sign-up-popup'
          title='Sign up'
          showOkButton={false}
          open={props.open}
          onClose={handleClose}
      >
        <div
            className={styles.content}
            onKeyDown={(e) => {
              if (e.key === KEYS.Escape) {
                handleClose();
              }
              if (e.key === KEYS.Enter && !formError) {
                handleSignUp();
              }
            }}
        >
          {!props.accountConfirmationEmailSent ?
              <FormPanel
                  className={styles.root}
                  okButtonText="Sign up"
                  okButtonClick={handleSignUp}
                  okButtonDisabled={!!formError}
                  errorMessage={formError ||
                      (props.signUpFailed && (props.signUpError ?? "Unable to create an account. Please retry later or address the issue to administrator."))
                  }
              >
                <FormField label="Email" testId="email-field">
                  <TextInput
                      className={styles.email}
                      testId="email-input"
                      field={form.email}
                      placeholder="name@host.com"
                      autoFocus={true}
                  />
                </FormField>

                <FormField label="Name" testId="name-field">
                  <TextInput
                      className={styles.name}
                      testId="name-input"
                      field={form.name}
                      placeholder="Name Surname"
                  />
                </FormField>

                <FormField label="Password" testId="password-field">
                  <PasswordInput
                      className={styles.password}
                      testId="password-input"
                      field={form.password}
                      placeholder="Password"
                  />
                </FormField>

                <FormField label="Password Confirmation" testId="password-confirmation-field">
                  <PasswordInput
                      className={styles.passwordConfirmation}
                      testId="password-confirmation-input"
                      field={form.passwordConfirmation}
                      placeholder="Password Confirmation"
                  />
                </FormField>


                <div className={styles["password-validation-rules-desc"]}>
                  Password should be the length of 8 or longer and should contain at least one character out of all of the following categories:
                  <ul>
                    <li>Lower case and upper case english letters</li>
                    <li>Digit</li>
                    <li>Special symbol, such as <span>!@#$%^&*_=+.,/?~</span></li>
                  </ul>
                </div>
              </FormPanel>

              :

              <div className={styles['confirmation-email-message']}>
                Email address confirmation is required in order to proceed. A message was sent to you. Please check your inbox.

                <ButtonGroupCountdown
                    className={styles['btn-group']}
                    shouldStartCountdown={props.accountConfirmationEmailSent || isEmailResent}
                    onCountdownButtonStarted={() => setIsEmailResent(false)}
                    onOkButtonClicked={handleResendAccountConfirmationRequest}
                    onCancelButtonClicked={handleEmailEditButtonClick}

                    okButtonText="Resend"
                    cancelButtonText="Edit email"
                />
              </div>
          }
        </div>
      </InfoDialog>
  );
});
