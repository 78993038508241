import React, { ReactNode } from "react";
import MUTooltip from "@mui/material/Tooltip";
import {
  IStyleableComponentProps,
  ITestableComponent,
} from "../common/component-props";
import styles from "./tooltip.module.scss";

interface ITooltipProps extends IStyleableComponentProps, ITestableComponent {
  title: ReactNode;
  children: React.ReactElement;
  open?: boolean;
}

export default function Tooltip(props: ITooltipProps) {
  const otherProps =
    props.open != null
      ? {
          disableFocusListener: true,
          disableHoverListener: true,
          disableTouchListener: true,
        }
      : {};

  return (
    <MUTooltip
      classes={{
        tooltip: styles.root,
        arrow: styles.arrow,
        popper: styles.popper,
      }}
      data-testid={props.testId}
      style={props.style}
      title={props.title}
      placement="top-start"
      open={props.open}
      arrow
      {...otherProps}
    >
      {props.children}
    </MUTooltip>
  );
}
