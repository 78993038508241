import React, { useContext } from "react";
import { TableSelected } from "../../shared-ui/table/table";
import { TableToolbar } from "../../shared-ui/table-toolbar/table-toolbar";
import Button from "../../shared-ui/button";
import { IStoragePoolShort } from "../../../api/entities/storage-pool";
import { storagePoolUtils } from "../../../utils/storage-pool-utils";
import { routeUtils } from "../../../utils/route-utils";
import { useNavigate } from "../../../utils/hooks/use-navigate";
import { VersionContext } from "../storage-pools";


export interface IToolbarProps {
  selected: TableSelected<IStoragePoolShort>,
  onClose: () => void;
}

export function Toolbar(props: IToolbarProps) {
  const selectedPools = Object.values(props.selected);
  const selectionCount = selectedPools.length;
  const navigate = useNavigate();
  const version = useContext(VersionContext);

  function canRestoreFiles() {
    return storagePoolUtils.canRestoreFiles(version);
  }


  return (
    <TableToolbar
      text={`${selectionCount} Storage Pool${selectionCount > 1 ? "s" : ""} selected`}
      open={selectionCount > 0}
      onClose={props.onClose}
    >
      {canRestoreFiles() && (
        <Button
          testId="restore-files-button"
          colors="secondary"
          onClick={() => navigate(routeUtils.getRouteToStoragePoolsRestoreFiles())}
        >
          Restore Files
        </Button>
      )}
    </TableToolbar>
  );
}
