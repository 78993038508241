import { IPage } from "../entities/page";
import { IBlackoutListItem, ISharingInstanceBlackoutCreateRequest, IStoragePoolBlackoutCreateRequest, IUserBlackoutCreateRequest, ISharingInstanceBlackoutsRequestParams, IStoragePoolBlackoutsRequestParams, IUserBlackoutsRequestParams } from "../entities/blackouts";
import { DataService } from "./data-service";

class RestoreFileService extends DataService {

  constructor() {
    super("");
  }

  createUserBlackout(request: IUserBlackoutCreateRequest): Promise<void> {
    return this.api.post<void>("user/blackout", request);
  }

  createStoragePoolBlackout(request: IStoragePoolBlackoutCreateRequest): Promise<void> {
    return this.api.post<void>("storagepool/blackout", request);
  }

  createSharingInstanceBlackout(request: ISharingInstanceBlackoutCreateRequest): Promise<void> {
    return this.api.post<void>("sharing/instance/blackout", request);
  }

  getUserBlackouts(uid: string, request?: IUserBlackoutsRequestParams): Promise<IPage<IBlackoutListItem>> {
    return this.api.get<IPage<IBlackoutListItem>>(`user/${uid}/blackout`, request);
  }
  
  getStoragePoolBlackouts(storagePoolId: number, request?: IStoragePoolBlackoutsRequestParams): Promise<IPage<IBlackoutListItem>> {
    return this.api.get<IPage<IBlackoutListItem>>(`storagepool/${storagePoolId}/blackout`, request);
  }

  getSharingInstanceBlackouts(sharingInstanceId: number, request?: ISharingInstanceBlackoutsRequestParams): Promise<IPage<IBlackoutListItem>> {
    return this.api.get<IPage<IBlackoutListItem>>(`sharing/instance/${sharingInstanceId}/blackout`, request);
  }

  deleteUserBlackout(blackoutId: number): Promise<void> {
    return this.api.delete<void>(`user/blackout/${blackoutId}`, null);
  }

  deleteStoragePoolBlackout(blackoutId: number): Promise<void> {
    return this.api.delete<void>(`storagepool/blackout/${blackoutId}`, null);
  }

  deleteSharingInstanceBlackout(blackoutId: number): Promise<void> {
    return this.api.delete<void>(`sharing/instance/blackout/${blackoutId}`, null);
  }
}

export const restoreFileService = new RestoreFileService();
