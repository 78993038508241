import { IIdName } from './id-name';
import { IDeletedParam, IQueryStrictParams, IRequestParams } from './page';
import { StorageProviderType } from "./storage-node-v2";
import { IStoragePool, IStoragePoolSecurityLevel } from "./storage-pool";

export interface IStoragePoolV2ListItem extends IIdName<string> {
}

export interface IStoragePoolV2DetailsItem extends IStoragePoolV2ListItem {
    contentRedundancyLevel: number;
    keyRedundancyLevel: number;
    metaRedundancyLevel: number;
    contentSecurityLevel: number;
    keySecurityLevel: number;
    metaSecurityLevel: number;

    contentStorageNodes?: IIdName<string>[];
    keyStorageNodes?: IIdName<string>[];
    metaStorageNodes?: IIdName<string>[];
}

export function getSecurityLevels(x: IStoragePool | IStoragePoolV2DetailsItem): StoragePoolSecurityLevel {
    return {
        contentSecurityLevel: 'ContentSecurityLevel' in x ? x.ContentSecurityLevel : x.contentSecurityLevel,
        keySecurityLevel: 'KeySecurityLevel' in x ? x.KeySecurityLevel : x.keySecurityLevel,
        metaSecurityLevel: 'MetaSecurityLevel' in x ? x.MetaSecurityLevel : x.metaSecurityLevel
    };
}

export interface IStoragePoolsV2RequestParams extends
    IRequestParams<StoragePoolV2SortField>,
    IQueryStrictParams,
    IDeletedParam {
    fields?: (keyof IStoragePoolV2ListItem)[];
    pool?: string;
    unused?: boolean;
    provider?: StorageProviderType;
}

export enum StoragePoolV2SortField {
    Name = "name",
    Id = "id"
}

export type StoragePoolSecurityLevel = {
    [Property in keyof IStoragePoolSecurityLevel as Uncapitalize<Property>]: IStoragePoolSecurityLevel[Property];
}

