import * as fnvPlus from 'fnv-plus';
import JSZip from "jszip";
import { IApplicationCredentialsCreateResponse } from "../api/entities/application";
import { IBucketV2ExportResponse, ICognitoTokenV2ExportResponse, IDeviceConfigV2ExportResponse, IEnvironmentSettingsResponse, IExportFileNameData, IPolicyV2ExportResponse, IStorageApplicationRoleConfigResponse, IStorageNodeDeviceTokenV2ExportResponse, IStorageNodeInfoV2ExportResponse, IStorageNodeV2ExportResponse, IStoragePoolV2ExportResponse } from "../api/entities/device-config";
import { IIdName } from "../api/entities/id-name";
import { IPageRequestParams, ISortRequestParams } from "../api/entities/page";
import { IBucketDetailsItem } from "../api/entities/bucket";
import { IStorageNodeRaw } from '../api/entities/storage-node';
import { getStorageProviderType, IStorageNodeV2 } from "../api/entities/storage-node-v2";
import { IStoragePoolV2DetailsItem } from "../api/entities/storage-pool-v2";
import { IStoragePoolNodeCredentialStatus } from "../components/storage-pools/panels/storage-pool-credentials-update-panel/storage-pool-credentials-update-panel";
import { deviceUtils } from "./device-utils";
import { storageExternalFactory } from "./factories/storage-external-factory";

class ExportUtils {
    getExportParams<TSearchParams extends IPageRequestParams & ISortRequestParams<any>>(searchParams: TSearchParams): TSearchParams {
        return {
            ...searchParams,
            offset: null,
            pageSize: null,
        };
    }

    async getDeviceConfigArchive(application: IIdName<string>, credential: IApplicationCredentialsCreateResponse, bucket: IBucketDetailsItem, storagePool: IStoragePoolV2DetailsItem, storagePoolNodes: IStoragePoolNodeCredentialStatus[]): Promise<string> {
        let exportFilesData = await this.getExportFilesData(application, credential, bucket, storagePool, storagePoolNodes);
        return await this.createArchive(exportFilesData);
    }

    async createArchive(files: IExportFileNameData[]): Promise<string> {
        const zip = new JSZip();

        files.forEach(x => {
            zip.file(x.name, JSON.stringify(x.data));
        })

        return await zip.generateAsync({ type: 'base64' });
    }

    private async getExportFilesData(application: IIdName<string>, credential: IApplicationCredentialsCreateResponse, bucket: IBucketDetailsItem, storagePool: IStoragePoolV2DetailsItem, storagePoolNodes: IStoragePoolNodeCredentialStatus[]): Promise<IExportFileNameData[]> {
        let repoURI = this.maskValue(bucket.tagURI);
        let policyURI = this.maskValue(`tag:myota.io,2019:policy/${bucket.bucketConfig.bucketId}`);

        let did = deviceUtils.generateDid();
        let deviceKey = deviceUtils.generateKey();

        let storageNodes = storagePoolNodes.map(x => x.nodeDetails);

        let filesData: IExportFileNameData[] = [];
        filesData.push(this.getBucketDetails(bucket, repoURI, storagePool, storageNodes));
        filesData.push(this.getCognitoTokenStub());
        filesData.push(this.getDeviceConfig(application, did, deviceKey, repoURI, policyURI));
        filesData.push(this.getCredentialConfig(credential, application));
        filesData.push(this.getEnvironmentSettings(did, deviceKey, application));
        filesData.push(this.getPolicyConfig(policyURI, bucket));

        await Promise.all(storageNodes.map(x => this.getStorageNodeDetails(x, did)))
            .then(values => filesData.push(...values));

        return filesData;
    }

    private getBucketDetails(bucket: IBucketDetailsItem, repoURI: string, storagePool: IStoragePoolV2DetailsItem, storageNodes: IStorageNodeRaw[]): IExportFileNameData<IBucketV2ExportResponse> {
        let storageNodesDict = storageNodes.reduce((acc, x) => {
            acc[x.id] = x;
            return acc;
        }, {});

        const getStorage = (x: IStorageNodeV2): IStorageNodeInfoV2ExportResponse => ({
            nodeId: x.id,
            type: getStorageProviderType(storageNodesDict[x.id].type),
            bucket: storageNodesDict[x.id].bucket ?? storageNodesDict[x.id].container,
            region: storageNodesDict[x.id].region
        });

        const getStorageNodeExportResponse = (type: string): IStorageNodeV2ExportResponse =>
            ({
                redundancyLevel: storagePool[`${type}RedundancyLevel`],
                securityLevel: storagePool[`${type}SecurityLevel`],
                storage: storagePool[`${type}StorageNodes`].map(x => getStorage(x))
            });
        let storagePoolInfo: IStoragePoolV2ExportResponse = {
            id: storagePool.id,
            contentNodes: getStorageNodeExportResponse('content'),
            metaNodes: getStorageNodeExportResponse('meta'),
            keyNodes: getStorageNodeExportResponse('key'),
        };

        let name = `api/v1/repo/${repoURI}`;
        let data: IBucketV2ExportResponse = {
            repository: {
                uri: repoURI,
                rootPath: bucket.id,
                folderName: bucket.name,
                storagePool: storagePoolInfo as IStoragePoolV2ExportResponse
            }
        };
        return { name, data };
    }

    private async getStorageNodeDetails(storageNode: IStorageNodeRaw, did: string): Promise<IExportFileNameData<IStorageNodeDeviceTokenV2ExportResponse>> {
        let storage = storageExternalFactory.getStorage(storageNode.type);
        let token = storage.getToken(storageNode);

        let name = `api/v1/device/${did}/token/${storageNode.id}`;
        let data: IStorageNodeDeviceTokenV2ExportResponse = {
            storageId: storageNode.id,
            type: getStorageProviderType(storageNode.type),
            token
        };
        return { name, data };
    }

    private getCognitoTokenStub(): IExportFileNameData<ICognitoTokenV2ExportResponse> {
        let name = 'api/v1/account/cognito/token';
        let data: ICognitoTokenV2ExportResponse = {
            token_type: "Bearer",
            expires_in: 900,
            access_token: "access",
            refresh_token: "refresh"
        };

        return { name, data };
    }

    private getCredentialConfig(credential: IApplicationCredentialsCreateResponse, application: IIdName<string>): IExportFileNameData<IStorageApplicationRoleConfigResponse> {
        let name = `api/v1/storapp/${application.name}/role/config`;
        let data: IStorageApplicationRoleConfigResponse = {
            roleCredentials: [
                {
                    role: credential.name,
                    accessKey: credential.accessKey,
                    secret: credential.accessSecret
                }
            ]
        };

        return { name, data };
    }

    private getDeviceConfig(application: IIdName<string>, did: string, deviceKey: string, repoURI: string, policyURI: string): IExportFileNameData<IDeviceConfigV2ExportResponse> {
        let name = `api/v1/device/${did}/config`;
        let data: IDeviceConfigV2ExportResponse = {
            device: {
                name: application.name,
                did,
                key: deviceKey,
                os: 'storage-application',
                status: 'active',
                approval: true,
                blocked: false
            },
            repos: [
                { repoURI, policyURI }
            ]
        };

        return { name, data };
    }

    private getEnvironmentSettings(did: string, deviceKey: string, application: IIdName<string>): IExportFileNameData<IEnvironmentSettingsResponse> {
        let name = `env.json`;
        let data: IEnvironmentSettingsResponse = {
            deviceId: did,
            deviceKey,
            appName: application.name
        };

        return { name, data };
    }

    private getPolicyConfig(policyURI: string, bucket: IBucketDetailsItem): IExportFileNameData<IPolicyV2ExportResponse> {
        let name = `api/v2/policy/${policyURI}`;
        let data: IPolicyV2ExportResponse = {
            uri: policyURI,
            name: `${bucket.name}-policy`,
            isDefault: true,
            maxDeviceMB: -1,
            maxDevicesPerUser: -1,
            notifyMB: -1,
            shouldNotify: false,
            previousVersionRetentionDays: bucket.bucketConfig.retainDeletedDays ?? 0,
            previousVersionRetentionMB: -1,
            deletedFilesRetentionDays: bucket.bucketConfig.retainPrevVerDays ?? 0,
            deletedFilesRetentionMB: -1,
            enableOffline: false,
            localAuth: {
                enable: false,
                timerInMinutes: -1
            }
        };

        return { name, data };
    }

    private maskValue(value: string) {
        return fnvPlus.hash(value, 64).str();
    }
}

export const exportUtils = new ExportUtils();
