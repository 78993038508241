import React, { useContext } from "react";
import { VersionContext } from "../../../storage-pools/storage-pools";
import styles from "./test-connection.module.scss";
import Button from "../../../shared-ui/button";
import clsx from "clsx";
import IComponentProps from "../../../shared-ui/common/component-props";
import { IAppState } from "../../../../redux/reducers";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { IStorageNodeTestConnectionRequest } from "../../../../api/entities/storage-node";
import { storageActions } from "../../../../redux/actions/storage";
import { storageUtils } from "../../../../utils/storage-utils";

const mapStateToProps = (state: IAppState) => ({
  isTestConnectionSuccess: state.storage.testConnectionFlag,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    testConnection: (payload: IStorageNodeTestConnectionRequest) => {
      dispatch(storageActions.testConnection(payload));
    },
  };
};

interface ITestConnectionProps
  extends IComponentProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  testConnectionRequest: IStorageNodeTestConnectionRequest;
}

export function TestConnection(props: ITestConnectionProps) {
  const version = useContext(VersionContext);

  return (
    <div
      className={clsx(styles.root, props.className)}
      style={props.style}
      data-testid={props.testId}
    >
      <Button
        testId="test-connection-button"
        className={styles.button}
        colors="secondary"
        disabled={props.disabled || !storageUtils.isTestConnectionRequestValid(props.testConnectionRequest, true, version)}
        onClick={() => props.testConnection(props.testConnectionRequest)}
      >
        Test Connection
      </Button>
      {props.isTestConnectionSuccess !== null && (
        <div
          className={clsx(styles.result, {
            [styles["result--success"]]: props.isTestConnectionSuccess,
            [styles["result--fail"]]: !props.isTestConnectionSuccess,
          })}
        >
          {props.isTestConnectionSuccess ? (
            <>
              <DoneIcon className={styles.icon} />
              <span>Connection all good!</span>
            </>
          ) : (
            <>
              <CloseIcon className={styles.icon} />
              <div>
                <div>Connection Failure!</div>
                <div className={styles.result__description}>
                  Please try again or check with your provider.
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TestConnection);
