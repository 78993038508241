import {
  FileVersionType,
  IChartResponse,
  IPolicyChartRecord,
} from "./dashboard";
import { IIdName } from "./id-name";
import { IRequestParams } from "./page";
import { IRepoDataMigration } from "./data-migration";
import { IRetentionPolicy, ISpacePolicy } from "./retention-policy";

export enum StorageSpaceUnit {
  None = "",
  Mb = "mb",
  Gb = "gb",
  Tb = "tb",
}

export enum TimeLengthUnit {
  None = "",
  Hours = "hours",
  Days = "days",
  Months = "months",
  Years = "years",
}

export enum PolicySortField {
  Name = "name",
  Users = "users",
  Storage = "storage",
  Retention = "retention",
  Alerts = "alerts",
}

export enum LogoutTimer {
  Forever = 0,
  Minutes5 = 5,
  Minutes15 = 15,
  Minutes30 = 30,
  OneHour = 60
}

export interface IPolicyShort {
  id: number;
  name: string;
  StoragePool: IIdName;
  spaceLimitMegabytes: number;
  default?: boolean;
}

export interface IPolicyListItem extends IPolicyShort, IRetentionPolicy, ISpacePolicy {
  dataMigrations?: IRepoDataMigration[];
  Users: IIdName[];
  shouldnotify: boolean;
}

export interface IPolicyUser {
  id: number;
  uid: string;
  name: string;
  email: string;
  Groups: IIdName[];
  usedSpaceBytes: number;
}

export interface IPolicy extends IPolicyListItem {
  Users: IPolicyUser[];
  allowOfflineFiles: boolean;
  localAuthentication: boolean;
  logoutTimer: LogoutTimer;
  notifyamount: number;
  notifyUsers: boolean;
  notifyAdmins: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IUpdateUsersRequest {
  ids: number[];
}

export interface IDeletePoliciesRequest {
  id: number[];
  force?: boolean;
}

export interface IStorageUsagePerProfileRequest {
  fileVersionTypeIds: FileVersionType[];
}

export interface IStorageUsagePerProfileResponse
  extends IChartResponse<IPolicyChartRecord> {}

export interface IPolicyUpdate extends Omit<IPolicyShort, "StoragePool">, IRetentionPolicy, ISpacePolicy {
  storagePoolId: number;
  shouldnotify: boolean;
  allowOfflineFiles: boolean;
  localAuthentication: boolean;
  logoutTimer: LogoutTimer
  notifyamount: number;
  selectedUsers?: number[];
}

export interface IPolicyAdd extends Omit<IPolicyUpdate, 'id'> {
}

export interface IPolicyFilterParams {
  q?: string;
  storagePoolFilter?: number;
}

export interface IPoliciesRequestParams extends IRequestParams<PolicySortField>, IPolicyFilterParams {
}

