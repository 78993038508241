import { IPolicy } from './policy';
import { IUserShort } from "./user";
import { IPageRequestParams, IRequestParams } from "./page";

export enum DeviceStatus {
  Active = "active",
  Pending = "pending",
  Inactive = "inactive",
}

export enum DeviceStatusForFilter {
  Blocked = "blocked",
  Pending = "pending",
  Inactive = "inactive",
  Active = "active",
}

export enum DeviceSortField {
  Status = "status",
  Email = "email",
  Name = "name",
  OS = "os",
  Lastlogin = "lastlogin",
  Lastlocation = "lastlocation",
  ClientVersion = "clientVersion"
}

export interface IDeviceCompact {
  id: number;
  did: string;
  name: string;
  key: string;
  os: string;
  blocked: number;
  status: DeviceStatus;
  clientVersion: string;
  createdAt: string;
  updatedAt: string;
}

export interface IDeviceListItem extends IDeviceCompact {
  User: IDeviceUser;
  lastLocation: string;
  lastLogin: string;
}

export interface IUserDevice extends IDeviceCompact {
  fileInfo: IDeviceFileInfo;
  lastLocation: string;
  lastLogin: string;
  lastIp: string;
}

export interface IDeviceHistory {
  id: number;
  ip: string;
  location: string;
  createdAt: string;
}

export interface IDeviceHistoryRequestParams extends IPageRequestParams {
}

export interface IDeviceFileInfo {
  secured: number;
  offline: number;
}

export interface IDeviceUser extends IUserShort {
  activeDataMigrationsCount: number;
  repoURI: string;
}

export interface IDevice extends IDeviceCompact {
  fileInfo: IDeviceFileInfo;
  Policy: IPolicy;
  User: IDeviceUser;
}

export interface IDeviceFilterParams {
  q?: string;
  statusFilter?: DeviceStatusForFilter;
  deviceTypeFilter?: string;
  clientVersionFilter?: string;
}

export interface IDevicesRequestParams
  extends IRequestParams<DeviceSortField>,
    IDeviceFilterParams {
}
