import { DataService } from "./data-service";
import { IStorageNodeIssue } from "../entities/storage-node-issue";

class StorageNodeIssuesService extends DataService {
    constructor() {
        super("storagenode/issues");
    }

    getStorageNodeIssues() {
        return this.api.get<IStorageNodeIssue[]>();
    }
}

export const storageNodeIssuesService = new StorageNodeIssuesService();