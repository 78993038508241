import React, { useState } from "react";
import styles from "./password-input.module.scss";
import TextInput, { ITextInputProps } from "../text-input/text-input";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from "../../button";

interface IPasswordInputProps extends ITextInputProps {
}

export function PasswordInput(props: IPasswordInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={styles.root}>
      <TextInput 
        {...props} 
        type={showPassword ? "text" : "password"}
        className={styles.input}
      />
      <Button 
        testId="show-password" 
        onClick={() => setShowPassword(!showPassword)}
        className={styles.button}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </Button>
    </div>
  );
}
