import React, { useState, useEffect, ReactNode } from "react";
import IComponentProps from "../../common/component-props";
import InputWithUnits from "../input-with-units";
import { ALLOTTED_SPACE_PRECISION } from "../../../../constants/common";

interface ISpaceSelectorProps extends IComponentProps {
  autoFocus?: boolean;
  value: number;
  hideMb?: boolean;
  tooltip?: ReactNode;
  hasError?: boolean;

  onChange: (value?: number) => void;
}

export default function SpaceSelector(props: ISpaceSelectorProps) {
  const UNIT_MB = 1,
    UNIT_GB = 1000,
    UNIT_TB = UNIT_GB * UNIT_GB,
    [inputValue, setInputValue] = useState(props.value),
    [unit, setUnit] = useState(props.hideMb ? UNIT_GB : UNIT_MB);

  useEffect(() => {
    onInputValueChanged(props.value && props.value / unit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const onInputValueChanged = (value: number) => {
    let inputResult = value,
      result = value === null ? null : value * unit,
      newUnit = UNIT_MB;

    if (result) {
      if (result >= UNIT_TB) {
        inputResult = result / UNIT_TB;
        newUnit = UNIT_TB;
      } else if (result >= UNIT_GB || props.hideMb) {
        inputResult = result / UNIT_GB;
        newUnit = UNIT_GB;
      }
      setUnit(newUnit);
    }
    setInputValue(inputResult);
    if (props.value !== result) {
      props.onChange(result);
    }
  };

  const onUnitChanged = (newUnit: number) => {
    let result = props.value;
    setUnit(newUnit);
    if (result) {
      setInputValue(result / newUnit);
    }
  };

  const getUnits = () => {
    const result = [
      { label: "GB", value: UNIT_GB },
      { label: "TB", value: UNIT_TB },
    ];
    if (!props.hideMb) {
      result.unshift({ label: "MB", value: UNIT_MB });
    }
    return result;
  };

  return (
    <InputWithUnits
      autoFocus={props.autoFocus}
      testId={props.testId}
      className={props.className}
      style={props.style}
      value={inputValue}
      units={getUnits()}
      selectedUnit={unit}
      precision={ALLOTTED_SPACE_PRECISION}
      tooltip={props.tooltip}
      hasError={props.hasError}
      onChange={onInputValueChanged}
      onUnitChange={onUnitChanged}
      disabled={props.disabled}
    />
  );
}
