import { AccountAction, AccountActionType } from "../actions/account";

export interface IAccountState {
  loginFailed: boolean;
  loginError?: string;
  signUpFailed: boolean;
  signUpError?: string;
  accountConfirmationEmailSent: boolean;
}

export const ACCOUNT_DEFAULT_STATE: IAccountState = {
  loginFailed: false,
  signUpFailed: false,
  accountConfirmationEmailSent: false
};

export default function account(
  state = ACCOUNT_DEFAULT_STATE,
  action: AccountAction
): IAccountState {
  switch (action.type) {
    case AccountActionType.LoginError:
      return {
        ...state,
        loginFailed: true,
        loginError: action.payload
      };
    case AccountActionType.RemoveLoginError:
      return {
        ...state,
        loginFailed: false,
        loginError: null
      };
    case AccountActionType.SignUpError:
      return {
        ...state,
        signUpFailed: true,
        signUpError: action.payload
      };
    case AccountActionType.RemoveSignUpError:
      return {
        ...state,
        signUpFailed: false,
        signUpError: null
      };
    case AccountActionType.AccountConfirmationEmailSending:
      return {
        ...state,
        accountConfirmationEmailSent: true,
      };
    case AccountActionType.ClearAccountConfirmationEmailSendingStatus:
      return {
        ...state,
        accountConfirmationEmailSent: false
      };
    default:
      return state;
  }
}
