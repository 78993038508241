import React from "react";
import logoPng from "../../../assets/logo.png";
import { LOGIN_ROUTE } from "../../../constants/routes";
import Button from "../../shared-ui/button";
import clientStyles from "../client.module.scss";

function LogoutSuccessPage() {
  return (
    <div className={clientStyles["data-wrapper"]}>
      <img src={logoPng} alt="logo" className={clientStyles.logo} />
      <h1>You have been successfully logged out.</h1>
      <Button
        colors="primary"
        href={LOGIN_ROUTE}
        testId="login-button"
        className={clientStyles["login-button"]}
      >
        Log In
      </Button>
    </div>
  );
}

export default LogoutSuccessPage;
