export class Auth2Utils {
  private dec2hex (dec: number): string {
    return dec.toString(16).padStart(2, "0")
  }

  private generateId (len: number): string {
    var arr = new Uint8Array(len / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, this.dec2hex).join('');
  }

  generateState(): string {
    return this.generateId(64);
  }
}

export const auth2Utils = new Auth2Utils();
