
export enum QueryPropertyType {
  Number = 1,
  String = 2,
  ArrayOfStrings = 3,
  ArrayOfNumbers = 4
}

export type QueryTypeMap<TSerializedObject extends object> = Record<keyof TSerializedObject, QueryPropertyType>;


export class QueryUtils {
  parseSearchParams<TSerializedObject extends object>(query: URLSearchParams, typeMap: QueryTypeMap<TSerializedObject>): Partial<TSerializedObject> {
    const obj: Partial<TSerializedObject> = {};
    query.forEach((value: string, key: string) => {
      const type = typeMap[key];
      if (type) {
        switch (type) {
          case QueryPropertyType.String:
            obj[key] = value || null;
            break;
          case QueryPropertyType.Number:
            obj[key] = (value && Number(value)) || null;
            break;
          case QueryPropertyType.ArrayOfNumbers:
            obj[key] = (value && value.split(",").map(v => Number(v))) || null;
            break;
          case QueryPropertyType.ArrayOfStrings:
            obj[key] = (value && value.split(",")) || null;
            break;
        }
      }
      else {
        console.error(`Unknown query parameter '${key}'`);
      }
    });
    return obj;
  }

  toQuery<TObjectToSerialize extends object>(obj: TObjectToSerialize): string {
    const urlSearchParams = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        urlSearchParams.append(key, obj[key]);
      }
    });
    return urlSearchParams.toString();
  }

}
export const queryUtils = new QueryUtils();
