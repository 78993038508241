import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import { IStyleableComponentProps, ITestableComponent } from "../../common/component-props";
import styles from "./form-field-value.module.scss";

export interface IFormFieldValueProps extends ITestableComponent, IStyleableComponentProps {
}

export function FormFieldValue(props: PropsWithChildren<IFormFieldValueProps>) {
  return (
    <div 
      data-testid={props.testId || "form-field-value"}
      style={props.style}
      className={clsx(styles.value, props.className)}
    >
      {props.children}
    </div>
  );
}
