import React from "react";
import {
  IStyleableComponentProps,
  ITestableComponent,
} from "../common/component-props";
import Tooltip from "../tooltip";
import styles from "./tooltip-question.module.scss";
import clsx from "clsx";

interface ITooltipQuestionProps
  extends IStyleableComponentProps,
    ITestableComponent {
  children: React.ReactChild;
}

export default function TooltipQuestion(props: ITooltipQuestionProps) {
  return (
    <Tooltip title={props.children}>
      <div
        className={clsx(styles.root, props.className)}
        data-testid={props.testId}
        style={props.style}
      >
        ?
      </div>
    </Tooltip>
  );
}
