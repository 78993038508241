import React, { useContext } from "react";
import { IStorageNode, StorageNodeStatus } from "../../../../api/entities/storage-node";
import { getReplacedByStorageNodeId, getStorageProvider, IStorageNodeV2 } from "../../../../api/entities/storage-node-v2";
import { CONFIG } from "../../../../config/config";
import { FormMode, IFormField } from "../../../../utils/form-utils";
import { storageUtils } from "../../../../utils/storage-utils";
import Button from "../../../shared-ui/button";
import { createRemoveColumn } from "../../../shared-ui/compact-table/columns/remove-column";
import { CompactTable } from "../../../shared-ui/compact-table/compact-table";
import FormSection from "../../../shared-ui/form/form-section/form-section";
import Checkbox from "../../../shared-ui/input/checkbox";
import { IColumnDefinition } from "../../../shared-ui/table/table";
import tableStyles from "../../../shared-ui/table/table.module.scss";
import { VersionContext } from "../../storage-pools";
import { IStorageNodeTypes } from "../storage-node-section/storage-node-section";
import { MoreOption } from "./moreoption";
import styles from "./storage-nodes-section.module.scss";

export interface IStoragePoolNode {
  node: IStorageNode | IStorageNodeV2;
  types: IStorageNodeTypes;
}

export interface IStorageNodesSectionProps {
  mode: FormMode;
  storagePoolNodes: IFormField<IStoragePoolNode[]>;

  onEditStorageNode?: (node: IStoragePoolNode) => void;
  onReplaceStorageNode?: (node: IStoragePoolNode) => void;
  onOpenSelectStorageNodesPanel?: () => void;
}

export function validateStoragePoolNodes(nodes: IStoragePoolNode[]) {
  if ( 
    nodes.filter(n => n.types.storeContent).length < CONFIG.minStoragesCount || 
    nodes.filter(n => n.types.storeKey).length < CONFIG.minStoragesCount || 
    nodes.filter(n => n.types.storeMetadata).length < CONFIG.minStoragesCount
  ) {
    return `Myota requires at least ${CONFIG.minStoragesCount} storage nodes configured for content, key, and metadata.`;
  }

  if ( 
    nodes.filter(n => n.types.storeContent).length > CONFIG.maxStoragesCount || 
    nodes.filter(n => n.types.storeKey).length > CONFIG.maxStoragesCount || 
    nodes.filter(n => n.types.storeMetadata).length > CONFIG.maxStoragesCount
  ) {
    return `Maximum allowed storages for content, key, and metadata is ${CONFIG.maxStoragesCount}.`;
  }

  const nodeWithoutType = nodes.find(n => !n.types.storeContent && !n.types.storeKey && !n.types.storeMetadata)
  if (nodeWithoutType) {
    return `Please select Storage Type for node '${nodeWithoutType.node.name}'`;
  }
  return null;
}

export const StorageNodesSection = (props: IStorageNodesSectionProps) => {
  const nodesToMigrateTo = props.storagePoolNodes.value.filter(n => n.node.status === StorageNodeStatus.MigrationInProgress);
  const nodesToMigrateFrom = props.storagePoolNodes.value.filter(
    n => getReplacedByStorageNodeId(n.node) &&
    nodesToMigrateTo.some(migrateTo => migrateTo.node.id === getReplacedByStorageNodeId(n.node)));

  const version = useContext(VersionContext);

  function deleteStorageNode(poolNode: IStoragePoolNode) {
    props.storagePoolNodes.onChange(props.storagePoolNodes.value.filter(n => n !== poolNode));
  }

  const columns: IColumnDefinition<IStoragePoolNode, null>[] = [
    {
      testId: "col-name",
      className: styles["col-name"],
      label: "Name",
      formatter: (poolNode) => (
        <>
          {poolNode.node.status === StorageNodeStatus.MigrationInProgress && (
            <>
              <div className={styles["migrate-from"]}>
                <span className={styles["migrate-from-icon"]}></span>
                {nodesToMigrateFrom.find(n => getReplacedByStorageNodeId(n.node) === poolNode.node.id).node.name}
              </div>
              <div className={styles["migrate-to"]}>
                <span className={styles["migrate-to-icon"]}></span>
                {poolNode.node.name}
              </div>
            </>
          )}
          {poolNode.node.status !== StorageNodeStatus.MigrationInProgress && (
            <Button testId="name-button" className={styles["node-link"]} colors="link" onClick={() => props?.onEditStorageNode(poolNode)}>
              {poolNode.node.name}
            </Button>
          )}
        </>
      )
    },
    {
      testId: "col-provider",
      className: styles["col-provider"],
      label: "Provider",
      formatter: (poolNode) => (
        <>
          {poolNode.node.status === StorageNodeStatus.MigrationInProgress && (
            <>
              <div className={styles["migrate-from"]}>
                {storageUtils.getStorageProviderName(getStorageProvider(nodesToMigrateFrom.find(n => getReplacedByStorageNodeId(n.node) === poolNode.node.id).node))}
              </div>
              <div className={styles["migrate-to"]}>
                {storageUtils.getStorageProviderName(getStorageProvider(poolNode.node))}
              </div>
            </>
          )}
          {poolNode.node.status !== StorageNodeStatus.MigrationInProgress && storageUtils.getStorageProviderName(getStorageProvider(poolNode.node))}
        </>
      )
    }
  ];


  let isCheckboxDisabled = props.mode !== FormMode.Create;

  columns.push(
        {
            testId: "col-content",
            className: styles["col-content"],
            label: "Content",
            formatter: poolNode =>
                <Checkbox
                    testId="content-checkbox"
                    checked={poolNode.types.storeContent}
                    disabled={isCheckboxDisabled}
                    onChange={(value) => {
                        poolNode.types.storeContent = value;
                        props.storagePoolNodes.onChange([...props.storagePoolNodes.value]);
                    }}
                />
        },
        {
            testId: "col-key",
            className: styles["col-key"],
            label: "Key",
            formatter: poolNode =>
                <Checkbox
                    testId="key-checkbox"
                    checked={poolNode.types.storeKey}
                    disabled={isCheckboxDisabled}
                    onChange={(value) => {
                        poolNode.types.storeKey = value;
                        props.storagePoolNodes.onChange([...props.storagePoolNodes.value]);
                    }}
                />
        },
        {
            testId: "col-metadata",
            className: styles["col-metadata"],
            label: "Metadata",
            formatter: poolNode =>
              <Checkbox
                  testId="metadata-checkbox"
                  checked={poolNode.types.storeMetadata}
                  disabled={isCheckboxDisabled}
                  onChange={(value) => {
                    poolNode.types.storeMetadata = value;
                    props.storagePoolNodes.onChange([...props.storagePoolNodes.value]);
                  }}
              />
        });
  if (props.mode === FormMode.Create) {
     columns.push(createRemoveColumn<IStoragePoolNode>(deleteStorageNode));
  }


  if (props.mode === FormMode.Edit) {
    columns.push({
      testId: "col-manage",
      className: tableStyles["col-manage"],
      label: "Manage",
      formatter: (poolNode) => (
        <>
          {poolNode.node.status === StorageNodeStatus.MigrationInProgress && (
            <div className={styles["migration-in-progress"]}>Replace<br/> in progress</div>
          )}
          {poolNode.node.status !== StorageNodeStatus.MigrationInProgress && (
            <MoreOption 
              storagePoolNode={poolNode}
              onEditStorageNode={props.onEditStorageNode}
              onReplace={props.onReplaceStorageNode}
              canReplaceNode={!nodesToMigrateTo.length && version === 1}
              isReplaceNodeDisabled={version === 2}
            />
          )}        
        </>
      )
    });
  }

  function getTable() {
    return <CompactTable
        testId="storage-pool-nodes-table"
        className={styles.table}
        loading={false}
        columns={columns}
        rows={props.storagePoolNodes.value.filter(n => nodesToMigrateFrom.indexOf(n) < 0)}
        rowId={(poolNode) => poolNode.node.id}
        onAddClick={props.mode === FormMode.Create ? props.onOpenSelectStorageNodesPanel : null}
        addButtonCaption={'Select Storage Nodes'}
    />
  }

  function getRootElement(children: JSX.Element) {
    if (props.mode === FormMode.ReadOnly) {
      return children;
    }

    let sectionTitle = props.mode === FormMode.Create ? 'Add Storage Nodes' : 'Storage Nodes';
    let sectionDescription = props.mode === FormMode.Create ? `Myota requires at least ${CONFIG.minStoragesCount} storage nodes.` : '';

    return (
        <FormSection
            testId="add-storage-nodes-section"
            title={sectionTitle}
            description={sectionDescription}
        >
          {children}
        </FormSection>
    );
  }

  return getRootElement(getTable());
};
