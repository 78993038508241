import { StorageProvider } from "../../api/entities/storage-node";
import { StorageABS } from "../storages/abs";
import { StorageGCS } from "../storages/gcs";
import { StorageS3 } from "../storages/s3";
import { IStorageExternal } from "../storages/storage-external";

class StorageExternalFactory {
    public getStorage(type: StorageProvider): IStorageExternal {
        switch (type) {
            case StorageProvider.ABS:
                return new StorageABS();
            case StorageProvider.GCS:
                return new StorageGCS();
            case StorageProvider.S3:
            case StorageProvider.S3Compatible:
                return new StorageS3();
        }
    }
}

export const storageExternalFactory = new StorageExternalFactory();
