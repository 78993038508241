import React, { ReactNode } from "react";
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle } from "../../../dialog/dialog-title";
import { IStyleableComponentProps, ITestableComponent } from "../../../common/component-props";
import classes from "./info-dialog.module.scss";
import Dialog from "@mui/material/Dialog";
import clsx from "clsx";
import DialogActions from "@mui/material/DialogActions";
import Button from "../../../button";

export enum DialogMode {
  None = "none",
  Critical = "critical",
}
export interface IInfoDialogProps extends ITestableComponent, IStyleableComponentProps {
  mode?: DialogMode;
  title: string;
  showOkButton?: boolean;
  contentClassName?: string;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

export function InfoDialog(props: IInfoDialogProps) {
  const paperClass = props.mode === DialogMode.Critical ? classes.critical : "";
  return (
    <Dialog 
      open={props.open} 
      onClose={props.onClose} 
      data-testid={props.testId || "info-dialog"}
      classes={{ paper: clsx(paperClass, props.contentClassName) }}
      className={props.className}
      style={props.style}
    >
      <DialogTitle onClose={props.onClose}>{props.title}</DialogTitle>
      <DialogContent>
        <div className={classes.content} data-testid="alert-description">
          {props.children}
        </div>
      </DialogContent>
      {props.showOkButton && (
        <DialogActions>
          <Button
            colors="primary"
            onClick={props.onClose}
            testId="button-ok"
            className={classes["button-ok"]}
          >
            OK
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
