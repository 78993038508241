import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { IAppState } from "../../../redux/reducers";
import styles from "./loader.module.scss";
import clsx from "clsx";
import { IStyleableComponentProps, ITestableComponent } from "../common/component-props";
import { theme } from "../../../theme/theme";

const useStyles = makeStyles(() => ({
  progress: {
    margin: theme.spacing(2),
  },
}));

function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <CircularProgress classes={{ root: classes.progress }} color="primary" />
  );
}

const mapStateToProps = (state: IAppState) => ({
  isLoading: state.common.isLoading,
});

interface ILoaderProps
  extends ReturnType<typeof mapStateToProps>,
    IStyleableComponentProps, ITestableComponent {
  isLoading: boolean;
}

const Loader = (props: PropsWithChildren<ILoaderProps>) => {
  const { isLoading, children } = props,
    content = (
      <div
        className={clsx(styles.root, {
          [styles["root--open"]]: isLoading,
        })}
        data-testid={props.testId || "loader"}
      >
        <CircularIndeterminate />
      </div>
    );

  if (children) {
    return (
      <div
        className={clsx(styles.wrapper, props.className)}
        style={props.style}
      >
        {children}
        {content}
      </div>
    );
  } else {
    return content;
  }
};

export { Loader as LocalLoader };

export default connect(mapStateToProps)(Loader);
