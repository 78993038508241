import React from "react";
import { connect } from "react-redux";
import { IAppState } from "../../../redux/reducers";
import styles from "./steps-indicator.module.scss";
import clsx from "clsx";

const stepNames = [
  "Storage Pool",
  "Default Storage Profile",
  //"Client Deployment",
];

const mapStateToProps = (state: IAppState) => ({
  initialSetupSteps: state.initialSetup.initialSetupStep,
});

interface IStepsIndicatorProps extends ReturnType<typeof mapStateToProps> {
  isHorizontal?: boolean;
}

export const StepsIndicator = connect(mapStateToProps)((props: IStepsIndicatorProps) => {
  const { initialSetupSteps, isHorizontal } = props;

  return (
    <div className={clsx(styles["steps-indicator"], isHorizontal && styles["steps-indicator--horizontal"])}>
      {stepNames.map((name, idx) => {
        idx++;
        return (
          <div
            key={idx}
            className={clsx(styles.step, {
              [styles["step--active"]]: initialSetupSteps === idx,
              [styles["step--done"]]: initialSetupSteps > idx,
            })}
          >
            <div className={styles.name}>{name}</div>
            <div className={styles.ball}>
              <div className={styles.num}>{idx}</div>
              <div className={styles.tick}></div>
            </div>
          </div>
        );
      })}
    </div>
  );
});
