import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { accountService } from "../../../api/services/account-service";
import { useApi } from "../../../utils/hooks/use-api";
import { useNavigate } from "../../../utils/hooks/use-navigate";
import { QueryPropertyType, QueryTypeMap, queryUtils } from "../../../utils/query-utils";
import { routeUtils } from "../../../utils/route-utils";
import Button from "../../shared-ui/button";
import styles from "./confirmation.module.scss";

interface IConfirmationProps {}

export interface IConfirmationParams {
  email: string;
  token: string;
}

export const confirmationParametersTypeMap: QueryTypeMap<IConfirmationParams> = {
  email: QueryPropertyType.String,
  token: QueryPropertyType.String,
}

export const defaultConfirmationParameters: IConfirmationParams = {
  email: "",
  token: ""
}

export const Confirmation = (props: IConfirmationProps) => {
  const [api] = useApi();
  const navigate = useNavigate();
  const { search } = useLocation();
  const confirmationParams = {
    ...defaultConfirmationParameters,
    ...queryUtils.parseSearchParams(new URLSearchParams(search), confirmationParametersTypeMap)
  };

  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);


  useEffect(() => {
    if (!!confirmationParams.email && !!confirmationParams.token) {
      const request = {
        email: confirmationParams.email,
        token: confirmationParams.token
      };

      api(accountService.confirmAccount(request))
          .then(() => {
            setIsConfirmed(true);
          });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.loginwrap}>
        <CssBaseline />
        <div className={styles.paper}>
          {isConfirmed ?
              <>
                <div>Your account has been confirmed.</div>

                <Button
                    colors="primary"
                    onClick={() => navigate(routeUtils.getRouteToSignInForm())}
                    className={styles.loginButton}
                    testId="login-button"
                >
                  Login
                </Button>
              </>
              :
              <div>An error occurred. Account was not confirmed. Please try again.</div>
          }
      </div>
    </div>
    </div>
  );
};
