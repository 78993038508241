import React, { PropsWithChildren, useEffect, useState } from "react";
import { IIdName } from "../../../../api/entities/id-name";
import { IPage, SortDirection } from "../../../../api/entities/page";
import { StoragePoolSortField } from "../../../../api/entities/storage-pool";
import { IStoragePoolV2DetailsItem } from "../../../../api/entities/storage-pool-v2";
import { ApiVersionNumber } from "../../../../api/services/data-service";
import { storagePoolServiceFactory } from "../../../../utils/factories/storage-pool-service-factory";
import { useApi } from "../../../../utils/hooks/use-api";
import { routeUtils } from "../../../../utils/route-utils";
import Button from "../../../shared-ui/button";
import IOption from "../../../shared-ui/common/option";
import { FormFieldError } from "../../../shared-ui/form/form-field-error/form-field-error";
import { Select } from "../../../shared-ui/select";
import SidePanelSection from "../../../shared-ui/side-panel-section/side-panel-section";
import AddIcon from '@mui/icons-material/Add';
import { PoolStorageNodesInfoSection } from "../../../v2/buckets/sections/pool-storage-nodes-info-section/pool-storage-nodes-info-section";
import styles from "./pool-section.module.scss";

export interface IPoolSectionV2Errors {
  storagePoolId?: string;
}

export interface IPoolSectionProps {
  storagePool: IIdName | IIdName<string>;
  autoFocus?: boolean;
  onChange?: (storagePool: IIdName | IIdName<string>) => void;
  isReadonly?: boolean;

  onNewStoragePoolClick?: () => void;
  version: ApiVersionNumber;
  errors?: IPoolSectionV2Errors;
  onError?: (errors: Partial<IPoolSectionV2Errors>) => void;
}

export const PoolSection = (props: PropsWithChildren<IPoolSectionProps>) => {
  const [options, setOptions] = useState<IOption[]>([]);
  const [api] = useApi();
  const service: any = storagePoolServiceFactory.getStoragePoolService(props.version);

  const [storagePool, setStoragePool] = useState<IStoragePoolV2DetailsItem>(null);

  function handleChange(poolId: number | string) {
    const option = options.find(o => o.value === poolId);
    props.onChange(option && { id: option.value, name: option.label });

    props.onError({
      storagePoolId: option.value ? null : 'Storage Pool is required'
    });

    api(service.get(poolId)).then(setStoragePool);
  }

  useEffect(() => {
    if (!props.isReadonly) {
      api(service.getPage({ fields: ["id", "name"], order: SortDirection.Ascending, field: StoragePoolSortField.Name })).then((page: IPage<any>) => {
        setOptions(page.data.map(pool => ({
          value: pool.id,
          label: pool.name
        })));
      })
    }

    // eslint-disable-next-line
  }, [props.isReadonly]);

  return (
    <SidePanelSection testId="storagePool" title="Storage Pool">
      {props.isReadonly && (
        <Button
          testId="storage-pool-link"
          colors="link" 
          href={routeUtils.getRouteToStoragePoolDetails(props.storagePool?.id, props.version)}>
          {props.storagePool?.name}
        </Button>
      )}
      {!props.isReadonly && (
          <>
            <Select
                testId="storage-pool-select"
                autoFocus={props.autoFocus}
                className={styles.select}
                placeholder="Choose one..."
                options={options}
                value={props.storagePool?.id}
                onChange={handleChange}
            />
            {props.onNewStoragePoolClick &&
                <Button
                    testId="new-storage-pool-link"
                    colors="primary"
                    onClick={props.onNewStoragePoolClick}>
                  <AddIcon />
                </Button>
            }
            <FormFieldError error={props.errors?.storagePoolId}/>
          </>
      )}

      <PoolStorageNodesInfoSection
          storagePool={storagePool}
          className={styles["pool-information-section"]}
      />
    </SidePanelSection>
  );
};
