import React, { PropsWithChildren } from "react";
import styles from "./filters-bar-item.module.scss";
import { IStyleableComponentProps } from "../common/component-props";
import clsx from "clsx";

export interface IFiltersBarItemProps extends IStyleableComponentProps {}

export function FiltersBarItem(props: PropsWithChildren<IFiltersBarItemProps>) {
  return (
    <div className={clsx(styles.root, props.className)} style={props.style}>
      {props.children}
    </div>
  );
}
