import { Schema, Validator } from 'jsonschema';
import { IEntity } from "../api/entities/id-name";
import { IPage } from "../api/entities/page";
import { IBucketListItem } from "../api/entities/bucket";
import { StorageProvider, StorageType } from "../api/entities/storage-node";
import { IStorageNodeV2 } from "../api/entities/storage-node-v2";
import { IStoragePoolV2ListItem } from "../api/entities/storage-pool-v2";
import { bucketService } from "../api/services/bucket-service";
import { StorageNodeV2Service } from "../api/services/storage-node-v2-service";
import { IGCSConfigProps } from "../components/storage-pools/sections/storage-node-section/storage-node-section";
import { storageNodeServiceFactory } from "./factories/storage-node-service-factory";
import { storagePoolServiceFactory } from "./factories/storage-pool-service-factory";

export interface IImportError extends IEntity {
    property: string;
    message: string
}

type onValidationErrorFunction = (errors: IImportError[]) => void;

class ImportUtils {
    private storageNodeV2Service = storageNodeServiceFactory.getStorageNodeService(2) as StorageNodeV2Service;

    async importBucket(jsonData: string, onValidationError: onValidationErrorFunction): Promise<IBucketListItem> {
        let data = this.parseJson(jsonData, "/Bucket", onValidationError);

        await this.validateBucket(data.repository);
        let storagePoolId = await this.validateStoragePool(data.repository.storagePool);

        let bucketCreateRequest = {
            id: data.repository.id,
            name: data.repository.name,
            immutable: data.repository.immutable,
            retainDeletedDays: data.repository.retainDeletedDays,
            retainPrevVerDays: data.repository.retainPrevVerDays,
            storagePoolId
        };

        return await bucketService.create(bucketCreateRequest);
    }

    async importGCSConfiguration(jsonData: string, onValidationError: onValidationErrorFunction): Promise<IGCSConfigProps> {
        return this.parseJson(jsonData, "/ConfigurationGCS", onValidationError);
    }

    private parseJson(jsonData: string, schema: string, onValidationError: onValidationErrorFunction) {
        let data;
        try {
             data = JSON.parse(jsonData);
        } catch (e) {
            console.error(e.message);
            throw new Error(`Provided file doesn't contain a correct json string.`);
        }

        let result = this.validator.validate(data, this.schemas[schema]);
        if (!result.valid) {
            let errors = result.errors.map((x, i) => ({ id: i, property: x.property, message: x.message}));
            onValidationError(errors);

            throw new Error(`Provided file has data errors.`);
        }

        return data;
    }

    private async validateBucket(bucket: any): Promise<void> {
        let buckets = await bucketService.getPage({ q: bucket.name, strict: true, pageSize: 0 });
        if (buckets.count > 0) {
            throw new Error(`Bucket with name '${bucket.name}' already exists.`);
        }
    }

    private async validateStoragePool(storagePool: any): Promise<string> {
        let service = storagePoolServiceFactory.getStoragePoolService(2);
        let storagePoolPage = await service.getPage({ q: storagePool.name, strict: true }) as IPage<IStoragePoolV2ListItem>;
        if (storagePoolPage.count === 0) {
            throw new Error(`Storage Pool with name '${storagePool.name}' doesn't exist.`);
        }

        let storagePoolId = storagePoolPage.data[0].id;
        let storageNodes = await this.storageNodeV2Service.getPage({ pool: storagePoolId, fields: ["bucket", "storageType", "types"] });

        this.validateStoragePoolContents(storagePool, storageNodes.data);

        return storagePoolId;
    }

    private validateStoragePoolContents(storagePool: any, storageNodes: IStorageNodeV2[]): void {
        let dict = {
            "keyNodes": StorageType.Key,
            "metaNodes": StorageType.Meta,
            "contentNodes": StorageType.Content
        };

        Object.keys(dict).forEach(prop => {
            let storageType = dict[prop];
            let storageNodesSelection = storageNodes.filter(x => x.types.includes(storageType));

            if (storagePool[prop].redundancyLevel !== storagePool[prop].storage.length) {
                throw new Error(`Storage Pool redundancy level '${storagePool.redundancyLevel}' doesn't match the number of storages '${storagePool[prop].storage.length}'.`);
            }

            storagePool[prop].storage.forEach(node => {
                let index = storageNodesSelection.findIndex(x => x.storageType === node.type && x.storageType === "ABS" ? x.bucket === node.container : x.bucket === node.bucket);
                if (index === -1) {
                    throw new Error(`Storage Pool with name '${storagePool.name}' described in the provided file has a different set of storage nodes comparing to the existing one. Please consider either correcting the file or creating a new storage pool instead.`);
                }
            });
        });
    }

    private _validator: Validator = null;
    private _schemas: { [id: string]: Schema } = null;

    private get validator(): Validator {
        if (this._validator !== null) {
            return this._validator;
        }

        let validator = new Validator();
        Object.keys(this.schemas).forEach(url => validator.addSchema(this.schemas[url], url));
        return validator;
    }

    private get schemas(): {[id: string]: Schema} {
        if (this._schemas !== null) {
            return this._schemas;
        }

        const bucketSchema: Schema = {
            id: "/Bucket",
            type: "object",
            properties: {
                "repository": {
                    type: "object",
                    properties: {
                        "id": { type: "string", format: "uuid" },
                        "name": { type: "string" },
                        "immutable": { type: "boolean" },
                        "retainPrevVerDays": { type: "integer" },
                        "retainDeletedDays": { type: "integer" },
                        "storagePool": { $ref: "/StoragePool" }
                    },
                    required: ["id", "name", "immutable"]
                }
            },
            required: ["repository"]
        };

        const storagePoolSchema: Schema = {
            id: "/StoragePool",
            type: "object",
            properties: {
                "id": { type: "string", format: "uuid" },
                "name": { type: "string" },
                "metaNodes": { $ref: "/StorageNodeStoragePool" },
                "keyNodes": { $ref: "/StorageNodeStoragePool" },
                "contentNodes": { $ref: "/StorageNodeStoragePool" }
            }
        };

        const storageNodeStoragePoolSchema: Schema = {
            id: "/StorageNodeStoragePool",
            type: "object",
            properties: {
                "storage": {
                    type: "array",
                    minItems: 3,
                    maxItems: 6,
                    items: {
                        anyOf: [
                            { $ref: "/StorageNodeS3" },
                            { $ref: "/StorageNodeS3C" },
                            { $ref: "/StorageNodeABS" },
                            { $ref: "/StorageNodeGCS" },
                        ]
                    }
                },
                "redundancyLevel": { type: "integer", minimum: 3, maximum: 6 },
                "securityLevel": { type: "integer", minimum: 2, maximum: 5 },
            }
        };

        const getStorageNodeProperties = (provider: StorageProvider): { [name: string]: Schema } => {
            let properties = {
                "type": { type: "string" },
                "nodeId": {
                    oneOf: [
                        { type: "string", format: "uuid" },
                        { type: "integer", minimum: 0 },
                    ]
                }
            };

            if (provider !== StorageProvider.S3Compatible) {
                properties["region"] = { type: "string" };
            }

            if (provider === StorageProvider.ABS) {
                properties["container"] = { type: "string" };
            } else {
                properties["bucket"] = { type: "string" };
            }

            return properties;
        };

        const storageNodeS3Schema: Schema = {
            id: "/StorageNodeS3",
            type: "object",
            properties: getStorageNodeProperties(StorageProvider.S3),
        };
        const storageNodeS3CSchema: Schema = {
            id: "/StorageNodeS3C",
            type: "object",
            properties: getStorageNodeProperties(StorageProvider.S3Compatible),
        };
        const storageNodeABSSchema: Schema = {
            id: "/StorageNodeABS",
            type: "object",
            properties: getStorageNodeProperties(StorageProvider.ABS),
        };
        const storageNodeGCSSchema: Schema = {
            id: "/StorageNodeGCS",
            type: "object",
            properties: getStorageNodeProperties(StorageProvider.GCS),
        };

        const configurationGCSSchema: Schema = {
            id: "/ConfigurationGCS",
            type: "object",
            properties: {
                "type": { const: "service_account" },
                "project_id": { type: "string" },
                "private_key_id": { type: "string" },
                "private_key": { type: "string" },
                "client_email": { type: "string" },
                "client_id": { type: "string" },
                "auth_uri": { type: "string", format: "uri" },
                "token_uri": { type: "string", format: "uri" },
                "auth_provider_x509_cert_url": { type: "string", format: "uri" },
                "client_x509_cert_url": { type: "string", format: "uri" },
            }
        };

        this._schemas = {
            "/Bucket": bucketSchema,
            "/StoragePool": storagePoolSchema,
            "/StorageNodeStoragePool": storageNodeStoragePoolSchema,
            "/StorageNodeS3": storageNodeS3Schema,
            "/StorageNodeS3C": storageNodeS3CSchema,
            "/StorageNodeABS": storageNodeABSSchema,
            "/StorageNodeGCS": storageNodeGCSSchema,

            "/ConfigurationGCS": configurationGCSSchema
        };

        return this._schemas;
    }
}

export const importUtils = new ImportUtils();
