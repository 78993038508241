import { Entity } from "../../api/entities/id-name";
import { IBucketListItem } from "../../api/entities/bucket";
import { IDeleteBlockedConfirmationData } from "../../components/v2/buckets/dialogs/delete-blocked-confirmation/delete-blocked-confirmation";
import { DialogActions } from "./dialog";

const BUCKET_ENTITY_NAME = 'bucket';

export const DELETE_CONFIRMATION_DIALOG_NAME = "deleteConfirmation";
export const DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME = "deleteBlockedConfirmation";

export const getBucketEntityName = () => BUCKET_ENTITY_NAME;

class BucketActions<TListItem extends Entity = IBucketListItem> {
    readonly entity;
    readonly deleteConfirmation;
    readonly deleteBlockedConfirmation;

    constructor() {
        let entityName = BUCKET_ENTITY_NAME;
        this.deleteConfirmation = new DialogActions<TListItem>(entityName, DELETE_CONFIRMATION_DIALOG_NAME);
        this.deleteBlockedConfirmation = new DialogActions<IDeleteBlockedConfirmationData>(entityName, DELETE_BLOCKED_CONFIRMATION_DIALOG_NAME);
    }
}

export const bucketActions = new BucketActions();
