import React, { useRef, useState } from "react";
import styles from "./multi-email.module.scss";
import IComponentProps from "../../common/component-props";
import clsx from "clsx";
import ClearIcon from "@mui/icons-material/Clear";
import { validators } from "../../../../utils/validators";
import PopupMenu, { IPopupMenuOption } from "../../popup-menu";
import { PopupType } from "../../popup";
import { KEYS } from "../../../../constants/keys";

const requestContactsTimeout = 300;

interface IMultiEmailProps extends IComponentProps {
  testId: string;
  emails: string[];
  name?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChange: (value: string[]) => void;
  onBlur?: (value: string[]) => void;
  onValidated?: (error: string) => void;
  contactsProvider?: (q: string) => Promise<{contacts: IMultiEmailContact[], q: string}>;
}

export interface IMultiEmailContact {
  email: string;
}

export function MultiEmail(props: IMultiEmailProps) {
  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = useRef(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [contacts, setContacts] = useState<IPopupMenuOption[]>(null);
  const [requestContactsTimeoutId, setRequestContactsTimeoutId] = useState<number>(null);
  const searchInProgressRef = useRef(0);

  function handleDelete(email: string) {
    props.onChange(props.emails.filter(item => item !== email));    
  }

  function selectContact(email: string) {
    addEmail(email);
    stopContactsRequest();
    inputRef.current.focus();
  }

  function stopContactsRequest() {
    setContacts(null);
    setShowPopup(false);
    if (requestContactsTimeoutId) {
      clearTimeout(requestContactsTimeoutId);
      setRequestContactsTimeoutId(null);
    }
  }

  function requestContacts(value: string) {
    stopContactsRequest();
    const timeout = window.setTimeout(() => { 
      setRequestContactsTimeoutId(null);
      searchInProgressRef.current += 1;
      setShowPopup(true);
      props.contactsProvider(value).then((data) => {
        if (inputRef.current.value === data.q) {
          if (data.contacts.length > 0) {
            const contacts: IPopupMenuOption[]  = data.contacts.map(c => ({ label: c.email, value: c.email, onClick: (e, value) => selectContact(c.email) }));
            contacts[0].className = styles["active-contact"];
            setContacts(contacts);
          }
          else if (searchInProgressRef.current === 1) {
            setContacts([{ label: "Nothing found", value: null, disabled: true, onClick: (e, value) => selectContact(value) }]);
          }
        }
      }).finally(() => { 
        searchInProgressRef.current -= 1;
      })
    }, requestContactsTimeout);
    setRequestContactsTimeoutId(timeout);
  }

  function getActiveContact(): IPopupMenuOption {
    if (contacts) {
      return contacts.find(contact => contact.className);
    }
    return null;
  }


  function selectNextContact() {
    if (contacts) {
      const activeContact = getActiveContact();
      const index = contacts.indexOf(activeContact);
      if (index >= 0 && index < contacts.length - 1) {
        activeContact.className = null;
        contacts[index + 1].className = styles["active-contact"];
        setContacts([...contacts]);
      } 
    }
  }

  function selectPreviousContact() {
    if (contacts) {
      const activeContact = getActiveContact();
      const index = contacts.indexOf(activeContact);
      if (index) {
        activeContact.className = null;
        contacts[index - 1].className = styles["active-contact"];
        setContacts([...contacts]);
      } 
    }
  }

  function handleInputChange(value: string) {
    setInputValue(value);
    props.onValidated(null);
    if (props.contactsProvider) {
      if (value && value.trim()) {
        requestContacts(value);
      }
      else {
        stopContactsRequest();
      }
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === KEYS.Escape && showPopup) {
      stopContactsRequest();
    }
    else if (e.key === KEYS.ArrowUp && showPopup) {
      e.preventDefault();
      selectPreviousContact();
    }
    else if (e.key === KEYS.ArrowDown && showPopup) {
      e.preventDefault();
      selectNextContact();
    }
    else if (e.key === KEYS.Enter && getActiveContact()) {
      const activeContact = getActiveContact();
      if (activeContact) {
        selectContact(activeContact.value);
      }
    }
    else if ([KEYS.Enter, " ", ",", ";"].includes(e.key)) {
      addEmail();
    }
    if (!inputValue && e.key === KEYS.Backspace && props.emails.length > 0) {
      props.onChange(props.emails.slice(0, props.emails.length - 1));
    }
  };

  function addEmail(selectedEmail?: string): string[] {
    const value = selectedEmail || inputValue.trim();
    const error = validate(value);
    let emails = props.emails;

    stopContactsRequest();

    if (value && !error) {
      setInputValue("");
      emails = [...emails, value];
      props.onChange(emails);
    }
    props.onValidated(error);
    return emails;
  }

  function validate(email: string) {
    if (email && props.emails.includes(email)) {
      return `${email} has already been added.`;
    }

    if (email && !validators.isEmail(email)) {
      return `'${email}' is not a valid email address.`;
    }
    return null;
  }

  return (
    <div className={clsx(styles.root, props.className)}>
      <div className={styles["input-container"]}>
        {props.emails.map(email => (
          <div className={styles["chip"]} key={email}>
            <div
              className={styles["delete-icon"]}
              onClick={() => handleDelete(email)}
              >
              <ClearIcon fontSize="inherit"/>
            </div>
            <div className={styles["chip-text"]}>
            {email}
            </div>
          </div>
        ))}
        <input
          ref={inputRef}
          autoComplete="off"
          autoFocus={props.autoFocus}
          name={props.name}
          data-testid={props.testId}
          placeholder={props.placeholder}
          className={styles.input}
          disabled={props.disabled}
          style={props.style}
          value={inputValue}
          onChange={e => handleInputChange(e.target.value)}
          onBlur={e => {
            const emails = addEmail(e.target.value);
            props.onBlur && props.onBlur(emails); 
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
      <PopupMenu
        show={showPopup}
        onClose={() => setShowPopup(false)}
        popupType={PopupType.FitToParent}
        options={contacts || [{ 
          label: "Searching...", 
          value: null, 
          disabled: true, 
          onClick: () => {}
        }]}
        className={styles.popup}
      />
    </div>
  );
}
