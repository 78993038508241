import React from "react";
import { FileIcon } from "../file-icon/file-icon";
import styles from "./files-list.module.scss";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Button from "../../../../../shared-ui/button";
import {ReactComponent as UploadIcon} from '../../../../../../assets/file_upload_black_24dp.svg';

export interface IFile {
  fileData: File;
  uploadProgress?: number;
  chunkUri?: string;
  fileId?: string;
}

export interface IFilesListProps {
  files: IFile[];
  onChange: (files: IFile[]) => void;
}

export function FilesList(props: IFilesListProps) {

  function handleFilesSelected(files: FileList) {
    const newFiles: IFile[] = [...files].map(fileData => ({ fileData }));
    props.onChange([...props.files, ...newFiles]);
  }

  function handleDelete(file: File) {
    props.onChange(props.files.filter(f => f.fileData !== file));
  }

  function clearAll() {
    props.onChange([]);
  }

  return (
    <div className={styles.root}>
      {props.files.length === 0 && (
        <div className={styles["no-files"]}>
            <UploadIcon/>
            <div className={styles["drag-files-here"]}>Drag Files Here</div>
            <label className={styles["add-file-button"]}>
              <input type="file" multiple={true} onChange={(e) => {
                handleFilesSelected(e.target.files)
                e.target.value = "";
              }}/>
              Browse Files
            </label>
        </div>
      )}
      {props.files.length > 0 && (
        <div className={styles.files}>
          <div className={styles.toolbar}>
            <div className={styles["files-count"]}>
              <div className={styles["files-count-chip"]}>
                {props.files.length}
              </div>
              <div>
                {`File${props.files.length === 1 ? "" : "s"} Selected`} 
              </div>
            </div>
            <div>
              {!!props.files.length && (
                <Button testId="clear-all-button" colors="link" onClick={clearAll}>
                  Clear All
                </Button>
              )}
            </div>

          </div>
          <ul className={styles.list}>
            {props.files.map((file, index) => (
              <li key={index} className={styles["list-item"]}>
                  <div onClick={() => handleDelete(file.fileData)} className={styles["delete-file-icon"]}>
                    <ClearOutlinedIcon fontSize="inherit"/>
                  </div>
                  <FileIcon fileName={file.fileData.name}/>
                  <div className={styles["file-name"]}>
                    {file.fileData.name}
                  </div>
              </li>
            ))}
          </ul>
          <div className={styles.footer}>
            <label className={styles["add-file-button"]}>
              <input type="file" multiple={true} onChange={(e) => {
                handleFilesSelected(e.target.files)
                e.target.value = "";
              }}/>
              + Add More Files
            </label>
          </div>
        </div>
      )}
    </div>
  );
}