import React from "react";
import { IFile } from "../../../client/addons/citrix-share-file/components/files-list/files-list";
import Button, { IButtonProps } from "../../../shared-ui/button";
import styles from "./import-button.module.scss";

export interface ImportButtonProps extends Pick<IButtonProps, 'testId' | 'colors'> {
    text?: string;
    file: IFile;
    onChange: (file: IFile) => void;
}

export function ImportButton(props: ImportButtonProps) {
    const hiddenFileInput = React.useRef(null);

    function handleFilesSelected(files: FileList) {
        props.onChange({ fileData: files[0] });
    }

    return (
        <>
            <Button
                testId={props.testId}
                colors={props.colors}
                onClick={() => {
                    hiddenFileInput.current.click();
                }}
            >
                {props.text ?? "Import"}
            </Button>

            <input
                type="file"
                multiple={false}
                accept=".json"
                className={styles["import-hidden-input"]}
                ref={hiddenFileInput}
                onChange={(e) => {
                    handleFilesSelected(e.target.files)
                    e.target.value = "";
                }}
            />
        </>
    );
}
