import React from "react";
import { IFormField } from "../../../../../../utils/form-utils";
import { FormFieldError } from "../../../../../shared-ui/form/form-field-error/form-field-error";
import { FormFieldValue } from "../../../../../shared-ui/form/form-field-value/form-field-value";
import { FormField } from "../../../../../shared-ui/form/form-field/form-field";
import { IMultiEmailContact, MultiEmail } from "../../../../../shared-ui/input/multi-email/multi-email";
import TextInput from "../../../../../shared-ui/input/text-input/text-input";
import Textarea from "../../../../../shared-ui/input/textarea/textarea";
import { AccountsService } from "../../api/services/accounts-service";
import styles from "./email-form.module.scss";

const maxMessageLength = 3999;

export interface IEmailForm {
  Emails: IFormField<string[]>;
  Subject: IFormField<string>;
  Body: IFormField<string>;
}

export interface IEmailFormProps {
  form: IEmailForm;
  accountsService: AccountsService;
}

export function EmailForm(props: IEmailFormProps) {
  const { form } = props;

  function loadContacts(q: string): Promise<{contacts: IMultiEmailContact[], q: string}> {
    return props.accountsService.getAddressBookPage({ skip: 0, top: 5, q}).then((data) => {
      return {
        contacts: data.value.map(contact => ({ email: contact.Email })),
        q
      };
    });
  }

  return (
    <>
      <FormField className={styles.field} label="Recipients:">
        <FormFieldValue>
          <MultiEmail
            testId="emails"
            emails={form.Emails.value}
            onChange={form.Emails.onChange}
            onBlur={form.Emails.onChange}
            onValidated={form.Emails.onError}
            contactsProvider={loadContacts}
          />
        </FormFieldValue>
        <FormFieldError error={form.Emails.controlError || form.Emails.error}/>
      </FormField>

      <FormField className={styles.field} label="Subject:">
        <FormFieldValue>
          <TextInput
            testId="subject-input"
            field={form.Subject}
          />
        </FormFieldValue>
        <FormFieldError error={form.Subject.error}/>
      </FormField>

      <FormField className={styles["message-field"]} label="Message:">
        <FormFieldValue className={styles["message-value"]}>
          <Textarea
            testId="message-input"
            field={form.Body}
          />
        </FormFieldValue>
        <div className={styles["message-length"]}>
          {`${form.Body.value.length}/${maxMessageLength}`}
        </div>
        <FormFieldError className={styles["message-error"]} error={form.Body.error}/>
      </FormField>
    </>
  );
}