import React from "react";
import { CONFIG } from "../../../../config/config";
import { IFormField } from "../../../../utils/form-utils";
import FormSection from "../../../shared-ui/form/form-section/form-section";
import { NumberInput } from "../../../shared-ui/input/number-input/number-input";
import styles from "./storage-reconstruction-section.module.scss";


export interface IStorageReconstructionSectionProps {
  isReadOnly: boolean;
  contentSecurityLevel: IFormField<number>;
  keySecurityLevel: IFormField<number>;
  metaSecurityLevel: IFormField<number>;
  maxContentSecurityLevel?: number;
  maxKeySecurityLevel?: number;
  maxMetaSecurityLevel?: number;
}

export function StorageReconstructionSection(props: IStorageReconstructionSectionProps) {
  return (   
    <FormSection 
      testId="storage-reconstruction-section" 
      title="Storage Reconstruction"
      description={props.isReadOnly ? 
        "The required number of storage nodes for data reconstruction" : 
        "Please choose the required number of storage nodes for data reconstruction"
      }
    >
      {props.isReadOnly && (
        <div data-testid="security-params" className={styles["security-params--readonly"]}>
          <div data-testid="content" className={styles["security-param--readonly"]}>
            {`Content: ${props.contentSecurityLevel.value}`}
          </div>
          <div data-testid="key" className={styles["security-param--readonly"]}>
            {`Key: ${props.keySecurityLevel.value}`}
          </div>
          <div data-testid="metadata" className={styles["security-param--readonly"]}>
            {`Metadata: ${props.metaSecurityLevel.value}`}
          </div>

        </div>
      )}

      {!props.isReadOnly && (

        <div data-testid="security-params" className={styles["security-params"]}>

          <div data-testid="content" className={styles["security-param"]}>
            <label className={styles["param-label"]}>
              Content:
            </label>
            <div className={styles["param-value"]}>
              <NumberInput
                testId="content"
                min={CONFIG.minSecurityLevel}
                max={props.maxContentSecurityLevel}
                field={props.contentSecurityLevel}
                showButtons={true}
              />
            </div>
          </div>

          <div data-testid="key" className={styles["security-param"]}>
            <label className={styles["param-label"]}>
              Key:
            </label>
            <div className={styles["param-value"]}>
              <NumberInput
                testId="key"
                min={CONFIG.minSecurityLevel}
                max={props.maxKeySecurityLevel}
                field={props.keySecurityLevel}
                showButtons={true}
              />
            </div>
          </div>

          <div data-testid="metadata" className={styles["security-param"]}>
            <label className={styles["param-label"]}>
              Metadata:
            </label>
            <div className={styles["param-value"]}>
              <NumberInput
                testId="metadata"
                min={CONFIG.minSecurityLevel}
                max={props.maxMetaSecurityLevel}
                field={props.metaSecurityLevel}
                showButtons={true}
              />
            </div>
          </div>

        </div>
      )}
    </FormSection>
  );
}