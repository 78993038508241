import React, { useEffect, useState } from "react";
import { IBlackoutListItem } from "../../../../api/entities/blackouts";
import { restoreFileService } from "../../../../api/services/blackouts-service";
import { Formatters } from "../../../../helper/formatters";
import { useApi } from "../../../../utils/hooks/use-api";
import { usePopup } from "../../../../utils/hooks/use-popup";
import { createRemoveColumn } from "../../../shared-ui/compact-table/columns/remove-column";
import { CompactTable } from "../../../shared-ui/compact-table/compact-table";
import { ConfirmDialog } from "../../../shared-ui/dialogs/common/confirm-dialog/confirm-dialog";
import { LocalLoader } from "../../../shared-ui/loader";
import { IColumnDefinition } from "../../../shared-ui/table/table";
import styles from "./restoration-history-section.module.scss";

export interface IRestorationHistorySectionProps { 
  uid?: string;
  storagePoolId?: number;
  sharingInstanceId?: number;
}

export function RestorationHistorySection(props: IRestorationHistorySectionProps) {
  const [listApi, isListLoading] = useApi();
  const [deleteApi, isDeleteLoading] = useApi();
  const [blackouts, setBlackouts] = useState<IBlackoutListItem[]>([]);
  const [isUndoConfirmationOpen, openUndoConfirmation, closeUndoConfirmation, undoConfirmationData] = usePopup<IBlackoutListItem>();  
  const columns: IColumnDefinition<IBlackoutListItem, null>[] = [
    {
      label: "Executed on",
      className: styles["col-executed-on"],
      formatter: row => Formatters.formatDateTime(row.endTime),
    },
    {
      label: "Restored to",
      className: styles["col-restored-to"],
      formatter: row => Formatters.formatDateTime(row.beginTime),
    },
    {
      label: "Restored by",
      className: styles["col-restored-by"],
      formatter: row => row.Creator.email,
    },
    createRemoveColumn<IBlackoutListItem>(openUndoConfirmation, "Undo")
  ];

  function undoRestore() {
    closeUndoConfirmation();

    let deleteResult: Promise<void>;

    if (props.uid) {
      deleteResult = deleteApi(restoreFileService.deleteUserBlackout(undoConfirmationData.id));
    }
    else if(props.storagePoolId) {
      deleteResult = deleteApi(restoreFileService.deleteStoragePoolBlackout(undoConfirmationData.id));
    }
    else if(props.sharingInstanceId) {
      deleteResult = deleteApi(restoreFileService.deleteSharingInstanceBlackout(undoConfirmationData.id));
    }

    deleteResult.then(() => {
      setBlackouts(blackouts.filter(restoration => restoration !== undoConfirmationData));
    });
  }

  useEffect(() => {
    if (props.uid) {
      listApi(restoreFileService.getUserBlackouts(props.uid))
        .then((page) => setBlackouts(page.data));
    }
    else if(props.storagePoolId) {
      listApi(restoreFileService.getStoragePoolBlackouts(props.storagePoolId))
        .then((page) => setBlackouts(page.data));
    }
    else if(props.sharingInstanceId) {
      listApi(restoreFileService.getSharingInstanceBlackouts(props.sharingInstanceId))
        .then((page) => setBlackouts(page.data));
    }
  // eslint-disable-next-line
  }, [props.uid, props.storagePoolId, props.sharingInstanceId]);

  return (
    <>
      <div className={styles.root}>
        {isListLoading && (
          <LocalLoader isLoading={isListLoading}>{" "}</LocalLoader>
        )}
        {!isListLoading && blackouts.length > 0 && (
          <div data-testid="restoration-history">
            <h2 className={styles["title"]}>Restoration History</h2>
            <div data-testid="description" className={styles["description"]}>
              Files created and changed within the restoration period are hidden.
            </div>
            <CompactTable
              testId="restoration-history-table"
              rows={blackouts || []}
              columns={columns}
              loading={isDeleteLoading}
            />
          </div>
        )}
      </div>

      <ConfirmDialog
        testId="undo-restoration-confirmation"
        title="Undo Restore"
        confirmText="Undo Restore"
        open={isUndoConfirmationOpen}
        onClose={closeUndoConfirmation}
        onConfirm={undoRestore}
      >
        <div className={styles["undo-confirmation-content"]}>
          Are you sure you want to undo this restore?<br/>
          Files hidden from this period will be returned to the user's Myota client.
        </div>
      </ConfirmDialog>
    </>
 );
}