import React, { useContext, useEffect, useState } from "react";
import { IEntity, IHasOwner, IIdName } from "../../../../api/entities/id-name";
import { IPage } from "../../../../api/entities/page";
import { IStorageNode, StorageNodeSortField, StorageProvider } from "../../../../api/entities/storage-node";
import { getBucketContainer, getReplacedByStorageNodeId, getStorageProvider, IStorageNodesV2RequestParams, IStorageNodeV2, IStorageNodeV2FilterParams } from "../../../../api/entities/storage-node-v2";
import { CONFIG } from "../../../../config/config";
import { getDefaultRequestParams, getEmptyPage } from "../../../../redux/reducers/entity";
import { storageNodeServiceFactory } from "../../../../utils/factories/storage-node-service-factory";
import { useApi } from "../../../../utils/hooks/use-api";
import { storageUtils } from "../../../../utils/storage-utils";
import Button from "../../../shared-ui/button";
import DetailsPanel from "../../../shared-ui/details-panel/details-panel";
import { FormPanel } from "../../../shared-ui/form/form-panel/form-panel";
import Table, { IColumnDefinition, TableSelected } from "../../../shared-ui/table/table";
import { defaultFilters, IStorageNodeFilters, StorageNodeListFilter, StorageNodeListFilters } from "../../filters/storage-node-list-filters/storage-node-list-filters";
import { VersionContext } from "../../storage-pools";
import styles from "./select-storage-nodes-panel.module.scss";

export interface IStorageNodePanelProps {
    open: boolean;
    onClose: () => void;
    onOpenStorageNodePanel: () => void;
    lastAddedStorageNode: IStorageNode | IStorageNodeV2;
    selection: TableSelected<IStorageNodeListItem>;
    setSelection: (selection: TableSelected<IStorageNodeListItem>) => void;
}

export interface IStorageNodeListItem
    extends IEntity<string>,
        Pick<IStorageNodeV2, 'name' | 'bucket' | 'status' | 'region' | 'props'>,
        IHasOwner {
    storageType: StorageProvider;
    replacedByStorageNodeId: number | string;

    storagePools: IIdName<string>[];
}

export function toStorageNodeListItem(node: IStorageNode | IStorageNodeV2): IStorageNodeListItem {
    return {
        id: String(node.id),
        name: node.name,
        status: node.status,
        region: node.region,
        props: node.props,
        storageType: getStorageProvider(node),
        bucket: getBucketContainer(node),
        replacedByStorageNodeId: getReplacedByStorageNodeId(node),
        storagePools: "storagePools" in node ? node.storagePools : [],
        ownerId: "ownerId" in node ? node.ownerId : '',
        owner: "owner" in node ? node.owner : undefined
    };
}

export const SelectStorageNodesPanel = (props: IStorageNodePanelProps) => {
    const { open } = props;
    const version = useContext(VersionContext);

    const [storageNodePage, setStorageNodePage] = useState<IPage<IStorageNodeListItem>>(getEmptyPage());
    const [api, loading] = useApi();
    const storageNodeService: any = storageNodeServiceFactory.getStorageNodeService(version);

    const [params, setParams] = useState<IStorageNodesV2RequestParams>({ ...getDefaultRequestParams(), deleted: false });
    const [filterSearchParams, setFilterSearchParams] = useState<IStorageNodeFilters>(defaultFilters);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const isSufficientStorageNodes = () =>  Object.keys(props.selection).length >= CONFIG.minStoragesCount;

    const columns: IColumnDefinition<IStorageNodeListItem, StorageNodeSortField>[] = [
        {
            sortKey: StorageNodeSortField.Name,
            label: "Name",
            className: styles["col--storage-node-name"],
            formatter: row => row.name
        } as IColumnDefinition<IStorageNodeListItem, StorageNodeSortField>,
        {
            sortKey: StorageNodeSortField.Provider,
            label: "Provider",
            className: styles["col--storage-node-provider"],
            formatter: row => storageUtils.getStorageProviderName(row.storageType)
        } as IColumnDefinition<IStorageNodeListItem, StorageNodeSortField>,
        {
            sortKey: StorageNodeSortField.Bucket,
            label: "Bucket / Container",
            className: styles["col--storage-node-bucket"],
            formatter: row => row.bucket
        } as IColumnDefinition<IStorageNodeListItem, StorageNodeSortField>,
    ];

    function updateFilters(filters: Partial<IStorageNodeFilters>) {
        const mapToParams = (x: Partial<IStorageNodeFilters>): IStorageNodeV2FilterParams => {
            let providers;
            if (!!x.provider?.value) {
                providers = [x.provider.value];

                if (x.provider.value === 'S3') {
                    providers.push('s3p');
                }
            }

            return { q: x.q, providers };
        };

        let updatedFilterParams = { ...filterSearchParams, ...filters };

        updateSearchParams({ ...mapToParams(updatedFilterParams), offset: 0 });
        setFilterSearchParams(updatedFilterParams);
    }

    function updateSearchParams(sp: IStorageNodesV2RequestParams) {
        setParams(searchParams => ({
            ...searchParams,
            ...sp,
        }));
    }

    function fetchData(params: IStorageNodesV2RequestParams) {
        api(storageNodeService.getPage(params))
            .then((page: IPage<IStorageNode> | IPage<IStorageNodeV2>) => ({ count: page.count, data: page.data.map(x => toStorageNodeListItem(x)) }))
            .then(setStorageNodePage);
    }

    useEffect(() => {
        fetchData(params);
        // eslint-disable-next-line
    }, [params]);

    useEffect(() => {
        if (!!props.lastAddedStorageNode) {
            fetchData(params);

            props.setSelection({
                ...props.selection,
                [props.lastAddedStorageNode.id]: toStorageNodeListItem(props.lastAddedStorageNode)
            });
        }
        // eslint-disable-next-line
    }, [props.lastAddedStorageNode]);

    useEffect(() => {
        if (!isSufficientStorageNodes()) {
            setErrorMessage(`Myota requires at least ${CONFIG.minStoragesCount} storage nodes.`);
        }
        else {
            setErrorMessage('');
        }
        // eslint-disable-next-line
    }, [props.selection]);

    return (
        <>
            <DetailsPanel
                isLoading={false}
                open={open}
                onCloseClick={props.onClose}
                title="Select Storage Nodes"
                className={styles.panel}
            >
                {open && (
                    <FormPanel
                        okButtonText="Select"
                        okButtonClick={() => props.onClose()}
                        okButtonDisabled={!isSufficientStorageNodes()}
                        errorMessage={errorMessage}
                    >
                        <Button
                            testId="add-button"
                            colors="primary"
                            onClick={props.onOpenStorageNodePanel}
                        >
                            Add Storage Node
                        </Button>

                        <div className={styles["border"]} data-testid="select-storage-nodes-list">
                            <StorageNodeListFilters
                                filterSearchParams={filterSearchParams}
                                visibleFilters={[StorageNodeListFilter.Search, StorageNodeListFilter.Provider]}
                                onFiltersChange={updateFilters}
                            />
                            <Table
                                autoHidePaging={false}
                                className={styles.table}
                                compact={true}
                                columns={columns}
                                rows={storageNodePage.data}
                                loading={loading}
                                totalRows={storageNodePage.count}
                                testId="storage-node-table"
                                requestParams={params}
                                onRequestParamsChanged={updateSearchParams}
                                hideCheckbox={false}
                                selected={props.selection}
                                onSetSelected={props.setSelection}
                            />
                        </div>
                    </FormPanel>
                )}
            </DetailsPanel>
        </>
    );
}
