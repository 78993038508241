import React from "react";
import styles from "./filters-data-item.module.scss";
import ClearIcon from "@mui/icons-material/Clear";
import Chip from "@mui/material/Chip";

export interface IFiltersDataItemProps {
  label: string;
  onDelete: () => void;
}

export function FiltersDataItem(props: IFiltersDataItemProps) {
  return (
    <Chip
      data-testid="filters-data-item"
      label={props.label}
      deleteIcon={<ClearIcon/>}
      onDelete={() => props.onDelete()}
      classes={{ root: styles.root }}
    />
  );
}