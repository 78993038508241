import React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IBucketFilterParams } from "../../../../api/entities/bucket";
import { IIdConsoleName, IIdName } from "../../../../api/entities/id-name";
import { IPolicyFilterParams } from "../../../../api/entities/policy";
import { ISharingProfileFilterParams } from "../../../../api/entities/sharing-profile";
import { BUCKETS_V2_ROUTE, SHARING_PROFILES_ROUTE, STORAGE_PROFILES_ROUTE } from "../../../../constants/routes";
import { storagePoolV1Actions, storagePoolV2Actions } from "../../../../redux/actions/storage-pool";
import { IAppState } from "../../../../redux/reducers";
import { useNavigate } from "../../../../utils/hooks/use-navigate";
import { ConfirmDialog } from "../../../shared-ui/dialogs/common/confirm-dialog/confirm-dialog";
import styles from "./delete-blocked-confirmation.module.scss";


const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    closeConfirmation: () => {
      dispatch(storagePoolV1Actions.deleteBlockedConfirmation.close());
      dispatch(storagePoolV2Actions.deleteBlockedConfirmation.close());
    }
  };
};

const mapStateToProps = (state: IAppState) => ({
  isOpen: state.storagePoolV1.deleteBlockedConfirmation.isOpen || state.storagePoolV2.deleteBlockedConfirmation.isOpen,
  data: state.storagePoolV1.deleteBlockedConfirmation.data ?? state.storagePoolV2.deleteBlockedConfirmation.data,
});

export interface IDeleteBlockedConfirmationData {
  storagePool: IIdName | IIdName<string>;
}

export interface IDeleteBlockedConfirmationDataV1 extends IDeleteBlockedConfirmationData {
  storageProfiles?: IIdName[];
  sharingProfiles?: IIdConsoleName[];
}

export interface IDeleteBlockedConfirmationDataV2 extends IDeleteBlockedConfirmationData {
  buckets?: IIdName<string>[];
}

export interface IDeleteBlockedConfirmationProps extends 
    ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {
}

export const DeleteBlockedConfirmation = connect(mapStateToProps, mapDispatchToProps)((props: IDeleteBlockedConfirmationProps) => {
  const navigate = useNavigate();
  const sharingProfiles = !!props.data && 'sharingProfiles' in props.data && props.data.sharingProfiles;
  const storageProfiles = !!props.data && 'storageProfiles' in props.data && props.data.storageProfiles;
  const buckets = !!props.data && 'buckets' in props.data && props.data.buckets;

  const isSingleDependency = sharingProfiles?.length === 1 || storageProfiles?.length === 1 || buckets?.length === 1;
  const dependencyName = sharingProfiles?.length > 0 || storageProfiles?.length > 1 ?
      isSingleDependency ? 'Profile': 'Profiles' :
      isSingleDependency ? 'Bucket': 'Buckets';

  function handleConfirm() {
    props.closeConfirmation();
    if (sharingProfiles?.length) {
      navigate<ISharingProfileFilterParams>(SHARING_PROFILES_ROUTE, { storagePoolFilter: Number(props.data.storagePool.id) });
    }
    if (storageProfiles?.length) {
      navigate<IPolicyFilterParams>(STORAGE_PROFILES_ROUTE, { storagePoolFilter: Number(props.data.storagePool.id) });
    }
    if (buckets?.length) {
      navigate<IBucketFilterParams>(`${BUCKETS_V2_ROUTE}/`, { pool: String(props.data.storagePool.id) });
    }
  }

  function getUsedIn() {
    if (sharingProfiles?.length > 1) {
      return `${sharingProfiles?.length} different sharing profiles`;
    }
    if (storageProfiles?.length > 1) {
      return `${storageProfiles?.length} different storage profiles`;
    }
    if (buckets?.length > 1) {
      return `${buckets?.length} different buckets`;
    }
    if (sharingProfiles?.length === 1) {
      return (<><strong>{sharingProfiles[0].consoleName}</strong> sharing profile</>);
    }
    if (storageProfiles?.length === 1) {
      return (<><strong>{storageProfiles[0].name}</strong> storage profile</>);
    }
    if (buckets?.length === 1) {
      return (<><strong>{buckets[0].name}</strong> bucket</>);
    }
    return null;
  }

  return (
    <ConfirmDialog
      testId="delete-storage-pool-confirmation"
      title="Delete Storage Pool"
      confirmText={`View Linked ${dependencyName}`}
      open={props.isOpen}
      onClose={props.closeConfirmation}
      onConfirm={handleConfirm}
    >
      <div className={styles.content}>
          This storage pool is currently being used in {getUsedIn()}. A storage pool
          must be unlinked in order to be deleted.
      </div>
    </ConfirmDialog>
  );
});
