import React from "react";
import { LogoutTimer } from "../../../../api/entities/policy";
import { FormFieldLabel } from "../../../shared-ui/form/form-field-label/form-field-label";
import { FormFieldValue } from "../../../shared-ui/form/form-field-value/form-field-value";
import { FormField } from "../../../shared-ui/form/form-field/form-field";
import { Select } from "../../../shared-ui/select";
import SidePanelSection from "../../../shared-ui/side-panel-section/side-panel-section";
import YesNoButtonGroup from "../../../shared-ui/yes-no-button-group/yes-no-button-group";
import styles from "./offline-files-section.module.scss";

export interface IOfflineFilesSectionProps {
  autoFocus?: boolean;
  allowOfflineFiles: boolean;
  localAuthentication: boolean;
  logoutTimer: number;
  onChange: (changes: Partial<{ 
    allowOfflineFiles: boolean,
    localAuthentication: boolean,
    logoutTimer: number
  }>) => void;
}

export function OfflineFilesSection(props: IOfflineFilesSectionProps) {
  return (
    <SidePanelSection testId="offlineFiles" title="Offline Files">
      <FormField>
        <FormFieldLabel noLeftMargin={true} className={styles["label"]}>
          Can files be edited offline?
        </FormFieldLabel>
        <FormFieldValue>
          <YesNoButtonGroup
            autoFocus={props.autoFocus}
            value={props.allowOfflineFiles}
            onChange={(allowOfflineFiles) => props.onChange({ allowOfflineFiles })}
          />
        </FormFieldValue>
      </FormField>
      {props.allowOfflineFiles && (
        <>
          <FormField testId="local-authentication-field" className={styles["local-authentication-field"]}>
            <FormFieldLabel noLeftMargin={true} className={styles["label"]}>
              Enable local authentication?
            </FormFieldLabel>
            <FormFieldValue>
              <YesNoButtonGroup
                value={props.localAuthentication}
                onChange={(localAuthentication) => props.onChange({ localAuthentication })}
              />
            </FormFieldValue>
          </FormField>
          {props.localAuthentication && (
            <FormField testId="logout-after-field" className={styles["logout-after-field"]}>
              <FormFieldLabel noLeftMargin={true} className={styles["label"]}>
                Log out user after inactive for:
              </FormFieldLabel>
              <FormFieldValue>
                <Select
                  className={styles["logout-after-select"]}
                  value={props.logoutTimer}
                  onChange={(logoutTimer) => props.onChange( { logoutTimer } )}
                  options={[
                    { value: LogoutTimer.Minutes5, label: "5 minutes" },
                    { value: LogoutTimer.Minutes15, label: "15 minutes" },
                    { value: LogoutTimer.Minutes30, label: "30 minutes" },
                    { value: LogoutTimer.OneHour, label: "1 hour" },
                    { value: LogoutTimer.Forever, label: "Forever" }
                  ]}
                />
              </FormFieldValue>
            </FormField>
          )}
        </>
      )}
    </SidePanelSection>
  );
}