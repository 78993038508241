import React, {
  PropsWithChildren,
  useState,
  useEffect,
  useCallback,
} from "react";
import styles from "./popup.module.scss";
import IComponentProps from "../common/component-props";
import { APP_ROOT_ELEMENT_ID } from "../../../constants/common";
import ReactDOM from "react-dom";
import _ from "underscore";
import clsx from "clsx";

export enum PopupType {
  /**Open popup to the left side from parent */
  Left,
  /**Set with the same as parent element */
  FitToParent,
}

export interface IPopupProps
  extends Omit<IComponentProps, "disabled" | "style"> {
  show: boolean;
  popupType?: PopupType;
}

export default function Popup(props: PropsWithChildren<IPopupProps>) {
  const [anhorEl, setAnhorEl] = useState(null as HTMLDivElement),
    [top, setTop] = useState(0),
    [left, setLeft] = useState(0),
    [bodyWidth, setBodyWidth] = useState(0),
    [bodyHeight, setBodyHeight] = useState(0),
    [anhorWidth, setAnhorWidth] = useState(0);

  const updatePosition = useCallback(() => {
    if (anhorEl && bodyWidth) {
      const rect = anhorEl.getBoundingClientRect();
      setTop(
        rect.y + bodyHeight > window.innerHeight &&
          rect.y - rect.height - bodyHeight > 0
          ? rect.y - rect.height - bodyHeight
          : rect.y
      );
      setLeft(
        props.popupType === PopupType.Left
          ? rect.x + rect.width - bodyWidth
          : rect.x
      );
    }
  }, [anhorEl, bodyWidth, bodyHeight, props.popupType]);

  useEffect(() => {
    const onViewChanged = _.debounce(updatePosition, 100);
    if (props.show) {
      window.addEventListener("resize", onViewChanged);
      window.addEventListener("scroll", onViewChanged);
    }
    return () => {
      window.removeEventListener("resize", onViewChanged);
      window.removeEventListener("scroll", onViewChanged);
    };
  }, [props.show, updatePosition]);

  useEffect(() => {
    updatePosition();
  }, [updatePosition]);

  if (!props.show) {
    return null;
  }

  function onRefAnhor(element: HTMLDivElement) {
    setAnhorEl(element);
    setAnhorWidth(element?.clientWidth);
  }

  function onRefBody(element: HTMLDivElement) {
    if (element) {
      const rect = element.getBoundingClientRect();
      setBodyWidth(rect.width);
      setBodyHeight(rect.height);
      element.focus();
    }
  }

  return (
    <div ref={onRefAnhor}>
      {ReactDOM.createPortal(
        <div
          className={clsx(styles.root, props.className)}
          style={{
            top: top,
            left: left,
            [props.popupType === PopupType.FitToParent
              ? "width"
              : "minWidth"]: anhorWidth,
          }}
          data-testid={props.testId}
          ref={onRefBody}
        >
          {props.children}
        </div>,
        document.getElementById(APP_ROOT_ELEMENT_ID)
      )}
    </div>
  );
}
