import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import _ from "underscore";
import { IPage } from "../../../../api/entities/page";
import { StorageType } from "../../../../api/entities/storage-node";
import { IStorageNodeV2 } from "../../../../api/entities/storage-node-v2";
import { IStoragePool, IStoragePoolCreateRequest, IStoragePoolUpdateRequest } from "../../../../api/entities/storage-pool";
import { getSecurityLevels, IStoragePoolV2DetailsItem } from "../../../../api/entities/storage-pool-v2";
import { ApiVersionNumber } from "../../../../api/services/data-service";
import { StorageNodeV2Service } from "../../../../api/services/storage-node-v2-service";
import { StoragePoolRouteAction, StoragePoolRouteParam } from "../../../../constants/routes";
import { storagePoolV1ThunkActions, storagePoolV2ThunkActions } from "../../../../redux/actions/storage-pool";
import { IAppState } from "../../../../redux/reducers";
import { storageNodeServiceFactory } from "../../../../utils/factories/storage-node-service-factory";
import { storagePoolServiceFactory } from "../../../../utils/factories/storage-pool-service-factory";
import { FormMode, formUtils, useField } from "../../../../utils/form-utils";
import { useApi } from "../../../../utils/hooks/use-api";
import { validators } from "../../../../utils/validators";
import DetailsPanel from "../../../shared-ui/details-panel/details-panel";
import { IStoragePoolNode, validateStoragePoolNodes } from "../../sections/storage-nodes-section/storage-nodes-section";
import { IStoragePoolForm, IStoragePoolFormValue, StoragePoolForm, storagePoolFormDefaultValue } from "../../storage-pool-form/storage-pool-form";
import styles from "./storage-pool-panel.module.scss";
import { OwnerInfoSection } from "../../../v2/common/sections/details-owner-info/details-owner-info";
import { Formatters } from "../../../../helper/formatters";

function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, void, Action>) {
    return {
        fetchPageV1: () => dispatch(storagePoolV1ThunkActions.entity.fetchPage()),
        fetchPageV2: () => dispatch(storagePoolV2ThunkActions.entity.fetchPage())
    };
}

export interface IStoragePoolPanelProps extends ReturnType<typeof mapDispatchToProps> {
    mode?: FormMode;
    embedded: boolean;
    onClose: () => void;
    version: ApiVersionNumber;
}

export const StoragePoolPanel = connect(null, mapDispatchToProps)((props: IStoragePoolPanelProps) => {
    const { idOrAction } = useParams<{
        [StoragePoolRouteParam.IdOrAction]: string
    }>();

    const [storagePoolApi, isStoragePoolLoading] = useApi();
    const [storageNodeApi] = useApi();

    const version = props.version;

    const open = props.embedded ?
        props.mode === FormMode.Create :
        (version === 1 ? Number(idOrAction) >= 0 : !!idOrAction);

    const isPoolCreation = props.embedded ?
        props.mode === FormMode.Create :
        version === 1 ? Number(idOrAction) === 0 : idOrAction === StoragePoolRouteAction.New;

    const form: IStoragePoolForm = {
        name: useField<string>(storagePoolFormDefaultValue.name, [validators.required("Storage Pool Name is required")]),
        storagePoolNodes: useField<IStoragePoolNode[]>(storagePoolFormDefaultValue.storagePoolNodes, [validateStoragePoolNodes]),
        contentSecurityLevel: useField<number>(storagePoolFormDefaultValue.contentSecurityLevel, []),
        keySecurityLevel: useField<number>(storagePoolFormDefaultValue.keySecurityLevel, []),
        metaSecurityLevel: useField<number>(storagePoolFormDefaultValue.metaSecurityLevel, []),
    };

    const [storagePool, setStoragePool] = useState<any>(null);

    const storageNodeService: any = storageNodeServiceFactory.getStorageNodeService(version);

    const fetchPage = version === 1 ? props.fetchPageV1 : props.fetchPageV2;

    function close(): void {
        props.onClose();
    }

    const storagePoolService: any = storagePoolServiceFactory.getStoragePoolService(version);

    function handleCreateStoragePool(createRequest: IStoragePoolCreateRequest | IStoragePoolCreateRequest<string>) {
        return storagePoolApi(storagePoolService.addStoragePool(createRequest)).then((pool) => {
            fetchPage();
            close();
            return pool;
        });
    }

    function handleUpdateStoragePool(updateRequest: IStoragePoolUpdateRequest) {
        return storagePoolApi(storagePoolService.update(updateRequest)).then((pool) => {
            fetchPage();
            close();
            return pool;
        });
    }

    function loadStoragePoolNodes(storagePool: any): Promise<any[]> {
        let storagePoolNodes = [];

        if (version === 1) {
            const storageNodes = _.unique([
                ...storagePool.ContentStorageNodes,
                ...storagePool.KeyStorageNodes,
                ...storagePool.MetaStorageNodes
            ], n => n.id);
            storagePoolNodes = storageNodes.map(node => ({
                node,
                types: {
                    storeContent: storagePool.ContentStorageNodes.some(n => n.id === node.id),
                    storeKey: storagePool.KeyStorageNodes.some(n => n.id === node.id),
                    storeMetadata: storagePool.MetaStorageNodes.some(n => n.id === node.id),
                }
            }));
            return new Promise((resolve) => resolve(storagePoolNodes));
        }

        return storageNodeApi((storageNodeService as StorageNodeV2Service).getPage({ pool: storagePool.id }))
            .then((storageNodePage: IPage<IStorageNodeV2>) => {
                let res = storageNodePage.data.map(node => ({
                    node,
                    types: {
                        storeContent: node.types.includes(StorageType.Content),
                        storeKey: node.types.includes(StorageType.Key),
                        storeMetadata: node.types.includes(StorageType.Meta),
                    }
                }));
                return res;
            });
    }

    function loadStoragePool() {
        const id = version === 1 ? Number(idOrAction) : idOrAction;

        return storagePoolApi(storagePoolService.get(id)).then((storagePool: IStoragePool | IStoragePoolV2DetailsItem) => {
            const securityLevels = getSecurityLevels(storagePool);

            setStoragePool(storagePool);
            loadStoragePoolNodes(storagePool)
                .then(storagePoolNodes => {
                    formUtils.setFormValue<IStoragePoolFormValue>(form, {
                        name: storagePool.name,
                        ...securityLevels,
                        storagePoolNodes: storagePoolNodes
                    });
                });
        });
    }

    useEffect(() => {
        if (open) {
            if (isPoolCreation) {
                formUtils.setFormValue<IStoragePoolFormValue>(form, storagePoolFormDefaultValue);
            } else {
                loadStoragePool();
            }
        }
        // eslint-disable-next-line
    }, [open]);

    return (
        <DetailsPanel
            isLoading={isStoragePoolLoading}
            open={open}
            onCloseClick={close}
            contentClassName={styles.content}
            title={isPoolCreation ? "Add New Storage Pool" : "Manage Storage Pool"}
            description={Number(idOrAction) > 0 && (<div className={styles.id}><strong>ID: </strong>{Number(idOrAction)}</div>)}
        >
            {open && (
                <>
                    {
                        !isPoolCreation && !!storagePool && <>
                            <OwnerInfoSection entity={storagePool}/>
                            <div>Created At: {Formatters.formatDateTime(storagePool?.createdAt)}</div>
                        </>
                    }
                    <StoragePoolForm
                        mode={isPoolCreation ? FormMode.Create : FormMode.Edit}
                        form={form}
                        storagePoolId={Number(idOrAction)}
                        onCreateStoragePool={handleCreateStoragePool}
                        onUpdateStoragePool={handleUpdateStoragePool}
                    />

                </>
            )}
        </DetailsPanel>
    );
});
