import React, { PropsWithChildren } from "react";
import styles from "./page-title.module.scss";

export interface IPageTitleProps {
  title?: string;
}

const PageTitle = (props: PropsWithChildren<IPageTitleProps>) => {
  return (
    <div className={styles.root} data-testid="page-title">
      <h1 data-testid="page-title-text" className={styles.title}>
        {props.title}
      </h1>
      {props.children && <div className={styles.content}>{props.children}</div>}
    </div>
  );
};

export default PageTitle;
