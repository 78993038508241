import { StorageProviderType } from "../../../api/entities/storage-node-v2";
import IOption from "../../shared-ui/common/option";
import { IFilterProps, SelectFilter } from "../../shared-ui/filters/select-filter";

const storageProviderForFilterToNameMap = {
    "S3": "S3",
    "GCS": "Google Cloud Storage",
    "ABS": "Azure Blob Storage",
};

export function ProviderFilter(props: IFilterProps<StorageProviderType, IOption<StorageProviderType>>) {

    function getStorageProviderOptions(): IOption<StorageProviderType>[] {
        return Object.keys(storageProviderForFilterToNameMap).map(x => ({ value: x as StorageProviderType, label: storageProviderForFilterToNameMap[x] }));
    }

    return SelectFilter({
        testId: "provider-filter",
        label: "Provider",
        optionAsValue: true,
        options: () => Promise.resolve(getStorageProviderOptions()),
        ...props
    });
}
