import { ISharingProfile, ISharingProfileCreateRequest, ISharingProfileListItem, ISharingProfileUpdateRequest, ISharingProfileDeleteRequest, IUpdateUsersRequest } from './../entities/sharing-profile';
import { IPage } from "../entities/page";
import { DataService } from "./data-service";
import { ISharingProfilesRequestParams } from '../entities/sharing-profile';

class SharingProfileService extends DataService {
  constructor() {
    super("sharing/profile");
  }

  getPage(requestParams: ISharingProfilesRequestParams): Promise<IPage<ISharingProfileListItem>> {
    return this.api.get<IPage<ISharingProfileListItem>>(null, requestParams);
  }

  get(id: number): Promise<ISharingProfile> {
    return this.api.get<ISharingProfile>(id, null);
  }

  create(createRequest: ISharingProfileCreateRequest): Promise<ISharingProfile> {
    return this.api.post<ISharingProfile>("", createRequest);
  }

  update(updateRequest: ISharingProfileUpdateRequest): Promise<ISharingProfile> {
    return this.api.putConditionally<ISharingProfile>(updateRequest.id, updateRequest);
  }

  updateUsers(sharingProfileId: number, updateUsersRequest: IUpdateUsersRequest): Promise<ISharingProfile> {
    return this.api.postConditionally(`update-users/${sharingProfileId}`, updateUsersRequest);
  }

  delete(deleteRequest: ISharingProfileDeleteRequest): Promise<void> {
    return this.api.post("delete", deleteRequest);
  }

  save(sharingProfile: ISharingProfile, ignoreFolderName?: boolean): Promise<ISharingProfile> {
    if (sharingProfile.id) {
      return this.update(this.getUpdateRequest(sharingProfile, ignoreFolderName));
    }
    return this.create(this.getCreateRequest(sharingProfile, ignoreFolderName));
  }

  private getCreateRequest(sharingProfile: ISharingProfile, ignoreFolderName?: boolean): ISharingProfileCreateRequest {
    return {
      spaceLimitMegabytes: sharingProfile.spaceLimitMegabytes,
      storagePoolId: sharingProfile.StoragePool.id,
      consoleName: sharingProfile.consoleName,
      folderName: sharingProfile.folderName,
      canEdit: sharingProfile.canEdit,
      ignoreFolderName,
      users: sharingProfile.users.map(user => ({ id: user.id})),
      retainDeletedFiles: sharingProfile.retainDeletedFiles,
      retainPreviousVersion: sharingProfile.retainPreviousVersion,
      deletedFilesRetentionMegabytes: sharingProfile.deletedFilesRetentionMegabytes,
      previousVersionRetentionMegabytes: sharingProfile.previousVersionRetentionMegabytes,
      deletedFilesRetentionDays: sharingProfile.deletedFilesRetentionDays,
      previousVersionRetentionDays: sharingProfile.previousVersionRetentionDays,
    };
  }

  private getUpdateRequest(sharingProfile: ISharingProfile, ignoreFolderName?: boolean): ISharingProfileUpdateRequest {
    return {
      ...this.getCreateRequest(sharingProfile, ignoreFolderName),
      id: sharingProfile.id,
      updatedAt: new Date(sharingProfile.updatedAt)
    };
  }
}

export const sharingProfileService = new SharingProfileService();
