import React, { useEffect, useState } from "react";
import _ from "underscore";
import { IPage } from "../../../../../api/entities/page";
import { defaultStorageNodeFields, IStorageNodesV2RequestParams, IStorageNodeV2 } from "../../../../../api/entities/storage-node-v2";
import { IStoragePoolV2DetailsItem } from "../../../../../api/entities/storage-pool-v2";
import { StorageNodeV2Service } from "../../../../../api/services/storage-node-v2-service";
import { getDefaultRequestParams } from "../../../../../redux/reducers/entity";
import { storageNodeServiceFactory } from "../../../../../utils/factories/storage-node-service-factory";
import { FormMode, formUtils, IFormField, useField } from "../../../../../utils/form-utils";
import { useApi } from "../../../../../utils/hooks/use-api";
import TooltipQuestion from "../../../../shared-ui/tooltip-question";
import { IStoragePoolNode, StorageNodesSection } from "../../../../storage-pools/sections/storage-nodes-section/storage-nodes-section";

export interface IPoolInfoSectionProps {
    className?: string;
    storagePool: IStoragePoolV2DetailsItem;
}

interface IStoragePoolNodesForm {
    storagePoolNodes: IFormField<IStoragePoolNode[]>;
}

interface IStoragePoolNodesFormValue {
    storagePoolNodes: IStoragePoolNode[];
}

export function getStoragePoolNodes(storagePool: IStoragePoolV2DetailsItem, storageNodePage: IPage<IStorageNodeV2>) {
    let storageNodeDict = storageNodePage.data.reduce((acc, x) => ({ [x.id]: x, ...acc }), {});

    const storageNodes = _.unique([
        ...storagePool.contentStorageNodes,
        ...storagePool.keyStorageNodes,
        ...storagePool.metaStorageNodes
    ], n => n.id)
        .sort((x, y) => x.id.localeCompare(y.id))
        .map(x => storageNodeDict[x.id]);

    const getTypes = (nodeId: string) => ({
        storeContent: storagePool.contentStorageNodes.some(n => n.id === nodeId),
        storeKey: storagePool.keyStorageNodes.some(n => n.id === nodeId),
        storeMetadata: storagePool.metaStorageNodes.some(n => n.id === nodeId),
    });

    return storageNodes.map(node => ({
        node,
        types: getTypes(node.id)
    }));
}

const STORAGE_NODE_TOOLTIP = 'Security Level / Redundancy Level';

export function PoolStorageNodesInfoSection(props: IPoolInfoSectionProps) {
    const { storagePool } = props;
    const [requestParams, setRequestParams] = useState<IStorageNodesV2RequestParams>({ ...getDefaultRequestParams(), fields: defaultStorageNodeFields });
    const [api] = useApi();

    const storageNodeService = storageNodeServiceFactory.getStorageNodeService(2) as StorageNodeV2Service;
    const form: IStoragePoolNodesForm = {
        storagePoolNodes: useField<IStoragePoolNode[]>([])
    };

    useEffect(() => {
        if (!!storagePool) {
            const updatedRequestParams = { ...requestParams, pool: storagePool.id, pageSize: undefined };
            setRequestParams(updatedRequestParams);

            api(storageNodeService.getPage(updatedRequestParams))
                .then((storageNodePage: IPage<IStorageNodeV2>) => {
                    let storagePoolNodes = getStoragePoolNodes(storagePool, storageNodePage);
                    formUtils.setFormValue<IStoragePoolNodesFormValue>(form, { storagePoolNodes });
                });
        }
        // eslint-disable-next-line
    }, [storagePool]);

    return (
        storagePool &&
        <div className={props.className}>
            <StorageNodesSection
                mode={FormMode.ReadOnly}
                storagePoolNodes={form.storagePoolNodes}
            />

            <div>
                <span>Content </span>
                <span>({storagePool.contentSecurityLevel}/{storagePool.contentRedundancyLevel})</span>
                <TooltipQuestion>{STORAGE_NODE_TOOLTIP}</TooltipQuestion>
            </div>

            <div>
                <span>Key </span>
                <span>({storagePool.keySecurityLevel}/{storagePool.keyRedundancyLevel})</span>
            </div>

            <div>
                <span>Metadata </span>
                <span>({storagePool.metaSecurityLevel}/{storagePool.metaRedundancyLevel})</span>
            </div>
        </div>
    );
}
