import { useEffect, useMemo, useRef, useState } from "react";
import { catchException } from "../../redux/actions/common";

export function useApi(): [<TData>(
    promise: Promise<TData>, 
    onReject?: (reason: any) => void,
    onFinally?: () => void,
    onCancel?: () => void) => Promise<TData>, boolean] {
  const [loading, setLoading] = useState<number>(0);
  const canceled = useRef(false);
  const cancelHandlers = useMemo(() => [], []);

  useEffect(() => {
    return function cancel() {
      canceled.current = true;

      cancelHandlers.forEach(cancelHandler => {
        cancelHandler();
      });
    };
  }, [cancelHandlers]);
  
  const callApi = function <TData>(promise: Promise<TData>, 
      onReject?: (reason: any) => void, 
      onFinally?: () => void,
      onCancel?: () => void): Promise<TData> {

    setLoading(l => l + 1);
    if (onCancel) {
      cancelHandlers.push(onCancel);
    }

    return new Promise<TData>((resolve) => {
      promise.then((data) => {
        if (!canceled.current) {
          resolve(data);
        }
      }).catch((reason: any) => {
        if (!canceled.current) {
          (onReject || catchException())(reason);
        }
      }).finally(() => {
        if (!canceled.current) {
          setLoading(l => l - 1);
          onFinally && onFinally();
          if (onCancel) {
            cancelHandlers.splice(cancelHandlers.indexOf(onCancel), 1);
          }
        }
      });
    });
  };

  return [callApi, !!loading];
}