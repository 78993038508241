import { IFileStats } from "./file-stats";
import { IUserShort } from './user';
import { IRequestParams } from './page';
import { ISharingProfileListItem } from './sharing-profile';
import { IConditionalUpdateRequest } from '../services/api-service';
import { IRepoDataMigration } from './data-migration';

export interface ISharingInstanceBase extends IFileStats {
    id: number;
    folderName: string;
    updatedAt: string;
    createdAt: string;
    deletedAt?: string;
    SharingPolicy: ISharingProfileListItem;
    dataMigrations?: IRepoDataMigration[];
    repoURI: string;
}

export interface ISharingInstanceShort extends ISharingInstanceBase {
    usedSpaceMb: number;
    managersCount: number;
    firstManagerEmail: string;
    sharingProfileName: string;
}

export interface ISharingInstance extends ISharingInstanceBase {
    users: ISharingInstanceUser[];
}

export interface ISharingInstanceUser extends IUserShort {
    uId: string;
    permission: SharingInstancePermission;
}

export enum SharingInstancePermission {
    Manager = 1,
    Reader = 2,
    Writer = 3
}

export const permissionToRoleMap = {
    [SharingInstancePermission.Manager]: "Manage",
    [SharingInstancePermission.Writer]: "Edit",
    [SharingInstancePermission.Reader]: "Read",

};

export interface ISharingInstanceUpdateRequest extends IConditionalUpdateRequest {
    folderName: string;
    users: { uid: string, permission: SharingInstancePermission}[];
}

export interface ISharingInstanceFilterParams {
    q?: string,
    sharingProfile?: number,
    exceedUsageMb?: number;
}

export interface ISharingInstancesRequestParams extends 
    IRequestParams<SharingInstanceSortField>, 
    ISharingInstanceFilterParams {
    fields?: (keyof ISharingInstanceShort)[];
}

export interface ISharingInstanceDeleteRequest {
    ids: { id: number, lastUpdated: string }[];
}

export enum SharingInstanceSortField {
    FolderName = "folderName",
    FirstManagerEmail = "firstManagerEmail",
    SharingProfile = "sharingProfile",
    Policy = "policy",
    Status = "status",
    CreationDate = "creationDate",
    UsedSpaceBytes = "usedspacebytes",
    UsedSpacePercentage = "usedspacepercentage",
    FileStatus = "filestatus",
}