import React, { useEffect } from "react";
import TooltipQuestion from "../../../shared-ui/tooltip-question";
import { FormField } from "../../../shared-ui/form/form-field/form-field";
import { FormFieldLabel } from "../../../shared-ui/form/form-field-label/form-field-label";
import { FormFieldValue } from "../../../shared-ui/form/form-field-value/form-field-value";
import { Select } from "../../../shared-ui/select";
import IOption from "../../../shared-ui/common/option";
import Checkbox from "../../../shared-ui/input/checkbox";
import SpaceSelector from "../../../shared-ui/input/space-selector";
import TimeIntervalSelector from "../../../storage-profiles/manage-storage-profile/time-interval-selector/time-interval-selector";
import styles from "./retention-section.module.scss";
import clsx from "clsx";
import SidePanelSection from "../../../shared-ui/side-panel-section/side-panel-section";
import { enumUtils } from "../../../../utils/enum-utils";
import { IRetentionPolicy } from "../../../../api/entities/retention-policy";

export enum RetentionPolicy {
  DeleteOnly = 1,
  RetainAndThenDelete = 2,
  RetainOnly = 3,
}

export const retentionPolicyToTextMap = {
  [RetentionPolicy.DeleteOnly]: "Delete-only",
  [RetentionPolicy.RetainAndThenDelete]: "Retain and then Delete",
  [RetentionPolicy.RetainOnly]: "Retain-only"
};

export const defaultRetentionSetting: IRetentionSettings = {
  deletedFilesMode: null,
  isDeletedFilesMbEnabled: true,
  isDeletedFilesDaysEnabled: true,
  deletedFilesDays: null,
  deletedFilesMb: null,
  revisionsMode: null,
  isRevisionsMbEnabled: true,
  isRevisionsDaysEnabled: true,
  revisionsDays: null,
  revisionsMb: null
};

export interface IRetentionSettingsErrors {
  deletedFilesMb?: string;
  revisionsMb?: string;
  deletedFilesDays?: string;
  revisionsDays?: string;
  retention?: string;
}
export interface IRetentionSettings {
  deletedFilesMode: RetentionPolicy;
  isDeletedFilesMbEnabled: boolean;
  isDeletedFilesDaysEnabled: boolean;
  deletedFilesDays: number;
  deletedFilesMb: number;

  revisionsMode: RetentionPolicy;
  isRevisionsMbEnabled: boolean;
  isRevisionsDaysEnabled: boolean;
  revisionsDays: number;
  revisionsMb: number;
}

function getRetentionMode(
  isRetentionEnabled: boolean,
  retentionMegabytes: number,
  retentionDays: number
) {
  if (isRetentionEnabled) {
    if (retentionMegabytes === 0 && retentionDays === 0) {
      return RetentionPolicy.DeleteOnly;
    }
    if (retentionMegabytes || retentionDays) {
      return RetentionPolicy.RetainAndThenDelete;
    }
  }
  return RetentionPolicy.RetainOnly;
}

export function isRetentionSettingsValid(
  settings: IRetentionSettings
): boolean {
  if (settings.deletedFilesMode === null || settings.revisionsMode === null) {
    return false;
  }

  if (
    settings.deletedFilesMode === RetentionPolicy.RetainAndThenDelete &&
    ((settings.isDeletedFilesDaysEnabled && settings.deletedFilesDays <= 0) ||
      (settings.isDeletedFilesMbEnabled && settings.deletedFilesMb <= 0))
  ) {
    return false;
  }

  if (
    settings.revisionsMode === RetentionPolicy.RetainAndThenDelete &&
    ((settings.isRevisionsDaysEnabled && settings.revisionsDays <= 0) ||
      (settings.isRevisionsMbEnabled && settings.revisionsMb <= 0))
  ) {
    return false;
  }

  return true;
}

export function validateRetentionSettings(settings: IRetentionSettings): string {
  return isRetentionSettingsValid(settings) ? null: "Invalid Retention Settings";
}

export function getRetentionSettings(
  policy: IRetentionPolicy
): IRetentionSettings {
  const result = {
    deletedFilesMode: getRetentionMode(
      policy.retainDeletedFiles,
      policy.deletedFilesRetentionMegabytes,
      policy.deletedFilesRetentionDays
    ),
    revisionsMode: getRetentionMode(
      policy.retainPreviousVersion,
      policy.previousVersionRetentionMegabytes,
      policy.previousVersionRetentionDays
    ),

    isDeletedFilesMbEnabled:
      policy.retainDeletedFiles && policy.deletedFilesRetentionMegabytes > 0,
    isRevisionsMbEnabled:
      policy.retainPreviousVersion &&
      policy.previousVersionRetentionMegabytes > 0,

    isDeletedFilesDaysEnabled:
      policy.retainDeletedFiles && policy.deletedFilesRetentionDays > 0,
    isRevisionsDaysEnabled:
      policy.retainPreviousVersion && policy.previousVersionRetentionDays > 0,

    deletedFilesDays: policy.deletedFilesRetentionDays || null,
    revisionsDays: policy.previousVersionRetentionDays || null,

    deletedFilesMb: policy.deletedFilesRetentionMegabytes || null,
    revisionsMb: policy.previousVersionRetentionMegabytes || null,
  };

  if (!result.isDeletedFilesMbEnabled && !result.isDeletedFilesDaysEnabled) {
    result.isDeletedFilesMbEnabled = true;
    result.isDeletedFilesDaysEnabled = true;
  }

  if (!result.isRevisionsMbEnabled && !result.isRevisionsDaysEnabled) {
    result.isRevisionsMbEnabled = true;
    result.isRevisionsDaysEnabled = true;
  }

  return result;
}

export function getRetentionPolicy(
  settings: IRetentionSettings
): IRetentionPolicy {
  return {
    retainDeletedFiles:
      settings.deletedFilesMode === RetentionPolicy.RetainAndThenDelete ||
      settings.deletedFilesMode === RetentionPolicy.DeleteOnly,
    retainPreviousVersion:
      settings.revisionsMode === RetentionPolicy.RetainAndThenDelete ||
      settings.revisionsMode === RetentionPolicy.DeleteOnly,

    deletedFilesRetentionMegabytes:
      settings.deletedFilesMode === RetentionPolicy.DeleteOnly
        ? 0
        : settings.isDeletedFilesMbEnabled
        ? settings.deletedFilesMb
        : null,
    previousVersionRetentionMegabytes:
      settings.revisionsMode === RetentionPolicy.DeleteOnly
        ? 0
        : settings.isRevisionsMbEnabled
        ? settings.revisionsMb
        : null,

    deletedFilesRetentionDays:
      settings.deletedFilesMode === RetentionPolicy.DeleteOnly
        ? 0
        : settings.isDeletedFilesDaysEnabled
        ? settings.deletedFilesDays
        : null,
    previousVersionRetentionDays:
      settings.revisionsMode === RetentionPolicy.DeleteOnly
        ? 0
        : settings.isRevisionsDaysEnabled
        ? settings.revisionsDays
        : null,
  };
}

const retentionOptions: IOption[] = enumUtils.values(RetentionPolicy).map((retentionMode) => ({
  value: retentionMode,
  label: retentionPolicyToTextMap[retentionMode]
}));

const REVISIONS_SPACE_TOOLTIP =
    "Myota will permanently delete your file version history once it exceed the defined space.",
  REVISIONS_TIME_TOOLTIP =
    "Myota will permanently delete your file version history once it exceed the allotted timeframe.",
  DELETED_FILES_SPACE_TOOLTIP =
    "Myota will permanently delete recoverable deleted files once they exceed the defined space.",
  DELETED_FILES_TIME_TOOLTIP =
    "Myota will permanently delete recoverable deleted files once they exceed the allotted timeframe.";

interface IRetentionSectionProps {
  autoFocus?: boolean;
  retentionSettings: IRetentionSettings;
  errors: IRetentionSettingsErrors;
  disabled?: boolean;
  onChange: (value: IRetentionSettings) => void;
  onError: (errors: Partial<IRetentionSettingsErrors>) => void;
}

export function RetentionSection(props: IRetentionSectionProps) {
  const retention = props.retentionSettings;

  function onFieldChanged(value: Partial<IRetentionSettings>) {
    const newSettings = {...props.retentionSettings, ...value};
    props.onChange(newSettings);
    validateField(Object.keys(value)[0] as keyof IRetentionSettings, value);    
  }

  function validateField(
    fieldName: keyof IRetentionSettings,
    value: Partial<IRetentionSettings>
  ) {
    switch (fieldName) {
      case "deletedFilesMb":
      case "revisionsMb":
      case "deletedFilesDays":
      case "revisionsDays":
        props.onError({
          [fieldName]:
            value[fieldName] <= 0
              ? "Entered value must be more than '0'"
              : null,
        });
    }
  }

  useEffect(() => {
    const isValid = isRetentionSettingsValid(props.retentionSettings);
    if (isValid === !!props.errors.retention) {
      props.onError({retention: isRetentionSettingsValid(props.retentionSettings)
        ? null
        : "Invalid Retention Settings"});
    }
    // eslint-disable-next-line
  }, [props.retentionSettings]);

  return (
    <SidePanelSection
      title="Retention Policy"
      testId="storage-retention"
      className={clsx(styles["root"], styles.input)}
    >
      <FormField testId="deletion-retention">
        <FormFieldLabel
          data-testid="storage-retention-description"
          noLeftMargin={true}
        >
          How would you like to handle a user’s file deletion?
          <TooltipQuestion>
            When a user deletes files, Myota can allow them to recover these
            files. Parameters for permanent deletion can be set.
          </TooltipQuestion>
        </FormFieldLabel>
        <FormFieldValue className={styles["retention-mode"]}>
          <Select
            testId="retention-mode"
            value={retention.deletedFilesMode}
            placeholder="Choose one.."
            autoFocus={props.autoFocus}
            disabled={props.disabled}
            options={retentionOptions}
            onChange={deletedFilesMode => onFieldChanged({ deletedFilesMode })}
          />
        </FormFieldValue>
      </FormField>

      {retention.deletedFilesMode === RetentionPolicy.RetainAndThenDelete && (
        <>
          <FormField
            className={clsx(
              styles["retention-param"],
              !retention.isDeletedFilesMbEnabled &&
                styles["retention-param--disabled"]
            )}
          >
            <FormFieldLabel className={styles["retention-param-label"]} noLeftMargin={true}>
              <Checkbox
                testId="retention-size"
                disabled={props.disabled}
                label="Automatically clear deleted files when they exceed:"
                checked={retention.isDeletedFilesMbEnabled}
                onChange={value =>
                  onFieldChanged({
                    isDeletedFilesMbEnabled:
                      value || !retention.isDeletedFilesDaysEnabled,
                  })
                }
              />
              <TooltipQuestion>{DELETED_FILES_SPACE_TOOLTIP}</TooltipQuestion>
            </FormFieldLabel>
            <FormFieldValue className={styles["retention-param-value"]}>
              <SpaceSelector
                testId="deleted-files-exceed-selector"
                hideMb={true}
                value={retention.deletedFilesMb}
                onChange={deletedFilesMb => onFieldChanged({ deletedFilesMb })}
                disabled={props.disabled || !retention.isDeletedFilesMbEnabled}
                tooltip={DELETED_FILES_SPACE_TOOLTIP}
                hasError={!!props.errors.deletedFilesMb}
              />
              {props.errors.deletedFilesMb && (
                <div className="error-text">{props.errors.deletedFilesMb}</div>
              )}
            </FormFieldValue>
          </FormField>

          <FormField
            className={clsx(
              styles["retention-param"],
              !retention.isDeletedFilesDaysEnabled &&
                styles["retention-param--disabled"]
            )}
          >
            <FormFieldLabel className={styles["retention-param-label"]}>
              <Checkbox
                testId="retention-age"
                disabled={props.disabled}
                label="Automatically clear deleted files older than:"
                checked={retention.isDeletedFilesDaysEnabled}
                onChange={value =>
                  onFieldChanged({
                    isDeletedFilesDaysEnabled:
                      value || !retention.isDeletedFilesMbEnabled,
                  })
                }
              />
              <TooltipQuestion>{DELETED_FILES_TIME_TOOLTIP}</TooltipQuestion>
            </FormFieldLabel>
            <FormFieldValue className={styles["retention-param-value"]}>
              <TimeIntervalSelector
                tooltip={DELETED_FILES_TIME_TOOLTIP}
                value={retention.deletedFilesDays}
                disabled={props.disabled || !retention.isDeletedFilesDaysEnabled}
                onChange={deletedFilesDays =>
                  onFieldChanged({ deletedFilesDays })
                }
              />
              {props.errors.deletedFilesDays && (
                <div className="error-text">
                  {props.errors.deletedFilesDays}
                </div>
              )}
            </FormFieldValue>
          </FormField>
        </>
      )}

      <FormField
        className={styles["revisions-retention"]}
        testId="revisions-retention"
      >
        <FormFieldLabel
          data-testid="storage-retention-description"
          noLeftMargin={true}
        >
          How would you like to handle a user’s file revisions?
          <TooltipQuestion>
            Myota can store file versions for users to retrieve, if needed. If
            you allow this, parameters for deletion can be set.
          </TooltipQuestion>
        </FormFieldLabel>
        <FormFieldValue className={styles["retention-mode"]}>
          <Select
            testId="retention-mode"
            value={retention.revisionsMode}
            placeholder="Choose one.."
            disabled={props.disabled}
            options={retentionOptions}
            onChange={revisionsMode => onFieldChanged({ revisionsMode })}
          />
        </FormFieldValue>
      </FormField>

      {retention.revisionsMode === RetentionPolicy.RetainAndThenDelete && (
        <>
          <FormField
            className={clsx(
              styles["retention-param"],
              !retention.isRevisionsMbEnabled && styles["retention-param--disabled"]
            )}
          >
            <FormFieldLabel className={styles["retention-param-label"]}>
              <Checkbox
                testId="retention-size"
                disabled={props.disabled}
                label="Automatically clear file revisions when they exceed:"
                checked={retention.isRevisionsMbEnabled}
                onChange={value =>
                  onFieldChanged({
                    isRevisionsMbEnabled:
                      value || !retention.isRevisionsDaysEnabled,
                  })
                }
              />
              <TooltipQuestion>{REVISIONS_SPACE_TOOLTIP}</TooltipQuestion>
            </FormFieldLabel>
            <FormFieldValue className={styles["retention-param-value"]}>
              <SpaceSelector
                testId="deleted-files-exceed-selector"
                hideMb={true}
                value={retention.revisionsMb}
                onChange={revisionsMb => onFieldChanged({ revisionsMb })}
                disabled={props.disabled || !retention.isRevisionsMbEnabled}
                tooltip={REVISIONS_SPACE_TOOLTIP}
              />
              {props.errors.revisionsMb && (
                <div className="error-text">{props.errors.revisionsMb}</div>
              )}
            </FormFieldValue>
          </FormField>

          <FormField
            className={clsx(
              styles["retention-param"],
              !retention.isRevisionsDaysEnabled &&
                styles["retention-param--disabled"]
            )}
          >
            <FormFieldLabel className={styles["retention-param-label"]}>
              <Checkbox
                testId="retention-age"
                disabled={props.disabled}
                label="Automatically clear file revisions older than:"
                checked={retention.isRevisionsDaysEnabled}
                onChange={value =>
                  onFieldChanged({
                    isRevisionsDaysEnabled:
                      value || !retention.isRevisionsMbEnabled,
                  })
                }
              />
              <TooltipQuestion>{REVISIONS_TIME_TOOLTIP}</TooltipQuestion>
            </FormFieldLabel>
            <FormFieldValue className={styles["retention-param-value"]}>
              <TimeIntervalSelector
                tooltip={REVISIONS_TIME_TOOLTIP}
                value={retention.revisionsDays}
                disabled={props.disabled || !retention.isRevisionsDaysEnabled}
                onChange={revisionsDays => onFieldChanged({ revisionsDays })}
              />
              {props.errors.revisionsDays && (
                <div className="error-text">{props.errors.revisionsDays}</div>
              )}
            </FormFieldValue>
          </FormField>
        </>
      )}
    </SidePanelSection>
  );
}
