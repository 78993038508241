import { useState } from "react";

export function usePopup<TData = void>(): [boolean, (data?: TData) => void, () => void, TData] {
  const [popupState, setPopupState] = useState<{open: boolean, data: TData}>({ open: false, data: null });
  return [
    popupState.open, 
    (data?: TData) => setPopupState({ open: true, data}), 
    () => setPopupState({ open: false, data: null}),
    popupState.data
  ];
}
