import React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IApplicationCredentialsListItem } from "../../../../api/entities/application";
import { applicationCredentialsActions } from "../../../../redux/actions/application-credentials";
import { IAppState } from "../../../../redux/reducers";
import MoreOptions from "../../../shared-ui/more-options";
import { IPopupMenuOption } from "../../../shared-ui/popup-menu";

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IAppState, void, Action>
) => {
  return {
    openDeleteConfirmation: (credentialsItem: IApplicationCredentialsListItem) =>
        dispatch(applicationCredentialsActions.deleteConfirmation.open(credentialsItem))
  };
};

export interface IMoreOptionProps
    extends ReturnType<typeof mapDispatchToProps> {
  data: IApplicationCredentialsListItem;
}

export const MoreOption = connect(null, mapDispatchToProps)((props: IMoreOptionProps) => {
  function openDeleteConfirmation() {
    props.openDeleteConfirmation(props.data);
  }

  function getOptions(): IPopupMenuOption[] {
    const result = [
      {
        label: "Delete",
        onClick: openDeleteConfirmation
      },
    ];
    return result;
  }

  return (
    <MoreOptions
      options={getOptions()}
    />
  );
});
