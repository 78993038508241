import { IEmailSettings } from "../../components/email-settings-panel/email-settings-panel";
import { IAuthParams } from "../entities/auth";

enum LocalStorageKey {
  AuthParams = "citrix-share-file/auth-params",
  TokenExpirationDate = "citrix-share-file/token-expiration-date",
  EmailSettings = "citrix-share-file/email-settings",
}

class LocalStorageService {
  setEmailSettings(emailSettings: IEmailSettings) {
    localStorage.setItem(LocalStorageKey.EmailSettings, JSON.stringify(emailSettings));
  }
  getEmailSettings(): IEmailSettings {
    return JSON.parse(localStorage.getItem(LocalStorageKey.EmailSettings) || null);
  }

  setAuthParams(value: IAuthParams) {
    localStorage.setItem(LocalStorageKey.AuthParams, JSON.stringify(value));
  }
  getAuthParams(): IAuthParams {
    return JSON.parse(localStorage.getItem(LocalStorageKey.AuthParams) || null);
  }

  setTokenExpirationDate(value: Date) {
    localStorage.setItem(LocalStorageKey.TokenExpirationDate, value.toISOString());
  }
  getTokenExpirationDate(): Date {
    const isoDate = localStorage.getItem(LocalStorageKey.TokenExpirationDate);
    return isoDate ? new Date(isoDate) : null;
  }
}
export const localStorageService = new LocalStorageService();
