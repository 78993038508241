import React, { useEffect, useState } from "react";
import { FormMode, formUtils, IFormField, useField } from "../../../../../../utils/form-utils";
import { validators } from "../../../../../../utils/validators";
import DetailsPanel from "../../../../../shared-ui/details-panel/details-panel";
import styles from "./invite-panel.module.scss";
import { EnterpriseConsoleUserPermission, InviteEmailRequest } from "../../../../../../api/entities/invitation";
import { FlagEnumCheckboxGroup } from "../../flag-enum-checkbox-group/flag-enum-checkbox-group";
import { FormPanel } from "../../../../../shared-ui/form/form-panel/form-panel";
import { MultiEmail } from "../../../../../shared-ui/input/multi-email/multi-email";
import { FormFieldValue } from "../../../../../shared-ui/form/form-field-value/form-field-value";
import { FormField } from "../../../../../shared-ui/form/form-field/form-field";
import { FormFieldError } from "../../../../../shared-ui/form/form-field-error/form-field-error";
import Checkbox from "../../../../../shared-ui/input/checkbox";

export interface IInvitePanelProps {
    mode?: FormMode;
    fetchInvitations?: () => void;
    onClose?: () => void;
    handleInviteUsers: (createRequest: InviteEmailRequest[]) => Promise<void>;
}

export interface IInviteUsersForm {
    emails: IFormField<string[]>;
    permission: IFormField<EnterpriseConsoleUserPermission>;
    isAdmin: IFormField<boolean>;
}

export interface IInviteUsersFormValue {
    emails: string[];
    permission: EnterpriseConsoleUserPermission;
    isAdmin: boolean;
}

export const inviteUsersFormDefaultValue: IInviteUsersFormValue = {
    emails: [],
    permission: EnterpriseConsoleUserPermission.Read,
    isAdmin: false,
}

function InvitePanel(props: IInvitePanelProps) {
    const open = props.mode === FormMode.Create;

    const [formError, setFormError] = useState<string>(null);

    const form: IInviteUsersForm = {
        emails: useField<string[]>(inviteUsersFormDefaultValue.emails, [validators.required("Emails are required")]),
        permission: useField<EnterpriseConsoleUserPermission>(inviteUsersFormDefaultValue.permission),
        isAdmin: useField<boolean>(inviteUsersFormDefaultValue.isAdmin),
    };
    const adminPermission = EnterpriseConsoleUserPermission.Read | EnterpriseConsoleUserPermission.Create | EnterpriseConsoleUserPermission.Modify | EnterpriseConsoleUserPermission.Delete;
    const [readonlyPermissionValues, setReadonlyPermissionValues] = useState<EnterpriseConsoleUserPermission[]>([EnterpriseConsoleUserPermission.Read]);

    const [permissionValue, setPermissionValue] = useState<number>(inviteUsersFormDefaultValue.permission);

    function close() {
        setFormError('');

        if (!!props.onClose) {
            props.onClose();
        }
    }

    function save() {
        formUtils.validateAll(form).then((isValid) => {
            if (isValid) {
                const formValue = formUtils.getFormValue<IInviteUsersFormValue>(form);
                const data = formValue.emails.map(x => ({
                    email: x,
                    permission: formValue.permission,
                    isAdmin: formValue.isAdmin
                }));

                props.handleInviteUsers(data).then(() => {
                    if (!!props.onClose) {
                        props.onClose();
                    }
                });
            }
        })
    }

    useEffect(() => {
        if (open) {
            formUtils.setFormValue<IInviteUsersFormValue>(form, inviteUsersFormDefaultValue);
            setReadonlyPermissionValues([EnterpriseConsoleUserPermission.Read]);
            setPermissionValue(EnterpriseConsoleUserPermission.Read);
        }
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        console.log(`changing the change`, form.permission.value, form.isAdmin.value);

        if (!!form.isAdmin.value) {
            const formValue = formUtils.getFormValue<IInviteUsersFormValue>(form);
            formUtils.setFormValue<IInviteUsersFormValue>(form, {
                permission: adminPermission,
                ...formValue
            });
            setPermissionValue(adminPermission);
            setReadonlyPermissionValues([EnterpriseConsoleUserPermission.Read, EnterpriseConsoleUserPermission.Create, EnterpriseConsoleUserPermission.Modify, EnterpriseConsoleUserPermission.Delete]);
        } else {
            setReadonlyPermissionValues([EnterpriseConsoleUserPermission.Read]);
        }
        // eslint-disable-next-line
    }, [form.isAdmin.value]);

    return (
        <>
            <DetailsPanel
                open={open}
                onCloseClick={close}
                isLoading={false}
                title={"Invite Users"}
                className={styles.panel}
            >
                {open && (
                    <FormPanel
                        okButtonText="Invite New Users"
                        okButtonClick={save}
                        okButtonDisabled={!!formError}
                        errorMessage={formError}
                    >
                        <FormField className={styles.field} label="Emails:">
                            <FormFieldValue>
                                <MultiEmail
                                    testId="emails"
                                    emails={form.emails.value}
                                    onChange={form.emails.onChange}
                                    onBlur={form.emails.onChange}
                                    onValidated={form.emails.onError}
                                />
                            </FormFieldValue>
                            <FormFieldError error={form.emails.controlError || form.emails.error}/>
                        </FormField>

                        <FormField className={styles.field} label="Permissions:">
                            <FlagEnumCheckboxGroup
                                enumValue={permissionValue}
                                enumType={EnterpriseConsoleUserPermission}
                                readonlyValues={readonlyPermissionValues}
                                onChange={(value: number) => {
                                    setPermissionValue(value);

                                    const formValue = formUtils.getFormValue<IInviteUsersFormValue>(form);
                                    formUtils.setFormValue<IInviteUsersFormValue>(form, {
                                        permission: value as EnterpriseConsoleUserPermission,
                                        ...formValue
                                    });
                                }}
                            />
                        </FormField>

                        <FormField>
                            <Checkbox
                                testId="invite-admins"
                                field={form.isAdmin}
                                label="Invited users will be administrators"
                            />
                        </FormField>
                    </FormPanel>
                )}
            </DetailsPanel>
        </>
    );
}

export default InvitePanel;
