import React from "react";
import IComponentProps from "../../common/component-props";
import { IFormField } from "../../../../utils/form-utils";
import { InputBase } from "../input-base/input-base";

export interface ITextInputProps extends IComponentProps {
  testId: string;
  value?: string;
  name?: string;
  placeholder?: string;
  type?: "text" | "password";
  autoFocus?: boolean;
  readOnly?: boolean;
  hasError?: boolean;
  field?: IFormField<string>;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

export default function TextInput(props: ITextInputProps) {
  return (
    <InputBase
      {...props}
    />
  );
}
