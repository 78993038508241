import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import Button from "../button";
import IComponentProps from "../common/component-props";
import styles from "./button-group-countdown.module.scss";

export interface IButtonGroupCountdownProps extends IComponentProps {
    shouldStartCountdown: boolean;
    okButtonText?: string;
    cancelButtonText?: string;

    onCountdownButtonStarted?: () => void;
    onOkButtonClicked: () => void;
    onCancelButtonClicked: () => void;
}

const countdownTimerDefaultValue = 120;

export function ButtonGroupCountdown (props: IButtonGroupCountdownProps) {
    const [countdownTimer, setCountdownTimer] = useState<number>(countdownTimerDefaultValue);
    const [isRestarted, setIsRestarted] = useState<boolean>(false);
    const intervalId = useRef<NodeJS.Timeout>(null);

    useEffect(() => {
        if (props.shouldStartCountdown || isRestarted) {
            setCountdownTimer(countdownTimerDefaultValue);

            let timer = countdownTimerDefaultValue;
            intervalId.current = setInterval(() => {
                if (timer > 0) {
                    timer--;
                }
                if (timer === 0) {
                    clearInterval(intervalId.current);
                }
                setCountdownTimer(timer);
            }, 1000);

            !!props.onCountdownButtonStarted && props.onCountdownButtonStarted();
            setIsRestarted(false);
            return () => clearInterval(intervalId.current);
        }
        // eslint-disable-next-line
    }, [props.shouldStartCountdown, isRestarted]);


    function formatCountdownTimer() {
        const pad = (x: number) => x < 10 ? `0${x}` : x;

        const seconds = countdownTimer % 60;
        const minutes = (countdownTimer - seconds) / 60;
        return `${minutes}:${pad(seconds)}`;
    }

    return (
        <section className={clsx(
            styles.root,
            props.className
        )}>
            <div className={styles['ok-btn-section']}>
                <div className={styles.countdown}>
                    {formatCountdownTimer()}
                </div>

                <Button
                    testId="ok-button"
                    colors="primary"
                    disabled={countdownTimer !== 0}
                    onClick={() => {
                        if (countdownTimer === 0) {
                            props.onOkButtonClicked();
                            setIsRestarted(true);
                        }
                    }}
                >
                    {props.okButtonText ?? "Ok"}
                </Button>
            </div>

            <Button
                testId="cancel-button"
                colors="primary"
                onClick={() => {
                    props.onCancelButtonClicked();
                    clearInterval(intervalId.current);
                }}
            >
                {props.cancelButtonText ?? "Cancel"}
            </Button>
        </section>
    );
}
