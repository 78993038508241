import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IAuthenticationRequest } from "../../../../api/entities/user";
import { KEYS } from "../../../../constants/keys";
import { accountActions, accountThunkActions } from "../../../../redux/actions/account";
import { IAppState } from "../../../../redux/reducers";
import { formUtils, IFormField, useField } from "../../../../utils/form-utils";
import { useNavigate } from "../../../../utils/hooks/use-navigate";
import { validators } from "../../../../utils/validators";
import Button from "../../../shared-ui/button";
import { InfoDialog } from "../../../shared-ui/dialogs/common/info-dialog/info-dialog";
import { FormField } from "../../../shared-ui/form/form-field/form-field";
import { FormPanel } from "../../../shared-ui/form/form-panel/form-panel";
import { PasswordInput } from "../../../shared-ui/input/password-input/password-input";
import TextInput from "../../../shared-ui/input/text-input/text-input";
import styles from "./signin.module.scss";

export interface ISignInForm {
  email: IFormField<string>;
  password: IFormField<string>;
}

export interface ISignInFormValue {
  email: string;
  password: string;
}

export const signInFormDefaultValue: ISignInFormValue = {
  email: "",
  password: ""
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IAppState, void, Action>
) => ({
  login: (accountRequest: IAuthenticationRequest) =>
      dispatch(accountThunkActions.login(accountRequest)),
  removeLoginError: () =>
      dispatch(accountActions.removeLoginErrorAction())
});

const mapStateToProps = (state: IAppState) => ({
  user: state.users.currentUser,
  loginFailed: state.account.loginFailed,
  loginError: state.account.loginError,
});

interface ISignInProps
    extends ReturnType<typeof mapStateToProps>,
        ReturnType<typeof mapDispatchToProps> {
  open: boolean;
  onClose: () => void;

  onSignUpPopUpWindowOpen: () => void;
  onResetPasswordPopUpWindowOpen: () => void;
}


export const SignIn = connect(mapStateToProps, mapDispatchToProps)((props: ISignInProps) => {
  const navigate = useNavigate();

  const form: ISignInForm = {
    email: useField<string>(signInFormDefaultValue.email, [validators.required("Email is required"), (value: string) => validators.isEmail(value) ? "" : `${value} is not a valid email address`]),
    password: useField<string>(signInFormDefaultValue.password),
  };
  const formError = formUtils.getFormError(form);

  function handleSignIn() {
    formUtils.validateAll(form).then((isValid) => {
      if (isValid) {
        props.removeLoginError();
        const formValue = formUtils.getFormValue<ISignInFormValue>(form);
        props.login(formValue);
      }
    });
  }

  useEffect(() => {
    if (props.user) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  useEffect(() => {
    props.removeLoginError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.email.value, form.password.value]);

  return (
      <InfoDialog
          testId='sign-in-popup'
          title='Sign in with your email and password'
          showOkButton={false}
          open={props.open}
          onClose={props.onClose}
      >
        <div
            className={styles.content}
            onKeyDown={(e) => {
              if (e.key === KEYS.Escape) {
                props.onClose();
              }
              if (e.key === KEYS.Enter && !formError) {
                handleSignIn();
              }
            }}
        >
          <FormPanel
              className={styles.root}
              okButtonText="Sign in"
              okButtonClick={handleSignIn}
              okButtonDisabled={!!formError}
              errorMessage={formError ||
                  (props.loginFailed && (props.loginError ?? "Incorrect login data. Please check your credentials."))
              }
          >
            <FormField label="Email" testId="email-field">
              <TextInput
                  className={styles.email}
                  testId="email-input"
                  field={form.email}
                  placeholder="name@host.com"
                  autoFocus={true}
              />
            </FormField>

            <FormField label="Password" testId="password-field">
              <PasswordInput
                  className={styles.password}
                  testId="password-input"
                  field={form.password}
                  placeholder="Password"
              />

              <Button
                  className={styles["reset-link"]}
                  colors="link"
                  onClick={() => {
                    props.onResetPasswordPopUpWindowOpen();
                    props.onClose();
                  }}
                  testId="button-reset-password"
              >
                Forgot your password?
              </Button>
            </FormField>
          </FormPanel>

          <div>
            Need an account?

            <Button
                colors="link"
                onClick={() => {
                  props.onSignUpPopUpWindowOpen();
                  props.onClose();
                }}
                testId="button-sign-up">
              Sign up
            </Button>
          </div>
        </div>
      </InfoDialog>
  );
});
