import IOption from "../../shared-ui/common/option";
import { userGroupService } from "../../../api/services/user-group-service";
import { UserGroupType } from "../../../api/entities/user-group";
import { IFilterProps, SelectFilter } from "./select-filter";

export interface IUserGroupFilterProps extends IFilterProps<number, IOption<number> | number>{
  optionAsValue?: boolean;
}

export function UserGroupFilter(props: IUserGroupFilterProps) {
  return SelectFilter({
    testId: "user-group-filter",
    label: "ADP Group",
    optionAsValue: props.optionAsValue,
    options: () => userGroupService.getPage({ typeFilter: UserGroupType.ActiveDirectory }).then((page) => page.data.map((group) => ({
        value: group.id,
        label: group.name
      }
    ))),
    ...props
  });
}
