import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import { IStyleableComponentProps, ITestableComponent } from "../../common/component-props";
import styles from "./form-field-label.module.scss";

export interface IFormFieldLabelProps extends ITestableComponent, IStyleableComponentProps {
  noLeftMargin?: boolean;
}

export function FormFieldLabel(props: PropsWithChildren<IFormFieldLabelProps>) {
  return (
    <div 
      data-testid={props.testId || "form-field-label"} 
      className={clsx(styles.label, props.className, props.noLeftMargin && styles["label--no-left-margin"])}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
