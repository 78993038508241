import React from "react";
import LeftPanel from "../../shared-ui/left-panel/left-panel";
import styles from "./left-nav.module.scss";
import { StepsIndicator } from "../steps-indicator/steps-indicator";

interface ILeftNavProps {}

export function LeftNav(prop: ILeftNavProps) {

  return (
    <LeftPanel className={styles.root} testId="initial-setup-nav">
      <StepsIndicator/>
    </LeftPanel>
  );
}