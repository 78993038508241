/**
 * Represents a ShareFile Item: an element that can exist inside a ShareFile Folder. 
 * This include Files and Folders, as well as other classes that are listed inside directory structures: Links, Notes and Symbolic Links.
 * 
 * https://api.sharefile.com/docs/resource?name=ShareFile.Api.Models.Item
 */
export interface IItem {
  Id: string;
}

export enum UploadMethod {
  Standart = "standart"
}

export interface IUpload2Request {
  Method: UploadMethod; 
  FileSize: number;
  FileName: string;
}

export interface IUpload2Response {
  ChunkUri: string;
}