import { IEntity, IHasOwner, IIdName, IIdNameDateTime } from "./id-name";
import { IDeletedParam, IPageRequestParams, IQueryStrictParams } from "./page";

export interface IApplicationFilterParams extends IQueryStrictParams {
    bucket?: string;
}

export interface IApplicationPageRequestParams extends
    IPageRequestParams,
    IApplicationFilterParams,
    IDeletedParam {

    fields?: (keyof IApplicationListItem)[];
}

export interface IApplicationListItem extends IIdNameDateTime<string>, IHasOwner {
    buckets?: IEntity<string>[];
}

export interface IApplicationDetailsItem extends IIdNameDateTime<string>, IHasOwner {
    credentials: IApplicationCredentialsListItem[];
}

export interface IApplicationUpdateRequest extends IIdName<string> {
}

export interface IApplicationCreateRequest extends Omit<IApplicationUpdateRequest, 'id'> {
}


export interface IApplicationCredentialsFilterParams {
    q?: string;
    strict?: boolean;
    application?: string;
    bucket?: string;
}

export interface IApplicationCredentialsPageRequestParams extends
    IPageRequestParams,
    IDeletedParam,
    IApplicationCredentialsFilterParams {

    fields?: (keyof IApplicationCredentialsListItem)[];
}

export interface IApplicationCredentialsListItem extends IIdNameDateTime<string>, IHasOwner {
    scope: ApplicationCredentialsScopeName;
    accessKey: string;
    bucketId: string;
    applicationId: string;

    bucket?: IIdName<string>;
    application?: IIdName<string>;
}

export interface IApplicationCredentialsCreateRequest extends Pick<IApplicationCredentialsListItem, 'name' | 'scope' | 'bucketId'> {}

export interface IApplicationCredentialsCreateResponse extends IApplicationCredentialsCreateRequest, IEntity<string> {
    accessKey: string;
    accessSecret: string;
}

export enum ApplicationCredentialsScope {
    Read = 1,
    Write = 2,
    Full = 3
}

export type ApplicationCredentialsScopeName = Uncapitalize<keyof typeof ApplicationCredentialsScope>;

export enum ApplicationSortField {
    ApplicationName = "name"
}

export enum ApplicationCredentialsSortField {
    Name = "name",
    Scope = "scope",
    Bucket = "bucket",
    Application = "application",
}

export const ApplicationCredentialListDefaultFields: (keyof IApplicationCredentialsListItem)[] = ['id', 'name', 'scope', 'bucket', 'application', 'ownerId', 'owner'];
